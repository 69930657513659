import React, { FC, useContext, useEffect, useState } from 'react'
import {
  Button,
  Col,
  DatePicker,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment, { Moment } from 'moment'
import { isNil } from 'ramda'

import LoadingSpinner from '../../../../components/LoadingSpinner'
import getDifferenceDays from '../../../../helpers/getDifferenceDays'
import { normalizeText } from '../../../../helpers/lowercaseStringButFirst'
import { getCityName } from '../../../../helpers/travel-page'
import { useEventSave } from '../../../../hooks/analytics.hook'
import { AutoCompleteOption } from '../../../../types/page-types/home-page.types'
import { TravelPageContext } from '../../../travel-page/Provider'
import { NewTripTabProps } from '../../types'

import styles from './styles.module.css'

const { Option } = Select

const { RangePicker } = DatePicker

const TwoWays: FC<NewTripTabProps> = ({
  handleClick,
  loading,
  loginLoading,
}) => {
  const {
    options: { destinies, origins, routes },
    search,
    setSearch,
  } = useContext(TravelPageContext)
  const {
    origin,
    destiny,
    departureCity,
    departureDate,
    destinationCity,
    numberOfSeats,
    returnDate,
  } = search

  const [departureMoment, setDepartureMoment] = useState<Moment | null>(null)
  const [returnMoment, setReturnMoment] = useState<Moment | null>(null)
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false)

  const onTravelSearch = () => {
    useEventSave('busqueda_de_viajes', {
      rutas_consultadas: `${origin}-${destiny}`,
    })
    handleClick()
  }

  useEffect(() => {
    const today = moment(new Date(), 'DD-MM-YYYY')
    search.departureDate &&
      setDepartureMoment(
        moment(search.departureDate, 'DD-MM-YYYY') >= today
          ? moment(search.departureDate, 'DD-MM-YYYY')
          : null,
      )

    search.returnDate &&
      setReturnMoment(
        moment(search.returnDate, 'DD-MM-YYYY') >= today
          ? moment(search.returnDate, 'DD-MM-YYYY')
          : null,
      )
  }, [])

  const setDualDates = (dates: Moment[]) => {
    if (dates) {
      setSearch({
        ...search,
        departureDate: dates[0].format('DD-MM-YYYY'),
        returnDate: dates[1] && dates[1].format('DD-MM-YYYY'),
      })

      setDepartureMoment(dates[0])
      setReturnMoment(dates[1])
    }
  }

  const isButtonDisabled = () =>
    isNil(departureDate) ||
    isNil(returnDate) ||
    isNil(departureCity) ||
    isNil(destinationCity)

  useEffect(() => {
    if (moment.utc(departureDate).isAfter(returnDate)) {
      setSearch({ ...search, returnDate: '' })
      setReturnMoment(null)
    }
  }, [departureDate])

  return loading ? (
    <LoadingSpinner size="50px" />
  ) : (
    <Row className={styles.row} gutter={16}>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 1 }}
        sm={8}
        md={8}
        lg={5}>
        <p className={styles.labelFont}>Origen</p>
        <Select
          allowClear
          className={styles.input}
          placeholder={'¿Desde dónde viajas?'}
          onChange={value => {
            setSearch({
              ...search,
              departureCity: (value as string) ?? '',
              origin:
                getCityName(routes, value as string)?.originCityName ?? '',
            })
          }}
          filterOption={(text, option) =>
            normalizeText(option?.children).includes(normalizeText(text))
          }
          defaultValue={search.origin === '' ? undefined : search.origin}
          optionFilterProp="children"
          showArrow={false}
          showSearch
          size="large">
          {origins.map(({ id, name }: AutoCompleteOption) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 2 }}
        sm={8}
        md={8}
        lg={5}>
        <p className={styles.labelFont}>Destino</p>
        <Select
          allowClear
          notFoundContent={<p>Selecciona un viaje de ida primero..</p>}
          className={styles.input}
          placeholder={'¿A dónde vas?'}
          onChange={value =>
            setSearch({
              ...search,
              destinationCity: (value as string) ?? '',
              destiny:
                getCityName(routes, value as string, 'destiny')
                  ?.destinationCityName ?? '',
            })
          }
          filterOption={(text, option) =>
            normalizeText(option?.children).includes(normalizeText(text))
          }
          defaultValue={search.destiny === '' ? undefined : search.destiny}
          optionFilterProp="children"
          showArrow={false}
          showSearch
          size="large">
          {destinies.map(({ id, name }: AutoCompleteOption) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 6 }}
        sm={16}
        md={16}
        lg={8}>
        <p className={styles.labelFont}>Fechas del viaje </p>
        <Tooltip
          title={
            !isDatePickerOpen &&
            getDifferenceDays(departureMoment, returnMoment)
          }>
          <div className={styles.rangePicker}>
            <RangePicker
              className={styles.datePicker}
              format="DD/MM/YYYY"
              defaultValue={[moment(), moment()]}
              value={[departureMoment, returnMoment]}
              onOpenChange={value => setDatePickerOpen(value)}
              onChange={e => setDualDates(e)}
              locale={locale}
              placeholder={['Fecha de ida', 'Fecha de vuelta']}
              disabledDate={current => current < moment.utc().startOf('day')}
              inputReadOnly={true}
              renderExtraFooter={() => (
                <div>{getDifferenceDays(departureMoment, returnMoment)}</div>
              )}
            />
          </div>
        </Tooltip>
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 5 }}
        sm={8}
        md={8}
        lg={2}>
        <p className={styles.labelFont}>Pasajeros</p>
        <InputNumber
          className={styles.inputNumber}
          onChange={value =>
            setSearch({
              ...search,
              numberOfSeats: (value as string) ?? '',
            })
          }
          max={45}
          min={1}
          size={'large'}
          value={!isNaN(parseInt(numberOfSeats)) ? parseInt(numberOfSeats) : 1}
        />
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 6 }}
        sm={8}
        md={8}
        lg={4}>
        <Button
          className={isButtonDisabled() ? styles.disabledButton : styles.button}
          disabled={isButtonDisabled()}
          loading={loginLoading}
          onClick={onTravelSearch}>
          Buscar viajes
        </Button>
      </Col>
    </Row>
  )
}

export default TwoWays

const condorStyles = {
  marginBottom: 5,
  marginLeft: 5,
  width: 65,
}

const jacStyles = { margin: 0, width: 55 }

const biobioStyles = {
  margin: 0,
  width: 60,
}

//

export { biobioStyles, condorStyles, jacStyles }

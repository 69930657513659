import React, { FC, useContext, useLayoutEffect, useState } from 'react'
import { Button, Col, Divider, Row } from 'antd'

import { numberWithSeparator } from '../../../../helpers/number.helper'
import { MyUserPageContext } from '../../Provider'
import { CecoRowProps } from '../../types'

import styles from './styles.module.css'

const CecoRow: FC<CecoRowProps> = ({
  costCenter: { _id, name, currentAccount },
}) => {
  const { costCenters, setCostCenters } = useContext(MyUserPageContext)
  const [width, setWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const deleteCeco = () => {
    const oldList = [...costCenters]
    const newList = oldList.filter(item => item._id !== _id)
    setCostCenters(newList)
  }

  const renderCols = (title: string, value: string) => {
    return (
      <Row align="middle" justify="space-around" gutter={10}>
        <Col xs={12}>
          <p className={styles.title}>{title}</p>
        </Col>
        <Col xs={12}>
          <p>{value}</p>
        </Col>
      </Row>
    )
  }

  const renderCecoMobile = () => {
    return (
      <div className={styles.cecoMobileContent}>
        <div className={styles.divider} />
        {renderCols('Cuenta corriente', currentAccount.name)}
        {renderCols(
          'Saldo',
          `$${numberWithSeparator(currentAccount?.balance) ?? '~~'}`,
        )}
        {renderCols(
          'Cupo',
          `$ ${numberWithSeparator(currentAccount?.totalAmount) ?? '~~'}`,
        )}
        {renderCols('Centro de costo', name)}
        <div className={styles.buttonContent}>
          <Button className={styles.deleteButton} onClick={deleteCeco}>
            Eliminar
          </Button>
        </div>
        <div className={styles.divider} />
      </div>
    )
  }

  if (width < 500) return renderCecoMobile()
  return (
    <>
      <Row align="middle" justify="space-around" gutter={10}>
        <Col>
          <p className={styles.title}>Cuenta corriente</p>
          <p>{currentAccount.name}</p>
        </Col>
        <Col>
          <p className={styles.title}>Saldo</p>
          <p className={styles.amountText}>
            ${numberWithSeparator(currentAccount?.balance) ?? '~~'}
          </p>
        </Col>
        <Col>
          <p className={styles.title}>Cupo</p>
          <p className={styles.amountText}>
            ${numberWithSeparator(currentAccount?.totalAmount) ?? '~~'}
          </p>
        </Col>
        <Col>
          <p className={styles.title}>Centro de costo</p>
          <p>{name}</p>
        </Col>
        <Col>
          <Button className={styles.deleteButton} onClick={deleteCeco}>
            Eliminar
          </Button>
        </Col>
      </Row>
      <Divider style={{ marginTop: '0.3rem', marginBottom: '0' }} />
    </>
  )
}

export default CecoRow

import React, { FC, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { message, Modal } from 'antd'

import DownloadTemplateButton from '../../../../components/PassengersDrawerUtils/downloadTemplateButton'
import GroupSelect from '../../../../components/PassengersDrawerUtils/GroupSelect'
import PassengersList from '../../../../components/PassengersDrawerUtils/PassengersList'
import StageButtons from '../../../../components/PassengersDrawerUtils/stageButtons'
import UploadPassengersButton from '../../../../components/PassengersDrawerUtils/uploadButtonPassenger'
import UploadSuccess from '../../../../components/PassengersDrawerUtils/UploadSuccess'
import { RolesEnum } from '../../../../helpers/enums'
import {
  COST_CENTERS_WITH_PASSENGERS,
  CREATE_PASSENGERS,
} from '../../../../queries'
import { SessionContext } from '../../../../services/session'
import { TravelPageContext } from '../../Provider'
import { Passenger, UploadPassengersProps } from '../../types'

const UploadPassengers: FC<UploadPassengersProps> = ({
  handleDrawerButton,
}) => {
  const { user } = useContext(SessionContext)
  const {
    search: { numberOfSeats },
    setQuotas,
    quotas,
  } = useContext(TravelPageContext)
  const [stage, setStage] = useState(1)
  const [costCenterId, setcostCenterId] = useState('')
  const [passengers, setPassengers] = useState<Passenger[]>([])

  const [createPassengersMutation, { loading }] = useMutation(
    CREATE_PASSENGERS,
    {
      refetchQueries: [{ query: COST_CENTERS_WITH_PASSENGERS }],
    },
  )

  const addPassengersToList = (passengers: Passenger[]) => {
    setPassengers(passengers)
    setStage(stage + 1)
  }

  const reset = () => {
    handleDrawerButton(0)
    setPassengers([])
    setStage(1)
  }

  const callCreatePassengersMutation = async (userRole: string) => {
    const passengerstoSave = passengers
      .filter(passenger => passenger.saved)
      .map(p => {
        if (
          userRole === RolesEnum.CCC ||
          userRole === RolesEnum.MULTI_RUT_BUYER ||
          userRole === RolesEnum.ADMIN_CCC ||
          userRole === RolesEnum.MULTI_RUT_ADMIN
        )
          p.costCenter = costCenterId
        else p.costCenter = null
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { company, saved, errorMessage, ...rest } = p
        return rest
      })

    try {
      const {
        data: { createPassengers },
      } = await createPassengersMutation({
        variables: {
          input: passengerstoSave,
        },
      })

      const newQuotas = [...quotas]

      createPassengers.forEach((passenger: Passenger, index: number) => {
        newQuotas[index].passenger = passenger
      })
      setQuotas(newQuotas)
      message.success('Pasajeros creados exitosamente.')
      setStage(stage + 1)
      setcostCenterId('')
    } catch (err) {
      message.error(err)
    }
  }

  const chargePassengers = async () => {
    const { role } = user

    if (role === 'CCC' || role === 'ADMIN_CCC')
      if (!costCenterId)
        return message.error('Debe seleccionar un centro de costo')

    if (passengers.some(passenger => !passenger.saved))
      Modal.confirm({
        title: 'Hay registros con Error. ¿Deseas continuar?',
        content: 'Los registros con mensajes de error no serán guardados.',
        onOk: () => callCreatePassengersMutation(role),
        okText: 'Continuar',
        cancelText: 'Cancelar',
      })
    else callCreatePassengersMutation(role)
  }
  const checkPassengersLimit = () => {
    const remaining = passengers.length - parseInt(numberOfSeats)
    passengers.length <= parseInt(numberOfSeats)
      ? chargePassengers()
      : message.error(`Debes remover ${remaining} pasajeros ya que solo se requiere
de ${numberOfSeats} para la asignación`)
  }

  const removePassenger = (passengerToRemove: Passenger) => {
    const list = passengers.filter(
      (passenger: Passenger) => passenger !== passengerToRemove,
    )
    setPassengers(list)
    if (list.length === 0) setStage(1)
  }

  return (
    <div style={{ width: '100%' }}>
      {(stage < 3 && user.role === 'CCC') || user.role === 'ADMIN_CCC' ? (
        <GroupSelect setSelectedCostCenter={setcostCenterId} />
      ) : null}
      {stage === 1 && (
        <>
          <UploadPassengersButton
            handleSelectedPassengers={addPassengersToList}
          />
          <DownloadTemplateButton />
        </>
      )}
      {stage === 2 && (
        <PassengersList
          passengers={passengers}
          removePassenger={removePassenger}
        />
      )}
      {stage === 3 && (
        <UploadSuccess
          passengersNumber={
            passengers.filter(passenger => passenger.saved).length
          }
        />
      )}
      <StageButtons
        loading={loading}
        stage={stage}
        saveAndAddPassengers={checkPassengersLimit}
        onCancelClick={reset}
      />
    </div>
  )
}

export default UploadPassengers

import React, { useEffect, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import { Col, Row } from 'antd'

import { Navbar } from '../../components/navbar/Navbar'
import { useEventSave } from '../../hooks/analytics.hook'
import {
  getUser,
  guestEmail,
  publicTokenInfo,
  tokenInfo,
} from '../../services/auth'
import { ButtonToNavbar } from '../landing-page/ButtonsToNavbar'

import styles from './styles.module.css'
import Footer from '../landing-page/new-landing/Footer'

const TermsAndConditionsPage: React.FC<RouteComponentProps> = () => {
  const linkMenu = [
    {
      id: 1,
      name: 'Inicio',
      link: '/inicio',
    },
    {
      id: 2,
      name: '¿Por qué Simplus?',
      link: '/inicio',
    },
    {
      id: 3,
      name: '¿Cómo funciona?',
      link: '/inicio',
    },
    {
      id: 4,
      name: 'Contáctanos',
      link: '/app/solicitud-demo',
    },
  ]

  const scrollTo = (
    ref: React.MutableRefObject<HTMLDivElement | null>,
  ): void => {
    setTimeout(
      () =>
        window.scrollTo({ top: ref.current?.offsetTop, behavior: 'smooth' }),
      100,
    )
  }

  useEffect(() => {
    const { user } = publicTokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'terminos_y_condiciones' as string

    if (user.email !== guestEmail) {
      useEventSave('vista_terminos_y_condiciones', {
        email_usuarios: user.email,
      })

      useEventSave('paginas_por_usuario', params as { [key: string]: string })
    }
  }, [])

  return (
    <div className={styles.baseContainer}>
      <div className={styles.topContent}>
        <Navbar
          ButtonOrOptions={ButtonToNavbar}
          linkMenu={linkMenu}
          scrollTo={scrollTo}
          active={0}
          isTermsAndCondition={true}
        />
      </div>
      <Row justify="center">
        <Col lg={12} xs={20} className={styles.container}>
          <h2 className={styles.firstTitle}>Condiciones contractuales</h2>
          <h2 className={styles.secondTitle}>Política de privacidad</h2>
          <p className={styles.text}>
            El acceso, navegación y realización de operaciones transaccionales
            en el Sitio, se regirá por los términos y condiciones que se
            establecen a continuación y por la legislación que al respecto rija
            en la República de Chile; lo anterior implica que todas las visitas
            que se realicen a dicho sitio y la navegación y operaciones que se
            realicen en éste se regirán por estas disposiciones y sometidas a
            dicha legislación.
          </p>
          <p className={styles.text}>
            Teniendo presente lo anterior, los términos y condiciones contenidos
            en este instrumento se entenderán parte integrante y se aplicarán a
            todos los actos y contratos que se ejecuten o celebren mediante los
            sistemas de oferta y comercialización comprendidos en el Sitio.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>
              ¿Qué es Simplus? Descripción del servicio:
            </b>
          </p>
          <p className={styles.text}>
            Simplus es una plataforma de viajes que tiene por objeto, entre
            otros, la venta de servicios de transporte de pasajeros, en adelante
            los “<b className={styles.boldText}>Servicios”</b>, prestados por
            terceros Operadores distintos de Simplus, facilitando el cobro y
            pago de los Servicios utilizados por sus clientes a través de medios
            de pagos tradicionales o de una línea de crédito.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>¿Quién es el Cliente?:</b>
          </p>
          <p className={styles.text}>
            El Cliente es toda persona jurídica que genere una cuenta en el
            Sitio, con objeto de comprar los Servicios que en él se ofrecen. El
            Cliente comprará los Servicios para sus trabajadores o contratistas,
            quienes tendrán la calidad de Pasajeros de los Operadores.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>¿Quiénes son los Operadores?:</b>
          </p>
          <p className={styles.text}>
            Los Operadores son empresas de transporte interurbano, distintos de
            Simplus, que ofrecen sus Servicios a través del Sitio, y llevan a
            cabo los mismos de la forma y en las oportunidades por ellos
            establecida y ofrecida a través de Simplus. Los Operadores son
            directamente responsables de cualquier perjuicio que sufra el
            Cliente en la prestación de los Servicios. El Cliente acepta que
            Simplus es solamente responsable de la confirmación de
            disponibilidad de pasajes, la reserva de los mismos y las ventas de
            boletos de los Servicios. Una vez realizada la venta, regirán los
            respectivos términos y condiciones de los correspondientes
            Operadores. Todos los términos del servicio en particular, para la
            relación contractual entre el Operador y el Cliente, serán los que
            los respectivos Operadores hayan establecido para sus propios
            servicios.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>
              Línea de crédito y cuenta corriente:
            </b>
          </p>
          <p className={styles.text}>
            El Cliente puede tener acceso a una línea de crédito por parte de
            Simplus, para ser utilizada exclusivamente en la compra de los
            Servicios, en adelante la “Línea de Crédito”. La existencia y/o el
            monto de la Línea de Crédito es único por Cliente y estará
            condicionado a una evaluación de riesgo a ser efectuada por Simplus
            o por quien este determine, y para lo cual se requerirá que el
            Cliente entregue la información que le sea solicitada para tales
            efectos.
          </p>
          <p className={styles.text}>
            Cuando el Cliente abra una Línea de Crédito se le asignará una o más
            cuentas corrientes, en adelante la “Cuenta Corriente” o las “Cuentas
            Corrientes” según sea el caso, asociada cada una a un código que la
            individualizará internamente y donde se registrarán todas las
            compras que el Cliente efectúe bajo la modalidad de crédito, el pago
            de servicios, y los saldos que permanezcan pendientes de pago.
          </p>
          <p className={styles.text}>
            Esta Línea de Crédito podrá ser distribuida en una o más Cuentas
            Corrientes como sea requerido por el Cliente, con el fin exclusivo
            de que sean destinadas por éste a la adquisición de pasajes para los
            Servicios.
          </p>
          <p className={styles.text}>
            Se deja expresamente establecido que por el hecho de la apertura de
            la Línea de Crédito, no se devengará ningún tipo de interés.
          </p>
          <p className={styles.text}>
            El Cliente declara que los datos proporcionados para la obtención de
            una línea de crédito, corresponden a información fidedigna y
            suficiente para la apertura de una Cuenta Corriente y para la
            facturación y cobranza de las compras a crédito que efectúe en
            Simplus, y se compromete a mantenerlos permanentemente actualizados.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Registro:</b>
          </p>
          <p className={styles.text}>
            El registro en el Sitio y el uso de contraseña es requisito
            obligatorio para realizar compras, y facilita el acceso
            personalizado, confidencial y seguro al sitio. Adicionalmente, será
            requisito para la realización de compras, la aceptación de los
            presentes términos y condiciones.
          </p>
          <p className={styles.text}>
            Para el proceso de registro en Simplus, el Cliente deberá completar
            el formulario disponible en el sitio web, permitiendo la creación de
            un usuario y contraseña la cual podrá ser modificada según requiera
            el Cliente.
          </p>
          <p className={styles.text}>
            Posterior al proceso de creación de cuenta, el Cliente recibirá un
            email de confirmación y verificación de su cuenta, luego de lo cual
            estará habilitado para utilizar el Sitio y efectuar compras a través
            de este.
          </p>
          <p className={styles.text}>
            El Cliente será el único responsable del cuidado y manejo del
            usuario y contraseña, y sólo la compartirá con la o las personas por
            él designada/s para ejecutar las compras en su nombre, en adelante
            el “<b className={styles.boldText}>Administrador</b>” o los “
            <b className={styles.boldText}>Administradores</b>”. La contraseña
            será personal e intransferible y cada Administrador tendrá la
            propia. Simplus no se hará responsable del mal uso de la contraseña.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Compra de pasajes:</b>
          </p>
          <p className={styles.text}>
            • El Cliente buscará en el Sitio todos los Servicios disponibles
            para el recorrido que éste requiera.
          </p>
          <p className={styles.text}>
            • Simplus mostrará los resultados disponibles según la búsqueda de
            viajes realizada por el Cliente. Los precios indicados en el Sitio
            comprenderán el valor total del mismo. En caso que se ofrezca un
            servicio adicional, se indicará su precio en forma específica.
          </p>
          <p className={styles.text}>
            • La cantidad de pasajes que un Cliente puede adquirir, en un mismo
            itinerario, está limitado a la disponibilidad de asientos del
            itinerario seleccionado.
          </p>
          <p className={styles.text}>
            • Las transacciones con tarjetas de crédito bancarias, son
            autorizadas por Transbank S.A. u el proveedor de servicios de pago
            que corresponda. El uso, condiciones de crédito, intereses y otras
            condiciones aplicables a las tarjetas de crédito, son de exclusiva
            responsabilidad de su ente emisor (banco).
          </p>
          <p className={styles.text}>
            • La compra se considerará finalizada una vez que Simplus reciba los
            fondos desde el medio de pago seleccionado por el Cliente y se emita
            el/los boleto de embarque, el cual se enviará automáticamente al
            correo electrónico registrado para estos efectos por el Cliente.
          </p>
          <p className={styles.text}>
            • Solicitamos siempre descargar los boletos desde la página de
            confirmación de compra o del ítem mis{' '}
            <b className={styles.boldText}>viajes</b>.
          </p>
          <p className={styles.text}>
            • Los boletos adquiridos en el Sitio, son para el operador, fecha y
            hora seleccionada por el Cliente al momento de la compra.
          </p>
          <p className={styles.text}>
            • Para embarcar, el Pasajero deberá presentar el boleto emitido al
            finalizar la compra. El boleto puede ser presentado de manera
            digital desde cualquier dispositivo móvil, permitiendo la correcta
            visualización de número de boleto y datos del pasajero.
          </p>
          <p className={styles.text}>
            <b className={styles.boldText}>
              • Simplus podrá actualizar, modificar o eliminar el contenido de
              los presentes términos y condiciones en cualquier tiempo, total o
              parcialmente, y sin previo aviso. Estas modificaciones y
              actualizaciones serán publicadas en este Sitio. Los Términos y
              Condiciones que rigen son los últimos publicados en el Sitio.
            </b>
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Devoluciones de ticket:</b>
          </p>
          <p className={styles.text}>
            En caso de que el Cliente requiriera la devolución de alguna compra
            realizada, deberá realizarla a través de Simplus con al menos 4
            horas de anticipación a la entrega del Servicio. La devolución no
            podrá ser realizada por el pasajero de manera directa, sino que será
            el Cliente quien deberá realizar la gestión en el Sitio. No se harán
            devoluciones si la solicitud se hace fuera de plazo. Si la compra
            fue realizada con cargo a la Línea de Crédito y la devolución fue
            solicitada con la anticipación antes señalada, la devolución se hará
            automáticamente y por el 100% de lo pagado, liberando el cupo y el
            asiento que fue comprado. En caso de solicitar devolución de un
            Servicio comprado a través de Transbank , el proceso tomará un plazo
            de 20 días hábiles es ser procesado. En caso de que el Servicio se
            hubiera pagado con cargo a una tarjeta bancaria de crédito o débito,
            la devolución será del 85% del valor cancelado.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Facturación:</b>
          </p>
          <p className={styles.text}>
            Simplus facturará mensualmente todas las transacciones realizadas
            por el Cliente en el mes precedente incluyendo las que estén
            pendientes de pago de meses anteriores, y adjuntará a cada factura
            una relación (listado) con la información, que permita al Cliente
            revisar cada imputación. Además el Cliente tendrá disponible en el
            Sitio el detalle de movimientos mensuales (compras, devoluciones,
            cancelaciones, etc.) Este detalle de los servicios prestados,
            constituirá respaldo suficiente del monto facturado y el Cliente lo
            acepta como tal por este acto.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Cancelaciones de viajes:</b>
          </p>
          <p className={styles.text}>
            En caso de cancelación, modificación o no presentación de servicios
            por parte del Operador, Simplus notificará al Cliente en cuanto
            reciba la notificación por el Operador. Sin perjuicio de lo
            anterior, el Operador está facultado para notificar directamente al
            pasajero , en cuyo caso deberá informar a Simplus esta notificación
            de igual manera, para que Simplus notifique al Cliente. El reembolso
            de dinero, será mediante descuento de dicho monto en la próxima
            factura que se emitirá al Cliente.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Gestión:</b>
          </p>
          <p className={styles.text}>
            Al utilizar este Sitio para comprar Servicios, el Cliente autoriza a
            Simplus a administrar el proceso de compra con el (los) Operadores
            correspondientes, en todo lo que respecte al pago de los Servicios,
            con el fin de garantizar que la transacción entre el Cliente y el
            (los) Operadores se realice correctamente.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Pago y Morosidad:</b>
          </p>
          <p className={styles.text}>
            El Cliente se compromete a pagar las facturas referidas
            precedentemente, dentro del plazo de vencimiento estipulado, es
            decir, 30 días contados desde la fecha de emisión de la factura. En
            caso de atraso Simplus se reserva el derecho a publicación de la
            deuda en el boletín comercial y bloqueo de la Cuenta y Línea de
            Crédito del Cliente.
          </p>
          <p className={styles.text}>
            En caso de que exista discrepancia o reclamos respecto del monto de
            la factura, el Cliente deberá pagar la totalidad de la factura del
            mes respectivo, y en simultaneo solicitar, mediante un formulario
            disponible en el Sitio, la revisión de la cuenta. Para esto, Simplus
            deberá proveer al Cliente todos los antecedentes necesarios para
            responder y satisfacer la duda planteada y de ser procedente emitir
            las notas de crédito que correspondan.
          </p>
          <p className={styles.text}>
            La falta de pago, o la simple mora o retraso en el pago oportuno de
            las facturas y/o de los saldos pendientes por parte del Cliente,
            será causal suficiente para que Simplus suspenda total o
            parcialmente las transacciones de dicho Cliente (bloqueo de Cuenta
            Corriente), hasta el pago total del saldo pendiente ajustado de
            conformidad a la aplicación de la tasa máxima que la ley permita
            estipular para operaciones no reajustable de menos de 90 días, sin
            perjuicio de que inicie las acciones de cobranza que estime
            pertinentes. Esto no restringe al Cliente a la realización de
            compras mediante medios de pago tradicionales (tarjetas de débito y
            crédito).
          </p>
          <p className={styles.text}>
            Simplus se reserva el derecho a ponerle término en cualquier tiempo
            y/o a cerrar la(s) respectiva(s) Cuenta(s) Corriente(s) sin
            expresión de causa y sin derecho a indemnización, lo que el Cliente
            acepta en este acto. Asimismo, el Cliente se reserva el derecho a
            dejar de utilizar el Sitio, a solicitar la eliminación de su
            usuario, y a cerrar la(s) respectiva(s) Cuenta(s) Corriente(s) sin
            expresión de causa y sin derecho a indemnización, una vez pagadas
            todas y cada uno de los valores adeudados a Simplus. Para lo
            anterior, bastará informar de tal situación mediante formulario
            existente en el Sitio, debiendo solicitar la liquidación de los
            montos adeudados.
          </p>
          <p className={styles.text}>
            Con el objeto de dar cumplimiento a la Ley N° 19.628, sobre
            protección de datos de carácter personal, el Cliente faculta
            irrevocablemente a Simplus, para que pueda dar a conocer la
            morosidad en el incumplimiento del pago de facturas con morosidad
            mayor a 60 días, proporcionando dicha información a cualquier
            registro o banco de datos personales, tales como SICOM (Sistema
            Consolidado de Morosidad), que procesa y publica DICOM S.A., con el
            objeto que sea divulgado, relevando el Cliente a Simplus, de
            cualquier responsabilidad que se pudiera derivar al efecto.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Responsabilidades:</b>
          </p>
          <p className={styles.text}>
            Simplus se reserva el derecho a interrumpir el acceso a la
            Plataforma en cualquier momento y sin previo aviso, ya sea por
            motivos técnicos, de seguridad, de control o de mantenimiento, o por
            cualquier otra causa.
          </p>
          <p className={styles.text}>
            Simplus no controla, con carácter general, el uso que hacen los
            usuarios de la Plataforma, por lo tanto es total responsabilidad del
            Cliente velar por el buen comportamiento de sus usuarios
            autorizados.
          </p>
          <p className={styles.text}>
            Simplus solo es responsable de la reserva y el asesoramiento sobre
            los Servicios de viaje (que incluyen, entre otros, la verificación
            de la disponibilidad de los servicios de transporte). Sin embargo,
            una vez que se reserva el Servicio y se completa la compra, se
            aplicarán todos los términos y condiciones y otros términos
            contractuales de los Operadores respectivos. El Cliente tiene total
            conocimiento que Simplus sólo presta el servicio de compraventa de
            pasajes disponibles en línea por parte de los Operadores, y no
            realiza ni presta el servicio de transporte de pasajeros, en razón
            de lo cual no le cabe ninguna responsabilidad por el servicio de
            transporte. Simplus no será responsable de cualquier problema o daño
            relacionado con inconveniencias en la prestación del servicio de
            transporte, o de daños y extravíos de equipajes, accidentes de
            viaje, retrasos de viaje, demoras, desvíos en la ruta, cambios de
            paraderos o puntos de embarque, cancelaciones, o cualquier otro
            perjuicio relacionado. Simplus no será responsable directo de ningún
            incumplimiento o deficiencia durante la prestación de los Servicios
            por parte de los Operadores.
          </p>
          <p className={styles.text}>
            El acceso y navegación en este Sitio supone aceptar y conocer las
            advertencias legales, condiciones y términos de uso contenidas en el
            mismo. Simplus realiza los máximos esfuerzos para que el acceso y la
            navegación en este Sitio se realicen en las mejores condiciones,
            evitando perjuicios de cualquier tipo que pudiesen ocasionarse
            durante los mismos. Simplus no se responsabiliza ni garantiza que el
            acceso a este Sitio sea ininterrumpido o que esté libre de error, ni
            por las fallas en rendimiento, omisiones, interrupciones de
            servicio, defectos, demoras en la transmisión o fallas en las líneas
            de conexión. Tampoco se responsabiliza o garantiza que el contenido
            al que pueda accederse a través de este Sitio esté libre de error o
            no cause daños. Simplus hace todos los esfuerzos a su alcance para
            que este Sitio no contenga ningún virus informático que pueda causar
            daños a los Clientes. A pesar de estos esfuerzos, Simplus declara
            que es imposible garantizar en todo momento que este Sitio esté
            libre de virus informáticos, y por lo tanto, en ningún caso Simplus
            será responsable por las pérdidas, daños o perjuicios de cualquier
            tipo que surjan por el acceso y el uso de este Sitio, incluyéndose
            pero no limitándose, a los ocasionados a los sistemas informáticos o
            los provocados por la introducción de virus. Simplus no se hace
            responsable de los daños que pudiesen ocasionarse a los Clientes por
            un uso inadecuado de este Sitio. Simplus no se hace responsable por
            los daños o perjuicios, de cualquier naturaleza que estos sean,
            directos o indirectos, que se produzcan como consecuencia de o con
            ocasión o en relación con el uso que el Cliente haga del Sitio.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>Obligaciones de los Clientes:</b>
          </p>
          <p className={styles.text}>
            Los Clientes aceptan cumplir con las instrucciones y advertencias
            especiales contenidas en este documento y en el Sitio, y actuar de
            acuerdo con la ley.
          </p>
          <p className={styles.text}>
            El Cliente acepta pagar a Simplus en los términos acordados, todos
            los viajes reservados por el usuario en nombre del Cliente en el
            Sitio.
          </p>
          <p className={styles.text}>
            El Cliente será responsable de salvaguardar las contraseñas
            proporcionadas y creadas en Simplus para ingresar a la Plataforma y
            garantiza que ningún tercero no autorizado tendrá acceso a ellas.
          </p>
          <p className={styles.text}>
            El Cliente declara que toda la información proporcionada por él para
            ingresar al Sitio, antes y durante el uso, es verdadera, y garantiza
            la actualización constante.
          </p>
          <p className={styles.text}>
            El Cliente se compromete a no utilizar esta Plataforma con fines
            ilegales o indebidos. En particular, el Cliente acepta que los
            servicios adquiridos a través de esta Plataforma son para el
            traslado de sus empleados o contratistas, en cuyo nombre está
            legalmente autorizado para actuar.
          </p>
          <p className={styles.text}>
            El Cliente no puede revender los servicios adquiridos a través de
            esta Plataforma a terceros.
          </p>
          <div className={styles.space} />
          <p className={styles.text}>
            <b className={styles.boldText}>
              Derechos de Propiedad Intelectual e Industrial:{' '}
            </b>
          </p>
          <p className={styles.finalText}>
            La totalidad de la información que contiene este Sitio, será
            considerada para todos los efectos legales de propiedad de Simplus,
            sin perjuicio de que ella establezca lo contrario, pudiendo utilizar
            con cualquier fin toda idea, concepto, conocimiento o técnica
            incluidas en la información que proporcione un visitante a través de
            este Sitio. Este Sitio y los contenidos que alberga se encuentran
            protegidos por las leyes de Propiedad Industrial e Intelectual, y
            por los tratados internacionales suscritos y ratificados por Chile.
            Estos contenidos no podrán ser objeto de explotación, reproducción,
            distribución, modificación, comunicación pública, cesión o
            transformación, ni de ningún otro acto jurídico por parte de un
            tercero no autorizado expresamente y por escrito por Simplus. El
            acceso a este Sitio no otorga a los usuarios derecho, ni titularidad
            alguna sobre los derechos de propiedad intelectual de los contenidos
            que alberga este Sitio. El contenido de este Sitio puede ser
            descargado al terminal del usuario (download) siempre que sea para
            su uso privado inmediato y sin ningún fin comercial. Por lo tanto el
            Usuario no podrá explotar, reproducir, distribuir, modificar,
            comunicar públicamente, ceder, transformar o usar el contenido de
            este Sitio con fines públicos o comerciales. Simplus no transfiere a
            los Clientes la propiedad de su software. Simplus retiene todos los
            derechos de propiedad industrial e intelectual. Todas las marcas,
            logotipos, diseños, frases de propaganda, y en general todos los
            signos distintivos mostrados en este Sitio son propiedad de Simplus,
            salvo lo que respecta a los Operadores o a terceros que han
            autorizado el uso de los mismos a Simplus. Se prohíbe a los Clientes
            y a terceros usar estos signos distintivos, descargarlos
            permanentemente, copiarlos o distribuirlos por cualquier medio sin
            el permiso escrito de Simplus o de la tercera empresa titular de los
            derechos.
          </p>
        </Col>
      </Row>
      <Footer />
    </div>
  )
}

export default TermsAndConditionsPage

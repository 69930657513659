import React, { FC } from 'react'
import { Col, Row, Select, Typography } from 'antd'
const { Text } = Typography

import { CostCenterComboBoxProps } from '../../../../types/page-types/pay-page.types'

import styles from './styles.module.css'

const { Option } = Select

const CostCenterSelect: FC<CostCenterComboBoxProps> = ({
  costCenters,
  handleCreditLine,
}) => (
  <Col className={styles.inputCol} xs={24} md={11}>
    <Text>Centro de Costo</Text>
    <Row align="middle" className={styles.inputContainer} justify="center">
      <Select
        bordered={false}
        className={styles.costCenterSelect}
        defaultValue=""
        onChange={e => handleCreditLine(e, 'costCenter')}
        optionFilterProp="children"
        showSearch>
        {costCenters?.map(({ _id, name }) => (
          <Option value={_id} key={_id}>
            {name}
          </Option>
        ))}
      </Select>
    </Row>
  </Col>
)

export default CostCenterSelect

import React, { FC, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import moment from 'moment'

import OptionsHeader from '../../components/OptionsHeader'
import SEO from '../../components/seo'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../services/auth'
import { Company } from '../../types/company.type'
import { CurrentAccount } from '../../types/currentAccount.type'

import CurrentAccountDisplayer from './CurrentAccountDisplayer'
import DebtSummary from './DebtSummary'
import { COMPANY_DETAIL } from './graphql'
import InvoicesList from './InvoicesList'

import styles from './styles.module.css'

const InvoicesPage: FC = () => {
  const [
    selectedCurrentAccount,
    setSelectedCurrentAccount,
  ] = useState<CurrentAccount>()

  const { user } = tokenInfo(getUser() ?? '')
  const [from, setFrom] = useState<Date>(moment().subtract(1, 'month').toDate())
  const [to, setTo] = useState<Date>(moment().toDate())
  const [company, setCompany] = useState<Company>()

  const { data: companyResp } = useQuery(COMPANY_DETAIL, {
    variables: {
      companyId: user.company,
    },
  })

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_facturas' as string

    useEventSave('vista_facturas', {
      vista_viajes: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const setDateFilters = (from: Date, to: Date) => {
    setFrom(from)
    setTo(to)
  }

  useEffect(() => {
    setCompany(companyResp?.companyDetail)
  }, [companyResp])

  return (
    <Row>
      <Col span={24} className={styles.backgroundBlue}>
        <SEO title="Facturas" />
        <OptionsHeader />
        <Row justify="center">
          <Col xs={23} sm={18} md={12}>
            <CurrentAccountDisplayer
              onCurrentAccountChange={setSelectedCurrentAccount}
              onDateChange={setDateFilters}
              company={company}
            />
            <DebtSummary
              currentAccount={selectedCurrentAccount?._id}
              from={from}
              to={to}
            />
          </Col>
        </Row>
        <div className={styles.whiteLine} />
        <Row justify="center">
          <Col xs={24} lg={24} xl={16}>
            <InvoicesList
              currentAccount={selectedCurrentAccount?._id}
              from={from}
              to={to}
              company={company}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default InvoicesPage

import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Col, message, Row } from 'antd'
import { navigate } from 'gatsby'

import { RegisterPicture } from '../../assets/register'
import BackArrow from '../../components/BackArrow'
import { RolesEnum } from '../../helpers/enums'
import { stageInformation } from '../../helpers/stageInformation'
import { Company } from '../../types/company.type'

import AdditionalInformationForm from './AdditionalInformationForm'
import CompanyRegistrationForm from './CompanyRegistrationForm'
import FileUploadForm from './FileUploadForm'
import { REGISTER_CCC_USER, REGISTER_SCC_USER } from './graphql'
import PersonalRegistrationForm from './PersonalRegistrationForm'

import styles from './styles.module.css'

const RegisterPage: React.FC<RouteComponentProps> = () => {
  const [accountType, setAccountType] = useState('ADMIN_CCC')
  const [stage, setStage] = useState(1)
  const [registrationInformation, setRegistrationInformation] = useState<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }>({})

  const [saveSCCUser, { loading: SCCLoading }] = useMutation(REGISTER_SCC_USER)
  const [saveCCCUser, { loading: CCCLoading }] = useMutation(REGISTER_CCC_USER)


  const informationHandler: (newFields: {
    [key: string]: string | number
  }) => void = newFields => {
    setRegistrationInformation({ ...registrationInformation, ...newFields })
  }

  const registerUser = async () => {
    let saveToUse = undefined

    if ((registrationInformation as Company).role === RolesEnum.ADMIN_SCC)
      saveToUse = saveSCCUser
    else saveToUse = saveCCCUser

    // TODO: Check on this destructuring, possible refactor.
    try {
      const input = {
        firstName: registrationInformation.firstName,
        lastName: registrationInformation.lastName,
        phone: registrationInformation.phone,
        email: registrationInformation.email,
        password: registrationInformation.password,
        role: registrationInformation.role,
        name: registrationInformation.name,
        rut: registrationInformation.rut,
        companyRut: registrationInformation.companyRut,
        commercialActivity: registrationInformation.commercialActivity,
        estimatedPassengers: registrationInformation.estimatedPassengers,
        estimatedAmount: registrationInformation.estimatedAmount,
        businessArea: registrationInformation.businessArea,
        billingEmail: registrationInformation.billingEmail,
        country: registrationInformation.country,
        city: registrationInformation.city,
        commune: registrationInformation.commune,
        street: registrationInformation.street,
        streetNumber: registrationInformation.streetNumber,
        companyPosition: registrationInformation.companyPosition,
      }


      await saveToUse({
        variables: {
          input:
            input.role === RolesEnum.ADMIN_CCC ||
            input.role === RolesEnum.MULTI_RUT_ADMIN
              ? {
                  ...input,
                  DICOM: registrationInformation.DICOM,
                  taxFolder: registrationInformation.taxFolder,
                }
              : input,
        },
      })

      message.success('Usuario creado exitosamente.')

      navigate('/app/confirmar-registro', {
        state: { email: registrationInformation?.email },
      })

    } catch (err) {
      message.error(err)
      console.log(err)
    }
  }

  return (
    <Row className={styles.container}>
      <Col xs={24} lg={12} className={styles.formContainer}>
        <Row
          justify="space-between"
          align="middle"
          className={styles.headerContainer}>
          <Col xs={4}>
            <BackArrow
              clickAction={() => {
                navigate('/inicio-sesion/')
              }}
            />
          </Col>
          <Col xs={16} lg={{ span: 16, pull: 4 }}>
            <div className={styles.headerFont}>
              {stageInformation[stage][accountType].header}
            </div>
          </Col>
          <Col xs={4} lg={0}>
            {stageInformation[stage][accountType].pagination}
          </Col>
        </Row>

        <Row justify="center" align="middle" className={styles.minHeight}>
          <Col xs={24} sm={14}>
            <PersonalRegistrationForm
              stage={stage}
              stageHandler={setStage}
              informationHandler={informationHandler}
              accountTypeHandler={setAccountType}
            />
            <CompanyRegistrationForm
              stage={stage}
              accountType={accountType}
              stageHandler={setStage}
              informationHandler={informationHandler}
            />
            <AdditionalInformationForm
              stage={stage}
              accountType={accountType}
              stageHandler={setStage}
              informationHandler={informationHandler}
              registerUser={registerUser}
              loading={SCCLoading}
            />
            <FileUploadForm
              stage={stage}
              accountType={accountType}
              stageHandler={setStage}
              informationHandler={informationHandler}
              estimatedAmount={
                registrationInformation?.estimatedAmount?.split('-')[1]
              }
              loading={CCCLoading}
              registerUser={registerUser}
            />
          </Col>
        </Row>
      </Col>
      <Col xs={0} lg={12} className={styles.innerRightContainer}>
        <Row align="middle" className={styles.fullHeight}>
          <Col>
            <RegisterPicture className={styles.registerPicture} />
            <p className={styles.stageInfoTitleFont}>
              {stageInformation[stage][accountType].title}
            </p>
            <p className={styles.stageInfoBodyFont}>
              {stageInformation[stage][accountType].description}
            </p>
            <p className={styles.stageFont}>
              {stageInformation[stage][accountType].pagination}
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default RegisterPage
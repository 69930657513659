import React, { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Divider, Row, Select } from 'antd'

import { MY_CURRENT_ACCOUNTS } from '../../queries'
import { GroupNameProps } from '../../screens/travel-page/types'
import { CostCenter } from '../../types/costCenter.type'
import { CurrentAccount } from '../../types/currentAccount.type'

import styles from './styles.module.css'

const { Option } = Select

const GroupSelect: FC<GroupNameProps> = ({ setSelectedCostCenter }) => {
  const [currentAccounts, setCurrentAccounts] = useState<CurrentAccount[]>([])
  const [costCenters, setCostCenters] = useState<CostCenter[]>([])

  const { loading } = useQuery(MY_CURRENT_ACCOUNTS, {
    onCompleted: ({ myCurrentAccounts }) =>
      setCurrentAccounts(myCurrentAccounts ?? []),
    fetchPolicy: 'no-cache',
  })


  const onChangeCurrentAccount = (currentAccountId: string) => {
    const currentAccount = currentAccounts.find(
      ({ _id }) => _id === currentAccountId,
    )
    // console.log("myCurrentAccounts", currentAccount)
    setCostCenters(currentAccount?.costCenters ?? [])
  }

  return (
    <>
      <Row align="middle" justify="center" className={styles.inputRow}>
        <Col span={24}>
          <Row align="middle" justify="center" gutter={25}>
            <Col xs={24} sm={10}>
              <Select
                className={styles.select}
                onChange={e => onChangeCurrentAccount(e.toString())}
                optionFilterProp="children"
                placeholder={loading ? 'Cargando...' : 'Cuenta Corriente'}
                showSearch>
                {currentAccounts.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={10}>
              <Select
                className={styles.select}
                onChange={e => setSelectedCostCenter(e.toString())}
                optionFilterProp="children"
                placeholder="Centro De Costo"
                showSearch>
                {costCenters.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default GroupSelect

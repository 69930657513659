import React, { FC } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'

import LoadingSpinner from '../../../components/LoadingSpinner'
import { formatDate } from '../../../helpers/dateLongFormat'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { useScreenWidth } from '../../../hooks/screen.hook'
import { GET_DEBT_SUMMARY } from '../graphql'
import { filterProps } from '../types'

import styles from './styles.module.css'

const DebtSummary: FC<filterProps> = ({ currentAccount, from, to }) => {
  const { data, loading } = useQuery(GET_DEBT_SUMMARY, {
    variables: {
      currentAccount,
      from,
      to,
    },
    fetchPolicy: 'no-cache',
  })

  const screenWidth = useScreenWidth()

  const renderTotalUnpaid = (alignLeft?: boolean) => (
    <Row justify="start" align="middle">
      <Col xs={16} lg={24}>
        <div className={`${styles.label} ${alignLeft ? styles.left : null}`}>
          Tolal por pagar
        </div>
      </Col>
      <Col xs={8} lg={24}>
        <div
          className={`${styles.summaryData} ${alignLeft ? styles.left : null}`}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            `$${numberWithSeparator(data?.getDebtSummary?.totalDebt)}`
          )}
        </div>
      </Col>
    </Row>
  )

  const renderExpireDays = (alignLeft?: boolean) => (
    <Row>
      <Col xs={16} lg={24}>
        <div className={`${styles.label} ${alignLeft ? styles.left : null}`}>
          Días desde vencimiento
        </div>
      </Col>
      <Col xs={8} lg={24}>
        <div
          className={`${styles.summaryData} ${alignLeft ? styles.left : null}`}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <div>
                {numberWithSeparator(data?.getDebtSummary?.expiredDays)}
              </div>
              <div className={styles.expiredFromLabel}>
                {data?.getDebtSummary?.expiredFrom
                  ? `Desde: ${formatDate(data?.getDebtSummary?.expiredFrom)}`
                  : ''}
              </div>
            </>
          )}
        </div>
      </Col>
    </Row>
  )

  const renderInvoicesUnpaid = (alignLeft?: boolean) => (
    <Row>
      <Col xs={16} lg={24}>
        <div className={`${styles.label} ${alignLeft ? styles.left : null}`}>
          Facturas por pagar
        </div>
      </Col>
      <Col xs={8} lg={24}>
        <div
          className={`${styles.summaryData} ${alignLeft ? styles.left : null}`}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            `${numberWithSeparator(data?.getDebtSummary?.invoicesToPay)}`
          )}
        </div>
      </Col>
    </Row>
  )

  return (
    <Row justify="space-between" align="stretch">
      <Col
        xs={24}
        lg={0}
        className={`${styles.container} ${styles.mobileContainer} ${
          screenWidth <= 320
            ? styles.containerMobilePadding
            : styles.containerPadding
        }`}>
        <Row justify="center" align="middle">
          <Col span={24} className={styles.whiteBorder}>
            {renderTotalUnpaid(true)}
            {renderExpireDays(true)}
            {renderInvoicesUnpaid(true)}
          </Col>
        </Row>
      </Col>
      <Col xs={0} lg={8}>
        <div className={`${styles.container} ${styles.containerPadding}`}>
          <Row justify="center" align="middle" className={styles.fullHeight}>
            <Col>{renderTotalUnpaid()}</Col>
          </Row>
        </div>
      </Col>
      <Col xs={0} lg={8}>
        <div className={`${styles.container} ${styles.containerPadding}`}>{renderExpireDays()}</div>
      </Col>
      <Col xs={0} lg={8}>
        <div className={`${styles.container} ${styles.containerPadding}`}>
          <Row justify="center" align="middle" className={styles.fullHeight}>
            <Col>{renderInvoicesUnpaid()}</Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default DebtSummary

import { gql } from '@apollo/client'

export const DISCOUNT_DETAILS = gql`
  query getActiveDiscountDetails @api(name: "users") {
    getActiveDiscountDetails {
      percentDiscount {
        _id
        validFrom
        validTo
        companyRut
        version
        createdAt
        updatedAt
        discountInterval {
          amountOf
          amountTo
          percent
        }
      }
      amount
      periods {
        initialDate
        endDate
      }
      discount
    }
  }
`

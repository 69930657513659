import React, { FC } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'

import { genTitle } from '../../../../helpers/my-travel-page'
import { DetailSubHeaderProps } from '../../types'

import styles from '../styles.module.css'

const DetailSubHeader: FC<DetailSubHeaderProps> = ({
  icon,
  travel,
  travelType,
  setActiveDevolution,
}) => (
  <Row justify="space-between" align="middle">
    <Col
      xs={{ span: 22, offset: 2 }}
      md={{ span: 8 }}
      lg={{ span: 6, offset: 6 }}
      className={styles.paddingTop2}>
      <Row align="middle">
        <Col>{icon}</Col>
        <Col offset={2}>
          <div>
            <div className={styles.subHeaderTitle}> Detalle de tu viaje </div>
            <div className={styles.subHeaderSubTitle}>
              {genTitle(travelType, travel)}
            </div>
          </div>
        </Col>
      </Row>
    </Col>
    <Col
      xs={{ span: 10, offset: 2 }}
      md={{ span: 8 }}
      lg={{ span: 4, offset: 0 }}
      className={styles.paddingTop2}>
      <Button
        type="text"
        className={styles.devolutionButton}
        onClick={() => setActiveDevolution(true)}>
        Devolver Pasajes
      </Button>
    </Col>
    <Col
      xs={6}
      md={4}
      lg={0}
      className={`${styles.paddingTop2} ${styles.centered}`}>
      <Button
        className={styles.donwnloadButton}
        icon={<DownloadOutlined />}
        href={`${process.env.GATSBY_API_TICKETS_URI}${
          travel?.gone ? `${travel?.gone?._id}/` : ''
        }${travel?.return?._id ?? ''}`}></Button>
    </Col>
    <Col xs={0} lg={8} className={styles.paddingTop2}>
      <Button
        className={styles.donwnloadButton}
        icon={<DownloadOutlined />}
        href={`${process.env.GATSBY_API_TICKETS_URI}${
          travel?.gone ? `${travel?.gone?._id}/` : ''
        }${travel?.return?._id ?? ''}`}>
        Descargar boletos
      </Button>
    </Col>
  </Row>
)

export default DetailSubHeader

import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'

import DetailDrawer from '../../components/DetailDrawer'
import DetailForm from '../../components/DetailDrawer/DetailForm'
import SEO from '../../components/seo'
import { RolesEnum } from '../../helpers/enums'
import { useEventSave } from '../../hooks/analytics.hook'
import { ACCOUNTS_OPTIONS, PASSENGER_BY_COMPANY } from '../../queries'
import { getUser, tokenInfo } from '../../services/auth'
import { SessionContext } from '../../services/session'
import { CostCenter } from '../../types/page-types/pay-page.types'
import { Passenger } from '../travel-page/types'

import CreditLineFilter from './CreditLineFilter'
import MyPassengerLayout from './MyPassengerLayout'
import PassengerDisplay from './PassengerDisplay'
import { CreditLineOptions } from './types'
import UploadPassengers from './UpLoadPassengers'

import styles from './styles.module.css'
import moment from 'moment'
import { useLocation } from '@reach/router';
import queryString from 'query-string';
import { navigate } from 'gatsby';

const MyPassengers: FC = () => {
  const { user } = useContext(SessionContext)
  const [allPassengers, setAllPassengers] = useState<Passenger[]>([])
  const [costCenterList, setCostCenterList] = useState<CostCenter[]>([])
  const [filterAccounts, setFilterAccounts] = useState<CreditLineOptions[]>([])
  const [passengerList, setPassengerList] = useState<Passenger[]>([])
  const [selectedCostCenter, setSelectedCostCenter] = useState<string[]>([])
  const [updateDrawer, setUpdateDrawer] = useState(0)
  const [currentAccoutSelected, setCurrentAccountSelected] = useState<
    string | undefined
  >(undefined)
  const [updateVisible, setUpdateVisible] = useState(false)

  /*For searching */
  const [nameFilter, setFilter] = useState<string>('');

  /*For sorting*/
  const [sortOrder, setSort] = useState<number | undefined>(0);


  /*Paginación*/
  
  const location = useLocation()

  const { page } = queryString.parse(location.search)

  const [selectedPage, setSelectedPage] = useState<number>(
    parseInt(page?.toString() ?? '1'),
  )
  const [totalPassengers, setTotalPassengers] = useState<number>(0)
  const [passengersByPage, setPassengersByPage] = useState<number>(10)


  const handleSelectChange = (id: string) => {
    if (id === 'todos') {
      setCostCenterList([])
      setSelectedCostCenter([])
    } else {
      setCurrentAccountSelected(id)
      const idx = filterAccounts.findIndex(({ _id }) => id === _id)
      setCostCenterList(filterAccounts[idx].costCenters)
      setSelectedCostCenter([])
    }
  }

  const isCCC = [
    RolesEnum.ADMIN_CCC,
    RolesEnum.CCC,
    RolesEnum.MULTI_RUT_BUYER,
    RolesEnum.MULTI_RUT_ADMIN,
  ].includes(user?.role as RolesEnum)

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_mis_pasajeros' as string

    useEventSave('vista_mis_pasajeros', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const [getAccounts] = useLazyQuery(ACCOUNTS_OPTIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ myCurrentAccounts }) => {
      if (myCurrentAccounts) {
        setFilterAccounts(myCurrentAccounts)
        if (currentAccoutSelected)
          setCostCenterList(
            myCurrentAccounts.find(
              (currentAccount: CreditLineOptions) =>
                currentAccount._id === currentAccoutSelected,
            )?.costCenters ?? [],
          )
      }
    },
  })


  const changePage = (page: number) => {
    setSelectedPage(page);
    navigate(`/app/mis-pasajeros?page=${page}#pagination`);
  };

  
  

  const [loadPassengers, { loading, networkStatus }] = useLazyQuery(
    PASSENGER_BY_COMPANY,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: ({ passengersByCompany }) => {
        if (passengersByCompany) {
          const passengers = Object.values(passengersByCompany.data) as Passenger[];
          setTotalPassengers(passengersByCompany.total)
          setPassengersByPage(passengersByCompany.passengersByPage)
          setAllPassengers(passengers);
          setPassengerList(passengers);

        }

      },
      variables: {
        costCenterIds: selectedCostCenter.length > 0 ? selectedCostCenter : null,
        filters: {
          fullName:  nameFilter
        },
        input: {
          from: moment().format('YYYY-MM-DD HH:mm:ss'),
          to: moment().format('YYYY-MM-DD HH:mm:ss'),
          page: selectedPage,
          passengersByPage: passengersByPage > 0 ? passengersByPage : 10,
        },
        sortOrderValue: sortOrder
      },
    }
  );
  
  
  const applyFilter = () => {

    loadPassengers({
      variables: {
        costCenterIds: selectedCostCenter.length > 0 ? selectedCostCenter : null,
        filters: {
          fullName: nameFilter // Use the provided filterValue
        },
        input: {
          from: moment().format('YYYY-MM-DD HH:mm:ss'),
          to: moment().format('YYYY-MM-DD HH:mm:ss'),
          page: selectedPage,
          passengersByPage: passengersByPage > 0 ? passengersByPage : 10,
        },
        sortOrderValue: sortOrder
      },
    });
  };
  


  const handleUpdateDrawer = (value: number) => {
    if (value === 0) {
      setUpdateVisible(false)
      setUpdateDrawer(value)
    } else setUpdateDrawer(value)
  }

  const handleUpdateVisible = (value: boolean) => setUpdateVisible(value)

  const handleSearch = (query: string) => {
    if (query) {
      setFilter(query); 
      setSelectedPage(1);
    } else {
      setFilter('');
    }
   
  };

  const handleSortOrder = (option: number) => {
    //option 0 AZ - option 1 ZA
    setSort(option)
  }

  const selectedCostCenters = (e: boolean, id: string) =>
    e
      ? setSelectedCostCenter([id, ...selectedCostCenter])
      : setSelectedCostCenter(selectedCostCenter.filter(item => item !== id))


  const refetch = async () => {
    await applyFilter()
    await getAccounts()
  }

  useEffect(() => {
    getAccounts()
    applyFilter();
  }, [selectedPage, sortOrder, nameFilter]);
  

  return (
    <>
      <SEO title="Mis pasajeros" />
      <MyPassengerLayout
        quantity={costCenterList.reduce(
          (acc, { passengers }) => acc + passengers.length,
          0,
        )}>
        <DetailDrawer
          handleDrawerButton={handleUpdateDrawer}
          handleVisible={handleUpdateVisible}
          step={updateDrawer}
          visible={updateVisible}>
          {updateDrawer === 1 && (
            <DetailForm
              handleDrawerButton={handleUpdateDrawer}
              refetch={refetch}
            />
          )}
          {updateDrawer === 2 && (
            <UploadPassengers
              handleDrawerButton={handleUpdateDrawer}
              refetch={refetch}
            />
          )}
        </DetailDrawer>
        <Row
          align="top"
          className={styles.mainRowPassenger}
          justify="center"
          gutter={[14, 20]}>
          {isCCC && (
            <Col xs={24} sm={20} md={8} xl={6}>
              <CreditLineFilter
                applyFilter={() => applyFilter()}
                creditLineOptions={filterAccounts}
                costCenterList={costCenterList}
                handleSelectChange={handleSelectChange}
                selectedCostCenters={selectedCostCenters}
              />
            </Col>
          )}
          <Col xs={24} sm={20} md={14} xl={8}>
            <PassengerDisplay
              costCenters={costCenterList.length ?? 0}
              handleDrawerStep={handleUpdateDrawer}
              handleVisible={handleUpdateVisible}
              handleSearch={handleSearch}
              handleSortOrder={handleSortOrder}
              loading={loading || networkStatus === 4}
              passengerList={passengerList}
              changePage={changePage}
              totalPassengers={totalPassengers}
              passengersByPage={passengersByPage}
              selectedPage={selectedPage}
              setPassengersByPage={setPassengersByPage}
              refetch={refetch}
            />
          </Col>
        </Row>
      </MyPassengerLayout>
    </>
  )
}

export default MyPassengers

import { InvoiceStatus } from '../enums'

export const renderStatus = (status?: string): Record<string, string> | null => {
  if (!status) return null
  const options = {
    [InvoiceStatus.IN_PROGRESS.toString()]: {
      text: 'En Proceso',
      class: 'progressColor'
    },
    [InvoiceStatus.PAID.toString()]: {
      text: 'Pagado',
      class: 'paidColor',
    },
    [InvoiceStatus.UNPAID.toString()]: {
      text: 'No Pagado',
      class: 'unpaidColor',
    },
    [InvoiceStatus.EXPIRED.toString()]: {
      text: 'Vencido',
      class: 'expiredColor'
    },
    [InvoiceStatus.NULLED.toString()]: {
      text: 'Anulado',
      class: 'nulledColor'
    }
  }

  return options[status]
}
import React, { ComponentType, FC } from 'react'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import { isPlatformUser } from '../helpers/auth.helper'
import { getUser, isLoggedIn, tokenInfo } from '../services/auth'

interface Props extends RouteComponentProps {
  component: ComponentType
  roles?: string[]
}

const PrivateRoute: FC<Props> = ({
  component: Component,
  location,
  roles,
  ...rest
}) => {
  if (!isLoggedIn() && location?.pathname !== `/inicio-sesion/`) {
    navigate(`/inicio-sesion?logout&pathName=${location?.pathname}`)
    return null
  }

  const { user } = tokenInfo(getUser() ?? '')

  if (isPlatformUser(user.role) && window && location)
    window.location.href = `${process.env.GATSBY_ADMIN_URI}?token=${getUser()}`

  if (roles && !roles.includes(user.role)) {
    navigate(`/inicio-sesion?logout&pathName=${location?.pathname}`)
    return null
  }

  return <Component {...rest} />
}
export default PrivateRoute

import React, { FC, useEffect } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Avatar, Button, Col, Drawer, Form, message, Row } from 'antd'

import { DELETE_PASSENGER, UPDATE_PASSENGER_INFO } from '../../../queries'
import { PassengerFormValues } from '../../travel-page/types'
import { EditPassengerProps } from '../types'

import InnerDrawerForm from './InnerDrawerForm'

import styles from './styles.module.css'

const EditPassenger: FC<EditPassengerProps> = ({
  handleDrawer,
  refetch,
  passenger,
  visible,
}) => {
  const [form] = Form.useForm()

  const [updatePassenger, { loading }] = useMutation(UPDATE_PASSENGER_INFO)

  const [deletePassenger, { loading: deleteLoading }] = useMutation(
    DELETE_PASSENGER,
  )

  const onDelete = async () => {
    try {
      await deletePassenger({
        variables: {
          passengerId: passenger._id,
        },
      })
      handleDrawer(false)
      form.resetFields()
      message.success('Pasajero eliminado exitosamente.')
      refetch()
    } catch (err) {
      message.error(`Error al intentar eliminar pasajero ${err}.`)
    }
  }

  const onFinish = async (values: PassengerFormValues) => {
    const {
      firstName,
      fatherLastName,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      email,
      motherLastName,
      phone,
      areaCode,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      indentityPrefix,
      ...rest
    } = values

    try {
      await updatePassenger({
        variables: {
          input: {
            ...rest,
            firstName,
            _id: passenger._id,
            fatherLastName,
            motherLastName,
            email,
            phone: `${areaCode} ${phone}`,
            passport: undefined,
            rut: undefined,
          },
        },
      })
      handleDrawer(false)
      form.resetFields()
      message.success('Pasajero actualizado exitosamente.')
      refetch()
    } catch (err) {
      message.error(`Error al intentar actualizar pasajero ${err}.`)
    }
  }

  useEffect(() => {
    form.resetFields()
  }, [passenger])
  return (
    <Drawer
      bodyStyle={{ padding: '1rem' }}
      footer={
        <Row align="middle" justify="end" gutter={10}>
          <Col span={10}>
            <Button
              block
              className={styles.cancelButton}
              onClick={() => handleDrawer(false)}>
              Cancelar
            </Button>
          </Col>
          <Col span={10}>
            <Button
              block
              className={styles.saveButton}
              onClick={() => {
                form
                  .validateFields()
                  .then(values => onFinish(values))
                  .catch(info =>
                    message.info(
                      `Verifique que los campos fueron ingresados correctamente. ${info}`,
                    ),
                  )
              }}
              loading={loading}>
              Guardar cambios
            </Button>
          </Col>
        </Row>
      }
      height="100vh"
      onClose={() => {
        handleDrawer(false)
      }}
      title="Detalles del pasajero"
      visible={visible}
      width={window?.innerWidth > 900 ? 400 : window?.innerWidth - 10}>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="center">
            <Avatar size={100} icon={<UserOutlined />} />
          </Row>
          <Row justify="center">
            {passenger ? (
              <InnerDrawerForm form={form} passenger={passenger} />
            ) : null}
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={8}>
          <Button
            block
            className={styles.deleteButton}
            loading={deleteLoading}
            onClick={onDelete}>
            Eliminar
          </Button>
        </Col>
      </Row>
    </Drawer>
  )
}

export default EditPassenger

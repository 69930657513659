import React, { useEffect, useState } from 'react'
import { QuestionCircleFilled, ToTopOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Checkbox,
  Form,
  message,
  Row,
  Tooltip,
  Upload,
} from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import axios from 'axios'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import {
  FileUploadFormProps,
  FileUploadFormValues,
  OnChangeInfo,
} from '../../../types/component-types/FileUploadForm.types'

import styles from './styles.module.css'

const FileUploadForm: React.FC<FileUploadFormProps> = ({
  stage,
  accountType,
  stageHandler,
  informationHandler,
  estimatedAmount,
  loading,
  registerUser,
}) => {
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [taxFolderUri, setTaxFolderUri] = useState('')
  const [DICOMUri, setDICOMUri] = useState('')

  const [taxFolderUploaded, setTaxFolderUploaded] = useState(false);
  const [dicomFolderUploaded, setDicomFolderUploaded] = useState(false);


  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<FileUploadFormValues>,
  ) => void = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const hidden = !!(stage !== 3 || accountType !== 'ADMIN_CCC')

  const onChange = (info: OnChangeInfo, setUri: (uri: string) => void) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} se cargó con éxito`)
      const serverResponse = info.file.response
      setUri(serverResponse.data.fileUrl)
    } else if (info.file.status === 'error')
      message.error(`${info.file.name} tuvo un problema al cargar`)
  }

  // const onChangeDICOM = (info: OnChangeInfo) => onChange(info, setDICOMUri)

  // const onChangeTaxFolder = (info: OnChangeInfo) =>
  //   onChange(info, setTaxFolderUri)

  const onChangeDICOM = (info: OnChangeInfo) => {
    onChange(info, setDICOMUri)
    if (info.file.status === 'done') {
      setDicomFolderUploaded(true);
    }
  }

    const onChangeTaxFolder = (info: OnChangeInfo) => {
      onChange(info, setTaxFolderUri);
      if (info.file.status === 'done') {
        setTaxFolderUploaded(true);
      }
    };

    const onFinish = (values: any) => {
      // Check if taxFolderUploaded is true before submitting the form
      if (!taxFolderUploaded) {
        message.error('¡Debe cargar la carpeta tributaria antes de continuar!');
        return;
      }

      if (!dicomFolderUploaded) {
        message.error('¡Debe cargar la carpeta DICOM antes de continuar!');
        return;
      }

      registerUser()
      
    };

  const onRemove = (path: string, setFileUri: (uri: string) => void) => {
    axios
      .delete(process.env.GATSBY_API_UPLOAD_URI as string, {
        data: {
          path,
        },
      })
      .then(() => setFileUri(''))
      .catch(err => console.error(err))
  }

  const beforeUpload = (
    file: UploadFile,
    fileList: UploadFile[],
    fileUri: string,
  ) => {
    const isLt5M = file.size / 1024 / 1024 < 5

    if (!isLt5M) {
      message.error('¡Tamaño del documento es mayor al límite de 5MB!')
      fileList.length = 0
    }

    const notValid = !!fileUri

    if (notValid) {
      message.error('Primero elimine el documento seleccionado')
      fileList.pop()
    }

    return isLt5M && !notValid
  }

  const beforeUploadTaxFolder = (file: UploadFile, fileList: UploadFile[]) =>
    beforeUpload(file, fileList, taxFolderUri)

  const beforeuploadDICOM = (file: UploadFile, fileList: UploadFile[]) =>
    beforeUpload(file, fileList, DICOMUri)

  const uploadProps = {
    name: 'file',
    multiple: false,
    headers: {
      authorization: 'authorization-text',
    },
  }

  useEffect(() => {
    informationHandler({
      taxFolder: taxFolderUri,
      DICOM: DICOMUri,
    })
  }, [DICOMUri, taxFolderUri])

  const showTaxFolder =
  estimatedAmount &&
  (estimatedAmount === 'x' || parseInt(estimatedAmount))

  return (
    <Row>
      <Col>
        {/* <Form onFinish={registerUser} onFinishFailed={onFinishFailed}> */}
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          {showTaxFolder ? (
            <Form.Item name="taxFolder" hidden={hidden}>
              <Row justify="center" align="middle">
                <Col xs={24} sm={10}>
                  <p className={styles.uploadDocumentLabelFont}>
                    Carpeta tributaria
                    <Tooltip
                      placement="top"
                      title=" Carpeta Tributaria (Detalle: 24 IVA's (F29) y Declaración Renta últimos 3 años (F22)).">
                      <QuestionCircleFilled className={styles.icon} />
                    </Tooltip>
                    :
                  </p>
                </Col>
                <Col xs={24} sm={10}>
                  <Upload
                    {...uploadProps}
                    action={`${process.env.GATSBY_API_UPLOAD_URI}/taxFolder`}
                    onChange={onChangeTaxFolder}
                    onRemove={() => onRemove(taxFolderUri, setTaxFolderUri)}
                    beforeUpload={beforeUploadTaxFolder}>
                    <Button icon={<ToTopOutlined />}>Subir archivo</Button>
                  </Upload>
                </Col>
              </Row>
            </Form.Item>
          ) : null}

          <Form.Item name="DICOM" hidden={hidden}>
            <Row justify="center" align="middle">
              <Col xs={24} sm={10}>
                <p className={styles.uploadDocumentLabelFont}>
                  DICOM full
                  <Tooltip placement="top" title="DICOM Full (Recomendable)">
                    <QuestionCircleFilled className={styles.icon} />
                  </Tooltip>
                  :
                </p>
              </Col>
              <Col xs={24} sm={10}>
                <Upload
                  {...uploadProps}
                  action={`${process.env.GATSBY_API_UPLOAD_URI}/DICOM`}
                  onChange={onChangeDICOM}
                  onRemove={() => onRemove(DICOMUri, setDICOMUri)}
                  beforeUpload={beforeuploadDICOM}>
                  <Button icon={<ToTopOutlined />}>Subir archivo</Button>
                </Upload>
              </Col>
            </Row>
          </Form.Item>
          {hidden ? null : (
            <p className={styles.uploadDescriptionFont}>
              ¿Te falta un documento? Aún así puedes continuar tu solicitud. Uno
              de nuestros ejecutivos se contará a la brevedad contigo.
              Contáctanos a contacto.simplus@simplus.cl
            </p>
          )}

          <Form.Item hidden={hidden} className={styles.termsAndConditions}>
            <Checkbox
              onChange={() => setTermsAndConditions(!termsAndConditions)}>
              Acepto{' '}
              <a href="/app/terminos-y-condiciones" target="_blank">
                Términos y Condiciones
              </a>
            </Checkbox>
          </Form.Item>
          <div className={styles.lineSeparator} />
          <Form.Item hidden={hidden}>
            <Row justify="space-between" className={styles.buttonContainer}>
              <Col xs={{ span: 24, order: 2 }} sm={{ span: 11, order: 1 }}>
                <Button
                  className={styles.backButton}
                  onClick={() => stageHandler(2)}>
                  Volver
                </Button>
              </Col>
              <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 2 }}>
                <Button
                  className={
                    termsAndConditions
                      ? styles.continueButton
                      : styles.continueButtonDisabled
                  }
                  loading={loading}
                  disabled={!termsAndConditions}
                  htmlType="submit"
                  block>
                  Continuar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default FileUploadForm

import React from 'react'

import { InitTravelOptions } from '../../types'

import Premium from './svg/Premium.inline.svg'
import SalonCama from './svg/SalonCama.inline.svg'
import Semicama from './svg/Semicama.inline.svg'

export const typeSeat: Record<string, string> = {
  'clasico c.': 'Semi Cama',
  clasico: 'Semi Cama',
  'clasico local': 'Semi Cama',
  'salon cama': 'Salón Cama',
  'salón cama': 'Salón Cama',
  'semi cama': 'Semi Cama',
  premium: 'Premium',
  'cama premium': 'Premium',
  'semi cama internac.': 'Semi Cama',
  'salon cama suite': 'Salón Cama',
  'semi cama suite': 'Semi Cama',
  expreso: 'Semi Cama',
  'semicama superior': 'Salón Cama',
  'clasico superior': 'Semi Cama',
  'cama premium ii': 'Premium',
  'semicama superior ii': 'Semi Cama',
  'cama premium iii': 'Premium',
  'semicama suite': 'Semi Cama',
  'semicama suite int.': 'Semi Cama',
  'salon cama suite int': 'Salón Cama',
  semicama: 'Semi Cama',
  'clasico cce': 'Semi Cama',
  'cama premium 32 as.': 'Premium',
  aeropuerto: 'Semi Cama',
  'cama(bus viggo)': 'Salón Cama',
  'clasico electrico': 'Semi Cama',
  'semicama superioriii': 'Semi Cama',
  'cama cama (viggo)': 'Salón Cama',
  'salon premium': 'Premium',
  'semicama superior iv': 'Semi Cama',
  'semicama superior v': 'Semi Cama',
  'semicama superior 7': 'Semi Cama',
  operacional: 'Semi Cama',
  mantenimiento: 'Semi Cama',
  'bio-bio class': 'Semi Cama',
  'clasico 1': 'Semi Cama',
  especial: 'Semi Cama',
  'clas.cort.': 'Semi Cama',
  'clasico 1 conductor': 'Semi Cama',
  'long space superior': 'Semi Cama',
  ejecutivo: 'Semi Cama',
  'clas. corto superior': 'Semi Cama',
  'clasico corto superi': 'Semi Cama',
  'cla.corto zona centr': 'Semi Cama',
}

export const initTravelOptions: InitTravelOptions[] = [
  {
    seat_class: 'Semi Cama',
    available_seats: 0,
    price: 0,
    icon: <Semicama style={{ marginRight: 5 }} />,
  },
  {
    seat_class: 'Salón Cama',
    available_seats: 0,
    price: 0,
    icon: <SalonCama style={{ marginRight: 5 }} />,
  },
  {
    seat_class: 'Premium',
    available_seats: 0,
    price: 0,
    icon: <Premium style={{ marginRight: 5 }} />,
  },
]

import React, { FC, useLayoutEffect, useState } from 'react'
import { Button, Col, Divider, Empty, Row, Table } from 'antd'
import { Link, navigate } from 'gatsby'

import LoadingSpinner from '../../../components/LoadingSpinner'
import { DisplayColumnProps } from '../types'

import { colums, columsMobile } from './constant'

import styles from './styles.module.css'

const DisplayColumn: FC<DisplayColumnProps> = ({
  data,
  loading,
  handleFilters,
  pagination,
  total,
}) => {
  const [width, setWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <Col className={styles.content} xs={23} sm={14} md={14} lg={13} xl={11}>
      <Row className={styles.displayMainBox}>
        <Col span={24}>
          <Row align="middle" justify="center">
            <Col xs={0} md={0} lg={8} />
            <Col xs={0} md={0} lg={8}>
              <p className={styles.displayTitle}>Solicitudes realizadas</p>
            </Col>
            <Col xs={24} md={10} lg={8} style={{ textAlign: 'end' }}>
              <Link to={'/app/ayuda/crear-ticket'}>
                <Button className={styles.createRequestButton}>
                  Crear nueva solicitud
                </Button>
              </Link>
            </Col>
          </Row>
          <Divider style={{ marginTop: '1rem' }} />
          {loading ? (
            <LoadingSpinner size="5rem" />
          ) : data.length > 0 ? (
            <Row align="top" justify="center" className={styles.tableHeight}>
              <Table
                bordered={false}
                className={styles.displayTable}
                columns={width < 755 ? columsMobile : colums}
                dataSource={data}
                onRow={({ _id }) => ({
                  onClick: () => navigate(`/app/solicitudes/${_id}`),
                })}
                pagination={{
                  current: pagination.page,
                  total: total ?? 0,
                  onChange: e => handleFilters(e),
                  pageSize: pagination.limit,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  size: 'small',
                }}
                rowClassName={styles.rowClassName}
                rowKey={Math.random}
                showHeader={false}
                tableLayout="fixed"
              />
            </Row>
          ) : (
            <Empty description="No se encontraron solicitudes." />
          )}
        </Col>
      </Row>
    </Col>
  )
}

export default DisplayColumn

import React from 'react'
import { Row } from 'antd'

import styles from './styles.module.css'

const ReportsFooter: React.FC = () => {
  return <Row className={styles.footerContainer} justify="center" />
}

export default ReportsFooter

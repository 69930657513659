import React, { FC, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Col, Drawer, Input, message, Row, Select } from 'antd'

import { CREATE_COST_CENTER, UPDATE_COST_CENTER } from '../../../queries'
import { CECODrawerProps, CecoInput } from '../types'

import validation from './validation'

import styles from './styles.module.css'

const { TextArea } = Input
const { Option } = Select

const CECODrawer: FC<CECODrawerProps> = ({
  costCenter,
  creditLines,
  currentCreditLine,
  handleVisible,
  refetch,
  visible,
}) => {
  const [cecoInput, setCecoInput] = useState({} as CecoInput)

  const [createCostCenter, { loading: createLoading }] = useMutation(
    CREATE_COST_CENTER,
  )
  const [updateCostCenter, { loading: updateLoading }] = useMutation(
    UPDATE_COST_CENTER,
  )

  const checkInputs =
    Object.keys(cecoInput).length > 2 &&
    Object.values(cecoInput).every(x => (x ? x : null))

  const handleCecoInput = (value: string, key: keyof CecoInput) =>
    setCecoInput({ ...cecoInput, [key]: value })

  const handleSave = async () => {
    if (!validation(cecoInput)) return
    try {
      if (!costCenter)
        await createCostCenter({
          variables: {
            input: {
              currentAccountId:
                cecoInput.currentAccountId ?? currentCreditLine._id,
              description: cecoInput.description,
              name: cecoInput.name,
            },
          },
        })
      else
        await updateCostCenter({
          variables: {
            input: {
              costCenterId: costCenter._id,
              currentAccountId:
                cecoInput.currentAccountId ?? currentCreditLine._id,
              description: cecoInput.description ?? costCenter.description,
              name: cecoInput.name ?? costCenter.name,
              status: cecoInput.status ?? costCenter.status,
            },
          },
        })
      setCecoInput({} as CecoInput)
      handleVisible(false)
      message.success(
        `Se ha ${
          costCenter ? 'actualizado' : 'creado'
        } centro de costo exitosamente.`,
      )
      await refetch()
    } catch (err) {
      message.error(
        `Ha ocurrido un error al ${
          costCenter ? 'actualizar' : 'crear'
        } centro de costo. ${err}`,
      )
    }
  }

  useEffect(() => {
    if (costCenter) {
      cecoInput.currentAccountId = currentCreditLine._id
      cecoInput.description = costCenter.description
      cecoInput.name = costCenter.name
      cecoInput.status = costCenter.status
    }
  }, [costCenter])

  return (
    <Drawer
      bodyStyle={{ padding: '1rem' }}
      footer={
        <Row align="middle" justify="end" gutter={[15, 10]}>
          <Col xs={{ span: 24, order: 2 }} md={{ span: 8, order: 1 }}>
            <Button
              className={styles.cancelUpdateButton}
              onClick={() => {
                setCecoInput({} as CecoInput)
                handleVisible(false)
              }}>
              Cancelar
            </Button>
          </Col>
          <Col xs={{ span: 24, order: 1 }} md={{ span: 8, order: 2 }}>
            <Button
              className={
                !costCenter && !checkInputs
                  ? styles.disabledUpdateButton
                  : styles.saveUpdateButton
              }
              onClick={
                !costCenter && !checkInputs
                  ? () =>
                      message.info(
                        'Verifique que todos los campos se encuentran ingresados.',
                      )
                  : handleSave
              }
              loading={costCenter ? updateLoading : createLoading}>
              {costCenter ? 'Guardar cambios' : 'Crear'}
            </Button>
          </Col>
        </Row>
      }
      onClose={() => {
        setCecoInput({} as CecoInput)
        handleVisible(false)
      }}
      title={costCenter ? 'Editar Centro de costo' : 'Agregar Centro de costo'}
      visible={visible}
      width={window?.innerWidth > 900 ? 400 : window?.innerWidth - 10}>
      <Col span={24}>
        <Row align="top" justify="center">
          <Col className={styles.drawerCol} xs={24} sm={20}>
            <p>Cuenta corriente</p>
            {visible ? (
              <Select
                className={styles.drawerSelect}
                onChange={e => handleCecoInput(e, 'currentAccountId')}
                optionFilterProp="children"
                placeholder="Seleccionar cuenta corriente."
                showSearch
                value={
                  costCenter
                    ? cecoInput.currentAccountId ?? currentCreditLine._id
                    : undefined
                }>
                {creditLines?.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))}
              </Select>
            ) : null}

            <p>Nombre</p>
            <Input
              className={styles.drawerInput}
              onChange={e => handleCecoInput(e.target.value, 'name')}
              value={cecoInput.name ?? costCenter?.name}
              placeholder="¿Cómo se llamará este centro de costo?"
            />
            {costCenter ? (
              <>
                <p>Estado</p>
                <Select
                  className={styles.drawerSelect}
                  onChange={e => handleCecoInput(e, 'status')}
                  placeholder="Seleccione estado."
                  value={cecoInput.status ?? costCenter.status}>
                  <Option value={'ACTIVE'}>Vigente</Option>
                  <Option value={'INACTIVE'}>Inactiva</Option>
                </Select>
              </>
            ) : null}
            <p>Descripción</p>
            <TextArea
              className={styles.drawerTextArea}
              onChange={e => handleCecoInput(e.target.value, 'description')}
              placeholder="Agrega una breve descripción para este centro de costo..."
              value={cecoInput.description ?? costCenter?.description}
            />
          </Col>
        </Row>
      </Col>
    </Drawer>
  )
}

export default CECODrawer

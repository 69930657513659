import React, { FC } from 'react'
import { Col, Drawer, Row } from 'antd'

import PassengerForm from '../../SeatSelection/PassengerForm'
import PassengerGroups from '../../SeatSelection/PassengersGroups'
import UploadPassengers from '../../SeatSelection/UploadPassengers'
import { MainDrawerProps } from '../../types'

import styles from './styles.module.css'

const MainDrawer: FC<MainDrawerProps> = ({
  handleDrawerButton,
  handleVisible,
  step,
  visible,
}) => {
  return (
    <Drawer
      headerStyle={{ textAlign: 'start' }}
      onClose={() => {
        handleVisible(false)
        handleDrawerButton(0)
      }}
      placement="right"
      title={
        (step === 0 && 'Agregar pasajeros registrados') ||
        (step === 1 && 'Agregar pasajero') ||
        (step === 2 && 'Carga de pasajeros')
      }
      visible={visible}
      width={window?.innerWidth > 900 ? 600 : window?.innerWidth - 10}>
      <Row className={styles.drawerMainRow} justify="center">
        {step === 0 && (
          <PassengerGroups handleDrawerButton={handleDrawerButton} />
        )}
        {step === 1 && (
          <PassengerForm handleDrawerButton={handleDrawerButton} />
        )}
        {step === 2 && (
          <UploadPassengers handleDrawerButton={handleDrawerButton} />
        )}
      </Row>
    </Drawer>
  )
}

export default MainDrawer

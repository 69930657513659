import React, { FC } from 'react'

import { CostCenter } from '../../../types/page-types/pay-page.types'
import { Passenger } from '../../travel-page/types'

import styles from './styles.module.css'

type PropTypes = {
  passengerList: Partial<Passenger>[]
  onPassengerClick: (passenger: Partial<Passenger>) => void
}

const PassengerListPhone: FC<PropTypes> = ({
  passengerList,
  onPassengerClick,
}) => {
  const renderList = () => {
    return passengerList.map((passenger, idx) => {
      const costCenter = passenger.costCenter as CostCenter
      return (
        <>
          <div
            key={idx.toString()}
            className={styles.passengerList}
            onClick={() => onPassengerClick(passenger)}>
            <div className={styles.leftPanel}>
              <div className={styles.userName}>
                {passenger.firstName} {passenger.fatherLastName}
              </div>
              <div className={styles.userEmail}>{passenger.email}</div>
              <div className={styles.companyPosition}>
                {passenger.position || ' '}
              </div>
            </div>
            <div className={styles.costCenter}>
              Centro de costo: {costCenter.name}
            </div>
          </div>
          <div className={styles.divider} />
        </>
      )
    })
  }

  return <div className={styles.passengerListPhone}>{renderList()}</div>
}

export default PassengerListPhone

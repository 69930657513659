import React, { FC } from 'react'
import { Alert, Button, Col, Row } from 'antd'
import { navigate } from 'gatsby'

const AlertMessage: FC = () => (
  <Row justify="center">
    <Col>
      <Alert
        message="Error"
        description="Ha habido un error al intentar obtener el viaje seleccionado."
        type="error"
        showIcon
      />
      <Button block onClick={() => navigate(-1)}>
        Volver
      </Button>
    </Col>
  </Row>
)

export default AlertMessage

import { useEffect, useState } from 'react'

const isClient = typeof window === 'object'

const getSize = () => ({
  width: isClient ? window.innerWidth : undefined,
  height: isClient ? window.innerHeight : undefined,
})

interface WindowSize {
    width: number | undefined,
    height: number | undefined,
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {

    const handleResize = () => setWindowSize(getSize())

    const onFullscreen = () =>
      document.fullscreenElement &&
      window.removeEventListener('resize', handleResize)

    window.addEventListener('resize', handleResize)
    document.addEventListener('fullscreenchange', onFullscreen)

    return () => {
      window.removeEventListener('resize', handleResize)
      document.removeEventListener('fullscreenchange', onFullscreen)
    }

  }, [])

  return windowSize
}

export default useWindowSize

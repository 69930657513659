import React, { useContext } from 'react'

import TravelList from './Common/TravelList'
import AnonymousTraveler from './AnonymousTraveler'
import PayPage from './PayPage'
import { TravelPageContext } from './Provider'
import SeatSelection from './SeatSelection'
import { renderStepParams } from './types'

export const renderStep = ({
  onSelectGone,
  onSelectReturn,
  search,
  passengersView,
}: renderStepParams): JSX.Element | undefined => {
  const { hasReturn, step } = useContext(TravelPageContext)
  const {
    departureCity,
    destinationCity,
    departureDate,
    destiny,
    origin,
    numberOfSeats,
    returnDate,
  } = search
  if (
    !departureCity ||
    !destinationCity ||
    !departureDate ||
    !numberOfSeats ||
    isNaN(parseInt(numberOfSeats))
  )
    return undefined

  if (passengersView) return <SeatSelection />

  if (step === 0)
    return (
      <TravelList
        date={departureDate}
        destiny={destinationCity}
        destinyName={destiny ?? ''}
        numberOfSeats={parseInt(numberOfSeats)}
        origin={departureCity}
        originName={origin ?? ''}
        onSelect={onSelectGone}
      />
    )

  if (step === 1 && hasReturn)
    return (
      <TravelList
        date={returnDate as string}
        destiny={departureCity}
        destinyName={origin ?? ''}
        numberOfSeats={parseInt(numberOfSeats)}
        origin={destinationCity}
        originName={destiny ?? ''}
        onSelect={onSelectReturn}
      />
    )

  if (step === (hasReturn ? 2 : 1)) return <AnonymousTraveler />
  if (step === (hasReturn ? 3 : 2)) return <PayPage />
}

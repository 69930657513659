import React, { FC } from 'react'
import {
  DownloadOutlined,
  QuestionCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { Button, Pagination, Table, Tooltip } from 'antd'

import { formatDate } from '../../../helpers/dateLongFormat'
import { renderStatus } from '../../../helpers/invoices'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { Invoice } from '../../../types/invoice.type'
import { InvoiceListWebViewProps } from '../types'

import styles from './styles.module.css'

const InvoiceListWebView: FC<InvoiceListWebViewProps> = ({
  loading,
  openDocumentsDrawer,
  changePage,
  excelLoading,
  getExcel,
  columns,
  invoices,
  totalInvoices,
  invoicesByPage,
  selectedPage,
  setInvoicesByPage,
}) => {
  const createCol = (
    content: JSX.Element,
    label?: string,
    tooltip?: JSX.Element,
  ) => (
    <div>
      {label ? (
        <div className={styles.tableRowLabel}>
          {label} {tooltip}
        </div>
      ) : null}
      {content}
    </div>
  )

  const createRow = (invoice: Invoice, idx: number) => {
    return {
      key: invoice._id,
      documents: createCol(
        <Button
          className={!invoice.sent ? styles.uploadButton : styles.detailButton}
          onClick={() => openDocumentsDrawer(invoice)}>
          {invoice.sent ? 'Detalles' : <UploadOutlined />}
        </Button>,
        'OC/HES',
      ),
      invoiceNumber: createCol(
        <div className={styles.tableRowData}>
          {invoice.invoiceNumber ?? '---'}
        </div>,
        'Núm. de factura',
      ),
      accountNumber: createCol(
        <div className={styles.tableRowData}>
          {invoice.currentAccount?.sapNumber ?? '---'}
        </div>,
        'Cuenta corriente',
      ),
      dates: createCol(
        <>
          <div className={styles.dateData}>
            Emisión: {formatDate(invoice.emissionDate) ?? '---'}
          </div>
          <div className={styles.dateData}>
            Vence: {formatDate(invoice.dueDate) ?? '---'}
          </div>
        </>,
        undefined,
      ),
      amount: createCol(
        <div className={styles.tableRowData}>
          ${numberWithSeparator(invoice?.totalAmount ?? 0)}
        </div>,
        'Monto',
      ),
      status: createCol(
        <div
          className={`${styles.tableRowData} ${
            styles[renderStatus(invoice.status)?.class ?? '']
          }`}>
          {renderStatus(invoice.status)?.text}
        </div>,
        'Estado',
        idx === 0 ? (
          <Tooltip
            title={
              <div>
                <div>Tipos de estado:</div>
                <div>No Pagado: factura pendiente de pago.</div>
                <div>Vencido: fecha de pago ha expirado.</div>
                <div>En Proceso: factura no ha sido emitida.</div>
                <div>Pagado: factura se encuentra pagada.</div>
                <div>Anulado: factura fue anulada</div>
              </div>
            }>
            <QuestionCircleOutlined className={styles.tooltip} />
          </Tooltip>
        ) : undefined,
      ),
      excel: createCol(
        <Button
          loading={excelLoading === invoice._id}
          icon={<DownloadOutlined />}
          className={styles.documentButton}
          onClick={() => getExcel(invoice._id, invoice.invoiceNumber ?? '')}>
          Excel
        </Button>,
        'Movimientos',
      ),
      pdf: createCol(
        <a href={invoice.invoicePath}
        target="blank">
        <Button
          className={styles.documentButton}
          icon={<DownloadOutlined />}
          disabled={!invoice.invoicePath}
          >
          PDF
        </Button>
        </a>,
        'Factura',
      ),
    }
  }

  return (
    <>
      <Table
        loading={loading}
        dataSource={invoices?.map((invoice: Invoice, index: number) =>
          createRow(invoice, index),
        )}
        columns={columns}
        pagination={false}
      />
      <div id="pagination">
        {totalInvoices > 0 && invoicesByPage > 0 ? (
          <Pagination
            defaultCurrent={selectedPage}
            current={selectedPage}
            total={totalInvoices}
            onChange={e => changePage(parseInt(e.toString()))}
            defaultPageSize={invoicesByPage}
            onShowSizeChange={(current, pageSize) =>
              setInvoicesByPage(pageSize)
            }
            showSizeChanger={false}
            pageSizeOptions={['5', '10', '20', '50']}
          />
        ) : null}
      </div>
    </>
  )
}

export default InvoiceListWebView

import React, { FC, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Col, DatePicker, Divider, Row, Select, Tooltip } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment, { Moment } from 'moment'

import getDifferenceDays from '../../../helpers/getDifferenceDays'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { useScreenWidth } from '../../../hooks/screen.hook'
import { CurrentAccount } from '../../../types/currentAccount.type'
import { MY_CURRENT_ACCOUNTS } from '../graphql'
import { currentAccountDisplayer } from '../types'

import './calendarStyle.css'
import styles from './styles.module.css'

const { RangePicker } = DatePicker

const CurrentAccountDisplayer: FC<currentAccountDisplayer> = ({
  onCurrentAccountChange,
  onDateChange,
  company,
}) => {
  const { data, loading } = useQuery(MY_CURRENT_ACCOUNTS)

  const [currentAccounts, setCurrentAccounts] = useState<CurrentAccount[]>()
  const [totalBalance, setTotalBalance] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Moment | null>(
    moment().subtract(1, 'month'),
  )
  const [endDate, setEndDate] = useState<Moment | null>(moment())

  const screenWidth = useScreenWidth()

  const changeAccountsData = (currentAccount?: CurrentAccount) => {
    const balance = currentAccount
      ? currentAccount.balance ?? 0
      : company?.creditLine?.balance ?? 0
    setTotalBalance(balance)

    const amount = currentAccount
      ? currentAccount.totalAmount ?? 0
      : company?.creditLine?.totalAmount ?? 0

    setTotalAmount(amount)
  }

  const handleOnChange = (_id: string) => {
    const currentAccount = currentAccounts?.find(cc => cc._id === _id)
    onCurrentAccountChange(currentAccount)
    changeAccountsData(currentAccount)
  }

  const setDates = (dates: (Date | undefined)[]) => {
    if (dates.length !== 2) onDateChange(undefined, undefined)
    else {
      const [from, to] = dates
      setStartDate(from ? moment(from) : null)
      setEndDate(to ? moment(to) : null)
      onDateChange(from, to)
    }
  }

  useEffect(() => {
    if (data?.myCurrentAccounts) setCurrentAccounts(data.myCurrentAccounts)
    else setCurrentAccounts([])
  }, [data])

  useEffect(() => {
    setTotalBalance(company?.creditLine?.balance ?? 0)
    setTotalAmount(company?.creditLine?.totalAmount ?? 0)
  }, [company])

  return (
    <Row justify="center" align="middle">
      <Col
        span={24}
        className={`${styles.container} ${
          screenWidth <= 320
            ? styles.containerMobilePadding
            : styles.containerPadding
        }`}>
        <Row justify="space-between">
          <Col xs={24} sm={11} className={styles.centered}>
            <div className={styles.label}>Cuenta corriente</div>
            <Select
              className={styles.select}
              placeholder={loading ? 'Cargando' : 'Todas'}
              allowClear
              onChange={e => handleOnChange(e?.toString())}>
              {currentAccounts?.map(currentAccount => (
                <Select.Option
                  value={currentAccount._id}
                  key={currentAccount._id}>
                  {currentAccount.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={11} className={styles.centered}>
            <div className={styles.label}>Periodo visualizado</div>
            <Tooltip
              title={
                !isDatePickerOpen && getDifferenceDays(startDate, endDate)
              }>
              <div className={styles.rangePicker}>
                <RangePicker
                  className={styles.datePicker}
                  format="DD/MM/YYYY"
                  defaultValue={[moment().subtract(1, 'month'), moment()]}
                  onOpenChange={value => setDatePickerOpen(value)}
                  onChange={e => setDates([e?.[0]?.toDate(), e?.[1]?.toDate()])}
                  locale={locale}
                  inputReadOnly={true}
                  renderExtraFooter={() => (
                    <div>{getDifferenceDays(startDate, endDate)}</div>
                  )}
                />
              </div>
            </Tooltip>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={styles.summaryContainer}>
            <div className={styles.totalCurrentaccountsLabel}>
              {currentAccounts?.length} cuentas corrientes
            </div>
            <Divider className={styles.transtarentWhite} />
            <Row justify="space-between">
              <Col className={styles.centered} span={11}>
                <div className={styles.summaryLabel}>Saldo total</div>
                <div className={styles.summaryData}>
                  ${numberWithSeparator(totalBalance)}
                </div>
              </Col>
              <Col span={2} className={styles.centered}>
                <Divider
                  className={`${styles.transtarentWhite} ${styles.fullHeight}`}
                  type="vertical"
                  style={{ height: '100%' }}
                />
              </Col>
              <Col span={11} className={styles.centered}>
                <div className={styles.summaryLabel}>Monto crédito total</div>
                <div className={styles.summaryData}>
                  ${numberWithSeparator(totalAmount)}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default CurrentAccountDisplayer

import React, { FC } from 'react'
import { Col, Row } from 'antd'

import { AdminLayoutHeaderProps } from './types'

import styles from './styles.module.css'

const AdminLayoutHeader: FC<AdminLayoutHeaderProps> = ({
  addOn,
  quantity,
  subTitle,
  title,
}) => (
  <Row align="top" className={styles.adminsHeader} justify="space-between">
    <Col xs={12} sm={8} md={{ span: 6, offset: 4 }} lg={{ span: 6, offset: 6 }}>
      <div className={styles.title}>{title}</div>
    </Col>
    {quantity ? (
      <Col xs={12} sm={7}>
        <p>{`${quantity} ${subTitle}`}</p>
      </Col>
    ) : null}
    {addOn ? (
      <Col xs={12} sm={7} md={8} lg={8}>
        {addOn}
      </Col>
    ) : null}
  </Row>
)

export default AdminLayoutHeader

import React, { FC, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Col, Modal, Row } from 'antd'

import { AUTOMATIC_RESERVATION } from '../../../../queries'
import { TravelPageContext } from '../../Provider'
import { QueryString, Quota, Seat, Transaction } from '../../types'

import AssignSeatPicture from './assignSeat.inline.svg'

import './modalStyles.css'
import styles from './styles.module.css'

const QuestionModal: FC = () => {
  const {
    step,
    setStep,
    setVisible,
    setTransaction,
    setPassengersView,
    setQuotas,
    visible,
    search,
    transaction,
    percentDiscountValue,
  } = useContext(TravelPageContext)

  const [automaticReserve, { loading }] = useMutation(AUTOMATIC_RESERVATION)

  const createQuotas = async (goneSeats: Seat[], returnSeats: Seat[]) => {
    setQuotas(
      [...Array(parseInt(search.numberOfSeats ?? '0')).keys()].map(
        (num, index) =>
          ({
            passenger: 'Pasajero sin asignar',
            goneSeat: goneSeats?.length > 0 ? goneSeats[index] : undefined,
            returnSeat:
              returnSeats?.length > 0 ? returnSeats[index] : undefined,
          } as Quota),
      ),
    )
  }

  const reserveSeats = async (
    search: QueryString,
    transaction: Transaction,
  ) => {
    try {
      const { data } = await automaticReserve({
        variables: {
          input: {
            departure: search.departureCity,
            destination: search.destinationCity,
            goneDate: search.departureDate,
            returnDate: search.returnDate,
            numberOfSeats: parseInt(search.numberOfSeats ?? '1'),
            percentDiscountValue,
            origin: {
              serviceId: transaction?.travelGone?.id?.toString(),
              referencePrice: transaction?.travelGone?.price,
              token: transaction?.travelGone?.integration_code,
            },
            destiny: transaction.travelReturn?.id
              ? {
                  serviceId: transaction.travelReturn?.id?.toString(),
                  referencePrice: transaction.travelReturn?.price,
                  token: transaction.travelReturn?.integration_code,
                }
              : null,
          },
        },
      })

      if (data.automaticReservation) {
        const { automaticReservation } = data
        if (automaticReservation) {
          const {
            originReservation,
            originSeats,
            destinyReservation,
            destinySeats,
          } = automaticReservation
          setTransaction({
            ...transaction,
            goneReservation: originReservation,
            goneSeats: originSeats,
            returnReservation: destinyReservation,
            returnSeats: destinySeats,
          })

          createQuotas(originSeats, destinySeats)

          setStep(step + 1)
          setVisible(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      width={800}
      style={{ top: 20 }}
      className={styles.modal}
      footer={[
        <Button
          key={'manualButton'}
          onClick={() => {
            createQuotas([], [])
            setPassengersView(true)
            setVisible(false)
          }}
          className={styles.assignButton}>
          Selección Manual
        </Button>,
        <Button
          key={'randomButton'}
          onClick={() => reserveSeats(search, transaction)}
          loading={loading}
          type="text"
          className={styles.randomButton}>
          Selección Aleatoria
        </Button>,
      ]}>
      <AssignSeatPicture className={styles.assignSeatPicture} />
      <Row justify="center">
        <Col className={styles.titleFont}>
          Elige tus asientos o los elegimos por ti
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={0} sm={0} md={22} className={styles.contentFont}>
          Define si quieres elegir los asientos de tu compra o si prefieres que
          los elijamos por ti de manera aleatoria*. <br />
          Luego, podrás definir los nombres de tus pasajeros para cada asiento
          para una experiencia más personalizada. <br /> Además, envíales sus
          boletos automáticamente vía correo electrónico al cargarlos. Si lo
          prefieres, también puedes comprar sin identificarlos.
        </Col>
        <Col xs={24} sm={22} className={styles.contentFont}>
          Define si quieres elegir los asientos o si prefieres que los elijamos
          por ti*. Y luego podrás definir los nombres de tus pasajeros durante
          la compra o después.
        </Col>
      </Row>
      <Row justify="center">
        <Col xs={24} sm={22} className={styles.subContentFont}>
          (*) La elección aleatoria considera considera un llenado del bus de
          menor valor de la tarifa a la mayor. Además, por ahora, al elegir esta
          opción, no vas a poder modificar los asientos. Esta opción es sólo
          válida para la selección manual
        </Col>
      </Row>
    </Modal>
  )
}

export default QuestionModal

import React, { FC, useContext } from 'react'
import { Button, Col, Row } from 'antd'

import { RolesEnum } from '../../../../helpers/enums'
import { SessionContext } from '../../../../services/session'
import { PayMethodsProps } from '../../../../types/page-types/pay-page.types'

import styles from './styles.module.css'

const PayMethods: FC<PayMethodsProps> = ({
  handlePaymentChange,
  selectedPayment,
}) => {
  const { user, company } = useContext(SessionContext)

  const companyType = company.creditLine != null ? 'CCC' : 'SCC'

  return (
    <Row justify="space-around">
      {(user.role === RolesEnum.CCC ||
        user.role === RolesEnum.MULTI_RUT_BUYER ||
        user.role === RolesEnum.ADMIN_CCC ||
        user.role === RolesEnum.MULTI_RUT_ADMIN) && (
        <Col xs={24} md={11}>
          <Button
            className={
              selectedPayment !== 'creditLine'
                ? styles.inactiveCreditButton
                : styles.activeCreditButton
            }
            onClick={() => handlePaymentChange('creditLine')}>
            Línea de crédito
          </Button>
        </Col>
      )}

      {companyType === 'CCC' && (
        <Col xs={24} md={11}>
          <Button
            className={
              selectedPayment !== 'other'
                ? styles.inactiveCreditButton
                : styles.activeCreditButton
            }
            onClick={() => handlePaymentChange('other')}>
            Otro medio de pago
          </Button>
        </Col>
      )}
    </Row>
  )
}

export default PayMethods

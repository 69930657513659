import React, { FC } from 'react'
import { Col, Row } from 'antd'

import { SummarySectionProps } from '../types/props'

import AverageRate from './AverageRate'
import TotalCost from './TotalCost'

const SummarySection: FC<SummarySectionProps> = ({ report, userRol }) => (
  <Row justify="space-between" align="middle">
    <Col xs={24} md={12}>
      <TotalCost report={report} />
    </Col>
    <Col xs={24} md={12}>
      <AverageRate report={report} userRol={userRol} />
    </Col>
  </Row>
)

export default SummarySection

import moment, { Moment } from 'moment'

export default (startDate?: Moment | null, endDate?: Moment | null): string => {
  if (!startDate || !endDate) return 'Debes seleccionar ambas fechas'

  const duration = moment.duration(endDate.diff(startDate))
  const days = duration.asDays()

  if (days < 1) return 'Seleccionaste el mismo dia en ambas fechas'

  return `Hay una diferencia de ${days.toFixed()} dias entre las fechas seleccionadas`
}

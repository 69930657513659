import React, { FC } from 'react'
import { Col, Row } from 'antd'

import { lowerCaseStringButFirstLetter } from '../../../helpers/lowercaseStringButFirst'
import { StartOrFinishProps } from '../../types'

import styles from './styles.module.css'

const StartOrFinish: FC<StartOrFinishProps> = ({ date, city, terminal }) => {
  return (
    <Row align="middle">
      <Col style={{ marginRight: 10 }}>
        <span className={styles.tripHourFont}>{date?.split(' ')[1]}</span>
      </Col>
      <Col>
        <div className={styles.cardLightFont}>{city}</div>
        <span className={styles.cardLightFont}>
          {lowerCaseStringButFirstLetter(terminal)}
        </span>
      </Col>
    </Row>
  )
}

export default StartOrFinish

import React, { FC, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Button, Col, message, Row, Tabs } from 'antd'
import axios from 'axios'

import { ExcelFilters, TablesSectionProps } from '../types/props'

import MobileTravelTable from './MobileTravelTable'
import TravelTable from './TravelTable'

import styles from './styles.module.css'

const { TabPane } = Tabs

const renderTittleTab = (title: string, activeTab: boolean) => (
  <Col className={activeTab ? styles.activeTab : styles.disableTab}>
    {<span>{title}</span>}
  </Col>
)

const TablesSection: FC<TablesSectionProps> = ({
  purchasedTravels,
  canceledTravels,
  excelFilters,
}) => {
  const [activeTabPurchases, setActiveTabPurchases] = useState(false)
  const [activeTabReturns, setActiveTabReturns] = useState(false)

  const contentTable = purchasedTravels.length > 0 || canceledTravels.length > 0

  const handleChangeTab = (key: string) => {
    if (key === '1') {
      setActiveTabReturns(false)
      setActiveTabPurchases(true)
    } else {
      setActiveTabPurchases(false)
      setActiveTabReturns(true)
    }
  }
  const evalQueryValues = (excelFilters: ExcelFilters) => {
    const { startDate, endDate, idCurrentAccount, idCostCenter } = excelFilters
    const cleanFilters = {
      startDate,
      endDate,
      idCurrentAccount: idCurrentAccount || '',
      idCostCenter: idCostCenter || '',
    }
    return `?startDate=${cleanFilters.startDate}&endDate=${cleanFilters.endDate}&idCurrentAccount=${cleanFilters.idCurrentAccount}&idCostCenter=${cleanFilters.idCostCenter}`
  }
  const queryFilters = evalQueryValues(excelFilters)
  const xlsRoute = `${process.env.GATSBY_API_DOWNlOAD_XLS_REPORT_TEMPLATE_URI}/${queryFilters}`

  const handleClickDownload = () => {
    axios
      .get(xlsRoute, {
        method: 'GET',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        responseType: 'blob',
      })
      .then(r => {
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'reportes.xlsx')
        link.click()
      })
      .catch(e => console.log(e))
  }

  const downloadXlsButton = {
    right: (
      <Button
        className={
          contentTable ? styles.downloadButton : styles.disableDownloadButton
        }
        icon={<DownloadOutlined />}
        onClick={
          contentTable
            ? handleClickDownload
            : () =>
                message.info(
                  'No hay data seleccionada para descargar el documento Excel',
                )
        }>
        <span className={styles.downLoadButtonText}>.XLS</span>
      </Button>
    ),
  }

  return (
    <Row>
      <Col className={styles.contentCard} md={24}>
        <div className={styles.containerContent}>
          <Tabs
            tabBarExtraContent={downloadXlsButton}
            defaultActiveKey="1"
            onChange={handleChangeTab}
            className={styles.tabsContent}>
            <TabPane
              tab={renderTittleTab(
                `Compras (${purchasedTravels.length})`,
                activeTabPurchases,
              )}
              key="1">
              <Row>
                <Col xs={0} md={24}>
                  <TravelTable
                    travels={purchasedTravels}
                    showDevolutions={activeTabReturns}
                  />
                </Col>
                <Col xs={24} md={0}>
                  <MobileTravelTable
                    travels={purchasedTravels}
                    showDevolutions={activeTabReturns}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane
              tab={renderTittleTab(
                `Devoluciones (${canceledTravels.length})`,
                activeTabReturns,
              )}
              key="2">
              <Row>
                <Col xs={0} md={24}>
                  <TravelTable
                    travels={canceledTravels}
                    showDevolutions={activeTabReturns}
                  />
                </Col>
                <Col xs={24} md={0}>
                <MobileTravelTable
                    travels={canceledTravels}
                    showDevolutions={activeTabReturns}
                  />
                </Col>
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </Col>
    </Row>
  )
}

export default TablesSection

import React, { FC, Fragment, useLayoutEffect, useState } from 'react'
import { ExclamationCircleTwoTone } from '@ant-design/icons'
import { Button, Col, Divider, Modal, Row } from 'antd'

import { CostCenter } from '../../types/costCenter.type'

import CecoModalContent from './CECODrawer/CecoModalContent'
import { CostCenterListRowProps } from './types'

import styles from './styles.module.css'

const CostCenterListRow: FC<CostCenterListRowProps> = ({
  costCenters,
  handleDelete,
  handleVisible,
}) => {
  const [width, setWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const confirmModal = (costCenter: CostCenter) => {
    Modal.confirm({
      cancelText: 'No, volver',
      content: <CecoModalContent costCenter={costCenter} />,
      icon: <ExclamationCircleTwoTone />,
      okText: 'Si, eliminar',
      onOk: async () => await handleDelete(costCenter._id),
      title: <h4>¿Eliminar Centro de Costo?</h4>,
    })
  }

  const renderCostCenderMobile = () => {
    return costCenters.map((costCenter, idx) => {
      const { _id, name, status, description } = costCenter

      return (
        <>
          <Row key={idx.toString()} align="middle" justify="center">
            <Col xs={16}>
              <div>{name}</div>
              <div className={styles.currentStatus}>
                {status === 'ACTIVE' && 'Vigente'}
              </div>
            </Col>
            <Col xs={8}>
              <div className={styles.button}>
                <Button onClick={() => handleVisible(true, costCenter)}>
                  Editar
                </Button>
              </div>
              <div className={styles.button}>
                <Button onClick={() => confirmModal(costCenter)}>Borrar</Button>
              </div>
            </Col>
          </Row>
          <div className={styles.divider} />
        </>
      )
    })
  }

  return (
    <>
      <Row>
        <p className={styles.costCenterCount}>
          Mostrando {costCenters.length}{' '}
          {costCenters.length > 1 ? 'centros de costo' : 'centro de costo'}
        </p>
        <Divider />
      </Row>
      <Row align="middle" className={styles.mainCCListRow} justify="center">
        <Col className={styles.mainCCListCol} span={24}>
          {width < 900
            ? renderCostCenderMobile()
            : costCenters.map(costCenter => {
                const { _id, name, status, description } = costCenter

                return (
                  <Fragment key={_id}>
                    <Row align="middle" justify="center">
                      <Col span={14}>
                        <p className={styles.CCTitle}>Centro de costo</p>
                      </Col>
                      <Col span={9} offset={1}>
                        <p className={styles.currentStatus}>
                          {status === 'ACTIVE' && 'Vigente'}
                        </p>
                      </Col>
                    </Row>
                    <Row align="middle" justify="space-between">
                      <Col className={styles.titleCol} span={14}>
                        <p className={styles.CCName}>{name}</p>
                        <p className={styles.description}>{description}</p>
                      </Col>
                      <Col offset={4} span={6}>
                        <Row
                          className={styles.contentRow}
                          align="top"
                          justify="space-between">
                          <Col>
                            <Divider
                              className={styles.verticalDivider}
                              type="vertical"
                            />
                          </Col>
                          <Col>
                            <Row gutter={15} justify="space-between">
                              <Button
                                onClick={() => handleVisible(true, costCenter)}>
                                Editar
                              </Button>
                              <Button onClick={() => confirmModal(costCenter)}>
                                Borrar
                              </Button>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Divider className={styles.CCListRowDivider} />
                  </Fragment>
                )
              })}
        </Col>
      </Row>
    </>
  )
}

export default CostCenterListRow

import React, { FC, useContext, useEffect, useState } from 'react'
import {
  QuestionCircleOutlined,
  SearchOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { useLocation } from '@reach/router'
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Divider,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Tooltip,
} from 'antd'
import moment from 'moment'
import queryString from 'query-string'

import LoadingSpinner from '../../../../components/LoadingSpinner'
import { fullRut } from '../../../../helpers/rutValidators'
import { passengerFullName } from '../../../../helpers/userNames'
import {
  MY_DETAIL_CURRENT_ACCOUNTS,
  NEW_PASSENGERS,
  PASSENGER_BY_COMPANY,
} from '../../../../queries'
import { SessionContext } from '../../../../services/session'
import {
  CurrentAccount,
  GetCurrentAccountWithPassengers,
} from '../../../../types/currentAccount.type'
import { TravelPageContext } from '../../Provider'
import {
  Passenger,
  PassengerGroupsFilters,
  PassengerGroupsProps,
} from '../../types'

import styles from './styles.module.css'

const { Panel } = Collapse
const { Option } = Select

// TODO: Between this component & travelDetail group drawer there are several mutual components.
// TODO: Refactor component.

const PassengerGroups: FC<PassengerGroupsProps> = ({ handleDrawerButton }) => {
  const { user } = useContext(SessionContext)
  const { quotas, setQuotas, setDrawerRender } = useContext(TravelPageContext)
  const [currentAccounts, setCurrentAccounts] = useState<CurrentAccount[]>([])
  const [limitSelected, setLimitSelected] = useState(false)
  const [selectedPassenger, setSelectedPassenger] = useState<Passenger[]>([])
  const [filters, setFilters] = useState<PassengerGroupsFilters>({})
  const [newPassengersVisible, setNewPassengersVisible] = useState(true)

  /* Paginación*/

  const [selectedCostCenter, setSelectedCostCenter] = useState<string[]>([])

  const [totalPassengers, setTotalPassengers] = useState<number>(0)
  const [passengersByPage, setPassengersByPage] = useState<number>(10)
  const [passengerList, setPassengerList] = useState<Passenger[]>([])

  const [totalPassengersData, setTotalPassengersData] = useState<number>(0)
  const [passengersByPageData, setPassengersByPageData] = useState<number>(10)
  const [numPages, setNumPages] = useState(0)

  /* For searching */
  const [searchFilter, setSearchFilter] = useState<PassengerGroupsFilters>({})

  const location = useLocation()

  const { page } = queryString.parse(location.search)

  const [selectedPage, setSelectedPage] = useState<number>(
    parseInt(page?.toString() ?? '1'),
  )

  const changePage = (page: number) => {
    setSelectedPage(page)
    const paginationElement = document.getElementById('pagination')
    if (paginationElement)
      paginationElement.scrollIntoView({ behavior: 'smooth' })
  }

  const [
    passengersByCompany,
    { data: sccData, loading: sccDataLoading },
  ] = useLazyQuery(PASSENGER_BY_COMPANY, {
    fetchPolicy: 'no-cache',
    variables: {
      costCenterIds: null,
      input: {
        from: moment().format('YYYY-MM-DD HH:mm:ss'),
        to: moment().format('YYYY-MM-DD HH:mm:ss'),
        page: selectedPage,
        passengersByPage: passengersByPage > 0 ? passengersByPage : 10,
      },
      sortOrderValue: 0,
    },
  })

  const [
    getNewPassengers,
    { data: newPassengersData, loading: newPassengersLoading },
  ] = useLazyQuery(NEW_PASSENGERS, {
    fetchPolicy: 'no-cache',
  })

  const [
    getCurrentAccountWithPassengers,
    { data, loading },
  ] = useLazyQuery<GetCurrentAccountWithPassengers>(
    MY_DETAIL_CURRENT_ACCOUNTS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => setCurrentAccounts(data?.myCurrentAccounts),
    },
  )

  const handleClick = () => {
    const newQuotas = [
      ...quotas.map(quota => {
        quota.passenger = `Pasajero Sin Asignar`
        return quota
      }),
    ]

    selectedPassenger.forEach((passenger: Passenger, idx: number) => {
      newQuotas[idx].passenger = passenger
    })
    setQuotas(newQuotas)
    handleDrawerButton(0)
    setDrawerRender(false)

    message.success('Pasajeros asignados exitosamente.')
  }

  const handleSearch = () => {
    const variables = {
      filters,
    }

    if (filters) setSearchFilter(filters)
    else setSearchFilter({})

    getNewPassengers({ variables })
    // passengersByCompany({ variables })

    passengersByCompany({
      variables: {
        costCenterIds: null,
        input: {
          from: moment().format('YYYY-MM-DD HH:mm:ss'),
          to: moment().format('YYYY-MM-DD HH:mm:ss'),
          page: selectedPage,
          passengersByPage: passengersByPage > 0 ? passengersByPage : 10,
        },
        sortOrderValue: 0,
      },
    })

    getCurrentAccountWithPassengers({ variables })
    if (filters?.fullName || filters?.rut) setNewPassengersVisible(false)
  }

  const selectPassenger = (checked: boolean, passenger: Passenger) => {
    if (checked) {
      setSelectedPassenger([...selectedPassenger, passenger])

      message.success('Pasajero agregado a la lista')
    } else {
      setSelectedPassenger(
        selectedPassenger.filter(({ _id }) => _id !== passenger._id),
      )

      message.warning('Pasajero expulsado de la lista')
    }
  }

  useEffect(() => {
    setLimitSelected(quotas.length === selectedPassenger.length)
  }, [selectPassenger])

  useEffect(() => {
    setSelectedPassenger(
      quotas
        .filter(quota => quota.passenger && typeof quota.passenger !== 'string')
        .map(quota => quota.passenger as Passenger),
    )
  }, [quotas])

  useEffect(() => {
    getNewPassengers()
    // passengersByCompany()
  }, [getNewPassengers, passengersByCompany])
  // }, [getNewPassengers])

  useEffect(() => {
    if (!filters?.rut && !filters?.fullName) {
      setNewPassengersVisible(true)
      getNewPassengers()
      getCurrentAccountWithPassengers()
      passengersByCompany()
    }
  }, [
    filters,
    getNewPassengers,
    passengersByCompany,
    getCurrentAccountWithPassengers,
  ])
  const totalPassengersSize = 50
  const passengeresByPageSize = 10
  useEffect(() => {
    const calculatedNumPages = Math.ceil(
      totalPassengersSize / passengeresByPageSize,
    )
    setNumPages(calculatedNumPages)
  }, [])

  const getColumnSize = () => {
    if (numPages > 0 && numPages <= 5) return 24 / numPages
    else return 4
  }

  const renderPassengerSearch = () => (
    <Row justify="space-between" align="middle">
      <Col xs={12} sm={6}>
        Buscar pasajero{' '}
        <Tooltip title="Puedes buscar un pasajero por su nombre o RUT">
          <QuestionCircleOutlined className={styles.infoColorFont} />
        </Tooltip>
      </Col>

      <Col xs={12} sm={6}>
        <span className={styles.labelText}>Datos de pasajero</span>
      </Col>
      <Col xs={24} sm={12}>
        <Input
          className={styles.searchInput}
          placeholder="Buscar por rut"
          onChange={e => setFilters({ rut: e.target.value })}
          value={filters.rut}
          addonAfter={<SearchOutlined onClick={handleSearch} />}
          onKeyUp={e => (e.key === 'Enter' ? handleSearch() : null)}
        />
        <Input
          className={styles.searchInput}
          placeholder="Buscar por nombre"
          onChange={e => setFilters({ fullName: e.target.value })}
          value={filters.fullName}
          addonAfter={<SearchOutlined onClick={handleSearch} />}
          onKeyUp={e => (e.key === 'Enter' ? handleSearch() : null)}
        />
      </Col>
    </Row>
  )
  // TODO: puede convertirse en componente

  const renderSccPanel = (passengersByCompany: Passenger[]) => {
    return (
      <Collapse className={styles.drawerCollapse} key="by-company-collapse">
        <Panel header="Pasajeros" key="by-company">
          {passengersByCompany &&
            passengersByCompany.map(passenger => {
              const {
                _id: id,
                fatherLastName,
                firstName,
                motherLastName,
                rut,
                dv,
              } = passenger

              const isSelected = selectedPassenger.find(
                item => item._id.toString() === id,
              )

              return (
                <Row className={styles.checkBoxRow} key={id}>
                  <Col span={24}>
                    <Checkbox
                      disabled={
                        limitSelected
                          ? !selectedPassenger.some(({ _id }) => _id === id)
                          : false
                      }
                      checked={!!isSelected}
                      onChange={e =>
                        selectPassenger(e.target.checked, passenger)
                      }>
                      {passengerFullName(
                        firstName,
                        fatherLastName,
                        motherLastName,
                      )}{' '}
                      <span className={styles.rutColor}>
                        {fullRut(rut, dv)}
                      </span>
                    </Checkbox>
                  </Col>
                </Row>
              )
            })}
        </Panel>
      </Collapse>
    )
  }

  const [expandedPanels, setExpandedPanels] = useState<string[]>([])

  // another query

  const [
    loadPassengers,
    { loading: anotherLoading, networkStatus },
  ] = useLazyQuery(PASSENGER_BY_COMPANY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      const passengersByCompanyData = data.passengersByCompany
      if (passengersByCompanyData) {
        const passengersData = Object.values(
          passengersByCompanyData.data,
        ) as Passenger[]
        setTotalPassengersData(passengersByCompanyData.total)
        setPassengersByPageData(passengersByCompanyData.passengersByPage)
        setPassengerList(passengersData)
      }
    },
    variables: {
      costCenterIds: selectedCostCenter,
      filters: searchFilter,
      input: {
        from: moment().format('YYYY-MM-DD HH:mm:ss'),
        to: moment().format('YYYY-MM-DD HH:mm:ss'),
        page: selectedPage,
        passengersByPage: passengersByPageData > 0 ? passengersByPageData : 10,
      },
      sortOrderValue: 0,
    },
  })

  const applyFilter = () => {
    if (selectedCostCenter.length > 0)
      loadPassengers({
        variables: {
          costCenterIds: selectedCostCenter,
          filters: searchFilter,
          input: {
            from: moment().format('YYYY-MM-DD HH:mm:ss'),
            to: moment().format('YYYY-MM-DD HH:mm:ss'),
            page: selectedPage,
            passengersByPage:
              passengersByPageData > 0 ? passengersByPageData : 10,
          },
          sortOrderValue: 0,
        },
      })
  }

  useEffect(() => {
    applyFilter()
  }, [selectedPage, selectedCostCenter, searchFilter])

  const renderCostCenterPanel = (
    _id: string,
    name: string,
    passengers: Passenger[],
    costCenterIdsList: string[],
  ) => {
    const isExpanded = expandedPanels.includes(_id)

    const togglePanel = () => {
      if (isExpanded) {
        setExpandedPanels([])
        setSelectedCostCenter([])
        setSelectedPage(1)
      } else {
        setExpandedPanels([_id])
        setSelectedCostCenter([_id])
        setSelectedPage(1)
      }
    }

    return passengerList ? (
      <Collapse
        className={styles.drawerCollapse}
        key={`collapse-${_id}`}
        activeKey={isExpanded ? _id : undefined}
        onChange={togglePanel}>
        <Panel
          header={`${name} (${passengers?.length})`}
          key={_id}
          className={styles.paddingDiv}>
          {passengerList.map(passengerList => {
            const {
              _id: id,
              fatherLastName,
              firstName,
              motherLastName,
              rut,
              dv,
            } = passengerList

            return (
              <Row className={styles.checkBoxRow} key={id}>
                <Col span={24}>
                  <Checkbox
                    checked={selectedPassenger.some(({ _id }) => _id === id)}
                    disabled={
                      limitSelected
                        ? !selectedPassenger.some(({ _id }) => _id === id)
                        : false
                    }
                    onChange={e =>
                      selectPassenger(e.target.checked, passengerList)
                    }>
                    {passengerFullName(
                      firstName,
                      fatherLastName,
                      motherLastName,
                    )}{' '}
                    <span className={styles.rutColor}>{fullRut(rut, dv)}</span>
                  </Checkbox>
                </Col>
              </Row>
            )
          })}

          {/* Pagination */}
          <Row justify="center">
            {/* <Col xs={24} lg={24} xl={10}> */}
            <Col span={getColumnSize()}>
              <div id="pagination">
                {totalPassengersData > 0 && passengersByPageData > 0 ? (
                  <Pagination
                    current={selectedPage}
                    total={totalPassengersData}
                    onChange={e => changePage(parseInt(e.toString()))}
                    defaultPageSize={passengersByPageData}
                    onShowSizeChange={(current, pageSize) =>
                      setPassengersByPageData(pageSize)
                    }
                    showSizeChanger={false}
                    pageSizeOptions={['5', '10', '20', '50']}
                  />
                ) : null}
              </div>
            </Col>
          </Row>
          {/* End Pagination */}
        </Panel>
      </Collapse>
    ) : null
  }

  // const renderCccPanel = (currentAccounts: CurrentAccount[]) =>

  // currentAccounts.map(({ costCenters }) =>
  //   costCenters.map(({ _id, name, passengers }) => {

  //     return renderCostCenterPanel(
  //       _id,
  //       name,
  //       passengers,
  //       // selectedPage,
  //       // passengers.length,
  //       // passengersByPage
  //     );
  //   })
  // );

  const renderCccPanel = (currentAccounts: CurrentAccount[]) => {
    const costCenterIdsList: string[] = []

    currentAccounts.forEach(({ costCenters }) => {
      costCenters.forEach(({ _id }) => {
        costCenterIdsList.push(_id)
      })
    })

    return currentAccounts.map(({ costCenters }) =>
      costCenters.map(({ _id, name, passengers }) =>
        renderCostCenterPanel(_id, name, passengers, costCenterIdsList),
      ),
    )
  }

  const renderCurrentAccountSelect = () => {
    const onChange = (e: string) =>
      e && data
        ? setCurrentAccounts(
            data.myCurrentAccounts.filter(({ _id }) => _id?.toString() === e),
          )
        : data && setCurrentAccounts(data.myCurrentAccounts)

    const mapOption = (_id: string, name: string) => (
      <Option key={_id} value={_id}>
        {name}
      </Option>
    )

    return user.role.includes('CCC') ? (
      <Select
        className={styles.cecoFilter}
        onChange={onChange}
        placeholder="Filtre centro de costo por cuenta corriente"
        optionFilterProp="children"
        showArrow={false}
        defaultValue={data?.myCurrentAccounts[0]._id}
        showSearch>
        {data?.myCurrentAccounts.map(({ _id, name }) => mapOption(_id, name))}
      </Select>
    ) : null
  }
  const renderPassengerCollapse = () =>
    user.role.includes('SCC')
      ? renderSccPanel(sccData?.passengersByCompany.data)
      : renderCccPanel(currentAccounts)

  const controlCheckbockLimit = (id: string) =>
    limitSelected ? !selectedPassenger.some(({ _id }) => _id === id) : false

  const renderNewPassengersCollapse = (passengers: Passenger[]) => (
    <Collapse className={styles.drawerCollapse}>
      <Panel
        header={`Pasajeros recientes (${passengers?.length || 0})`}
        key="search panel">
        {passengers.map(passenger => {
          const {
            _id: id,
            fatherLastName,
            firstName,
            motherLastName,
            rut,
            dv,
          } = passenger

          return (
            <Row className={styles.checkBoxRow} key={id}>
              <Col span={24}>
                <Checkbox
                  checked={selectedPassenger.some(({ _id }) => _id === id)}
                  disabled={controlCheckbockLimit(id)}
                  onChange={e => selectPassenger(e.target.checked, passenger)}>
                  {passengerFullName(firstName, fatherLastName, motherLastName)}{' '}
                  <span className={styles.rutColor}>{fullRut(rut, dv)}</span>
                </Checkbox>
              </Col>
            </Row>
          )
        })}
      </Panel>
    </Collapse>
  )
  const renderInputsContainer = () => (
    <>
      {renderCurrentAccountSelect()}
      {renderPassengerSearch()}
    </>
  )

  const renderMainContainer = () => (
    <Col span={24} className={styles.mainContainer}>
      {renderInputsContainer()}
      {loading || sccDataLoading || newPassengersLoading ? (
        <LoadingSpinner size="50px" />
      ) : (
        <>
          {newPassengersVisible
            ? renderNewPassengersCollapse(
                newPassengersData?.getNewPassengers || [],
              )
            : null}
          {/* aqui se renderizan las pestañas de CC */}
          {renderPassengerCollapse()}
        </>
      )}
    </Col>
  )

  const renderSecondContainer = () => (
    <Col className={styles.secondaryContainer} span={24}>
      <Row align="middle" justify="space-between">
        <p className={styles.helpText}>
          ¿No encuentras un pasajero?. Agrégalo ahora
        </p>
        <Col xs={24} lg={11}>
          <Button
            block
            icon={<UserAddOutlined />}
            className={styles.multipleChargeButton}
            onClick={() => handleDrawerButton(1)}>
            Agregar pasajero
          </Button>
        </Col>
        <Col xs={0} lg={11}>
          <Button
            block
            icon={<UsergroupAddOutlined />}
            className={styles.multipleChargeButton}
            onClick={() => handleDrawerButton(2)}>
            Carga múltiple
          </Button>
        </Col>
      </Row>
    </Col>
  )

  const renderFooter = () => (
    <Col span={24}>
      <Row
        align="middle"
        className={styles.drawerButtonRow}
        justify="center"
        gutter={25}>
        <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
          <Button
            block
            className={styles.cancelButton}
            onClick={() => setDrawerRender(false)}>
            Cancelar
          </Button>
        </Col>
        <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
          <Button block className={styles.continueButton} onClick={handleClick}>
            Agregar {selectedPassenger.length ?? ''} pasajeros
          </Button>
        </Col>
      </Row>
    </Col>
  )

  return (
    <>
      {renderMainContainer()}
      {renderSecondContainer()}
      <Divider />
      {renderFooter()}
    </>
  )
}

export default PassengerGroups

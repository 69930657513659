import React, { FC } from 'react'
import { UserAddOutlined } from '@ant-design/icons'
import { Avatar, Col, Divider, Form, Input, Row } from 'antd'

import PhonePrefix from '../../../components/phonePrefix'
import { formatRut, validateRut } from '../../../helpers/rutValidators'

import styles from './styles.module.css'

const { Item } = Form

const BasicInfoRow: FC = () => (
  <Row className={styles.userForm}>
    <Col xs={15} sm={15}>
      <Avatar className={styles.avatar} icon={<UserAddOutlined />} />
    </Col>
    <Col xs={24} sm={18}>
      <p className={styles.labelText}>Nombre</p>
      <Item
        className={styles.itemRow}
        name="firstName"
        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
        <Input />
      </Item>
    </Col>
    <Col xs={24} sm={18}>
      <p className={styles.labelText}>Apellido</p>
      <Item
        className={styles.itemRow}
        name="lastName"
        rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
        <Input />
      </Item>
    </Col>
    <Col xs={24} sm={18}>
      <p className={styles.labelText}>Rut</p>
      <Item
        className={styles.itemRow}
        name="rut"
        normalize={companyRut => {
          return formatRut(companyRut)
        }}
        rules={[
          { required: true, message: 'Este campo es obligatorio' },
          ({}) => ({
            validator(rule, value) {
              if (validateRut(value) || !value) return Promise.resolve()

              return Promise.reject('RUT inválido')
            },
          }),
        ]}>
        <Input />
      </Item>
    </Col>
    <Col xs={24} sm={18}>
      <p className={styles.labelText}>Teléfono</p>
      <Item
        className={styles.itemRow}
        name="phone"
        normalize={value => value.replace(/\D/, '')}
        rules={[
          { required: true, message: 'Este campo es obligatorio' },
          {
            max: 9,
            message: 'no se permiten mas de 9 caracteres',
          },
          {
            pattern: /^[0-9]*$/,
            message: 'debe ingresar solo números',
          },
        ]}>
        <Input addonBefore={<PhonePrefix />} className={styles.phoneInput} />
      </Item>
    </Col>
    <Col xs={24} sm={18}>
      <p className={styles.labelText}>Correo electrónico</p>
      <Item
        className={styles.itemRow}
        name="email"
        rules={[
          { required: true, message: 'Este campo es obligatorio' },
          {
            pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
            message: 'Introduzca un correo válido',
          },
        ]}>
        <Input />
      </Item>
    </Col>
    <Divider />
  </Row>
)

export default BasicInfoRow

import React, { FC } from 'react'
import { Col, Row } from 'antd'

import OptionsHeader from '../../../components/OptionsHeader'
import { TravelLayoutProps } from '../types'

import TravelFooter from './footer'
import SubHeader from './subHeader'

import styles from './styles.module.css'

const TravelPageLayout: FC<TravelLayoutProps> = ({
  anonymous,
  children,
  onBack,
}) => (
  <Row align="stretch">
    <Col span={24} className={styles.colFullHeight}>
      <OptionsHeader fixedOnMobile={true} />
      <SubHeader onBack={onBack} />
      <Row>{children}</Row>
      {!anonymous && <TravelFooter />}
    </Col>
  </Row>
)

export default TravelPageLayout

import React, { FC, useContext, useEffect, useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'

import { TravelPageContext } from '../../Provider'
import { SeatDisplayProps } from '../../types'

import OneWayTrip from './OneWayTrip'

import styles from './styles.module.css'

const TwoWayTrip: FC<SeatDisplayProps> = ({ onChangeTravel }) => {
  const {
    transaction,
    currentQuotaSelected,
    hasReturn,
    quotas,
    activeIsGoneTravel,
  } = useContext(TravelPageContext)

  const [isGone, setIsGone] = useState<boolean>(true)

  const onclick = (isGone: boolean): void => {
    setIsGone(isGone)
    onChangeTravel(isGone)
  }

  useEffect(() => {
    if (hasReturn)
      if (
        currentQuotaSelected &&
        quotas.indexOf(currentQuotaSelected) === 0 &&
        currentQuotaSelected.goneSeat &&
        currentQuotaSelected.returnSeat
      )
        onclick(!isGone)
  }, [currentQuotaSelected])

  useEffect(() => {
    if (activeIsGoneTravel !== undefined) onclick(activeIsGoneTravel)
  }, [activeIsGoneTravel])

  return transaction.travelReturn ? (
    <Row justify="space-around" className={styles.paddingTop1}>
      <Col span={10}>
        <Button
          block
          onClick={() => onclick(true)}
          className={isGone ? styles.travelButtonActive : styles.travelButton}
          autoFocus>
          <RightOutlined /> Ida
        </Button>
      </Col>
      <Col span={10}>
        <Button
          block
          onClick={() => onclick(false)}
          className={isGone ? styles.travelButton : styles.travelButtonActive}>
          <LeftOutlined />
          Vuelta
        </Button>
      </Col>
      <Col span={24}>
        <OneWayTrip
          service={isGone ? transaction.travelGone : transaction.travelReturn}
          isGone={isGone}
        />
      </Col>
    </Row>
  ) : (
    <Row className={styles.paddingTop1}>
      <Col span={24}>
        <Button
          block
          onClick={() => onclick(true)}
          className={styles.travelButtonActive}
          autoFocus>
          <RightOutlined /> Ida
        </Button>
      </Col>
      <Col span={24}>
        <OneWayTrip service={transaction.travelGone} isGone={true} />
      </Col>
    </Row>
  )
}

export default TwoWayTrip

import { gql } from '@apollo/client'

export const GET_COMPANY_INFORMATION = gql`
  query companyDetail($companyId: String!) @api(name: "users"){
    companyDetail(companyId: $companyId) {
      _id
     name
        rut
        dv
      estimatedAmount{
          from
          to
      }
      estimatedPassengers{
          from
          to
      }
      businessArea
      commercialActivity
      country
      city
      commune
      street
      streetNumber
      executiveAssigned {
        _id
        firstName
        lastName
        email
        phone
      }
      creditLine {
        _id
        totalAmount
        balance
      }
    }
  }
`
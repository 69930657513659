import React, { FC } from 'react'
import { DownloadOutlined, ToTopOutlined } from '@ant-design/icons'
import { Button, Col, Row } from 'antd'

import styles from './styles.module.css'

const DownloadTemplateButton: FC = () => (
  <Row align="middle" justify="center" style={{ height: '20%' }}>
  <Col span={24}>
    <Row align="middle" justify="center">
      <p className={styles.baseFont}>
        Descarga esta plantilla y llénala los datos correspondientes.
      </p>
    </Row>
    <Row align="middle" justify="center">
      <Button
        icon={<DownloadOutlined />}
        href={process.env.GATSBY_API_DOWNLOAD_CECOS_TEMPLATE_URI}
        className={styles.downloadButton}
        >
        Descargar Plantilla
      </Button>
    </Row>
  </Col>
</Row>
)

export default DownloadTemplateButton

import React, { FC } from 'react'
import { Col, Row, Select, Typography } from 'antd'
const { Text } = Typography
const { Option } = Select

import { CurrentAccountSelectProps } from '../../../../types/page-types/pay-page.types'

import styles from './styles.module.css'

const CurrentAccountSelect: FC<CurrentAccountSelectProps> = ({
  currentAccounts,
  handleCreditLine,
}) => (
  <Col className={styles.inputCol} xs={24} md={11}>
    <Text>Cuenta corriente</Text>
    <Row align="middle" className={styles.inputContainer} justify="center">
      <Select
        bordered={false}
        className={styles.currentAccountSelect}
        defaultValue=""
        onChange={e => handleCreditLine(e)}
        optionFilterProp="children"
        showSearch>
        {currentAccounts &&
          currentAccounts.map(({ _id, name }) => (
            <Option value={_id} key={_id}>
              {name}
            </Option>
          ))}
      </Select>
    </Row>
  </Col>
)

export default CurrentAccountSelect

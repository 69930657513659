import React, { FC, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from '@reach/router'
import { Col, Row } from 'antd'
import axios from 'axios'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import { getCurrentUser, getUser } from '../../../services/auth'
import { Invoice } from '../../../types/invoice.type'
import DocumentsDrawer from '../documentsDrawer'
import { GET_INVOICES } from '../graphql'
import { filterProps } from '../types'

import InvoiceListMobileView from './mobile'
import InvoiceListWebView from './web'

import styles from './styles.module.css'

const InvoicesList: FC<filterProps> = ({
  currentAccount,
  from,
  to,
  company,
}) => {
  const location = useLocation()

  const { page } = queryString.parse(location.search)

  const [selectedPage, setSelectedPage] = useState<number>(
    parseInt(page?.toString() ?? '1'),
  )

  const [invoices, setInvoices] = useState<Invoice[]>()
  const [totalInvoices, setTotalInvoices] = useState<number>(0)
  const [invoicesByPage, setInvoicesByPage] = useState<number>(5)
  const [columns, setColumns] = useState<Record<string, string>[]>([])
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>()
  const [excelLoading, setExcelLoading] = useState<string | undefined>()

  const currentUser = getCurrentUser();
  const userRole = currentUser?.role

  const { data, loading } = useQuery(GET_INVOICES, {
    variables: {
      input: {
        currentAccount,
        from,
        to,
        page: selectedPage,
        invoicesByPage: invoicesByPage > 0 ? invoicesByPage : 5,
      },
    },
    fetchPolicy: 'no-cache',
  })

  const getExcel = (id: string, number: string) => {
    setExcelLoading(id)
    axios
      .get(
        `${process.env.GATSBY_FILES_URI?.replace(
          'files',
          '',
        )}invoices/excel/${id}/${userRole}`,
        {
          method: 'GET',
          headers: { Authorization: `Bearer ${getUser()}` },
          responseType: 'blob',
        },
      )
      .then(r => {
        const url = window.URL.createObjectURL(new Blob([r.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `movimientos factura ${number}.xlsx`)
        link.click()
        setExcelLoading(undefined)
      })
      .catch(e => console.log(e))
  }

  const openDocumentsDrawer = (invoice: Invoice) => {
    setSelectedInvoice(invoice)
  }

  const updateInvoice = (updatedInvoice: Invoice) => {
    const invoicesCopy = [...(invoices ?? [])]

    setInvoices(
      invoicesCopy.map(invoice => {
        if (invoice._id === updatedInvoice._id) {
          invoice.gloss = updatedInvoice.gloss
          invoice.hes = updatedInvoice.hes
          invoice.purchaseOrder = updatedInvoice.purchaseOrder
        }

        return invoice
      }),
    )
  }

  useEffect(() => {
    setSelectedPage(parseInt(page?.toString() ?? '1'))
  }, [page])

  const changePage = (page: number) => {
    setSelectedPage(page)
    navigate(`/app/facturas?page=${page}#pagination`)
  }

  useEffect(() => {
    if (data?.getInvoices) {
      setInvoices(data?.getInvoices.data)
      setTotalInvoices(data?.getInvoices.total)
      setInvoicesByPage(data?.getInvoices.invoicesByPage)
    } else {
      setInvoices([])
      setTotalInvoices(0)
      setInvoicesByPage(0)
    }
  }, [data])

  useEffect(() => {
    const columnsBuilder = [
      'invoiceNumber',
      'accountNumber',
      'dates',
      'amount',
      'status',
      'excel',
      'pdf',
    ]
    if (company?.hasERP) columnsBuilder.unshift('documents')

    const newColumns = columnsBuilder.map(data => ({
      dataIndex: data,
      key: data,
    }))

    setColumns(newColumns)
  }, [company])

  return (
    <Row>
      <Col span={24} className={styles.container}>
        <Row align="middle">
          <Col className={styles.totalInvoicesLabel}>
            {totalInvoices} facturas emitidas
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={0}>
            <InvoiceListMobileView
              showHESManager={company?.hasERP}
              invoices={invoices}
              openDocumentsDrawer={openDocumentsDrawer}
              getExcel={getExcel}
              excelLoading={excelLoading}
              changePage={changePage}
              selectedPage={selectedPage}
              pageBySize={invoicesByPage}
              totalInvoices={totalInvoices}
              loading={loading}
            />
          </Col>
          <Col xs={0} lg={24} className={styles.centered}>
            <InvoiceListWebView
              loading={loading}
              openDocumentsDrawer={openDocumentsDrawer}
              changePage={changePage}
              excelLoading={excelLoading}
              getExcel={getExcel}
              columns={columns}
              invoices={invoices}
              totalInvoices={totalInvoices}
              invoicesByPage={invoicesByPage}
              selectedPage={selectedPage}
              setInvoicesByPage={setInvoicesByPage}
            />
          </Col>
        </Row>
        <DocumentsDrawer
          setInvoice={setSelectedInvoice}
          invoice={selectedInvoice}
          updateInvoice={updateInvoice}
        />
      </Col>
    </Row>
  )
}

export default InvoicesList

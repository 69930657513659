import { useLayoutEffect } from 'react'

export const useNotification = (): ((msg: {
  title: string
  body: string
}) => void) => {
  const notify = (message: { title: string; body: string }) =>
    Notification.permission !== 'granted'
      ? Notification.requestPermission(permission => {
          if (permission !== 'granted')
            new Notification(message.title, { body: message.body })
        })
      : new Notification(message.title, {
          body: message.body,
        })

  useLayoutEffect(() => {
    if (Notification.permission !== 'granted') Notification.requestPermission()
  }, [])

  return notify
}

import React, { FC, useEffect, useState } from 'react'
import { Col, Divider, Row, Select, Typography } from 'antd'
import { isEmpty } from 'ramda'

import { numberWithSeparator } from '../../../helpers/number.helper'
import { CurrentAccountProps } from '../types/props'
import { CurrentAccount as CurrentAccountType } from '../types/types'

import styles from './styles.module.css'

type optionsSelect = {
  label: string
  value: string
}

const { Title, Text } = Typography
const { Option } = Select

const CurrentAccount: FC<CurrentAccountProps> = ({
  currentAccount,
  refetchGetReportByUser,
}) => {
  const [
    currentAccountSelected,
    setCurrentAccountSelected,
  ] = useState<CurrentAccountType>()
  const [optionsCurrentAccount, setOptionsCurrentAccount] = useState<
    optionsSelect[]
  >([])
  const [idCostCenterSelected, setIdCostCenterSelected] = useState<string>()
  const [optionsCostCenter, setOptionsCostCenter] = useState<optionsSelect[]>(
    [],
  )

  const getTotalAmount = (
    accounts: CurrentAccountType[],
    key: keyof Pick<CurrentAccountType, 'balance' | 'totalAmount'>,
  ) => accounts.reduce((acc, item) => acc + item[key], 0)

  const renderOptionsCurrentAccount = () =>
    !isEmpty(optionsCurrentAccount)
      ? optionsCurrentAccount.map(({ label, value }) => (
          <Option key={label} value={value}>
            {label}
          </Option>
        ))
      : null

  const renderOptionsCostCenter = () =>
    !isEmpty(optionsCostCenter)
      ? optionsCostCenter.map(({ label, value }) => (
          <Option key={label} value={value}>
            {label}
          </Option>
        ))
      : null

  const renderBalance = () => (
    <Title level={1} className={styles.title}>
      {`$${numberWithSeparator(
        currentAccountSelected?.balance ||
          getTotalAmount(currentAccount, 'balance'),
      )}`}
    </Title>
  )

  const renderTotalAmount = () => (
    <Title level={4} className={styles.text}>
      {`$${numberWithSeparator(
        currentAccountSelected?.totalAmount ||
          getTotalAmount(currentAccount, 'totalAmount'),
      )}`}
    </Title>
  )

  const handleChangeCurrentAccount = (idCurrentAccount: string) => {
    const selected = currentAccount.find(({ _id }) => _id === idCurrentAccount)

    if (selected) {
      refetchGetReportByUser(idCurrentAccount, undefined)
      setCurrentAccountSelected(selected)
      setIdCostCenterSelected(undefined)
      setOptionsCostCenter(
        selected.costCenters.map(x => ({
          label: x.name,
          value: x._id,
        })),
      )
    }
  }

  const handleChangeCostCenter = (idCostCenter: string) => {
    if (idCostCenter && currentAccountSelected) {
      setIdCostCenterSelected(idCostCenter)
      refetchGetReportByUser(currentAccountSelected._id, idCostCenter)
    }
  }

  const handleClearCurrentAccount = () => {
    refetchGetReportByUser(undefined, undefined)
    setCurrentAccountSelected(undefined)
    setIdCostCenterSelected(undefined)
    setOptionsCostCenter([])
  }

  const handleClearCostCenter = () => {
    refetchGetReportByUser(currentAccountSelected?._id, undefined)
    setIdCostCenterSelected(undefined)
  }

  useEffect(() => {
    const setData: optionsSelect[] = currentAccount.map(({ _id, name }) => ({
      label: name,
      value: _id,
    }))
    setOptionsCurrentAccount(setData)
  }, [currentAccount])

  return (
    <Row className={styles.rowContainer}>
      <Col className={styles.containerCard} span={24}>
        <div className={styles.containerContent}>
          <Row align="top" justify="space-between">
            <Col span={24}>
              <Row justify="space-between">
                <Col span={8}>
                  <Text className={styles.textOpacity}>Saldo</Text>
                </Col>
                <Col span={15}>
                  <Select
                    allowClear
                    className={styles.selectBalance}
                    onChange={handleChangeCurrentAccount}
                    onClear={handleClearCurrentAccount}
                    optionFilterProp="children"
                    placeholder="Cuenta corriente"
                    showSearch
                    size="middle">
                    {renderOptionsCurrentAccount()}
                  </Select>
                </Col>
              </Row>
              <Row justify="space-between" style={{ marginTop: '1rem' }}>
                <Col span={8}>
                  <Text className={styles.textOpacity}>Centros de costo</Text>
                </Col>
                <Col span={15}>
                  <Select
                    allowClear
                    className={styles.selectBalance}
                    onChange={handleChangeCostCenter}
                    onClear={handleClearCostCenter}
                    optionFilterProp="children"
                    placeholder="Centro de costo"
                    showSearch
                    size="middle"
                    value={idCostCenterSelected}
                    notFoundContent={
                      'Selecciona una CC para poder elegir un CECO'
                    }>
                    {renderOptionsCostCenter()}
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={22} className={styles.colBalance}>
              {renderBalance()}
            </Col>
          </Row>
          <Divider plain className={styles.divider} />
          <Row justify="space-between" align="middle">
            <Col>
              <Text className={styles.textOpacity}>Cupo Total</Text>
            </Col>
            <Col>{renderTotalAmount()}</Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default CurrentAccount

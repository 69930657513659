import React, { FC } from 'react'
import { Col, Divider, Row } from 'antd'

import {
  BiobioLogo,
  CondorLogo,
  JacLogo,
  TurbusLogo,
} from '../../assets/operators'
import { lowerCaseStringButFirstLetter } from '../../helpers/lowercaseStringButFirst'
import { TravelInfoBoxProps } from '../types'

import {
  biobioStyles,
  condorStyles,
  jacStyles,
  turbusStyles,
} from './logoStyles'

import styles from './styles.module.css'

const MobileTravelInfoBox: FC<TravelInfoBoxProps> = ({
  departureTripDuration,
  returnTripDuration,
  transaction,
  type,
}) => {
  const travel =
    type === 'gone' ? transaction?.travelGone : transaction?.travelReturn

  const renderLogo = (operatorName: string) => {
    if (operatorName === 'TURBUS') return <TurbusLogo style={turbusStyles} />

    if (operatorName === 'CONDOR BUS')
      return <img src={CondorLogo} alt="condorLogo" style={condorStyles} />

    if (operatorName === 'JAC LTDA.')
      return <img src={JacLogo} alt="jacLogo" style={jacStyles} />

    if (operatorName === 'BUSES BIO-BIO')
      return <img src={BiobioLogo} alt="biobioLogo" style={biobioStyles} />

    return null
  }

  return (
    <Row justify="space-between" align="middle">
      <Col span={24}>
        <Row>
          <Col>{renderLogo(travel?.bus_operator_name ?? '')}</Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col span={8} className={styles.centered}>
            <div>
              <span className={styles.tripHourFont}>
                {travel?.departs_at?.split(' ')[1]}
              </span>
            </div>
            <div>
              <span className={styles.cardLightFont}>
                {lowerCaseStringButFirstLetter(travel?.departure_terminal_name)}
              </span>
            </div>
          </Col>
          <Col span={6} className={styles.centered}>
            <div>
              {departureTripDuration || returnTripDuration ? (
                <span className={styles.stopsNumberFont}>
                  {travel?.number_of_intermediate_sections ?? 0 > 0
                    ? `${travel?.number_of_intermediate_sections} Parada(s)`
                    : 'Directo'}
                </span>
              ) : null}
            </div>
            <Divider className={styles.noMargin} />
            <div className={styles.durationFont}>
              {type === 'gone'
                ? departureTripDuration &&
                  `${Math.floor(departureTripDuration / 60)}h `
                : returnTripDuration &&
                  `${Math.floor(returnTripDuration / 60)}h `}
              {type === 'gone'
                ? departureTripDuration && `${departureTripDuration % 60}m `
                : returnTripDuration && `${returnTripDuration % 60}m `}
            </div>
          </Col>
          <Col span={8} className={styles.centered}>
            <div>
              <span className={styles.tripHourFont}>
                {travel?.arrives_at?.split(' ')[1]}
              </span>
            </div>
            <div>
              <span className={styles.cardLightFont}>
                {lowerCaseStringButFirstLetter(
                  travel?.destination_terminal_name,
                )}
              </span>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MobileTravelInfoBox

import React, { FC, SetStateAction, useContext } from 'react'
import { Button, Col, Divider, Row } from 'antd'


import { StageButtonProps } from './props'

import styles from './styles.module.css'

const StageButtons: FC<StageButtonProps> = ({
  onCancelClick,
  onClose,
  stage,
  costCenter,
  saveCeCos
}) => {


  return (
    <>
      <Divider />

      <Col span={24}>
        <Row align="middle" className={styles.drawerButtonRow} justify="center">
          <Col span={10}>
            <Button
              block
              className={styles.cancelButton}
              onClick={onCancelClick}>
              Cancelar
            </Button>
          </Col>
          <Col span={10}>
            {stage < 3 ? (
              <Button
                block
                className={styles.continueButton}
                disabled={stage !== 2}
                onClick={saveCeCos}>
                 Crear
              </Button>
            ) : (
              <Button
                block
                className={styles.continueButton}
                onClick={saveCeCos}>
                Crear
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default StageButtons

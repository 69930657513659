import React, { FC } from 'react'
import { Col, Divider, Form, Radio, Row } from 'antd'

import { UserTypeRowProps } from '../types'

import styles from '../UpdateUserDrawer/styles.module.css'

const { Item } = Form
const { Group } = Radio

const radioStyle = {
  display: 'block',
  height: '2.5rem',
  margin: 'auto, 0',
}

const UserTypeRow: FC<UserTypeRowProps> = ({ userType }) => (
  <Item
    className={styles.itemRow}
    initialValue={userType && userType.includes('ADMIN') ? 1 : 2}
    name="role"
    rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
    <Row align="middle" className={styles.stepsRows} justify="end" gutter={25}>
      <Col xs={12} md={8}>
        <p className={styles.labelText}>Tipo de usuarios</p>
      </Col>
      <Col xs={12} md={16}>
        <Group defaultValue={userType && userType.includes('ADMIN') ? 1 : 2}>
          <Radio style={radioStyle} value={1}>
            Administrador
          </Radio>
          <Radio style={radioStyle} value={2}>
            Comprador
          </Radio>
        </Group>
      </Col>
      <Divider style={{ marginTop: 0 }} />
    </Row>
  </Item>
)

export default UserTypeRow

import moment, { Moment } from 'moment'

import { Seat, Transaction } from '../../screens/travel-page/types'
import { numberWithSeparator } from '../number.helper'

export type TravelInfoObj = {
  departureTripDuration: number
  formattedDepartureDate: string
  formattedReturnDate: string
  returnTripDuration: number
}

export type PriceItem = {
  price: number
  priceFormatted: string
  quantity: number
}

export type TravelPriceInfo = {
  gonePrices: PriceItem[]
  returnPrices?: PriceItem[]
  total: string
  totalToPay: number
}

export const getTimeDiff = (init: Moment, finish: Moment): string => {
  const diff = moment.utc(finish.diff(init)).valueOf() / 60000
  let hours: number
  let minutes: number

  if (diff % 60 === 0) {
    hours = diff / 60
    minutes = 0
  } else {
    hours = Math.floor(diff / 60)
    minutes = diff % 60
  }

  return `${hours < 10 ? '0' : ''}${hours}h ${
    minutes < 10 ? '0' : ''
  }${minutes}m`
}

export const getDepartureDate = (date: string) => {
  const newDate = moment.utc(date.split(' ')[0], 'DD/MM/YYYY').format('MMM DD')
  return newDate.charAt(0).toUpperCase() + newDate.slice(1)
}

const getTripDuration = (departs: string, arrives: string) =>
  moment(arrives, 'DD/MM/YYYY hh:mm').diff(
    moment(departs, 'DD/MM/YYYY hh:mm'),
    'minutes',
  )

export const getTravelInfo = (item?: Transaction): TravelInfoObj => {
  if (item) {
    const { travelGone, travelReturn } = item

    if (travelGone && travelReturn) {
      const departureTripDuration = getTripDuration(
        travelGone.departs_at,
        travelGone.arrives_at,
      )
      const formattedDepartureDate = getDepartureDate(travelGone.departs_at)
      const formattedReturnDate = getDepartureDate(travelReturn.departs_at)
      const returnTripDuration = getTripDuration(
        travelReturn.departs_at,
        travelReturn.arrives_at,
      )

      return {
        departureTripDuration,
        formattedDepartureDate,
        formattedReturnDate,
        returnTripDuration,
      }
    }
    if (!travelGone && travelReturn) {
      const formattedReturnDate = getDepartureDate(travelReturn.departs_at)
      const returnTripDuration = getTripDuration(
        travelReturn.departs_at,
        travelReturn.arrives_at,
      )

      return {
        departureTripDuration: 0,
        formattedDepartureDate: '',
        formattedReturnDate,
        returnTripDuration,
      }
    }
    if (travelGone && !travelReturn) {
      const departureTripDuration = getTripDuration(
        travelGone.departs_at,
        travelGone.arrives_at,
      )
      const formattedDepartureDate = getDepartureDate(travelGone.departs_at)

      return {
        departureTripDuration,
        formattedDepartureDate,
        formattedReturnDate: '',
        returnTripDuration: 0,
      }
    }
  }
  return {
    departureTripDuration: 0,
    formattedDepartureDate: '',
    formattedReturnDate: '',
    returnTripDuration: 0,
  }
}

const summarySeats = (seats: Seat[]): PriceItem[] =>
  seats?.reduce((accu: PriceItem[], current: Seat): PriceItem[] => {
    const existing = accu.find(
      reg => reg.price === (current.discount_price ?? current.price),
    )

    if (existing) {
      accu = accu.filter(
        reg => reg.price !== (current.discount_price ?? current.price),
      )
      existing.quantity = existing.quantity + 1
      accu.push(existing)
    } else
      accu.push({
        price: current.discount_price ?? current.price,
        priceFormatted: numberWithSeparator(
          current.discount_price || current.price,
        ),
        quantity: 1,
      })

    return accu
  }, [])

export const getTravelResume = (
  goneSeats: Seat[],
  returnSeats: Seat[],
): TravelPriceInfo => {
  const gonePrices = summarySeats(goneSeats ?? [])
  const returnPrices = summarySeats(returnSeats ?? [])
  const total =
    gonePrices.reduce(
      (total, toSum) => total + toSum.price * toSum.quantity,
      0,
    ) +
    returnPrices.reduce(
      (total, toSum) => total + toSum.price * toSum.quantity,
      0,
    )

  return {
    gonePrices,
    returnPrices,
    total: numberWithSeparator(total),
    totalToPay: total,
  }
}

import React, { FC, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Skeleton,
  Tooltip,
} from 'antd'
import axios, { AxiosResponse } from 'axios'

import FileInput from '../../../components/FileInput'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { getFileName } from '../../../helpers/documents'
import { UPDATE_INVOICE_DOCUMENTS } from '../graphql'
import { DrawerProps } from '../types'

import styles from './styles.module.css'

const DocumentsDrawer: FC<DrawerProps> = ({
  setInvoice,
  invoice,
  updateInvoice,
}) => {
  const DOCUMENTS = {
    HES: 'HES',
    OC: 'OC',
  }
  const [form] = Form.useForm()
  const [HES, setHES] = useState<string | undefined>()
  const [OC, setOC] = useState<string | undefined>()
  const [loadingUpload, setLoadingUpload] = useState<string | undefined>()

  const cancel = () => {
    form.resetFields()
    setInvoice(undefined)
    setHES(undefined)
    setOC(undefined)
  }

  const [updateDocuments, { loading }] = useMutation(UPDATE_INVOICE_DOCUMENTS, {
    onCompleted: ({ updateInvoiceDocuments }) => {
      updateInvoice(updateInvoiceDocuments)
      message.success('Documentos actualizados con éxito')
      cancel()
    },
  })

  const handleFileUpload = (
    files: FileList | null,
    typeDocument: string,
    onOk: (response: AxiosResponse) => void,
  ) => {
    if (files) {
      const form = new FormData()
      form.append('file', files[0])
      axios
        .post(`${process.env.GATSBY_API_UPLOAD_URI}/${typeDocument}`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(onOk)
        .catch(err => console.error(err))
    }
  }

  const uploadHES = (files: FileList) => {
    setLoadingUpload(DOCUMENTS.HES)
    handleFileUpload(files, DOCUMENTS.HES, ({ data }) => {
      setHES(data.data.fileUrl)
      setLoadingUpload(undefined)
    })
  }

  const uploadOC = (files: FileList) => {
    setLoadingUpload(DOCUMENTS.OC)
    handleFileUpload(files, DOCUMENTS.OC, ({ data }) => {
      setOC(data.data.fileUrl)
      setLoadingUpload(undefined)
    })
  }

  const onFinish = (values: Record<string, string>) => {
    if (!OC) return message.error('Se debe agregar un archivo OC')
    if (values.hesNumber && !HES)
      return message.error(
        'Se agregó un número hes, pero falta el documento asociado',
      )
    if (!values.hesNumber && HES)
      return message.error(
        'Se agregó un documento hes, pero falta el campo número asociado',
      )
    try {
      updateDocuments({
        variables: {
          invoice: invoice?._id,
          input: {
            hes: HES
              ? {
                  fileUrl: HES,
                  number: values.hesNumber,
                }
              : undefined,
            purchaseOrder: {
              fileUrl: OC,
              number: values.ocNumber,
            },
            gloss: values.gloss,
          },
        },
      })
    } catch (err) {
      message.error(err.message)
    }
  }

  const deleteFile = async (
    url: string,
    onOk: () => void,
    documentLoading: string,
  ) => {
    Modal.confirm({
      title: '¿Eliminar documento?',
      content: `El documenro ${getFileName(url)} será eliminado`,
      onOk: () => {
        setLoadingUpload(documentLoading)
        axios
          .delete(process.env.GATSBY_API_UPLOAD_URI as string, {
            data: {
              path: url,
            },
          })
          .then(onOk)
          .catch(err => console.error(err))
      },
    })
  }

  const deleteOC = (OCUrl: string) => {
    deleteFile(
      OCUrl,
      () => {
        setOC(undefined)
        setLoadingUpload(undefined)
      },
      DOCUMENTS.OC,
    )
  }

  const deleteHES = (HESUrl: string) => {
    deleteFile(
      HESUrl,
      () => {
        setHES(undefined)
        setLoadingUpload(undefined)
      },
      DOCUMENTS.HES,
    )
  }

  useEffect(() => {
    setOC(invoice?.purchaseOrder?.fileUrl)
    setHES(invoice?.hes?.fileUrl)
    form.setFieldsValue({
      ocNumber: invoice?.purchaseOrder?.number,
      hesNumber: invoice?.hes?.number,
      gloss: invoice?.gloss,
    })
  }, [invoice])

  const glossErrorMessage = (
    <span>
      Formato de glosa inválido. Se deben cumplir las siguientes condiciones:
      <ul>
        <li>Solo se aceptan 30 caracteres.</li>
        <li>No se aceptan caracteres especiales.</li>
        <li>No se permiten saltos de línea.</li>
      </ul>
    </span>
  );

  return (
    <Drawer
      visible={!!invoice}
      title="OC/HES"
      width={window?.innerWidth > 900 ? 400 : window?.innerWidth - 10}
      onClose={cancel}
      footer={
        <Row justify="end">
          <Col>
            <Button onClick={cancel}>Cancelar</Button>
            {!invoice?.sent && (
              <Button
                loading={loading}
                className={styles.saveButton}
                onClick={() => form.submit()}>
                Agregar
              </Button>
            )}
          </Col>
        </Row>
      }>
      <Row justify="center">
        <Col>
          <p className={styles.drawerTitle}>Número OC/HES</p>
          <span className={styles.drawerSubTitle}>
            Por favor, proporciona el número de OC y HES
          </span>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={24} className={styles.marginTop4em}>
          {invoice ? (
            <Form
              form={form}
              onFinish={onFinish}
              initialValues={{
                gloss: invoice?.gloss,
                ocNumber: invoice?.purchaseOrder?.number,
                hesNumber: invoice?.hes?.number,
              }}>
              <span className={styles.label}>Número OC</span>
              <Form.Item
                name="ocNumber"
                rules={[
                  { required: true, message: 'Número OC es requerido' },
                  {
                    pattern: /^(?=.*\d)[0-9-]{1,11}$/,
                    message: 'Número OC inválido. Se permiten solo hasta 11 dígitos y no letras',
                  },
                ]}>
                <Input
                  disabled={invoice?.sent}
                  maxLength={11}
                  defaultValue={invoice?.purchaseOrder?.number}
                  onKeyPress={(e) => {
                    const charCode = e.which ? e.which : e.keyCode;
                    if ((charCode < 48 || charCode > 57) && charCode !== 45) {
                      e.preventDefault();
                    }
                  }}
                  onChange={e =>
                    form.setFieldsValue({ ocNumber: e.target.value })
                  }
                  placeholder="Proporciona el numero OC"
                />
              </Form.Item>
              <span className={styles.label}>Número HES (Opcional)</span>
              <Form.Item
                name="hesNumber"
                rules={[
                  {
                    pattern: /^[A-Z0-9 _]*[A-Z0-9][A-Z0-9 _]*$/i,
                    message: 'Número HES inválido',
                  },
                ]}>
                <Input
                  // disabled={invoice?.sent}
                  disabled={true}
                  maxLength={25}
                  onChange={e =>
                    form.setFieldsValue({ hesNumber: e.target.value })
                  }
                  defaultValue={invoice?.hes?.number}
                  placeholder="Proporciona el numero HES"
                />
              </Form.Item>

              <Form.Item name="gloss"
                rules={[
                  {
                    pattern: /^(?!.*[\n\r])(?!.*[!@#$%^&*()_+=[\]{};':"\\|,.<>/?])[\w\s]{1,30}$/,
                    message: glossErrorMessage,
                  },
                ]}
              >
                <span className={styles.label}>Glosa (Opcional)</span>
                <Tooltip placement="topLeft" title="No se puede agregar numero de OC o HES en glosa. Contacte a su ejecutivo">
                  <Input.TextArea
                    disabled={invoice?.sent}
                    defaultValue={invoice?.gloss}
                    onKeyPress={(e) => {
                      const charCode = e.which ? e.which : e.keyCode;
                      if (
                        (charCode >= 65 && charCode <= 90) ||   // A-Z
                        (charCode >= 97 && charCode <= 122) ||  // a-z
                        (charCode >= 48 && charCode <= 57) ||   // 0-9
                        charCode === 32 ||                     // espacios
                        charCode === 46 ||                     // .
                        charCode === 44 ||                     // ,
                        charCode === 47 ||                     // /
                        charCode === 59                        // ;
                      ) {
                      } else {
                        e.preventDefault();
                      }
                    }}
                    onChange={e => form.setFieldsValue({ gloss: e.target.value })}
                    placeholder="Proporciona glosa de manera opcional..."
                  />
                </Tooltip>
              </Form.Item>
            </Form>
          ) : (
            <Skeleton />
          )}
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col>
          <span className={styles.drawerTitle}>Adjunto OC/HES</span>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>Archivo OC</Col>
        <Col>
          {loadingUpload === DOCUMENTS.OC ? (
            <LoadingSpinner />
          ) : OC ? (
            <span>
              {
                <Button
                  href={`${process.env.GATSBY_FILES_URI}${OC}`}
                  target="blank"
                  type="text">
                  {getFileName(OC)}
                </Button>
              }
              {!invoice?.sent && (
                <Button
                  size="small"
                  shape="circle"
                  icon="x"
                  onClick={() => deleteOC(OC ?? '')}
                />
              )}
            </span>
          ) : invoice?.sent ? (
            <p className={styles.infoColor}>Factura ya fue creada</p>
          ) : (
            <FileInput onChange={uploadOC} inputId="oc">
              Subir archivo
            </FileInput>
          )}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>Archivo HES (Opcional)</Col>
        <Col>
          {loadingUpload === DOCUMENTS.HES ? (
            <LoadingSpinner />
          ) : HES ? (
            <span>
              {
                <Button
                  href={`${process.env.GATSBY_FILES_URI}${HES}`}
                  target="blank"
                  type="text">
                  {getFileName(HES)}
                </Button>
              }
              {!invoice?.sent && (
                <Button
                  size="small"
                  shape="circle"
                  icon="x"
                  onClick={() => deleteHES(HES ?? '')}
                />
              )}
            </span>
          ) : invoice?.sent ? (
            <p className={styles.infoColor}>Factura ya fue creada</p>
          ) : (
            <FileInput onChange={uploadHES} inputId="hes">
              Subir archivo
            </FileInput>
          )}
        </Col>
      </Row>
    </Drawer>
  )
}

export default DocumentsDrawer

import React, { FC } from 'react'
import { Button, Col, Form, Input, Row, Upload } from 'antd'

import { allowedFiles, genUploadProps } from '../../../helpers/documents'
import { LevelThreeInputsProps } from '../../../types/page-types/create-ticket-page.types'

import styles from './styles.module.css'

const { Item } = Form

const LevelThreeInputs: FC<LevelThreeInputsProps> = ({
  options,
  isOperatorComplain,
  documents,
  setBoletusNumber,
  setComment,
  setTransactionNumber,
}) => {
  const uploadProps = genUploadProps(allowedFiles, documents)
  return (
    <>
      {options.includes(3) && options.includes(4) && (
        <Row justify="space-between">
          <Col xs={24} sm={11}>
            {isOperatorComplain ? (
              <Item
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                ]}
                name="boletusNumber"
               label='Núm. de boleto'
              >
                <Input
                  className={styles.halfInput}
                  placeholder={'Número de boleto'}
                  onChange={e => setBoletusNumber(e.target.value)}
                />
              </Item>
            ) : (
              <Item
                  name="boletusNumber"
                  label="Núm. de boleto"
              >
                <Input
                  className={styles.halfInput}
                  placeholder={'Número de boleto'}
                  onChange={e => setBoletusNumber(e.target.value)}
                />
              </Item>
            )}
          </Col>
          <Col xs={24} sm={11}>
            <Item label="Núm. transacción del viaje (opcional)">
              <Input
                className={styles.halfInput}
                placeholder={'Número de transacción'}
                onChange={e => setTransactionNumber(e.target.value)}
              />
            </Item>
          </Col>
        </Row>
      )}

      {options.includes(1) && (
        <Row>
          <Col xs={24}>
            <Item label="Detalles de la situación">
              <Input.TextArea
                className={styles.textArea}
                rows={10}
                showCount
                maxLength={1000}
                placeholder="Por favor explica brevemente tu situación."
                onChange={e => setComment(e.target.value)}
              />
            </Item>
          </Col>
        </Row>
      )}

      {options.includes(2) && (
        <Row justify="center">
          <Col xs={24} className={styles.center}>
            <Item>
              <Upload {...uploadProps}>
                <Button className={styles.upload}>Adjuntar archivo</Button>
              </Upload>

              <p className={styles.uploadDescriptionFont}>
                Puedes adjuntar un archivo de máximo 10 mb de peso. <br />
                Los formatos permitidos son JPEG, PNG, PDF y DOC
              </p>
            </Item>
          </Col>
        </Row>
      )}
    </>
  )
}

export default LevelThreeInputs

import React, { FC, useState } from 'react'
import { useQuery } from '@apollo/client'
import moment, { Moment } from 'moment'
import { last } from 'ramda'

import Loading from '../LoadingSpinner'

import Bar from './Bar'
import Header from './Header'
import { DISCOUNT_DETAILS } from './queries.graphql'
import {
  BarIntervalType,
  DiscountDetailType,
  DiscountInterval,
  PropsTypes,
  QueryType,
} from './types'

import styles from './styles.module.css'

const DiscountBar: FC<PropsTypes> = ({ isHome }) => {
  const [hasDiscount, setHasDiscount] = useState<boolean>(false)
  const [startPeriod, setStartPeriod] = useState<Moment | null>(null)
  const [endPeriod, setEndPeriod] = useState<Moment | null>(null)

  const [discountPercent, setDiscountPercent] = useState<number | null>(0)
  const [biggestNumber, setBiggestNumber] = useState<number | null>(0)
  const [barInterval, setBarInterval] = useState<BarIntervalType[] | null>(null)
  const [totalCompanyAmount, setTotalCompanyAmount] = useState<number | null>(0)
  const [activePercentDiscount, setActivePercentDiscount] = useState<
    number | null
  >(0)

  const { loading: loading } = useQuery(DISCOUNT_DETAILS, {
    variables: {},
    fetchPolicy: 'network-only',
    onCompleted: (data: QueryType) => {
      const { getActiveDiscountDetails } = data

      if (getActiveDiscountDetails) {
        const {
          amount,
          percentDiscount,
          discount,
          periods,
        } = getActiveDiscountDetails as DiscountDetailType

        if (!percentDiscount) return

        const { discountInterval } = percentDiscount

        const firstElement = discountInterval[0]
        const intervals =
          (firstElement.amountOf !== 0 && [
            {
              value: 0,
              percentage: 0,
              percentageLabel: '0%',
              label: '0 $MM',
            },
          ]) ||
          []

        const lastItem = last(discountInterval) as DiscountInterval
        const biggestNumber = lastItem.amountTo
        const actualPercent = (100 / biggestNumber) * amount

        discountInterval.forEach(interval => {
          const renderMargin = (100 / biggestNumber) * interval.amountOf
          intervals.push({
            value: interval.amountOf,
            percentage: renderMargin,
            percentageLabel: `${interval.percent}%`,
            label: `${interval.amountOf / 1000000} $MM`,
          })
        })

        const lastInterval = last(intervals)
        const newIntervals = intervals.map((interval, idx) => {
          if (interval.value === lastInterval?.value)
            return {
              ...interval,
              percentageLabelMargin:
                interval.percentage + (100 - interval.percentage) / 2,
            }
          else {
            const nextItem = intervals[idx + 1]
            return {
              ...interval,
              percentageLabelMargin:
                interval.percentage +
                (nextItem.percentage - interval.percentage) / 2,
            }
          }
        })

        setBiggestNumber(biggestNumber)
        setStartPeriod(moment(periods.initialDate))
        setEndPeriod(moment(periods.endDate))
        setDiscountPercent(actualPercent)
        setBarInterval(newIntervals)
        setTotalCompanyAmount(amount)
        setHasDiscount(true)
        setActivePercentDiscount(discount)
      }
    },
    onError: error => {
      setHasDiscount(false)
      console.log(error)
    },
  })

  const renderWithoutdiscount = () => (
    <div
      className={isHome ? styles.withoutDiscount : styles.PayWithoutDiscount}>
      No hay descuentos asociados a esta cuenta
    </div>
  )

  const renderDivider = () => {
    if (isHome) return <span />
    return <div className={styles.divider} />
  }

  const renderFooter = () => {
    if (!isHome) return <span />
    return (
      <div className={styles.period}>
        Desde {startPeriod?.format('LL')} hasta {endPeriod?.format('LL')}
      </div>
    )
  }

  if (loading) return <Loading size={30} white={isHome} />
  if (!hasDiscount) return renderWithoutdiscount()

  return (
    <div className={styles.container}>
      <Header
        totalCompanyAmount={totalCompanyAmount}
        startPeriod={startPeriod}
        endPeriod={endPeriod}
        isHome={isHome}
      />
      {renderDivider()}
      <Bar
        biggestNumber={biggestNumber}
        barInterval={barInterval}
        totalCompanyAmount={totalCompanyAmount}
        discountPercent={discountPercent}
        isHome={isHome}
        activePercentDiscount={activePercentDiscount}
      />
      {renderFooter()}
    </div>
  )
}

export default DiscountBar

import React, { FC, useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { Button, Col, Divider, Row, Table } from 'antd'
import { navigate } from 'gatsby'

import { SuccessIcon } from '../../assets'
import { TICKETS_RETURNED_COLUMNS } from '../../helpers/finish-redolution-page'
import { travelToIntegrationTravel } from '../../helpers/travel-page/compute.helper'
import { DevolutionState } from '../../types/page-types/devolution-finish-page.types'
import { Seat, Travel } from '../../types/page-types/travel-details-page.types'
import TravelResume from '../travel-page/PayPage/TravelResume'
import { QueryString, Transaction } from '../travel-page/types'

import styles from './styles.module.css'

const FinishDevolution: FC = () => {
  const location = useLocation() as DevolutionState

  const [transactions, setTransactions] = useState({})
  const [travelSummaries, setTravelSummaries] = useState<
    Record<string, Record<string, unknown>>
  >({})

  const createSummary = (travel: Travel): QueryString => {
    return ({
      departureCity: travel.departureCity,
      origin: travel.departureCity,
      destinationCity: travel.destinationCity,
      destiny: travel.destinationCity,
      numberOfSeats: travel.seats.length.toString(),
    } as unknown) as QueryString
  }

  const setTravelData = (travels: Travel[]) => {
    const summaries = { ...travelSummaries }
    const travelData = travels.reduce(
      (accu: Record<string, Record<string, unknown>>, travel) => {
        if (!accu[travel.bookingId]) accu[travel.bookingId] = {}

        if (travel.isGone) {
          accu[travel.bookingId].travelGone = travelToIntegrationTravel(travel)
          accu[travel.bookingId].goneSeats = travel.seats
        } else {
          accu[travel.bookingId].travelReturn = travelToIntegrationTravel(
            travel,
          )
          accu[travel.bookingId].returnSeats = travel.seats
        }

        if (!summaries[travel.bookingId]) {
          const tmp = !travel.isGone
            ? travels.find(t => t.bookingId === travel.bookingId && t.isGone) ??
              travel
            : travel
          if (tmp) summaries[travel.bookingId] = createSummary(tmp)
        }

        return accu
      },
      {},
    )
    setTravelSummaries(summaries)

    return travelData
  }

  const getSeatByTicket = (ticket: string): Seat | undefined => {
    if (location?.state?.travelDevolutions)
      return location.state.travelDevolutions
        .filter(travel => travel !== null)
        .flatMap(travel => travel.seats)
        .find(seat => seat.ticketId === parseInt(ticket))

    return undefined
  }

  const getSeatTypeByTicket = (ticket: string): string | undefined => {
    if (location?.state?.travelDevolutions) {
      const travel = location.state.travelDevolutions.find(
        travel =>
          travel !== null &&
          travel.seats.some(seat => seat.ticketId === parseInt(ticket)),
      )
      if (travel) return travel.isGone ? 'Ida' : 'Vuelta'
    }

    return undefined
  }

  const seatObject = (ticket: string, key: string) => {
    const seat = getSeatByTicket(ticket)
    if (!seat) return {}
    const { passenger } = seat

    return {
      key,
      name: passenger
        ? `${passenger.firstName} ${passenger.fatherLastName} ${passenger.motherLastName}`
        : 'Pasajero no asignado',
      seatNumber: seat.number,
      ticket: seat.ticketId,
      price: seat.price,
      type: getSeatTypeByTicket(ticket),
    }
  }

  const getDataSource = () => {
    if (location?.state?.ticketsReturned)
      return location.state.ticketsReturned.map((ticket, index) =>
        seatObject(ticket.toString(), index.toString()),
      )
    return []
  }

  useEffect(() => {
    if (location?.state?.travelDevolutions)
      setTransactions(
        setTravelData(
          location?.state?.travelDevolutions.filter(travel => travel !== null),
        ),
      )
  }, [])

  return (
    <Row justify="center" className={styles.fullHeight} align="middle">
      <Col span={24} className={styles.centered}>
        <Row align="middle" justify="center">
          <Col className={styles.centered}>
            <SuccessIcon />
            <p className={styles.title}>Devoluciones realizadas</p>
            <p className={styles.subtitle}>
              Se han realizado devoluciones de los siguientes pasajes:
            </p>
          </Col>
        </Row>
        <Divider />
        {Object.entries(transactions).map(array => (
          <Row justify="center" key={array[0]}>
            <Col xs={24} sm={12} className={styles.bottomMargin}>
              <TravelResume
                transaction={array[1] as Transaction}
                travelResume={travelSummaries[array[0]] as QueryString}
              />
            </Col>
          </Row>
        ))}
        {location?.state?.ticketsReturned ? (
          <Row justify="center">
            <Col xs={24} sm={12}>
              <p>Tickets cancelados</p>
              <Table
                columns={TICKETS_RETURNED_COLUMNS}
                dataSource={getDataSource()}
                pagination={false}
              />
            </Col>
          </Row>
        ) : null}

        <Divider />
        <Row justify="center">
          <Col xs={24} sm={5} md={3}>
            <Button
              block
              className={styles.continueButton}
              onClick={() => navigate('/app/mis-viajes')}>
              Continuar
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FinishDevolution

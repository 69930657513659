export const countriesExtensions = [
  
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AF.svg',
    name: 'Afghanistan',
    callingCodes: ['93'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AZ.svg',
    name: 'Åland Islands',
    callingCodes: ['358'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AL.svg',
    name: 'Albania',
    callingCodes: ['355'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AS.svg',
    name: 'American Samoa',
    callingCodes: ['1684'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AD.svg',
    name: 'Andorra',
    callingCodes: ['376'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AO.svg',
    name: 'Angola',
    callingCodes: ['244'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AI.svg',
    name: 'Anguilla',
    callingCodes: ['1264'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AQ.svg',
    name: 'Antarctica',
    callingCodes: ['672'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AG.svg',
    name: 'Antigua and Barbuda',
    callingCodes: ['1268'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AR.svg',
    name: 'Argentina',
    callingCodes: ['54'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AM.svg',
    name: 'Armenia',
    callingCodes: ['374'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AW.svg',
    name: 'Aruba',
    callingCodes: ['297'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AU.svg',
    name: 'Australia',
    callingCodes: ['61'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AT.svg',
    name: 'Austria',
    callingCodes: ['43'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AZ.svg',
    name: 'Azerbaijan',
    callingCodes: ['994'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BS.svg',
    name: 'Bahamas',
    callingCodes: ['1242'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BH.svg',
    name: 'Bahrain',
    callingCodes: ['973'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BD.svg',
    name: 'Bangladesh',
    callingCodes: ['880'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BB.svg',
    name: 'Barbados',
    callingCodes: ['1246'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BY.svg',
    name: 'Belarus',
    callingCodes: ['375'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BE.svg',
    name: 'Belgium',
    callingCodes: ['32'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BZ.svg',
    name: 'Belize',
    callingCodes: ['501'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BJ.svg',
    name: 'Benin',
    callingCodes: ['229'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BM.svg',
    name: 'Bermuda',
    callingCodes: ['1441'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BT.svg',
    name: 'Bhutan',
    callingCodes: ['975'],
  },
  {
    flag: 'https://www.countryflags.io/bo/flat/64.png',
    name: 'Bolivia (Plurinational State of)',
    callingCodes: ['591'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BQ.svg',
    name: 'Bonaire, Sint Eustatius and Saba',
    callingCodes: ['5997'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BA.svg',
    name: 'Bosnia and Herzegovina',
    callingCodes: ['387'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BW.svg',
    name: 'Botswana',
    callingCodes: ['267'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BV.svg',
    name: 'Bouvet Island',
    callingCodes: [''],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg',
    name: 'Brazil',
    callingCodes: ['55'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IO.svg',
    name: 'British Indian Ocean Territory',
    callingCodes: ['246'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/UM.svg',
    name: 'United States Minor Outlying Islands',
    callingCodes: [''],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/VG.svg',
    name: 'Virgin Islands (British)',
    callingCodes: ['1284'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/VI.svg',
    name: 'Virgin Islands (U.S.)',
    callingCodes: ['1 340'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BN.svg',
    name: 'Brunei Darussalam',
    callingCodes: ['673'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BG.svg',
    name: 'Bulgaria',
    callingCodes: ['359'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BF.svg',
    name: 'Burkina Faso',
    callingCodes: ['226'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BI.svg',
    name: 'Burundi',
    callingCodes: ['257'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KH.svg',
    name: 'Cambodia',
    callingCodes: ['855'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CM.svg',
    name: 'Cameroon',
    callingCodes: ['237'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg',
    name: 'Canada',
    callingCodes: ['1'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KY.svg',
    name: 'Cayman Islands',
    callingCodes: ['1345'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CF.svg',
    name: 'Central African Republic',
    callingCodes: ['236'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TD.svg',
    name: 'Chad',
    callingCodes: ['235'],
  },
  {
    flag: "http://purecatamphetamine.github.io/country-flag-icons/3x2/CL.svg",
    name: 'Chile',
    callingCodes: ['56'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg',
    name: 'China',
    callingCodes: ['86'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CX.svg',
    name: 'Christmas Island',
    callingCodes: ['61'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CC.svg',
    name: 'Cocos (Keeling) Islands',
    callingCodes: ['61'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CO.svg',
    name: 'Colombia',
    callingCodes: ['57'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KM.svg',
    name: 'Comoros',
    callingCodes: ['269'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CG.svg',
    name: 'Congo',
    callingCodes: ['242'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CD.svg',
    name: 'Congo (Democratic Republic of the)',
    callingCodes: ['243'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CK.svg',
    name: 'Cook Islands',
    callingCodes: ['682'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CR.svg',
    name: 'Costa Rica',
    callingCodes: ['506'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/HR.svg',
    name: 'Croatia',
    callingCodes: ['385'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CU.svg',
    name: 'Cuba',
    callingCodes: ['53'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CW.svg',
    name: 'Curaçao',
    callingCodes: ['599'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CY.svg',
    name: 'Cyprus',
    callingCodes: ['357'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CZ.svg',
    name: 'Czech Republic',
    callingCodes: ['420'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/DK.svg',
    name: 'Denmark',
    callingCodes: ['45'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/DJ.svg',
    name: 'Djibouti',
    callingCodes: ['253'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/DM.svg',
    name: 'Dominica',
    callingCodes: ['1767'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/DO.svg',
    name: 'Dominican Republic',
    callingCodes: ['1809', '1829', '1849'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/EC.svg',
    name: 'Ecuador',
    callingCodes: ['593'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/EG.svg',
    name: 'Egypt',
    callingCodes: ['20'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SV.svg',
    name: 'El Salvador',
    callingCodes: ['503'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GQ.svg',
    name: 'Equatorial Guinea',
    callingCodes: ['240'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ER.svg',
    name: 'Eritrea',
    callingCodes: ['291'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/EE.svg',
    name: 'Estonia',
    callingCodes: ['372'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ET.svg',
    name: 'Ethiopia',
    callingCodes: ['251'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/FK.svg',
    name: 'Falkland Islands (Malvinas)',
    callingCodes: ['500'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/FO.svg',
    name: 'Faroe Islands',
    callingCodes: ['298'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/FJ.svg',
    name: 'Fiji',
    callingCodes: ['679'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/FI.svg',
    name: 'Finland',
    callingCodes: ['358'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg',
    name: 'France',
    callingCodes: ['33'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GF.svg',
    name: 'French Guiana',
    callingCodes: ['594'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PF.svg',
    name: 'French Polynesia',
    callingCodes: ['689'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TF.svg',
    name: 'French Southern Territories',
    callingCodes: [''],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GA.svg',
    name: 'Gabon',
    callingCodes: ['241'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GM.svg',
    name: 'Gambia',
    callingCodes: ['220'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GE.svg',
    name: 'Georgia',
    callingCodes: ['995'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/DE.svg',
    name: 'Germany',
    callingCodes: ['49'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GH.svg',
    name: 'Ghana',
    callingCodes: ['233'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GI.svg',
    name: 'Gibraltar',
    callingCodes: ['350'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GR.svg',
    name: 'Greece',
    callingCodes: ['30'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GL.svg',
    name: 'Greenland',
    callingCodes: ['299'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GD.svg',
    name: 'Grenada',
    callingCodes: ['1473'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GP.svg',
    name: 'Guadeloupe',
    callingCodes: ['590'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GU.svg',
    name: 'Guam',
    callingCodes: ['1671'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GT.svg',
    name: 'Guatemala',
    callingCodes: ['502'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GG.svg',
    name: 'Guernsey',
    callingCodes: ['44'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GN.svg',
    name: 'Guinea',
    callingCodes: ['224'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GW.svg',
    name: 'Guinea-Bissau',
    callingCodes: ['245'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GY.svg',
    name: 'Guyana',
    callingCodes: ['592'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/HT.svg',
    name: 'Haiti',
    callingCodes: ['509'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/HM.svg',
    name: 'Heard Island and McDonald Islands',
    callingCodes: [''],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/VA.svg',
    name: 'Holy See',
    callingCodes: ['379'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/HN.svg',
    name: 'Honduras',
    callingCodes: ['504'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/HK.svg',
    name: 'Hong Kong',
    callingCodes: ['852'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/HU.svg',
    name: 'Hungary',
    callingCodes: ['36'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IS.svg',
    name: 'Iceland',
    callingCodes: ['354'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg',
    name: 'India',
    callingCodes: ['91'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ID.svg',
    name: 'Indonesia',
    callingCodes: ['62'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CI.svg',
    name: "Côte d'Ivoire",
    callingCodes: ['225'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IR.svg',
    name: 'Iran (Islamic Republic of)',
    callingCodes: ['98'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IQ.svg',
    name: 'Iraq',
    callingCodes: ['964'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IE.svg',
    name: 'Ireland',
    callingCodes: ['353'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IM.svg',
    name: 'Isle of Man',
    callingCodes: ['44'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IL.svg',
    name: 'Israel',
    callingCodes: ['972'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/IT.svg',
    name: 'Italy',
    callingCodes: ['39'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/JM.svg',
    name: 'Jamaica',
    callingCodes: ['1876'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/JP.svg',
    name: 'Japan',
    callingCodes: ['81'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/JE.svg',
    name: 'Jersey',
    callingCodes: ['44'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/JO.svg',
    name: 'Jordan',
    callingCodes: ['962'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KZ.svg',
    name: 'Kazakhstan',
    callingCodes: ['76', '77'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KE.svg',
    name: 'Kenya',
    callingCodes: ['254'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KI.svg',
    name: 'Kiribati',
    callingCodes: ['686'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KW.svg',
    name: 'Kuwait',
    callingCodes: ['965'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KG.svg',
    name: 'Kyrgyzstan',
    callingCodes: ['996'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LA.svg',
    name: "Lao People's Democratic Republic",
    callingCodes: ['856'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LV.svg',
    name: 'Latvia',
    callingCodes: ['371'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LB.svg',
    name: 'Lebanon',
    callingCodes: ['961'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LS.svg',
    name: 'Lesotho',
    callingCodes: ['266'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LR.svg',
    name: 'Liberia',
    callingCodes: ['231'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LY.svg',
    name: 'Libya',
    callingCodes: ['218'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LI.svg',
    name: 'Liechtenstein',
    callingCodes: ['423'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LT.svg',
    name: 'Lithuania',
    callingCodes: ['370'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LU.svg',
    name: 'Luxembourg',
    callingCodes: ['352'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MO.svg',
    name: 'Macao',
    callingCodes: ['853'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MK.svg',
    name: 'Macedonia (the former Yugoslav Republic of)',
    callingCodes: ['389'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MG.svg',
    name: 'Madagascar',
    callingCodes: ['261'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MW.svg',
    name: 'Malawi',
    callingCodes: ['265'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MY.svg',
    name: 'Malaysia',
    callingCodes: ['60'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MV.svg',
    name: 'Maldives',
    callingCodes: ['960'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ML.svg',
    name: 'Mali',
    callingCodes: ['223'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MT.svg',
    name: 'Malta',
    callingCodes: ['356'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MH.svg',
    name: 'Marshall Islands',
    callingCodes: ['692'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MQ.svg',
    name: 'Martinique',
    callingCodes: ['596'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MR.svg',
    name: 'Mauritania',
    callingCodes: ['222'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MU.svg',
    name: 'Mauritius',
    callingCodes: ['230'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/YT.svg',
    name: 'Mayotte',
    callingCodes: ['262'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg',
    name: 'Mexico',
    callingCodes: ['52'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/FM.svg',
    name: 'Micronesia (Federated States of)',
    callingCodes: ['691'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MD.svg',
    name: 'Moldova (Republic of)',
    callingCodes: ['373'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MC.svg',
    name: 'Monaco',
    callingCodes: ['377'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MN.svg',
    name: 'Mongolia',
    callingCodes: ['976'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ME.svg',
    name: 'Montenegro',
    callingCodes: ['382'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MS.svg',
    name: 'Montserrat',
    callingCodes: ['1664'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MA.svg',
    name: 'Morocco',
    callingCodes: ['212'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MZ.svg',
    name: 'Mozambique',
    callingCodes: ['258'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MM.svg',
    name: 'Myanmar',
    callingCodes: ['95'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NA.svg',
    name: 'Namibia',
    callingCodes: ['264'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NR.svg',
    name: 'Nauru',
    callingCodes: ['674'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NP.svg',
    name: 'Nepal',
    callingCodes: ['977'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NL.svg',
    name: 'Netherlands',
    callingCodes: ['31'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NC.svg',
    name: 'New Caledonia',
    callingCodes: ['687'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NZ.svg',
    name: 'New Zealand',
    callingCodes: ['64'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NI.svg',
    name: 'Nicaragua',
    callingCodes: ['505'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NE.svg',
    name: 'Niger',
    callingCodes: ['227'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NG.svg',
    name: 'Nigeria',
    callingCodes: ['234'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NU.svg',
    name: 'Niue',
    callingCodes: ['683'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NF.svg',
    name: 'Norfolk Island',
    callingCodes: ['672'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KP.svg',
    name: "Korea (Democratic People's Republic of)",
    callingCodes: ['850'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MP.svg',
    name: 'Northern Mariana Islands',
    callingCodes: ['1670'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/NO.svg',
    name: 'Norway',
    callingCodes: ['47'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/OM.svg',
    name: 'Oman',
    callingCodes: ['968'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PK.svg',
    name: 'Pakistan',
    callingCodes: ['92'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PW.svg',
    name: 'Palau',
    callingCodes: ['680'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PS.svg',
    name: 'Palestine, State of',
    callingCodes: ['970'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PA.svg',
    name: 'Panama',
    callingCodes: ['507'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PG.svg',
    name: 'Papua New Guinea',
    callingCodes: ['675'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PY.svg',
    name: 'Paraguay',
    callingCodes: ['595'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PE.svg',
    name: 'Peru',
    callingCodes: ['51'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PH.svg',
    name: 'Philippines',
    callingCodes: ['63'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PN.svg',
    name: 'Pitcairn',
    callingCodes: ['64'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg',
    name: 'Poland',
    callingCodes: ['48'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PT.svg',
    name: 'Portugal',
    callingCodes: ['351'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PR.svg',
    name: 'Puerto Rico',
    callingCodes: ['1787', '1939'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/QA.svg',
    name: 'Qatar',
    callingCodes: ['974'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/RE.svg',
    name: 'Réunion',
    callingCodes: ['262'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/RO.svg',
    name: 'Romania',
    callingCodes: ['40'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg',
    name: 'Russian Federation',
    callingCodes: ['7'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/RW.svg',
    name: 'Rwanda',
    callingCodes: ['250'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/BL.svg',
    name: 'Saint Barthélemy',
    callingCodes: ['590'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SH.svg',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    callingCodes: ['290'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KN.svg',
    name: 'Saint Kitts and Nevis',
    callingCodes: ['1869'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LC.svg',
    name: 'Saint Lucia',
    callingCodes: ['1758'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/MF.svg',
    name: 'Saint Martin (French part)',
    callingCodes: ['590'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/PM.svg',
    name: 'Saint Pierre and Miquelon',
    callingCodes: ['508'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/VC.svg',
    name: 'Saint Vincent and the Grenadines',
    callingCodes: ['1784'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/WS.svg',
    name: 'Samoa',
    callingCodes: ['685'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SM.svg',
    name: 'San Marino',
    callingCodes: ['378'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ST.svg',
    name: 'Sao Tome and Principe',
    callingCodes: ['239'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SA.svg',
    name: 'Saudi Arabia',
    callingCodes: ['966'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SN.svg',
    name: 'Senegal',
    callingCodes: ['221'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/RS.svg',
    name: 'Serbia',
    callingCodes: ['381'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SC.svg',
    name: 'Seychelles',
    callingCodes: ['248'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SL.svg',
    name: 'Sierra Leone',
    callingCodes: ['232'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SG.svg',
    name: 'Singapore',
    callingCodes: ['65'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SX.svg',
    name: 'Sint Maarten (Dutch part)',
    callingCodes: ['1721'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SK.svg',
    name: 'Slovakia',
    callingCodes: ['421'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SI.svg',
    name: 'Slovenia',
    callingCodes: ['386'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SB.svg',
    name: 'Solomon Islands',
    callingCodes: ['677'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SO.svg',
    name: 'Somalia',
    callingCodes: ['252'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ZA.svg',
    name: 'South Africa',
    callingCodes: ['27'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GS.svg',
    name: 'South Georgia and the South Sandwich Islands',
    callingCodes: ['500'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/KR.svg',
    name: 'Korea (Republic of)',
    callingCodes: ['82'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SS.svg',
    name: 'South Sudan',
    callingCodes: ['211'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ES.svg',
    name: 'Spain',
    callingCodes: ['34'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/LK.svg',
    name: 'Sri Lanka',
    callingCodes: ['94'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SD.svg',
    name: 'Sudan',
    callingCodes: ['249'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SR.svg',
    name: 'Suriname',
    callingCodes: ['597'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SJ.svg',
    name: 'Svalbard and Jan Mayen',
    callingCodes: ['4779'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SZ.svg',
    name: 'Swaziland',
    callingCodes: ['268'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SE.svg',
    name: 'Sweden',
    callingCodes: ['46'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CH.svg',
    name: 'Switzerland',
    callingCodes: ['41'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SY.svg',
    name: 'Syrian Arab Republic',
    callingCodes: ['963'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TW.svg',
    name: 'Taiwan',
    callingCodes: ['886'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TJ.svg',
    name: 'Tajikistan',
    callingCodes: ['992'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TZ.svg',
    name: 'Tanzania, United Republic of',
    callingCodes: ['255'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TH.svg',
    name: 'Thailand',
    callingCodes: ['66'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TL.svg',
    name: 'Timor-Leste',
    callingCodes: ['670'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TG.svg',
    name: 'Togo',
    callingCodes: ['228'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TK.svg',
    name: 'Tokelau',
    callingCodes: ['690'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TO.svg',
    name: 'Tonga',
    callingCodes: ['676'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TT.svg',
    name: 'Trinidad and Tobago',
    callingCodes: ['1868'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TN.svg',
    name: 'Tunisia',
    callingCodes: ['216'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg',
    name: 'Turkey',
    callingCodes: ['90'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TM.svg',
    name: 'Turkmenistan',
    callingCodes: ['993'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TC.svg',
    name: 'Turks and Caicos Islands',
    callingCodes: ['1649'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TV.svg',
    name: 'Tuvalu',
    callingCodes: ['688'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/UG.svg',
    name: 'Uganda',
    callingCodes: ['256'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/UA.svg',
    name: 'Ukraine',
    callingCodes: ['380'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg',
    name: 'United Arab Emirates',
    callingCodes: ['971'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg',
    name: 'United Kingdom of Great Britain and Northern Ireland',
    callingCodes: ['44'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/UM.svg',
    name: 'United States of America',
    callingCodes: ['1'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/UY.svg',
    name: 'Uruguay',
    callingCodes: ['598'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/UZ.svg',
    name: 'Uzbekistan',
    callingCodes: ['998'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/VU.svg',
    name: 'Vanuatu',
    callingCodes: ['678'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/VE.svg',
    name: 'Venezuela (Bolivarian Republic of)',
    callingCodes: ['58'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/VN.svg',
    name: 'Viet Nam',
    callingCodes: ['84'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/WF.svg',
    name: 'Wallis and Futuna',
    callingCodes: ['681'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/EH.svg',
    name: 'Western Sahara',
    callingCodes: ['212'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/YE.svg',
    name: 'Yemen',
    callingCodes: ['967'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ZM.svg',
    name: 'Zambia',
    callingCodes: ['260'],
  },
  {
    flag: 'http://purecatamphetamine.github.io/country-flag-icons/3x2/ZW.svg',
    name: 'Zimbabwe',
    callingCodes: ['263'],
  },
]

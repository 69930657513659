import React, { FC, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Col, message, Modal, Row } from 'antd'

import DownloadTemplateButton from '../../../../../../components/PassengersDrawerUtils/downloadTemplateButton'
import GroupSelect from '../../../../../../components/PassengersDrawerUtils/GroupSelect'
import PassengersList from '../../../../../../components/PassengersDrawerUtils/PassengersList'
import StageButtons from '../../../../../../components/PassengersDrawerUtils/stageButtons'
import UploadPassengersButton from '../../../../../../components/PassengersDrawerUtils/uploadButtonPassenger'
import UploadSuccess from '../../../../../../components/PassengersDrawerUtils/UploadSuccess'
import { RolesEnum } from '../../../../../../helpers/enums'
import { checkAnonymousDrawer } from '../../../../../../helpers/my-travel-page'
import {
  COST_CENTERS_WITH_PASSENGERS,
  CREATE_PASSENGERS,
  UPDATE_TRAVEL_PASSENGERS,
} from '../../../../../../queries'
import { SessionContext } from '../../../../../../services/session'
import { Passenger } from '../../../../../travel-page/types'
import { UploadToDetailProps } from '../../../../types'

const UploadToDetail: FC<UploadToDetailProps> = ({
  anonymousPassengers,
  bookingId,
  handleDrawerButton,
  handlePassengers,
  handleVisible,
  passengers,
}) => {
  const { user } = useContext(SessionContext)
  const [stage, setStage] = useState(1)
  const [costCenterId, setcostCenterId] = useState('')
  const [selectedPassengers, setSelectedPassengers] = useState<Passenger[]>([])

  const [createPassengersMutation] = useMutation(CREATE_PASSENGERS, {
    refetchQueries: [{ query: COST_CENTERS_WITH_PASSENGERS }],
  })

  const [updateMyTravelPassenger, { loading: updatePassengerLoading }] =
    useMutation(UPDATE_TRAVEL_PASSENGERS)

  const addPassengersToList = (selectedPassengers: Passenger[]) => {
    setSelectedPassengers(selectedPassengers)
    setStage(stage + 1)
  }

  const reset = () => {
    handleDrawerButton(0)
    setSelectedPassengers([])
    setStage(1)
  }

  const callCreatePassengersMutation = async () => {
    const passengerstoSave = selectedPassengers
      .filter(passenger => passenger.saved)
      .map(p => {
        p.costCenter = costCenterId || null
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { company, saved, errorMessage, ...rest } = p
        return rest
      })

    try {
      const {
        data: { createPassengers },
      } = await createPassengersMutation({
        variables: {
          input: passengerstoSave,
        },
      })

      const { canCallQuery, newQuotas, passengersToSubmit, rule } =
        checkAnonymousDrawer(passengers, createPassengers)

      if (rule)
        return message.info(
          'Alguna de sus selecciones ya se encuentran dentro del listado.',
        )
      if (canCallQuery.length > 0)
        return message.info(
          `Debe completar la selección de pasajeros anónimos, faltan ${canCallQuery.length}`,
        )
      if (canCallQuery.length < 1) {
        await updateMyTravelPassenger({
          variables: {
            input: {
              bookingId,
              passengers: passengersToSubmit,
            },
          },
        })
        handlePassengers(newQuotas)
        message.success('Pasajero creado exitosamente.')
      }
      handleDrawerButton(0)
      handleVisible(false)
    } catch (error) {
      message.error(`Ha ocurrido el siguiente error, ${error}`, 5)
    }
  }

  const chargePassengers = async () => {
    if (
      !costCenterId &&
      [
        RolesEnum.CCC,
        RolesEnum.ADMIN_CCC,
        RolesEnum.MULTI_RUT_BUYER,
        RolesEnum.MULTI_RUT_ADMIN,
      ].includes(user.role as RolesEnum)
    )
      return message.error('Debe seleccionar centro de costo')

    if (selectedPassengers.some(passenger => !passenger.saved))
      Modal.confirm({
        title: 'Hay registros con Error. ¿Deseas continuar?',
        content: 'Los registros con mensajes de error no serán guardados.',
        onOk: () => callCreatePassengersMutation(),
        okText: 'Continuar',
        cancelText: 'Cancelar',
      })
    else callCreatePassengersMutation()
  }

  const checkPassengersLimit = () => {
    const remaining = selectedPassengers.length - anonymousPassengers
    selectedPassengers.length <= anonymousPassengers
      ? chargePassengers()
      : message.error(`Debes remover ${remaining} pasajeros ya que solo se requiere
de ${anonymousPassengers} para la asignación`)
  }

  const removePassenger = (passengerToRemove: Passenger) => {
    const list = selectedPassengers.filter(
      (passenger: Passenger) => passenger !== passengerToRemove,
    )
    setSelectedPassengers(list)
    if (list.length === 0) setStage(1)
  }

  return (
    <>
      {stage < 3 && user.role.includes('CCC') ? (
        <GroupSelect setSelectedCostCenter={setcostCenterId} />
      ) : null}
      {stage === 1 && (
        <Row justify="center">
          <Col>
            <UploadPassengersButton
              handleSelectedPassengers={addPassengersToList}
            />
            <DownloadTemplateButton />
          </Col>
        </Row>
      )}
      {stage === 2 && (
        <PassengersList
          passengers={selectedPassengers}
          removePassenger={removePassenger}
        />
      )}
      {stage === 3 && (
        <UploadSuccess passengersNumber={selectedPassengers.length} />
      )}
      <StageButtons
        loading={updatePassengerLoading}
        onCancelClick={reset}
        stage={stage}
        saveAndAddPassengers={checkPassengersLimit}
      />
    </>
  )
}

export default UploadToDetail

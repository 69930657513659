import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row } from 'antd'

import LoadingSpinner from '../../components/LoadingSpinner'
import OptionsHeader from '../../components/OptionsHeader'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../services/auth'
import { HelpRequest } from '../../types/HelpRequest.type'
import { HelpRequestDetailProps } from '../../types/page-types/help-request-detail-page.types'

import Chat from './Chat'
import { GET_HELP_REQUEST } from './graphql'
import HelpRequestInfo from './HelpRequestInfo'
import SubHeader from './SubHeader'

import styles from './styles.module.css'

const HelpRequestDetailPage: React.FC<HelpRequestDetailProps> = ({ id }) => {
  const [helpRequest, setHelpRequest] = useState({} as HelpRequest)
  const [loadingFile, setLoadingFile] = useState(false)

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_detalle_solicitud' as string

    useEventSave('vista_detalle_solicitud', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const { data, loading } = useQuery(GET_HELP_REQUEST, {
    variables: {
      id,
    },
    onCompleted: () => {
      const { getHelpRequestById } = data
      if (getHelpRequestById) setHelpRequest(getHelpRequestById)
    },
  })

  return (
    <>
      <OptionsHeader />
      {loading ? (
        <LoadingSpinner size="100px" />
      ) : helpRequest?._id ? (
        <>
          <Row>
            <Col span={24} style={{ zIndex: 99 }}>
              <SubHeader />
            </Col>
          </Row>

          <Row className={styles.content}>
            <Col className={styles.infoSection} xs={24} md={14}>
              <HelpRequestInfo
                helpRequest={helpRequest}
                loading={loadingFile}
              />
            </Col>
            <Col className={styles.chatSection} xs={24} md={10}>
              <Chat helpRequest={helpRequest} setLoading={setLoadingFile} />
            </Col>
          </Row>
        </>
      ) : null}
    </>
  )
}

export default HelpRequestDetailPage

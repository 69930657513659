import React, { FC, useEffect, useState } from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useLocation } from '@reach/router'
import { Button, Col, Divider, message, Pagination, Row, Tabs, Modal } from 'antd'
import { navigate } from 'gatsby'

import { MY_TRAVELS, TRAVEL_DEVOLUTION } from '../../../queries'
import { TravelDateRange } from '../../../types/enums'
import {
  DevolutionResponse,
  MyTravelsContentProps,
  MyTravelsData,
} from '../types'

import DrawerFilter from './Filter'
import TravelsTab from './TravelsTab'
import { FilterTypes } from './types'

import styles from './styles.module.css'
import { filter } from 'ramda'

const { TabPane } = Tabs

const MyTravelsContent: FC<MyTravelsContentProps> = ({
  activeDevolution,
  setActiveDevolution,
}) => {
  const location: Record<string, any> = useLocation()

  const [onGoingTravels, setOnGoingTravels] = useState<MyTravelsData>({
    totalItems: 0,
    travels: [],
  } as MyTravelsData)
  const [scheduledTravels, setScheduledTravels] = useState<MyTravelsData>({
    totalItems: 0,
    travels: [],
  } as MyTravelsData)
  const [finishedTravels, setFinishedTravels] = useState<MyTravelsData>({
    totalItems: 0,
    travels: [],
  } as MyTravelsData)
  const [filterVisible, setFilterVisible] = useState<boolean>(false)
  const [tabKey, setTabKey] = useState<string>('1')
  const [listForDevolution, setListForDevolution] = useState<string[]>([])
  const [filterItems, setFilterItems] = useState<FilterTypes>({} as FilterTypes)
  const [filterScheduledItems, setFilterScheduledItems] = useState<FilterTypes>(
    { travelDateRange: TravelDateRange.PROGRAMMED } as FilterTypes,
  )
  const [filterOnGoingItems, setFilterOnGoingItems] = useState<FilterTypes>({
    travelDateRange: TravelDateRange.IN_PROGRESS,
  } as FilterTypes)
  const [filterFinishedItems, setFilterFinishedItems] = useState<FilterTypes>({
    travelDateRange: TravelDateRange.FINISHED,
  } as FilterTypes)
  const [page, setPage] = useState<number>(1)
  const [scheduledPage, setScheduledPage] = useState<number>(1)
  const [onGoingPage, setOnGoingPage] = useState<number>(1)
  const [finishedPage, setFinishedPage] = useState<number>(1)

  const [totalItems, setTotalItems] = useState<number>(0)

  const [travelDevolution, { loading: devolutionLoading }] = useMutation(
    TRAVEL_DEVOLUTION,
  )

  const [isTicketFilter, setIsTicketFilter] = useState<boolean>(false)
  const [ticketNumber, setTicketNumber] = useState<string>()
  const [transactionListForDevolution, setTransactionListForDevolution] = useState<string[]>([])


  const onDrawerClose = () => setFilterVisible(false)

  const [getOnGoinTravels, { loading: onGoindLoading }] = useLazyQuery(
    MY_TRAVELS,
    {
      variables: {
        filters: {
          ...filterOnGoingItems,
          travelDateRange: TravelDateRange.IN_PROGRESS,
        },
        page: onGoingPage ?? 1,
      },
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setOnGoingTravels({
          travels: data.myTravels.travels,
          totalItems: data.myTravels.totalItems,
        })
        if (tabKey === '2') setTotalItems(data.myTravels.totalItems)
      },
    },
  )

  const [getScheduledTravels, { loading: scheduledLoading }] = useLazyQuery(
    MY_TRAVELS,
    {
      variables: {
        filters: {
          ...filterScheduledItems,
          travelDateRange: TravelDateRange.PROGRAMMED,
        },
        page: scheduledPage ?? 1,
      },
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setScheduledTravels({
          travels: data.myTravels.travels,
          totalItems: data.myTravels.totalItems,
        })
        if (tabKey === '1') setTotalItems(data.myTravels.totalItems)
      },
    },
  )

  const [getFinishedTravels, { loading: finishedLoading }] = useLazyQuery(
    MY_TRAVELS,
    {
      variables: {
        filters: {
          ...filterFinishedItems,
          travelDateRange: TravelDateRange.FINISHED,
        },
        page: finishedPage ?? 1,
      },
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setFinishedTravels({
          travels: data.myTravels.travels,
          totalItems: data.myTravels.totalItems,
        })
        if (tabKey === '3') setTotalItems(data.myTravels.totalItems)
      },
    },
  )

  const toggleDevolutionList = (items: string[], itemsTransaction: string[]) => {

    let newList = [...listForDevolution]
    let transactionList = [...transactionListForDevolution]
    items.forEach(item => {
      if (newList.includes(item)) newList = newList.filter(i => i !== item)
      else newList.push(item)
    })
    setListForDevolution(newList)


    itemsTransaction.forEach(item => {
      if (transactionList.includes(item)) transactionList = transactionList.filter(i => i !== item)
      else transactionList.push(item)
    })

    setTransactionListForDevolution(transactionList)

    if(filterItems.ticketId){
      setTicketNumber(filterItems.ticketId)
      setIsTicketFilter(true);
    }else{
      setTicketNumber('')
      setIsTicketFilter(false);
    }

  }

  const cancelDevolution = () => {
    setListForDevolution([])
    setActiveDevolution(false)
  }

  const confirmDevolutionModal = () => {
    let titleContent: string;
    let textContent: string;

    if(filterItems.ticketId){
      titleContent = 'boleto #' + filterItems.ticketId
      textContent = 'Esto tendrá como consecuencia la anulación del boleto. En caso de anular la transacción completa, elimine el filtro.'
    }else{
      if(transactionListForDevolution.length > 1){
        titleContent = 'transacciones: ' + transactionListForDevolution
      }else{
        titleContent = 'transacción: #' + transactionListForDevolution
      }
      textContent = 'Estó tendrá como consecuencia la anulación de todos los boletos asociados a las transacciones seleccionadas . En caso de querer anular solo un boleto debe filtrar los viajes por número de boleto.'
    }

    Modal.confirm({
      title: '¿Está seguro de anular ' + titleContent + ' ?',
      content: textContent,
      onCancel: () => cancelDevolution(),
      cancelText: `Cancelar `,
      okText: 'Anular',
      bodyStyle: {
        padding: '0px !important',
      },
      style: {
        padding: '0px !important',
      },
      okButtonProps: {
        size: 'small',
        style: {
          padding: '0px !important',
          margin: '0px !important',
        },
      },
      cancelButtonProps: {
        size: 'small',
        style: {
          padding: '0px !important',
          margin: '0px !important',
        },
      },
      onOk: () => cancelTravels(),
    })
  }

  /*Aqui se devuelven los boletos*/
  const cancelTravels = async () => {
    try {
      const { data } = await travelDevolution({
        variables: {
          input: listForDevolution,
          isTicketFilter: isTicketFilter,
          ticketNumber: ticketNumber
        },
      })

      const { travelDevolution: resp } = data

      const response = resp as DevolutionResponse
      if (response.notCanceled.length > 0)
        message.error(
          [...new Set(response.notCanceled.map(item => item.message))].join(
            ', ',
          ),
        )
      if (response.devolutions.length > 0) {
        cancelDevolution()
        navigate('/app/mis-viajes/devolucion', {
          state: {
            travelDevolutions: response.devolutions,
          },
        })
      }
    } catch (err) {
      message.error(err)
    }
  }

  useEffect(() => {
    getScheduledTravels()
    getOnGoinTravels()
    getFinishedTravels()
  }, [])

  useEffect(() => {
    setFilterScheduledItems({ ...filterItems })
    setFilterOnGoingItems({ ...filterItems })
    setFilterFinishedItems({ ...filterItems })

    if(filterItems.ticketId){
      setTicketNumber(filterItems.ticketId)
      setIsTicketFilter(true)
    }else{
      setTicketNumber('')
      setIsTicketFilter(false)
    }

    setPage(1)
  }, [filterItems])

  useEffect(() => {
    if (tabKey === '1') setTotalItems(scheduledTravels.totalItems)
    if (tabKey === '2') setTotalItems(onGoingTravels.totalItems)
    if (tabKey === '3') setTotalItems(finishedTravels.totalItems)

    setPage(1)
  }, [tabKey])

  useEffect(() => {
    if (tabKey === '1') setScheduledPage(page)
    if (tabKey === '2') setOnGoingPage(page)
    if (tabKey === '3') setFinishedPage(page)
  }, [page])

  return (
    <>
      <Row justify="center">
        <Col
          className={styles.contentCard}
          xs={24}
          sm={20}
          md={16}
          lg={18}
          xl={16}>
          <Tabs
            className={styles.tabsContent}
            defaultActiveKey={`${location?.state?.defaultActive ?? 1}`}
            onChange={(value: string) => setTabKey(value)}
            centered>
            {
              <TabPane
                tab={
                  <span
                    className={`${styles.spanTabMargin} ${styles.marginKeyOne}`}>
                    <span className={`${styles.spanTabViajes} `}>Viajes </span>
                    {`Programados(${
                      scheduledLoading ? '...' : scheduledTravels.totalItems
                    })`}
                  </span>
                }
                key="1">
                <TravelsTab
                  tabKey={1}
                  activeDevolution={activeDevolution}
                  toggleDevolutionList={toggleDevolutionList}
                  loading={scheduledLoading}
                  travels={scheduledTravels.travels}
                  isTicketFilter={isTicketFilter}
                  ticketNumber={ticketNumber}
                />
              </TabPane>
            }
            {
              <TabPane
                tab={
                  <span className={styles.spanTabMargin}>
                    <span className={`${styles.spanTabViajes}`}>Viajes </span>
                    {`En Curso (${
                      onGoindLoading ? '...' : onGoingTravels.totalItems
                    })`}
                  </span>
                }
                key="2">
                <TravelsTab
                  tabKey={2}
                  loading={onGoindLoading}
                  travels={onGoingTravels.travels}
                />
              </TabPane>
            }
            {
              <TabPane
                tab={
                  <span className={styles.spanTabMargin}>
                    <span className={`${styles.spanTabViajes}`}>Viajes </span>
                    {`Finalizados (${
                      finishedLoading ? '...' : finishedTravels.totalItems
                    })`}
                  </span>
                }
                key="3">
                <TravelsTab
                  tabKey={3}
                  loading={finishedLoading}
                  travels={finishedTravels.travels}
                />
              </TabPane>
            }
          </Tabs>
          <button
            className={styles.filterButton}
            onClick={() => setFilterVisible(true)}>
            <span className={styles.textFilter}>Filtrar</span>
            <FilterOutlined />
          </button>
          {activeDevolution && tabKey === '1' ? (
            <Row justify="center" align="middle">
              <Col xs={0} lg={24}>
                <Divider />
              </Col>
              <Col xs={{ span: 22, order: 2 }} lg={{ span: 6, order: 1 }}>
                <Button
                  block
                  type="text"
                  className={styles.devolutionCancelButton}
                  onClick={cancelDevolution}>
                  Cancelar
                </Button>
              </Col>
              <Col xs={{ span: 22, order: 1 }} lg={{ span: 6, order: 2 }}>
                <Button
                  block
                  className={styles.devolutionAcceptButton}
                  // onClick={cancelTravels}
                  onClick={confirmDevolutionModal}
                  loading={devolutionLoading}
                  disabled={listForDevolution.length === 0}
                  >
                  Devolver
                </Button>
              </Col>
            </Row>
          ) : null}
          <DrawerFilter
            visible={filterVisible}
            onClose={onDrawerClose}
            setFilterItems={setFilterItems}
          />
          <div className={styles.pagination}>
            <Pagination
              showSizeChanger={false}
              current={page}
              total={totalItems}
              pageSize={6}
              onChange={(value, items) => setPage(value)}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default MyTravelsContent

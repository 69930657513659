import React, { FC } from 'react'
import { Col, Row, Tooltip } from 'antd'

import { PropTypes } from './types'

import styles from '../styles.module.css'

const NormalHeader: FC<PropTypes> = ({
  userName,
  haveCreditLine,
  balance,
  totalCredit,
  executive,
  isExecutiveAssigned,
  executiveEmail,
  executivePhone,
}) => {
  return (
    <div className={styles.container}>
      <Row className={styles.informationRow} justify="center" gutter={8}>
        <Col
          xs={24}
          sm={24}
          md={11}
          lg={10}
          xl={9}
          xxl={10}
          className={styles.userInformationContainer}>
          <Row>
            <div className={styles.welcomeMessageFont}>
              ¡Bienvenido de nuevo, {userName}!
            </div>
          </Row>
          <Row>
            <Col xs={0} sm={0} md={0} lg={24}>
              <div className={styles.welcomeMessageDescriptionFont}>
                Con Simplus, puedes agendar y administrar todos tus viajes
                laborales de manera rápida y sencilla.¿Estás listo para reservar
                tu próximo viaje?
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={13}
          lg={10}
          xl={11}
          xxl={6}
          className={styles.companyInformationContainer}>
          {haveCreditLine ? (
            <Row justify="center">
              <Col className={styles.companyBalanceContainer}>
                <Row justify="center">
                  <p className={styles.infoTitleFont}>Saldo total disponible</p>
                </Row>
                <Row justify="center">
                  <p className={styles.infoContentFont}>${balance}</p>
                </Row>
              </Col>
              <Col className={styles.companyCreditLineContainer}>
                <Row justify="center">
                  <p className={styles.infoTitleFont}>Monto total de crédito</p>
                </Row>
                <Row justify="center">
                  <p className={styles.infoContentFont}>${totalCredit}</p>
                </Row>
              </Col>
            </Row>
          ) : null}
          <Row justify="center">
            <Col
              className={
                haveCreditLine
                  ? styles.companyExecutiveContainer
                  : styles.notAssignedExecutiveContainer
              }>
              <Row>
                {isExecutiveAssigned ? (
                  <p className={styles.infoTitleFont}>Ejecutivo asignado</p>
                ) : (
                  <p className={styles.infoTitleFont}>Ejecutivo asignado</p>
                )}
              </Row>
              <Row>
                {isExecutiveAssigned ? (
                  <p className={styles.infoContentFont}>{executive}</p>
                ) : (
                  <p className={styles.infoContentFont}>
                    Aún no tienes un ejecutivo asignado...
                  </p>
                )}

                {isExecutiveAssigned ? (
                  <div className={styles.contact}>
                    <Tooltip
                      placement="rightTop"
                      title={`Correo: ${executiveEmail} Teléfono: ${executivePhone}`}>
                      <p className={styles.contactFont}>Contacto</p>
                    </Tooltip>
                  </div>
                ) : null}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default NormalHeader

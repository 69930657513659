import React, { FC } from 'react'
import { Col, message, Row, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'

import { UploadIcon } from '../../assets'
import { OnChangeInfo } from '../../types/component-types/FileUploadForm.types'

import { UploadPassengersButtonProps } from './props'

import styles from './styles.module.css'

const UploadPassengersButton: FC<UploadPassengersButtonProps> = ({
  handleSelectedPassengers,
}) => {
  const allowedFiles = ['.xlsx']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const documents: any[] = []
  const uploadProps = {
    name: 'file',
    accept: allowedFiles.join(','),
    multiple: false,
    action: `${process.env.GATSBY_API_UPLOAD_PASSENGERS_URI}`,
    headers: {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    beforeUpload(file: UploadFile, fileList: UploadFile[]) {
      const fileIsValid = allowedFiles.includes(
        `.${file.name.split('.').pop() as string}`.toLocaleLowerCase(),
      )

      if (!fileIsValid) {
        message.error(
          'Este archivo no tiene ninguno de los formatos permitidos',
        )
        fileList.pop()
      }

      return fileIsValid
    },
    async onChange(info: OnChangeInfo) {
      const { file } = info
      if (file.status === 'done') {
        message.success(`${file.name} se cargó con éxito`)
        const { passengers } = file.response
        handleSelectedPassengers(passengers)
        documents.push({
          uid: file.uid,
          size: file.size,
        })
      } else if (file.status === 'error') {
        const errorMessage =
          file.response?.message ?? `${file.name} tuvo un problema al cargar`
        message.error(errorMessage)
      }
    },
  }


  return (
    <Row align="middle" justify="center" style={{ height: '65.5%' }}>
      <Col span={24}>
        <Row align="middle" justify="center">
          <Upload
            {...uploadProps}
            className={styles.uploadAlign}
            style={{ color: '#6477ED' }}>
            <UploadIcon
              className={styles.uploadIcon}
              style={{ color: '#6477ED' }}
            />
          </Upload>
        </Row>
        <Row align="middle" justify="center">
          <p className={styles.uploadPassengersFont}>
            Presiona acá para cargar tus pasajeros
          </p>
        </Row>
        <Row align="middle" justify="center">
          <p className={styles.uploadPassengersDescriptionFont}>
            Puedes subir un archivo .xlsx para agregar varios pasajeros a la
            vez.
          </p>
        </Row>
      </Col>
    </Row>
  )
}

export default UploadPassengersButton

import React, { FC, useState } from 'react'
import { Button, Col, Divider, message, Row, Select } from 'antd'

import { CostCenterFilterRowProps } from './types'

import styles from './styles.module.css'
import MasivaModal from './CECOMasivaDrawer/cargaMasivaModal'
import { getUser } from '../../services/auth'
import { User } from '../../types/user.type'
import { CostCenter, CostCenterMasivaInput, CreateManyCostCenterInput } from '../../types/costCenter.type'
import { CREATE_MANY_COST_CENTER } from '../../queries'
import { useMutation } from '@apollo/client';

const { Option } = Select

const CostCenterFilterRow: FC<CostCenterFilterRowProps> = ({
  currentAccounts,
  handleVisible,
  onChangeCurrentAccount,
  refetch
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const onOpenUploadModal = () => setModalVisible(true);
  const onCloseUploadModal = () => setModalVisible(false);

  const [formattedCecos, setFormattedCecos] = useState<CostCenterMasivaInput[]>([])

  const [createManyCostCenter, { loading: createLoading }] = useMutation(
    CREATE_MANY_COST_CENTER,
  )


  const userCompany = getUser() as unknown as User;


  const handleReceivedCeCos = (cecos: any[]) => {
    // console.log("cecos recibidos", cecos)
    if(cecos.length > 0){
      setFormattedCecos((prevCecos) => {
        const cecosFormatted: CostCenterMasivaInput[] = cecos.map((p) => {
          const {currentAccount, name, description } = p;
          const formattedCeCo: CreateManyCostCenterInput = {
            currentAccount: String(currentAccount), 
            name,
            description,
          };
    
          return formattedCeCo;
        });
        handleSaveCeCos(cecosFormatted); 
        return cecosFormatted; 
      });
    }

  };

  const validateData = (costCenters: any[]) => {
    let validatedData : boolean = false;
    const wrongData = costCenters.some(
      (ceco) =>
        ceco.currentAccount === null ||
        ceco.currentAccount === undefined ||
        ceco.name === null ||
        ceco.name === undefined ||
        ceco.description === null ||
        ceco.description === undefined
    );

    const duplicateFound = hasDuplicates(costCenters.map((ceco) => [ceco.currentAccount, ceco.name, ceco.description]));

    if(wrongData || duplicateFound){
      validatedData= false
    }else{
      validatedData= true
    }

    return validatedData
  
  };

  const hasDuplicates = (arr: any[][]) => {
    const seen = new Set();
    for (const item of arr) {
      const key = JSON.stringify(item);
      if (seen.has(key)) {
        return true; // Se encontraron duplicados
      }
      seen.add(key);
    }
    return false; // No se encontaron duplicados
  };
  

  const handleSaveCeCos = async (formattedCecos: CreateManyCostCenterInput[]) => {
  // console.log("costCentersToSave", formattedCecos);
  const validatedCeco = validateData(formattedCecos)
  // console.log("validatedCeco", validatedCeco)

  if(validatedCeco){
    try {

      const result = await createManyCostCenter({
        variables: {
          input: formattedCecos,
        },
      });
  
      const resultData = result.data.createManyCostCenter
  
      handleVisible(false);
  
      if(resultData.length > 0){
        message.success(`Se han creado los centros de costos exitosamente.`);
        await refetch();
      }
  
    } catch (err) {
      message.error(`Ha ocurrido un error al crear centros de costos. ${err}`);
    }
  }else{
    message.error("Hay registros con error")
  }

  
};


  return (
    <>
      <Row
        align="middle"
        className={styles.costCenterFilterRow}
        justify="space-between"
        gutter={[5, 10]}>
      <Col xs={20} sm={24} md={12}>
        <p>Cuenta corriente</p>
        <Select
          className={styles.creditLineSelect}
          onChange={id => onChangeCurrentAccount(id.toString())}
          optionFilterProp="children"
          placeholder="Seleccione cuenta corriente."
          showSearch>
          {currentAccounts?.map(({ _id, name }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col xs={20} sm={20} md={5}>
        <Button block onClick={() => handleVisible(true)}>
          Agregar CECO
        </Button>
      </Col>
        <Col xs={20} sm={20} md={5}>
          <Button block onClick={onOpenUploadModal}>
            Carga Masiva
          </Button>
          <MasivaModal
            onClose={onCloseUploadModal}
            visible={modalVisible}
            handleCeCos={handleReceivedCeCos}
            userCompany={userCompany}
          />
        </Col>
      </Row>
      <Divider className={styles.costCenterDivider} />
    </>
  );
};

export default CostCenterFilterRow;

import React, { FC, useContext, useState } from 'react'
import { UserOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import {
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
} from 'antd'
import { RuleObject } from 'rc-field-form/lib/interface'

import {
  evalPassportOrRutPassenger,
  groupCostCenters,
} from '../../../helpers/my-passengers'
import { formatRut, validateRut } from '../../../helpers/rutValidators'
import {
  CREATE_SINGLE_PASSENGER,
  GET_ADDRESSES_QUERY,
  MY_CURRENT_ACCOUNTS,
} from '../../../queries'
import { DetailFormProps } from '../../../screens/my-travels/types'
import { PassengerFormValues } from '../../../screens/travel-page/types'
import { SessionContext } from '../../../services/session'
import { CostCenter } from '../../../types/page-types/pay-page.types'
import IdentityPrefix from '../../IdentityPrefix'
import PhonePrefix from '../../phonePrefix'

import styles from './styles.module.css'

const { Item } = Form
const { Option } = Select

const DetailForm: FC<DetailFormProps> = ({ handleDrawerButton, refetch }) => {
  const { user } = useContext(SessionContext)
  const [idRule, setIdRule] = useState(1)
  const [form] = Form.useForm()
  const { loading: addressLoading, data: addressData } = useQuery(
    GET_ADDRESSES_QUERY,
  )
  const { loading: currentAccountLoading, data: currentAccountData } = useQuery(
    MY_CURRENT_ACCOUNTS,
  )
  const [createPassenger, { loading }] = useMutation(CREATE_SINGLE_PASSENGER)

  const onFinish = async (values: PassengerFormValues) => {
    const {
      firstName,
      fatherLastName,
      motherLastName,
      phone,
      areaCode,
      identityData,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      indentityPrefix,
      ...rest
    } = values
    try {
      await createPassenger({
        variables: {
          input: {
            ...rest,
            firstName,
            fatherLastName,
            motherLastName,
            phone: `${areaCode} ${phone}`,
            ...evalPassportOrRutPassenger(idRule, identityData),
          },
        },
      })
      message.success('Pasajero creado correctamente')
      form.resetFields()
      handleDrawerButton(0)
      if (refetch) refetch()
    } catch (error) {
      message.error(`Ha ocurrido el siguiente error, ${error}`, 5)
    }
  }

  return (
    <>
      <Col span={24}>
        <Row align="middle" justify="center">
          <Avatar size={100} icon={<UserOutlined />} />
        </Row>
      </Col>
      <Col className={styles.mainContainer} span={24}>
        <Form form={form} initialValues={{ remember: true }} layout="vertical">
          <Item
            className={styles.inputRow}
            name="firstName"
            label="Nombre"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Nombre del pasajero" />
          </Item>
          <Item
            className={styles.inputRow}
            name="fatherLastName"
            label="Apellido paterno"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Apellido paterno del pasajero" />
          </Item>
          <Item
            className={styles.inputRow}
            name="motherLastName"
            label="Apellido materno"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Apellido materno del pasajero" />
          </Item>
          <Item
            className={styles.inputRow}
            label="Identificación"
            name="identityData"
            normalize={data => (idRule === 1 ? formatRut(data) : data)}
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              () =>
                idRule === 1
                  ? {
                      validator(rule, value) {
                        console.log('validando', idRule)
                        if (validateRut(value) || !value)
                          return Promise.resolve()

                        return Promise.reject('Documento inválido.')
                      },
                    }
                  : ({} as RuleObject),
            ]}>
            <Input
              addonBefore={<IdentityPrefix setIdRule={setIdRule} />}
              maxLength={12}
              placeholder={
                idRule === 1 ? 'Ejemplo: 76.325.945-k' : 'Ejemplo: 7537575'
              }
            />
          </Item>
          <Item
            className={styles.inputRow}
            name="phone"
            label="Teléfono"
            rules={[
              { required: true, message: '' },
              {
                pattern: /^[0-9]*$/,
                message: 'debe ingresar solo números',
              },
            ]}>
            <Input
              addonBefore={<PhonePrefix />}
              placeholder="Ejemplo: 912345678"
            />
          </Item>
          <Item
            className={styles.inputRow}
            label="Correo electrónico"
            name="email"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Introduzca un correo válido',
              },
            ]}>
            <Input placeholder="Ejemplo: micorreo@correo.cl" />
          </Item>
          <Item
            className={styles.inputRow}
            label="Nacionalidad"
            name="nationality"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            {addressData && (
              <Select
                allowClear
                className={styles.selectInputRow}
                loading={addressLoading}
                placeholder="Seleccione País"
                optionFilterProp="children"
                showArrow={false}
                showSearch>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                {addressData.getAddresses?.map(({ countryName }: any) => (
                  <Option key={countryName} value={countryName}>
                    {countryName}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item
            className={styles.inputRow}
            label="Cargo"
            name="position"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Cargo del pasajero" />
          </Item>
          {user?.role === 'CCC' ||
          user?.role === 'ADMIN_CCC' ||
          user?.role === 'MULTI_RUT_ADMIN' ||
          user?.role === 'MULTI_RUT_BUYER' ?  (
            <Item
              className={styles.inputRow}
              label="Centro de costo"
              name="costCenter"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
              ]}>
              {currentAccountData && (
                <Select
                  className={styles.selectInputRow}
                  loading={currentAccountLoading}
                  optionFilterProp="children"
                  placeholder="Centro de costo"
                  showSearch>
                  {groupCostCenters(currentAccountData.myCurrentAccounts).map(
                    ({ _id, name }: CostCenter) => (
                      <Option key={_id} value={_id}>
                        {name}
                      </Option>
                    ),
                  )}
                </Select>
              )}
            </Item>
          ) : null}
        </Form>
      </Col>
      <Col className={styles.drawerButtonContainer} span={24}>
        <Row align="middle" justify="space-between" gutter={10}>
          <Divider />
          <Col xs={{ span: 24, order: 2 }} lg={{ span: 11, order: 1 }}>
            <Button
              block
              className={styles.cancelButton}
              onClick={() => {
                form.resetFields()
                handleDrawerButton(0)
              }}>
              Cancelar
            </Button>
          </Col>
          <Col xs={{ span: 24, order: 1 }} lg={{ span: 11, order: 2 }}>
            <Button
              block
              className={styles.continueButton}
              loading={loading}
              onClick={() => {
                form
                  .validateFields()
                  .then(values => onFinish(values))
                  .catch(info => {
                    message.info(
                      `Verifique que los campos fueron ingresados correctamente`,
                    )
                    console.log(info)
                  })
              }}>
              Agregar pasajero
            </Button>
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default DetailForm

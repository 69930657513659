import React from 'react'
import { Button, Col, Divider, Row } from 'antd'
import { navigate } from 'gatsby'

import { SuccessIcon } from '../../assets'
import SEO from '../../components/seo'

import Cross from './cross.inline.svg'

import styles from './styles.module.css'

interface Props {
  ticketNumber: string
}
const HelpCreatedPage: React.FC<Props> = ({ ticketNumber }) => {
  return (
    <>
      <SEO title="Envío de reclamo o sugerencia" />
      <Row justify="end" className={styles.outerContainer}>
        <Col xs={24} sm={12} className={styles.innerRightContainer}>
          <div
            className={styles.crossIcon}
            onClick={() => {
              navigate('/app/mis-solicitudes')
            }}>
            <Cross />
          </div>
          <div className={styles.pageTitleContainer}>
            <p className={styles.pageTitleFont}>Enviar reclamo o sugerencias</p>
          </div>
          <Divider />
          <Row className={styles.body} justify="center">
            <Col>
              <SuccessIcon />
              <p className={styles.successFont}>Solicitud enviada</p>
              <p className={styles.descriptionFont}>
                Número de ticket: {ticketNumber}
              </p>
              <p className={styles.descriptionFont}>
                Recibirás una respuesta en un máximo de 24 horas.
              </p>
            </Col>
          </Row>
          <Divider />
          <Row justify="center">
            <Col>
              <Button
                className={styles.button}
                onClick={() => navigate('/app/mis-solicitudes')}>
                Cerrar
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default HelpCreatedPage

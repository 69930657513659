import moment from 'moment'

export const getDisplayDate = (
  date: string,
  format = 'DD/MM/YYYY, h:mm',
  response = 'ddd, D [de] MMMM YYYY, h:mm',
): string => moment(date, format).format(response)


export const formatDate = (date?: string, format?: string): string | undefined => {
  if (!date) return undefined
  return moment.utc(date).format(format ?? 'DD/MM/YYYY')
}
export const dateToNumber = (date: Date): number => {

  const day = `0${date.getDate()}`
  const month = `0${date.getMonth() + 1}`

  return parseInt(`${date.getFullYear()}${month.substr(month.length - 2)}${day.substr(day.length - 2)}`)
}

export const timeToNumber = (date: Date): number => {

  const hour = `0${date.getHours()}`
  const min = `0${date.getMinutes()}`

  return parseInt(`${hour.substr(hour.length - 2)}${min.substr(min.length - 2)}`)
}

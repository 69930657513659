import { gql } from '@apollo/client'

export const REGISTER_SCC_USER = gql`
  mutation registerSCC($input: CreateSCCUserInput!) @api(name: "users") {
    registerSCC(input: $input) {
      _id
      firstName
      lastName
      email
      role
    }
  }
`

export const REGISTER_CCC_USER = gql`
  mutation registerCCC($input: CreateCCCUserInput!) @api(name: "users") {
    registerCCC(input: $input) {
      _id
      firstName
      lastName
      phone
      email
      role
    }
  }
`

import React, { FC, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Row, Skeleton } from 'antd'

import { getToken, isCCC } from '../../../helpers/auth.helper'
import { numberWithSeparator } from '../../../helpers/number.helper'
import {
  GET_COMPANY_INFORMATION,
  GET_PERSONAL_INFORMATION,
  HAS_PERCENT_DISCOUNT,
} from '../../../queries'
import { tokenInfo } from '../../../services/auth'

import HeaderWithDiscount from './HeaderWithDiscount'
import NormalHeader from './NormalHeader'
import { PersonalInformationProps } from './types'

import styles from './styles.module.css'
import { RolesEnum } from '../../../helpers/enums'

const PersonalInformation: FC<PersonalInformationProps> = ({
  companyInformationError,
  companyInformationLoading,
  companyInformationData,
  getCompanyInformation,
  getPercentDiscountData,
  getPercentDiscount,
}) => {
  const { loading, error, data } = useQuery(GET_PERSONAL_INFORMATION)
  const userToken = getToken()
  const { user } = tokenInfo(userToken)

  const companyId = user?.company || data?.myProfile?.company?._id

  if (error || companyInformationError) return <p>error</p>
  const isExecutiveAssigned =
    !!companyInformationData?.companyDetail?.executiveAssigned

  const haveCreditLine = !!companyInformationData?.companyDetail?.creditLine
  useEffect(() => {
    if (companyId) {
      getCompanyInformation({ variables: { companyId } })
      getPercentDiscount()
    }
  }, [])

  const isViewDiscount = (role: RolesEnum): boolean =>
  [
    RolesEnum.ADMIN_CCC,
    RolesEnum.MULTI_RUT_BUYER,
    RolesEnum.MULTI_RUT_ADMIN,
  ].includes(role)
  
  return (
    <>
      {loading || companyInformationLoading ? (
        <Row justify="center">
          <div className={styles.skeletonContainer}>
            <Skeleton
              active
              className={styles.skeleton}
              paragraph={{ rows: 4 }}
            />
          </div>
        </Row>
      ) : isViewDiscount(data?.myProfile?.role) ? (
        getPercentDiscountData?.hasPercentDiscount ?? true ? (
          <HeaderWithDiscount
            userName={data?.myProfile?.firstName}
            executiveName={`${companyInformationData?.companyDetail?.executiveAssigned?.firstName} ${companyInformationData?.companyDetail?.executiveAssigned?.lastName}`}
            totalAmount={numberWithSeparator(
              companyInformationData?.companyDetail?.creditLine?.totalAmount,
            )}
            balance={numberWithSeparator(
              companyInformationData?.companyDetail?.creditLine?.balance,
            )}
            executiveEmail={
              companyInformationData?.companyDetail?.executiveAssigned?.email
            }
            executivePhone={
              companyInformationData?.companyDetail?.executiveAssigned?.phone
            }
          />
        ) : data?.myProfile?.role === RolesEnum.CCC ? (
          <NormalHeader
            userName={data?.myProfile?.firstName}
            haveCreditLine={haveCreditLine}
            balance={numberWithSeparator(
              companyInformationData?.companyDetail?.creditLine?.balance,
            )}
            totalCredit={numberWithSeparator(
              companyInformationData?.companyDetail?.creditLine?.totalAmount,
            )}
            executive={`${companyInformationData?.companyDetail?.executiveAssigned?.firstName} ${companyInformationData?.companyDetail?.executiveAssigned?.lastName}`}
            isExecutiveAssigned={isExecutiveAssigned}
            executiveEmail={
              companyInformationData?.companyDetail?.executiveAssigned?.email
            }
            executivePhone={
              companyInformationData?.companyDetail?.executiveAssigned?.phone
            }
          />
        ) : (
          <NormalHeader
            userName={data?.myProfile?.firstName}
            haveCreditLine={haveCreditLine}
            balance={numberWithSeparator(
              companyInformationData?.companyDetail?.creditLine?.balance,
            )}
            totalCredit={numberWithSeparator(
              companyInformationData?.companyDetail?.creditLine?.totalAmount,
            )}
            executive={`${companyInformationData?.companyDetail?.executiveAssigned?.firstName} ${companyInformationData?.companyDetail?.executiveAssigned?.lastName}`}
            isExecutiveAssigned={isExecutiveAssigned}
            executiveEmail={
              companyInformationData?.companyDetail?.executiveAssigned?.email
            }
            executivePhone={
              companyInformationData?.companyDetail?.executiveAssigned?.phone
            }
          />
        )
      ) : data?.myProfile?.role === RolesEnum.CCC ? (
        <NormalHeader
          userName={data?.myProfile?.firstName}
          haveCreditLine={haveCreditLine}
          balance={numberWithSeparator(
            companyInformationData?.companyDetail?.creditLine?.balance,
          )}
          totalCredit={numberWithSeparator(
            companyInformationData?.companyDetail?.creditLine?.totalAmount,
          )}
          executive={`${companyInformationData?.companyDetail?.executiveAssigned?.firstName} ${companyInformationData?.companyDetail?.executiveAssigned?.lastName}`}
          isExecutiveAssigned={isExecutiveAssigned}
          executiveEmail={
            companyInformationData?.companyDetail?.executiveAssigned?.email
          }
          executivePhone={
            companyInformationData?.companyDetail?.executiveAssigned?.phone
          }
        />
      ) : (
        <Row className={styles.informationRow} justify="center" gutter={8}>
          <Col span={24} className={styles.userInformationContainer}>
            <Row justify="center">
              <p className={styles.welcomeMessageFontSCC}>
                ¡Bienvenido de nuevo, {data?.myProfile?.firstName}!
              </p>
            </Row>
            <Row justify="center">
              <p className={styles.welcomeMessageDescriptionFontSCC}>
                Con Simplus, puedes agendar y administrar todos tus viajes
                laborales de manera rápida y sencilla.
                <br />
                ¿Estás listo para reservar tu próximo viaje?
              </p>
            </Row>
          </Col>
        </Row>
      )}
    </>
  )


}

export default PersonalInformation

import moment from 'moment'
import { compose, prop, sortBy, toLower } from 'ramda'

import {
  Passenger,
  PassengerInput,
  QueryString,
  Quota,
  Seat,
  Transaction,
  TravelInput,
} from '../../screens/travel-page/types'
import {
  AutoCompleteOption,
  AutoCompleteOptionFilter,
  RouteResponse,
} from '../../types/page-types/home-page.types'
import { DiscountTypeEnum } from '../enums'

export const getCityName = (
  arr: RouteResponse[],
  value: string,
  type = 'origin',
): RouteResponse | undefined => {
  if (type === 'origin')
    return arr.find(({ originCityId }) => value === originCityId)
  if (type === 'destiny')
    return arr.find(({ destinationCityId }) => value === destinationCityId)
}

export const getDestinies = (
  allRoutes: RouteResponse[],
  departureCity: string,
): AutoCompleteOption[] => {
  const destinies = allRoutes
    .filter(
      route =>
        (route.originCityId === departureCity ||
          route.originCityName === departureCity) &&
        route.destinationCityId !== departureCity &&
        route.destinationCityName !== departureCity,
    )
    .map(route => {
      return {
        id: route.destinationCityId,
        name: route.destinationCityName,
      }
    })

  const sortByName = sortBy(compose(toLower, prop('name')))

  return sortByName(destinies)
}

export const getOrigins = (
  allRoutes: RouteResponse[],
): AutoCompleteOption[] => {
  const routes = allRoutes.reduce(
    (
      accu: AutoCompleteOption[],
      { originCityId, originCityName }: RouteResponse,
    ) => {
      if (
        !accu.find(({ id }) => id === originCityId) &&
        originCityName !== 'Administrativo C-B'
      )
        accu.push({
          id: originCityId,
          name: originCityName,
        })
      return accu
    },
    [],
  )

  const sortByName = sortBy(compose(toLower, prop('name')))

  return sortByName(routes)
}

export const getOriginsFilter = (
  allRoutes: RouteResponse[],
): AutoCompleteOptionFilter[] => {
  const routes = allRoutes.reduce(
    (
      accu: AutoCompleteOptionFilter[],
      { originCityId, originCityName }: RouteResponse,
    ) => {
      if (
        !accu.find(reg => reg.originCityId === originCityId) &&
        originCityName !== 'Administrativo C-B'
      )
        accu.push({
          originCityId,
          label: originCityName,
          value: originCityName,
        })
      return accu
    },
    [],
  )

  const sortByName = sortBy(compose(toLower, prop('label')))

  return sortByName(routes)
}

export const getGoneOrReturn = (
  hasReturn: boolean,
  step: number,
): number | undefined => {
  if (!hasReturn) return 0
  if (hasReturn && !step) return 1
  if (hasReturn && step >= 1) return 2
}

export const passengerToInput = (
  origin: string,
  destiny: string,
  seatNumber: number,
  passenger: Passenger,
): PassengerInput => {
  const {
    dv,
    email,
    fatherLastName,
    firstName,
    rut,
    passport,
    motherLastName,
    phone,
  } = passenger

  const identification = passport ? passport : `${rut}-${dv}`
  const identification_type = passport ? 'PAS' : 'RUT'

  return {
    departure_bus_stop: origin,
    destination_bus_stop: destiny,
    email,
    father_last_name: fatherLastName,
    first_name: firstName,
    identification,
    identification_type,
    mother_last_name: motherLastName,
    phone,
    seat_number: seatNumber,
  }
}

const createSeatInput = (
  passenger: string,
  seat: Seat,
  seatType: string,
  percentDiscount?: number,
) => {
  const price = percentDiscount
    ? seat.price - calcPercentDiscount(seat.price, percentDiscount)
    : seat?.discount_price ?? seat.price
  const priceDifference = percentDiscount
    ? seat.price - price
    : seat.discount_price
    ? seat.price - seat.discount_price
    : 0

  return {
    price,
    originalPrice: seat.price,
    priceDifference,
    number: seat.number,
    row: seat.row,
    col: seat.column,
    passenger,
    floorNumber: seat.floor_number,
    type: seatType,
  }
}

export const createTravelInput = (
  search: QueryString,
  transaction: Transaction,
  currentAccount: string | undefined,
  costCenter: string | undefined,
  quotas: Quota[],
  isGone: boolean,
  percentDiscount?: number,
): TravelInput => {
  const {
    travelGone,
    travelReturn,
    goneReservation,
    returnReservation,
  } = transaction
  const travel = isGone ? travelGone : travelReturn

  const discountType = percentDiscount
    ? DiscountTypeEnum.PERCENT_DISCOUNT
    : quotas.some(quota => quota.goneSeat?.discount_price)
    ? DiscountTypeEnum.FLAT_RATE_DISCOUNT
    : undefined


  if(goneReservation === null || goneReservation === undefined  ){
    throw new Error('Error: reservationId no disponible')
  }


  return {
    reservationId: (isGone ? goneReservation : returnReservation) ?? '',
    operatorName: travel?.bus_operator_name ?? '',
    departsAt: moment(travel?.departs_at, 'DD-MM-YYYY').format('DD--MM-YYYY'),
    departsTime: parseInt(
      moment(travel?.departs_at, 'DD-MM-YYYY HH:mm').format('HHmm'),
    ),
    departureCity: isGone ? search.origin : search.destiny,
    departureTerminal: travel?.departure_terminal_name ?? '',
    arrivesAt: moment(travel?.arrives_at, 'DD-MM-YYYY').format('DD-MM-YYYY'),
    arrivesTime: parseInt(
      moment(travel?.arrives_at, 'DD-MM-YYYY HH:mm').format('HHmm'),
    ),
    destinationCity: isGone ? search.destiny : search.origin,
    destinationTerminal: travel?.destination_terminal_name ?? '',
    seats: quotas.map(quota => {
      return createSeatInput(
        (quota.passenger as Passenger)._id,
        (isGone ? quota.goneSeat : quota.returnSeat) as Seat,
        travel?.seat_class ?? '',
        percentDiscount,
      )
    }),
    sections: travel?.sections.map(section => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete section.__typename
      return section
    }),
    currentAccount: currentAccount ? currentAccount : undefined,
    costCenter: costCenter ? costCenter : undefined,
    isGone,
    discountType,
  }
}

/* 
 This function is used to calculate the discount in percent correctly.
 For example: 100 * 10 / 100 = 10.000000000000002
 this function returns 10
*/
export const calcPercentDiscount = (price: number, discount: number) => {
  return Math.round(price * discount) / 100
}

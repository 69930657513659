import React, { FC } from 'react'
import { Col, Form, Input, Row } from 'antd'

import styles from './styles.module.css'

const { Item } = Form
const { Password } = Input

const PasswordRow: FC = () => (
  <Row className={styles.userForm}>
    <Col xs={24} sm={18}>
      <p className={styles.labelText}>Definir contraseña</p>
      <Item
        className={styles.itemRow}
        name="password"
        rules={[
          { required: true, message: 'Este campo es obligatorio' },
          {
            pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*.?&#])[A-Za-z\d@$!%*.?&#]{8,}/,
            message: 'Introduzca una contraseña válida',
          },
        ]}>
        <Password className={styles.passwordInput} />
      </Item>
    </Col>
    <Col xs={24} sm={18}>
      <p className={styles.labelText}>Confirmar nueva contraseña</p>
      <Item
        className={styles.itemRow}
        name="repeatedPassword"
        rules={[
          { required: true, message: 'Este campo es obligatorio' },
          {
            pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*.?&#])[A-Za-z\d@$!%*.?&#]{8,}/,
            message: 'Introduzca una contraseña válida',
          },
        ]}>
        <Password className={styles.passwordInput} />
      </Item>
    </Col>
    <Col xs={24} sm={18}>
      <p>
        Tu contraseña debe contener al menos 8 caracteres, incluyendo
        mayúsculas, minúsculas y un caracter especial (Ej. !@$#%&*)
      </p>
    </Col>
  </Row>
)

export default PasswordRow

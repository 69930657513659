import { message } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'
import axios from 'axios'

import { OnChangeInfo } from '../types/component-types/FileUploadForm.types'
import { UploadDocument } from '../types/page-types/create-ticket-page.types'

export const allowedFiles = ['.pdf', '.jpg', '.png', '.doc']

export const genUploadProps = (allowedFiles: string[], documents: UploadDocument[]) => ({
  name: 'file',
  accept: allowedFiles.join(','),
  multiple: false,
  action: `${process.env.GATSBY_API_UPLOAD_URI}/HELP`,
  headers: {
    authorization: 'authorization-text',
  },
  beforeUpload(file: UploadFile, fileList: UploadFile[]) {
    const fileIsValid = allowedFiles.includes(
      `.${file.name.split('.').pop() as string}`.toLocaleLowerCase(),
    )

    if (!fileIsValid) {
      message.error('Este archivo no tiene ninguno de los formatos permitidos')
      fileList.pop()
    }
    const currentSize = documents.reduce((acu, curr): number => {
      acu += curr.size / 1024 / 1024
      return acu
    }, 0)

    const isLt10M = file.size / 1024 / 1024 + currentSize < 10

    const notValid = documents.length > 2

    if (!isLt10M) {
      message.error(
        '¡La suma de los archivos no puede superar el límite de 10MB!',
      )
      fileList.length = 0
    }

    if (notValid) {
      message.error('Solo se permite un máximo de 3 archivos')
      fileList.pop()
    }

    return isLt10M && !notValid && fileIsValid
  },
  async onChange(info: OnChangeInfo) {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} se cargó con éxito`)

      const serverResponse = info.file.response
      documents.push({
        url: serverResponse.data.fileUrl,
        uid: info.file.uid,
        size: info.file.size,
      })
    } else if (info.file.status === 'error')
      message.error(`${info.file.name} tuvo un problema al cargar`)
  },
  async onRemove(file: UploadFile) {
    const document = documents.find(doc => doc.uid === file.uid)

    if (document)
      try {
        await axios.delete(process.env.GATSBY_API_UPLOAD_URI as string, {
          data: {
            path: document?.url,
          },
        })

        documents = documents.filter(doc => doc.uid !== document.uid)
      } catch (err) {
        console.log(err)
      }
  },
})

export const getFileName = (path: string): string => {
  return path.split('/').pop() ?? ''
}
import React, { FC, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Col, message, Row } from 'antd'

import BackArrow from '../../../../components/BackArrow'
import {
  calcPercentDiscount,
  passengerToInput,
} from '../../../../helpers/travel-page'
import {
  ADD_PASSENGERS_TO_TRAVEL,
  RESEVATION,
} from '../../../../queries/travel-page'
import { TravelPageContext } from '../../Provider'
import { Passenger, PassengerInput, Seat } from '../../types'

import styles from '../styles.module.css'

const SeatSelectionHeader: FC = () => {
  const [reservation, { loading: reservationLoading }] = useMutation(RESEVATION)
  const [addPassengersToTravel, { loading: addPassengerLoading }] = useMutation(
    ADD_PASSENGERS_TO_TRAVEL,
  )

  const {
    setPassengersView,
    setStep,
    hasReturn,
    transaction,
    setTransaction,
    quotas,
    setQuotas,
    percentDiscountValue,
    search,
  } = useContext(TravelPageContext)

  const reserveTravel = async (
    serviceId: string,
    seatNumbers: number[],
    token: string,
    discountValue: number,
    chargeValue: number,
  ): Promise<string | null> => {
    // console.log("entra a reserveTravel")
    try {
      const { data } = await reservation({
        variables: {
          input: {
            serviceId,
            seatNumbers,
            token,
            discountValue,
            chargeValue,
          },
        },
      })
      return data.reservation
    } catch (err) {
      console.log(err)
      return null
    }
  }

  const addPassengers = async (
    reservationId: string,
    passengers: PassengerInput[],
    token: string,
  ): Promise<boolean> => {
    try {
      const { data } = await addPassengersToTravel({
        variables: {
          input: {
            passengers,
            reservation_id: reservationId,
            token,
          },
        },
      })
      return data.addPassengersToTravel
    } catch (err) {
      console.log(err)
      return false
    }
  }

  const createReserve = async (
    serviceId: string,
    seats: Seat[],
    passengers: PassengerInput[],
    token: string,
  ): Promise<string | null> => {
    const [firstSeat] = seats

    if (serviceId && seats.length > 0 && token) {
      const reservation = await reserveTravel(
        serviceId,
        seats.map(seat => seat.number) ?? [],
        token,
        percentDiscountValue
          ? calcPercentDiscount(firstSeat.price, percentDiscountValue)
          : firstSeat?.discount_price &&
            firstSeat.price > firstSeat.discount_price
          ? firstSeat.price - firstSeat.discount_price
          : 0,
        firstSeat.price < firstSeat.discount_price
          ? firstSeat.discount_price - firstSeat.price
          : 0,
      )
      if (passengers.length > 0 && reservation)
        await addPassengers(reservation, passengers, token)
      return reservation
    }
    return null
  }

  const saveAndContinue = async () => {
    try {
      if (
        !quotas.map(quota => quota.goneSeat).some(seat => !!seat) ||
        (hasReturn &&
          !quotas.map(quota => quota.returnSeat).some(seat => !!seat))
      )
        return message.error('Debe seleccionar los asientos para el viaje')

      const goneSeats =
        quotas
          .filter(q => !!q.goneSeat?.number)
          .map(quota => quota.goneSeat as Seat) ?? []

      const returnSeats =
        quotas
          .filter(q => !!q.returnSeat?.number)
          .map(quota => quota.returnSeat as Seat) ?? []

      const seatsToBuy = parseInt(search.numberOfSeats)

      if (
        goneSeats.length < seatsToBuy ||
        (hasReturn && returnSeats.length < seatsToBuy)
      )
        return message.error('Faltan asientos por seleccionar')

      const {
        goneReservation,
        returnReservation,
        travelGone,
        travelReturn,
      } = transaction

      let goneReservationDone: string | null = null,
        returnReservationDone: string | null = null

      let passengers = quotas
        .filter(q => typeof q.passenger !== 'string')
        .map(q =>
          passengerToInput(
            search.departureCity,
            search.destinationCity,
            q.goneSeat?.number ?? 0,
            q.passenger as Passenger,
          ),
        )


      //travelGone
      if (travelGone && !goneReservation && quotas)        
        goneReservationDone = await createReserve(
          travelGone.id.toString(),
          goneSeats,
          passengers,
          travelGone.integration_code,
        )
        
      else if (passengers.length > 0 && goneReservation)
        await addPassengers(
          goneReservation,
          passengers,
          travelGone?.integration_code ?? '',
        )


      if (travelReturn && !returnReservation && quotas) {
        passengers = quotas
          .filter(q => typeof q.passenger !== 'string')
          .map(q =>
            passengerToInput(
              search.destinationCity,
              search.departureCity,
              q.returnSeat?.number ?? 0,
              q.passenger as Passenger,
            ),
          )

        returnReservationDone = await createReserve(
          travelReturn.id.toString(),
          returnSeats,
          passengers,
          travelReturn.integration_code,
        )
      } else if (travelReturn && passengers.length > 0 && returnReservation)
        await addPassengers(
          returnReservation,
          passengers,
          travelReturn.integration_code,
        )

      setTransaction({
        ...transaction,
        goneReservation: goneReservationDone ?? goneReservation,
        goneSeats,
        returnReservation: returnReservationDone ?? returnReservation,
        returnSeats,
      })

      setPassengersView(false)
      setStep(hasReturn ? 2 : 1)
    } catch (err) {
      console.log(err)
    }
  }

  const cancel = () => {
    setQuotas(
      quotas.map(quota => {
        if (!transaction.goneReservation) quota.goneSeat = undefined
        if (!transaction.returnReservation) quota.returnSeat = undefined
        quota.passenger = 'Pasajero sin asignar'
        return quota
      }),
    )
    setPassengersView(false)
  }

  return (
    <Row justify="space-between" align="middle" className={styles.underHeader}>
      <Col xs={2} md={0} className={styles.centered}>
        <BackArrow clickAction={cancel} whiteColor />
      </Col>
      <Col
        xs={{ span: 8, push: 2 }}
        md={{ span: 8, offset: 5 }}
        lg={{ span: 10, offset: 4 }}>
        <p className={styles.underHeaderTitleFont}>Asignar asientos</p>
      </Col>
      <Col xs={8} md={0} className={styles.centered}>
        <Button
          className={styles.saveChangesButton}
          onClick={saveAndContinue}
          loading={reservationLoading || addPassengerLoading}>
          Guardar
        </Button>
      </Col>
      <Col xs={0} md={10} lg={8} xl={6}>
        <Row justify="start" align="middle">
          <Col xs={11}>
            <Button className={styles.goBackButton} onClick={cancel}>
              Descartar y volver
            </Button>
          </Col>
          <Col xs={11} push={1}>
            <Button
              className={styles.saveChangesButton}
              onClick={saveAndContinue}
              loading={reservationLoading || addPassengerLoading}>
              Guardar cambios
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SeatSelectionHeader

import React, { FC, useEffect, useState } from 'react'
import { useMutation, useSubscription } from '@apollo/client'
import { Button, Col, message, Modal, Row } from 'antd'
import axios, { AxiosResponse } from 'axios'

import FileInput from '../../../components/FileInput'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { getFileName } from '../../../helpers/documents'
import { Company } from '../../../types/company.type'
import { COMPANY_UPDATED, UPDATE_COMPANY_DOCUMENTS } from '../graphql'

type Props = {
  company: Company
}

const CreditLineDocumentsViewer: FC<Props> = ({ company }) => {
  const DOCUMENTS = {
    TAX_FOLDER: 'taxFolder',
    DICOM: 'DICOM',
  }

  const [comp, setCompany] = useState<Company>()

  const [loading, setLoading] = useState<string | null>(null)

  const [updateDocuments] = useMutation(UPDATE_COMPANY_DOCUMENTS, {
    onCompleted: () => {
      setLoading(null)
    },
    onError: err => {
      setLoading(null)
      message.error(err.message)
    },
  })

  const { data } = useSubscription(COMPANY_UPDATED, {
    variables: {
      id: company._id,
    },
  })

  const handleFileUpload = (
    files: FileList | null,
    typeDocument: string,
    onOk: (response: AxiosResponse) => void,
  ) => {
    if (files) {
      const form = new FormData()
      form.append('file', files[0])
      axios
        .post(`${process.env.GATSBY_API_UPLOAD_URI}/${typeDocument}`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(onOk)
        .catch(err => console.error(err))
    }
  }

  const deleteFile = async (url: string, onOk: () => void) => {
    Modal.confirm({
      title: '¿Eliminar documento?',
      content: `El documenro ${getFileName(url)} será eliminado`,
      onOk: () => {
        axios
          .delete(process.env.GATSBY_API_UPLOAD_URI as string, {
            data: {
              path: url,
            },
          })
          .then(onOk)
          .catch(err => console.error(err))
      },
    })
  }

  const uploadTaxFolder = (files: FileList) => {
    setLoading(DOCUMENTS.TAX_FOLDER)
    handleFileUpload(files, DOCUMENTS.TAX_FOLDER, ({ data }) => {
      updateDocuments({
        variables: {
          company: comp?._id,
          input: {
            taxFolder: data.data.fileUrl,
            DICOM: comp?.documents.DICOM,
          },
        },
      })
    })
  }

  const uploadDICOM = (files: FileList) => {
    setLoading(DOCUMENTS.DICOM)
    handleFileUpload(files, DOCUMENTS.DICOM, ({ data }) => {
      updateDocuments({
        variables: {
          company: comp?._id,
          input: {
            taxFolder: comp?.documents.taxFolder,
            DICOM: data.data.fileUrl,
          },
        },
      })
    })
  }

  const deleteTaxFolder = (taxFolder: string) => {
    deleteFile(taxFolder, () => {
      setLoading(DOCUMENTS.TAX_FOLDER)
      updateDocuments({
        variables: {
          company: comp?._id,
          input: {
            taxFolder: '',
            DICOM: comp?.documents?.DICOM,
          },
        },
      })
    })
  }

  const deleteDICOM = (DICOM: string) => {
    deleteFile(DICOM, () => {
      setLoading(DOCUMENTS.DICOM)
      updateDocuments({
        variables: {
          company: comp?._id,
          input: {
            taxFolder: comp?.documents?.taxFolder,
            DICOM: '',
          },
        },
      })
    })
  }

  useEffect(() => {
    if (data) {
      const { companyUpdated } = data
      if (companyUpdated) {
        const newCompany = { ...comp }
        newCompany.documents = companyUpdated.documents
        setCompany(newCompany as Company)
      }
    }
  }, [data])

  useEffect(() => {
    setCompany(company)
  }, [company])

  return (
    <Row>
      <Col span={24}>
        <Row align="middle" justify="space-between">
          <Col>Carpeta tributaria</Col>
          <Col>
            {loading === DOCUMENTS.TAX_FOLDER ? (
              <LoadingSpinner size="50px" />
            ) : comp?.documents?.taxFolder ? (
              <span>
                {
                  <Button
                    href={`${process.env.GATSBY_FILES_URI}${comp.documents.taxFolder}`}
                    target="blank"
                    type="text">
                    {getFileName(comp.documents.taxFolder)}
                  </Button>
                }
                <Button
                  size="small"
                  shape="circle"
                  icon="x"
                  onClick={() =>
                    deleteTaxFolder(comp.documents.taxFolder ?? '')
                  }
                />
              </span>
            ) : (
              <FileInput onChange={uploadTaxFolder} inputId="taxFolderInput">
                Subir archivo
              </FileInput>
            )}
          </Col>
        </Row>
        <Row justify="space-between" align="middle">
          <Col>DICOM Full</Col>
          <Col>
            {loading === DOCUMENTS.DICOM ? (
              <LoadingSpinner size="50px" />
            ) : comp?.documents?.DICOM ? (
              <span>
                {
                  <Button
                    href={`${process.env.GATSBY_FILES_URI}${comp.documents.taxFolder}`}
                    target="blank"
                    type="text">
                    {getFileName(comp.documents.DICOM)}
                  </Button>
                }
                <Button
                  size="small"
                  shape="circle"
                  icon="x"
                  onClick={() => deleteDICOM(comp?.documents.DICOM ?? '')}
                />
              </span>
            ) : (
              <FileInput onChange={uploadDICOM} inputId="DICOMInput">
                Subir archivo
              </FileInput>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default CreditLineDocumentsViewer

import { gql } from '@apollo/client'

export const MESSAGE_ADDED = gql`
  subscription messageAdded($id: String!) {
    messageAdded(id:$id){
      _id
      createdAt
      message
      seen
      user{
        _id
        name
        
      }
    }
  }
`

export const DOCUMENT_ADDED = gql`
  subscription documentAdded($id: String!){
    documentAdded(id: $id){
      path
      userId
      userFullName
    }
  }
`

export const DOCUMENT_DELETED = gql`
  subscription documentDeleted($id: String!){
    documentDeleted(id: $id){
      path
      userId
      userFullName
    }
  }
`

export const COMPANY_UPDATED = gql`
  subscription companyUpdated($id: String!){
    companyUpdated(id: $id){
      documents{
        taxFolder
        DICOM
      }
    }
  }
`
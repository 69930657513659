import React from 'react'
import { gql, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Col, Divider, Row, Skeleton } from 'antd'
import { navigate } from 'gatsby'

import BackArrow from '../../components/BackArrow'
import SEO from '../../components/seo'
import withLocation from '../../components/WithLocation'

import styles from './styles.module.css'

const GET_FAQ = gql`
  query getFaqById($_id: String!) @api(name: "reports") {
    getFaqById(_id: $_id) {
      _id
      title
      body
    }
  }
`

interface Props extends RouteComponentProps {
  search: {
    _id: string
  }
}

const FAQPage: React.FC<Props> = ({ search }) => {
  const { _id } = search

  const { loading: faqLoading, error: faqError, data: faqData } = useQuery(
    GET_FAQ,
    {
      variables: { _id },
    },
  )

  if (faqError) return <p>error</p>

  return (
    <>
      <SEO title="FAQ" />
      <Row justify="center" className={styles.container}>
        <Col xs={24} className={styles.innerContainer}>
          <Row align="middle">
            <Col className={styles.titleContainer} span={24}>
              <Row justify="center" align="middle">
                <Col xs={2}>
                  <div style={{ marginLeft: '20%' }}>
                    <BackArrow
                      clickAction={() => navigate('/app/ayuda')}
                      whiteColor
                    />
                  </div>
                </Col>
                <Col xs={20}>
                  <p className={styles.pageTitleFont}>
                    {faqData?.getFaqById?.title}
                  </p>
                </Col>
                <Col xs={2}></Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24} sm={12} className={styles.faqColTitle}>
              <span className={styles.faqTitleFont}>
                {faqLoading ? (
                  <Skeleton.Input
                    active
                    size={'small'}
                    className={styles.titleLoader}
                  />
                ) : (
                  faqData?.getFaqById?.title
                )}
              </span>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24} sm={12} className={styles.faqColBody}>
              <span className={styles.faqBodyFont}>
                {faqLoading ? (
                  <>
                    <Skeleton active />
                    <Skeleton active />
                    <Skeleton active />
                  </>
                ) : (
                  faqData?.getFaqById?.body
                )}
              </span>
            </Col>
          </Row>

          <Divider />
          <Row justify="center">
            <Col>
              <Button
                className={styles.backButton}
                onClick={() => {
                  navigate('/app/ayuda')
                }}>
                Volver
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default withLocation(FAQPage)

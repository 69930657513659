import React, { FC } from 'react'

import Content from './Content'
import { MyUserPageProvider } from './Provider'

const MyUsersPage: FC = () => (
  <MyUserPageProvider>
    <Content />
  </MyUserPageProvider>
)

export default MyUsersPage

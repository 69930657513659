import React, { useState } from 'react'
import { Drawer, message } from 'antd'

import {Props} from './types'

import styles from './styles.module.css'
import DownloadTemplateButton from './downloadTemplateButton'
import UploadButton from './uploadButton'
import { CostCenter, CostCenterMasiva } from '../../../types/costCenter.type'


import StageButtons from './stageButtons'
import UploadedCecosList from './uploadedCecosList'

const MasivaModal: React.FC<Props> = ({
  onClose,
  visible,
  handleCeCos,
  userCompany,
}) => {


  const [costCenters, setCostCenters] = useState<CostCenter[]>([])
  const [costCentersInList, setCostCentersInList] = useState<CostCenterMasiva[]>([])

  const [stage, setStage] = useState(1)

    const addCostCenter = (costCenters: any[]) => {
      // console.log("costCenters en addCostCenter", costCenters)
      setCostCenters(costCenters);
      setCostCentersInList(costCenters);
      setStage(stage + 1);
    };


    const addFromStageButton = () => {
      const isCeCosValid = addCeCosToList(costCenters);
    
      if (isCeCosValid) {
        onClose(); // Cierra el modal solo si no hay errores o duplicados en la lista
        setStage(1);
      } else {
        setStage(2); // Deja abierto el modal en  caso de algún error
      }
    };

    const addCeCosToList = (costCenters: CostCenter[]) => {
      const dataValidated = validateData(costCenters)
      // console.log("dataValidated", dataValidated)
      if (dataValidated) {
        setCostCenters(costCenters);
        setStage(stage + 1);
        handleCeCos(costCenters);
        return true
      }else{
        return false
      }
    };

    const validateData = (costCenters: any[]) => {
      let validatedData : boolean = false;
      const wrongData = costCenters.some(
        (ceco) =>
          ceco.currentAccount === null ||
          ceco.currentAccount === undefined ||
          ceco.name === null ||
          ceco.name === undefined ||
          ceco.description === null ||
          ceco.description === undefined
      );

      const duplicateFound = hasDuplicates(costCenters.map((ceco) => [ceco.currentAccount, ceco.name, ceco.description]));

      if(duplicateFound){
        message.error(`Hay registros duplicados.`);
      }

      //Si se encontró alguna data null o indefinida o si hay algún diplicado.
      if(wrongData || duplicateFound){
        validatedData= false
      }else{
        validatedData= true
      }

      return validatedData
    
    };

    const hasDuplicates = (arr: any[][]) => {
      const seen = new Set();
      for (const item of arr) {
        const key = JSON.stringify(item);
        if (seen.has(key)) {
          return true; // Se encontraron duplicados
        }
        seen.add(key);
      }
      return false; // No se encontaron duplicados
    };


  const reset = () => {
    handleCeCos([])
    setCostCenters([])
    setCostCentersInList([]);
    setStage(1)
    if(stage === 1){
      onClose()
    }
  }

  // console.log("costCenterinList", costCentersInList)


return (

  <Drawer
    title={
      <div>
        <div className={styles.headerTitle}>
          Carga de CECOs
        </div>
      </div>
    }
    placement={'right'}
    closable={true}
    width={'50%'}
    onClose={onClose}
    visible={visible}
    key={'right'}>

      {stage === 1 && (
        <>
        <UploadButton
          handleCeCos={addCostCenter}
          userCompany={userCompany}
        />
       <DownloadTemplateButton />
        </>
      )}

      {stage === 2 && (
        <UploadedCecosList
          stage={stage}
          costCenters={costCentersInList}
          setCostCenterList={setCostCentersInList}
          setCostCenters = {setCostCenters}
        />
      )}

      <StageButtons
          onCancelClick={reset}
          onClose={onClose}
          stage={stage}
          costCenter={costCenters}
          saveCeCos = {addFromStageButton}
      />
  
  </Drawer>

)
}

export default MasivaModal

import React, { FC, useState } from 'react'
import {
    ArrowRightOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons'
import {Col, Row, Tooltip } from 'antd'


import { UploadedCeCosListProps } from './props'
import { CostCenter, CostCenterMasiva } from '../../../types/costCenter.type'

import styles from './styles.module.css'
import Item from 'antd/lib/descriptions/Item'

const UploadedCecosList: FC<UploadedCeCosListProps> = ({
  costCenters,
  stage,
  setCostCenterList,
  setCostCenters
}) => {

  // console.log("costCenters", costCenters)

  const isDuplicated = (cecoReg: CostCenterMasiva): boolean => {
    const duplicateCount = costCenters.filter((costCenter) => {
      return (
        costCenter.currentAccount === cecoReg.currentAccount &&
        costCenter.name === cecoReg.name &&
        costCenter.description === cecoReg.description
      );
    }).length;
  
    // If duplicateCount is greater than 1, it means there are duplicates
    return duplicateCount > 1;
  }


  const deleteFromList = (toDelete: CostCenterMasiva) => {
    const updatedCostCenters = costCenters.filter((costCenter, index) => {
      const isMatch =
        costCenter.currentAccount === toDelete.currentAccount &&
        costCenter.name === toDelete.name &&
        costCenter.description === toDelete.description;
  
      if (isMatch && index === costCenters.indexOf(toDelete)) {
        return false; 
      }

      return true; 
    });
  
    setCostCenterList(updatedCostCenters);
    setCostCenters(updatedCostCenters);

  };
  


  return (
    <Row className={styles.marginTop1}>
      <Col span={20} id="container">
            {costCenters
            .map((costCenter, index) => (

            <Row
              align="middle"
              justify="space-between"
              className={`${styles.costCenterDiscountRow} ${
                !costCenter.saved ? styles.errorRow : null
              }`}>
            <Col
              span={1}
              className={`${styles.errorColor} ${styles.centered}`}>
              {!costCenter.saved ? <CloseCircleOutlined /> : null}
            </Col>
            <Col span={1}>
              {isDuplicated(costCenter) && (
                <Tooltip title="Registro duplicado.">
                  <ExclamationCircleOutlined style={{ color: 'red' }} />
                </Tooltip>
              )}
            </Col>
                  <Col
                    lg={6}
                    className={`${styles.rightBordered} ${styles.centered} ${
                      styles.marginTop1_8
                    }`}>
                    {<div className={styles.label}>Cuenta Corriente</div>}
                    {costCenter.currentAccount ? (
                      <Item>
                      {costCenter.currentAccount}
                    </Item>
                    ) : (
                      costCenter.errorMessage &&
                      costCenter.errorMessage.split('.').map((errorMessagePart, i) => (
                        errorMessagePart.includes('Cuenta Corriente es requerido') && (
                          <Item key={i}>{errorMessagePart}</Item>
                        )
                      ))
                    )}

                  </Col>
                  <Col
                    lg={6}
                    className={`${styles.rightBordered} ${styles.centered} ${
                      styles.marginTop1_8
                    }`}>
                    {<div className={styles.label}>Nombre CeCo</div>}
                    {costCenter.name ? (
                      <Item>
                      {costCenter.name}
                    </Item>
                    ) : (
                      costCenter.errorMessage &&
                      costCenter.errorMessage.split('.').map((errorMessagePart, i) => (
                        errorMessagePart.includes('Nombre es requerido') && (
                          <Item key={i}>{errorMessagePart}</Item>
                        )
                      ))
                    )}

                  </Col>
                  <Col
                    lg={6}
                    className={`${styles.rightBordered} ${styles.centered} ${
                      styles.marginTop1_8
                    }`}>
                    {<div className={styles.label}>Descripción</div>}
                    {costCenter.description ? (
                      <Item>
                      {costCenter.description}
                    </Item>
                    ) : (
                      costCenter.errorMessage &&
                      costCenter.errorMessage.split('.').map((errorMessagePart, i) => (
                        errorMessagePart.includes('Descripción es requerido') && (
                          <Item key={i}>{errorMessagePart}</Item>
                        )
                      ))
                    )}

                  </Col>
                  <Col span={1}>
                    <Tooltip title="Eliminar registro del listado">
                      <CloseCircleOutlined
                        onClick={() => deleteFromList(costCenter)}
                        className={styles.removeButton}
                      />
                    </Tooltip>
                  </Col>
                </Row>
            ))}

      </Col>


    </Row>
  )
}

export default UploadedCecosList

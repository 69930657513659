import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Button, Col, Row } from 'antd'
import { navigate } from 'gatsby'

import { RegisterConfirmationPageLocation } from '../../types/page-types/register-confirmation-page.types'

import Success from './success.inline.svg'

import styles from './styles.module.css'

type Props = RouteComponentProps<{ location: RegisterConfirmationPageLocation }>

const RegisterConfirmationPage: React.FC<Props> = ({ location }) => {
  return (
    <Row justify="center" align="middle" className={styles.outerContainer}>
      <Col xs={24} sm={12} className={styles.innerContainer}>
        <Success />
        <p className={styles.title}>Tu solicitud ha sido enviada</p>
        <p className={styles.body}>
          Nos pondremos en contacto contigo a través del correo electrónico
          <b> {location?.state?.email}</b> para completar tu registro.
          ¡Esperamos verte pronto!
        </p>
        <Button
          className={styles.continueButton}
          onClick={() => {
            navigate('/inicio-sesion/')
          }}>
          Volver al inicio
        </Button>
      </Col>
    </Row>
  )
}

export default RegisterConfirmationPage

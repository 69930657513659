import React, { FC, useContext, useEffect, useState } from 'react'
import { UserAddOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Col, Empty, Row } from 'antd'
import moment from 'moment'

import AdminLayout from '../../../components/AdminLayout'
import LoadingSpinner from '../../../components/LoadingSpinner'
import SEO from '../../../components/seo'
import { USERS_BY_COMPANY } from '../../../queries'
import { User } from '../../my-travels/types'
import CreateUserDrawer from '../CreateUserDrawer'
import { MyUserPageContext, UserSearchFilters } from '../Provider'
import UpdateUserDrawer from '../UpdateUserDrawer'

import DeleteUserModal from './DeleteUserModal'
import MyUsersListRow from './MyUsersListRow'
import UsersFilterRow from './UsersFilterRow'

import styles from './styles.module.css'

const MyUserContent: FC = () => {
  const {
    allUsers,
    filteredUsers,
    selectedUser,
    setAllUsers,
    setCreateDrawer,
    setFilteredUsers,
    /* nuevo*/
    changePage,
    selectedPage,
    setSelectedPage,
    setTotalUsers,
    setUsersByPage,
    usersByPage,
  } = useContext(MyUserPageContext)

  /* For searching */
  const [filters, setFilters] = useState<UserSearchFilters>({})
  /* For sorting*/
  const [sortOrder, setSort] = useState<number | undefined>(0)

  const { loading, refetch } = useQuery(USERS_BY_COMPANY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ usersByCompany }) => {
      const users = Object.values(usersByCompany.data) as User[]
      setTotalUsers(usersByCompany.total)
      setUsersByPage(usersByCompany.usersByPage)
      setAllUsers(users)
      setFilteredUsers(users)
    },
    variables: {
      filters,
      input: {
        from: moment().format('YYYY-MM-DD HH:mm:ss'),
        to: moment().format('YYYY-MM-DD HH:mm:ss'),
        page: selectedPage,
        usersByPage: usersByPage > 0 ? usersByPage : 10,
      },
      sortOrderValue: sortOrder,
    },
  })

  useEffect(() => {
    if (selectedPage && !loading) refetch()
  }, [selectedPage])

  const addUser = (
    <Button
      className={styles.addUser}
      icon={<UserAddOutlined />}
      onClick={() => setCreateDrawer(true)}>
      Agregar usuario
    </Button>
  )

  const handleSearch = (query: string) => {
    // console.log("query", query);
    const emailPattern = /^\S+@\S+\.\S+$/
    const rutPattern = /^[1-9]\d*\-(\d|k|K)$/
    const namePattern = /^[A-Za-z\s]+$/
    if (query) {
      if (emailPattern.test(query)) {
        // console.log("es email");
        setFilters({ ...filters, email: query })
        setSelectedPage(1)
      } else if (rutPattern.test(query)) {
        // console.log("es rut");
        setFilters({ ...filters, rut: query })
        setSelectedPage(1)
      } else if (namePattern.test(query)) {
        // console.log("es nombre")
        setFilters({ ...filters, fullName: query })
        setSelectedPage(1)
      }
    } else setFilters({})
  }

  const handleSortOrder = (option: number) => {
    // option 0 AZ - option 1 ZA
    // console.log("option", option)
    setSort(option)
  }

  return (
    <>
      <SEO title="Mis usuarios" />
      <AdminLayout addOn={addUser} title="Mis usuarios">
        <CreateUserDrawer refetch={refetch} />
        {Object.keys(selectedUser).length > 0 ? (
          <>
            <DeleteUserModal refetch={refetch} />
            <UpdateUserDrawer refetch={refetch} />
          </>
        ) : null}
        <Row className={styles.usersDisplay} justify="space-around">
          <Col span={24}>
            <UsersFilterRow
              handleSearch={handleSearch}
              handleSort={handleSortOrder}
            />
            {loading ? (
              <LoadingSpinner size="50px" />
            ) : filteredUsers.length > 0 ? (
              <MyUsersListRow />
            ) : (
              <Empty description="No se encontraron usuarios asociados a su cuenta." />
            )}
          </Col>
        </Row>
      </AdminLayout>
    </>
  )
}

export default MyUserContent

import React, { FC } from 'react'
import { Button, Col, Row } from 'antd'

import { MyTravelsHeaderProps } from '../types'

import styles from '../styles.module.css'

const MyTravelsHeader: FC<MyTravelsHeaderProps> = ({ setActiveDevolution }) => {
  return (
    <Row
      className={styles.headerContainer}
      justify="space-around"
      align="middle">
      <Col
        xs={{ span: 20 }}
        sm={{ span: 14, push: 2 }}
        md={{ span: 10 }}
        xl={{ span: 10, push: 2 }}>
        <h2>Gestión de viajes</h2>
      </Col>
      <Col xs={20} sm={10} md={6} className={styles.centered}>
        <Button
          type="text"
          onClick={() => setActiveDevolution(true)}
          className={styles.devolutionButton}>
          Solicitar devoluciones
        </Button>
      </Col>
    </Row>
  )
}

export default MyTravelsHeader

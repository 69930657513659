import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Col, message, Popover, Row } from 'antd'

import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import { numberWithSeparator } from '../../../../../../helpers/number.helper'
import { VALIDATE_SEAT } from '../../../../../../queries/travel-page'
import { TravelPageContext } from '../../../../Provider'
import { Seat } from '../../../../types'
import { SingleSeatProps } from '../../../../types/props'

import styles from './styles.module.css'

const SingleSeat: React.FC<SingleSeatProps> = ({
  seat,
  minPrice,
  maxPrice,
  isGone,
}) => {
  const {
    transaction,
    quotas,
    setQuotas,
    hasReturn,
    currentQuotaSelected,
    setCurrentQuotaSelected,
    setActiveIsGoneTravel,
  } = useContext(TravelPageContext)

  const [validateSeat, { loading }] = useMutation(VALIDATE_SEAT)

  const [reservedSeats, setReservedSeats] = useState<Seat[]>([])
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false)

  const removeSeatFromQuota = async (seat: Seat) => {
    setQuotas(
      quotas.map(quota => {
        if (isGone && quota.goneSeat === seat) quota.goneSeat = undefined
        if (!isGone && quota.returnSeat === seat) quota.returnSeat = undefined
        return quota
      }),
    )
  }

  const manageSeat = async (seat: Seat) => {
    const { goneSeats, returnSeats } = transaction
    if (reservedSeats.find(s => s.number === seat.number)) return
    if (currentQuotaSelected) {
      if (currentQuotaSelected.goneSeat === seat) {
        removeSeatFromQuota(seat)
        return
      }

      if (currentQuotaSelected.returnSeat === seat) {
        removeSeatFromQuota(seat)
        return
      }

      if (
        quotas.find(
          quota => quota.goneSeat === seat || quota.returnSeat === seat,
        )
      )
        return message.error('Este asiento ya se encuentra seleccionado')

      if (
        isGone
          ? !goneSeats?.find(s => s.number === seat.number)
          : !returnSeats?.find(s => s.number === seat.number)
      ) {
        const { travelGone, travelReturn } = transaction
        try {
          const { data } = await validateSeat({
            variables: {
              input: {
                seatNumber: seat.number,
                serviceId: isGone
                  ? travelGone?.id.toString()
                  : travelReturn?.id.toString(),
                token: isGone
                  ? travelGone?.integration_code
                  : travelReturn?.integration_code,
              },
            },
          })

          if (!data.validateSeat)
            return message.error('El asiento no se encuentra disponible')
        } catch (err) {
          console.log(err)
          return
        }
      }

      const newQuotas = quotas.map(quota => {
        if (quota === currentQuotaSelected) {
          quota.goneSeat = isGone ? seat : quota.goneSeat
          quota.returnSeat = isGone ? quota.returnSeat : seat
        }
        return quota
      })
      setQuotas(newQuotas)

      const nextQuota =
        newQuotas.indexOf(currentQuotaSelected) < newQuotas.length - 1
          ? newQuotas[newQuotas.indexOf(currentQuotaSelected) + 1]
          : newQuotas[0]

      const withoutGone = newQuotas.find(quota => !quota.goneSeat)
      const withoutReturn = hasReturn
        ? newQuotas.find(quota => !quota.returnSeat) ?? nextQuota
        : nextQuota

      const newSelectedQuota = withoutGone ? withoutGone : withoutReturn

      if (hasReturn) {
        let activeIsGone: boolean | undefined = undefined
        setActiveIsGoneTravel(activeIsGone)
        if (!newSelectedQuota.returnSeat) activeIsGone = false
        if (!newSelectedQuota.goneSeat) activeIsGone = true
        setActiveIsGoneTravel(activeIsGone)
      }

      setCurrentQuotaSelected(newSelectedQuota)
    } else removeSeatFromQuota(seat)
  }

  const handleVisibleChange = (visible: boolean) => {
    setPopoverVisible(visible)
  }

  useEffect(() => {
    if (isGone) setReservedSeats(transaction.goneSeats ?? [])
    else setReservedSeats(transaction.returnSeats ?? [])
  }, [])

  const isSelected = (seat: Seat) => {
    if (isGone)
      return !!quotas.find(quota => quota.goneSeat?.number === seat.number)
    else return !!quotas.find(quota => quota.returnSeat?.number === seat.number)
  }

  const getPassengerInitials = (seat: Seat): string | undefined => {
    const quota = isGone
      ? quotas.find(quota => quota.goneSeat?.number === seat.number)
      : quotas.find(quota => quota.returnSeat?.number === seat.number)
    if (quota?.passenger && typeof quota?.passenger !== 'string')
      return `${quota?.passenger?.firstName[0]}${quota?.passenger.fatherLastName[0]}`

    return undefined
  }

  if (
    (seat?.available && !(reservedSeats.length > 0)) ||
    (reservedSeats.find(s => s.number === seat.number) &&
      reservedSeats.length > 0)
  )
    return (
      <Popover
        visible={popoverVisible}
        onVisibleChange={handleVisibleChange}
        trigger="hover"
        title={
          <Row justify="space-between">
            <Col>Información</Col>
            <Col>
              <a onClick={() => handleVisibleChange(false)}>x</a>
            </Col>
          </Row>
        }
        content={
          <div>
            <div>
              Precio: ${numberWithSeparator(seat.discount_price ?? seat.price)}
            </div>
            <div>Número: {seat.number}</div>
            <div>Pasajero: {getPassengerInitials(seat) ?? '--'}</div>
          </div>
        }>
        <Row
          className={`${styles.pointer} ${
            isSelected(seat)
              ? styles.selectedSeat
              : seat.price === minPrice
              ? styles.emptySeatP1
              : seat.price === maxPrice
              ? styles.emptySeatP2
              : ''
          }`}
          justify="center"
          align="middle"
          onClick={() => manageSeat(seat)}>
          {loading ? (
            <Col span={24} className={styles.whiteText}>
              <LoadingSpinner />
            </Col>
          ) : (
            <Col span={24}>
              <div className={` ${styles.seatNumberFont}`}>
                {getPassengerInitials(seat) ?? seat.number}
              </div>

              {isSelected(seat) &&
              !reservedSeats.find(s => s.number === seat.number) ? (
                <div className={styles.removeButton}>x</div>
              ) : null}
            </Col>
          )}
        </Row>
      </Popover>
    )

  return (
    <div className={styles.notAvailableSeat}>
      <div></div>
    </div>
  )
}

export default SingleSeat

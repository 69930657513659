import React from 'react'
import { Col, Divider, Row, Typography } from 'antd'

import { RowJustify } from '../../../helpers/enums'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { AverageRateProps } from '../types/props'

import styles from './styles.module.css'

const { Text } = Typography
const AverageRate: React.FC<AverageRateProps> = ({ report, userRol }) => {
  const { averageRate, totalOriginalCost, totalDiscount } = report

  const renderAverageRate = () => (
    <Text strong className={styles.text}>
      {`$${numberWithSeparator(averageRate)}`}
    </Text>
  )

  const renderTotalSaving = () => (
    <Text strong className={styles.text}>
      {`$${numberWithSeparator(totalDiscount)}`}
    </Text>
  )

  const renderDiscountPercentage = () => {
    const percentage = !totalDiscount
      ? 0
      : (100 / totalOriginalCost) * totalDiscount
    return (
      <Text strong className={styles.text}>
        {`(${percentage.toFixed(0)}%)`}
      </Text>
    )
  }

  const renderDivider = () => {
    if (window?.innerWidth < 576) return <span />
    return <Divider type="vertical" plain className={styles.divider} />
  }

  const renderTotalSavingSection = () => {

    if (totalDiscount > 0)
      return (
        <>
          {renderDivider()}
          <Col xs={24} md={10} className={styles.colTotalCost}>
            <Row>
              <Col xs={10} md={24} className={styles.centered}>
                <Text className={styles.textOpacity}>Ahorro total</Text>
              </Col>
              <Col
                xs={{ span: 10, offset: 4 }}
                md={{ span: 24, offset: 0 }}
                className={styles.centered}>
                {renderTotalSaving()} {renderDiscountPercentage()}
              </Col>
            </Row>
          </Col>
        </>
      )
    else return null
  }

  const rowJustify = `${
    totalDiscount > 0 ? RowJustify.spaceBetween : RowJustify.center
  }` as RowJustify

  return (
    <Row className={styles.rowMargingX} align="middle">
      <Col className={styles.contentCard} span={24}>
        <Row justify={rowJustify} align="middle" className={styles.height90}>
          <Col
            xs={24}
            md={10}
            className={`${
              totalDiscount > 0 ? styles.colTotalCost : styles.marginAverageRate
            } ${styles.centered}`}>
            <Row align="middle">
              <Col xs={10} md={24} className={styles.centered}>
                <Text className={styles.textOpacity}>Tarifa media</Text>
              </Col>
              <Col
                xs={{ span: 10, offset: 4 }}
                md={{ span: 24, offset: 0 }}
                className={styles.centered}>
                {renderAverageRate()}
              </Col>
            </Row>
          </Col>

          {renderTotalSavingSection()}
        </Row>
      </Col>
    </Row>
  )
}

export default AverageRate
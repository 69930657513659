import React, { FC } from 'react'
import { Button, Checkbox, Col, Divider, message, Row } from 'antd'
import { navigate } from 'gatsby'
import moment from 'moment'

import {
  displayHelpRequestStatus,
  HelpRequestStatusEnum,
  RolesEnum,
} from '../../../helpers/enums'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { FilterColumnProps } from '../types'

import styles from './styles.module.css'

// TODO: Refactor components in conditionals.

const FilterColumn: FC<FilterColumnProps> = ({
  creditLine,
  dataDisplay,
  handleCheckbox,
  handleFilters,
  handleVisible,
  role,
  status,
}) => (
  <Col xs={24} sm={14} md={9} lg={7} xl={6} xxl={5}>
    {role.includes('SCC') ? (
      <Row className={styles.requestBox}>
        {dataDisplay && !creditLine ? (
          <Col span={24}>
            <p className={styles.stateTitle}>Estado de cuenta</p>
            <p className={styles.statusText}>
              {displayHelpRequestStatus(
                dataDisplay.status as HelpRequestStatusEnum,
              ).toLocaleLowerCase()}
            </p>
            <p className={styles.infoText}>
              Desde {moment(dataDisplay.createdAt).format('DD/MM/YYYY')}
            </p>
            <Divider className={styles.requestBoxDivider} />
            <p className={styles.infoText}>Cupo:</p>
            <p> -- </p>
          </Col>
        ) : (
          !creditLine && (
            <>
              <Col span={24}>
                <h5>¡Mejora tu experiencia!</h5>
                <p>Solicita una línea de crédito con nosotros.</p>
              </Col>
              <Col span={24}>
                <Button
                  className={styles.requestButton}
                  onClick={
                    role === RolesEnum.ADMIN_SCC || RolesEnum.SCC
                      ? () => handleVisible(true)
                      : message.info(
                          'Usted no posee los permisos requeridos para esta solicitud.',
                        )
                  }>
                  Solicitar
                </Button>
              </Col>
            </>
          )
        )}
      </Row>
    ) : !creditLine && dataDisplay ? (
      <Row className={styles.requestBox}>
        <Col span={24}>
          <p className={styles.stateTitle}>Estado de cuenta</p>
          <p className={styles.statusText}>
            {displayHelpRequestStatus(
              dataDisplay.status as HelpRequestStatusEnum,
            ).toLocaleLowerCase()}
          </p>
          <p className={styles.infoText}>
            Desde {moment(dataDisplay.createdAt).format('DD/MM/YYYY')}
          </p>
          <Divider className={styles.requestBoxDivider} />
          <p className={styles.infoText}>Cupo:</p>
          <p> -- </p>
        </Col>
      </Row>
    ) : (
      creditLine && (
        <Row className={styles.requestBox}>
          <Col span={24}>
            <p className={styles.stateTitle}>Estado de cuenta</p>
            <p className={styles.statusText}>Línea de crédito</p>
            <p className={styles.infoText}>
              Desde {moment(creditLine.createdAt).format('DD/MM/YYYY')}
            </p>
            <Divider className={styles.requestBoxDivider} />
          </Col>
          <Col span={24}>
            <Row align="middle" justify="space-between" gutter={25}>
              <Col span={12}>
                <p className={styles.infoText}>Cupo:</p>
                <p className={styles.amountText}>
                  ${numberWithSeparator(creditLine.balance)}
                </p>
              </Col>
              <Button
                className={styles.requestButton}
                onClick={() => {
                  navigate('/app/ayuda/crear-ticket')
                }}>
                Solicitar aumento
              </Button>
            </Row>
          </Col>
        </Row>
      )
    )}
    <Row className={styles.filterBox}>
      <Col xs={0} md={24}>
        <Row align="middle" justify="space-between">
          <span>Filtrar</span>
          <Button
            className={styles.resetFiltersButton}
            onClick={() => handleFilters(undefined, 'reset')}>
            Restaurar filtros
          </Button>
        </Row>
        <Divider style={{ marginTop: '0.5rem' }} />
        <Row align="middle" justify="start">
          <p>Estado</p>
          <Col span={24}>
            {Object.keys(HelpRequestStatusEnum).map((item, idx: number) => (
              <Row
                align="middle"
                className={styles.checkboxRow}
                justify="space-between"
                key={`${item}${idx}`}>
                <Checkbox
                  checked={status.includes(item as HelpRequestStatusEnum)}
                  onChange={e =>
                    handleCheckbox(
                      e.target.checked,
                      item as HelpRequestStatusEnum,
                    )
                  }>
                  {displayHelpRequestStatus(
                    item as HelpRequestStatusEnum,
                  ).toLocaleLowerCase()}
                </Checkbox>
              </Row>
            ))}
          </Col>
        </Row>
        <Divider />
        <Row align="middle" justify="center">
          <Button
            className={styles.applyFilterButton}
            onClick={() => handleFilters(undefined, 'filter')}>
            Aplicar
          </Button>
        </Row>
      </Col>
    </Row>
  </Col>
)

export default FilterColumn

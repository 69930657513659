import React, { FC } from 'react'
import { Col, Divider, Row } from 'antd'

import { numberWithSeparator } from '../../../../helpers/number.helper'
import { CurrentAccountResumeProps } from '../../../../types/page-types/pay-page.types'
import CostCenterSelect from '../CostCentersSelect'
import CurrentAccountSelect from '../CurrentAccountSelect'

import styles from './styles.module.css'

const CurrentAccountMobileSummary: FC<CurrentAccountResumeProps> = ({
  currentAccount,
  currentAccounts,
  handleCreditLine,
  status,
}) => (
  <Row align="middle" justify="center" className={styles.resumeBox}>
    <Col span={24}>
      <Row align="middle" justify="center">
        <CurrentAccountSelect
          currentAccounts={currentAccounts}
          handleCreditLine={handleCreditLine}
        />
        <CostCenterSelect
          costCenters={currentAccount?.costCenters}
          handleCreditLine={handleCreditLine}
        />
      </Row>
    </Col>
    {currentAccount && (
      <Col className={styles.detailContainer} span={22}>
        <Divider />
        <Row justify="space-between" align="middle">
          <Col xs={24} md={21}>
            <Row>
              <Col xs={24} md={24} className={styles.title}>
                Detalles de linea de credito
              </Col>
              <Col xs={0} md={24} className={styles.currentAccountName}>
                {currentAccount?.name}
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={3}>
            {status ? (
              status === 'ACTIVE' ? (
                <span className={styles.active}>Vigente</span>
              ) : (
                <span className={styles.inactive}>Inactivo</span>
              )
            ) : null}
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col className={styles.balanceResume} xs={24} md={11}>
            <Row justify="space-between" align="middle">
              <Col xs={8} md={24} className={styles.balanceResumeLabel}>
                Saldo
              </Col>
              <Col xs={26} md={24} className={styles.balanceResumeValue}>
                ${numberWithSeparator(currentAccount?.balance ?? 0)}
              </Col>
            </Row>
          </Col>
          <Col xs={0} md={2} className={styles.centered}>
            <Divider className={styles.verticalDivider} type="vertical" />
          </Col>
          <Col className={styles.balanceResume} xs={24} md={11}>
            <Row justify="space-between" align="middle">
              <Col xs={8} md={24} className={styles.balanceResumeLabel}>
                Monto Crédito
              </Col>
              <Col xs={26} md={24} className={styles.balanceResumeValue}>
                ${numberWithSeparator(currentAccount?.totalAmount ?? 0)}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    )}
  </Row>
)

export default CurrentAccountMobileSummary

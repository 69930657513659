import React, { FC } from 'react'
import { Tooltip } from 'antd'

import { PropTypes } from './types'

import styles from './styles.module.css'

const Bar: FC<PropTypes> = ({
  biggestNumber,
  barInterval,
  totalCompanyAmount,
  discountPercent,
  isHome,
  activePercentDiscount,
}) => {
  const renderLineDividers = () => {
    return (
      <div className={styles.lines}>
        {barInterval?.map((item, idx) => {
          const style =
            item.value === 0 || item.value === biggestNumber
              ? ''
              : item.value === totalCompanyAmount
              ? styles.sameAmountLine
              : item.value < (totalCompanyAmount || 0)
              ? styles.lineDividerBefore
              : styles.lineDividerAfter
          return (
            <div
              className={style}
              key={idx.toString()}
              style={{ left: `${item.percentage}%` }}
            />
          )
        })}
      </div>
    )
  }

  const renderFooter = () => {
    return (
      <div className={styles.bottomItems}>
        {barInterval?.map((item, idx) => {
          return (
            <div
              className={styles.bottomItem}
              key={idx.toString()}
              style={{ left: `${item.percentage - 1}%` }}>
              {item.label}
            </div>
          )
        })}
      </div>
    )
  }

  const renderPercents = () => {
    return (
      <div className={styles.topItems}>
        {barInterval?.map((item, idx) => {
          const itemStyle = isHome ? styles.homeTopItem : styles.topItem
          const labelMargin = item.percentageLabelMargin || item.percentage
          return (
            <div
              className={itemStyle}
              key={idx.toString()}
              style={{
                left: `${labelMargin <= 100 ? labelMargin : 99}%`,
              }}>
              {item.percentageLabel}
            </div>
          )
        })}
      </div>
    )
  }

  const renderLineColor = () => (
    <div
      className={styles.lineColor}
      style={{ width: `${discountPercent}%` }}
    />
  )

  const renderText = () =>
    `Hasta ahora has conseguido un ${activePercentDiscount}% de descuento!`

  return (
    <div className={styles.container}>
      <Tooltip title={renderText()}>
        <div className={styles.discountBar}>
          {renderPercents()}
          <div className={styles.line}>
            {renderLineColor()}
            {renderLineDividers()}
          </div>
          {renderFooter()}
        </div>
      </Tooltip>
    </div>
  )
}

export default Bar

import React, { FC, useContext, useState } from 'react'
import { useMutation } from '@apollo/client'
import { message, Modal } from 'antd'

import DownloadTemplateButton from '../../../components/PassengersDrawerUtils/downloadTemplateButton'
import GroupSelect from '../../../components/PassengersDrawerUtils/GroupSelect'
import PassengersList from '../../../components/PassengersDrawerUtils/PassengersList'
import StageButtons from '../../../components/PassengersDrawerUtils/stageButtons'
import UploadPassengersButton from '../../../components/PassengersDrawerUtils/uploadButtonPassenger'
import UploadSuccess from '../../../components/PassengersDrawerUtils/UploadSuccess'
import { RolesEnum } from '../../../helpers/enums'
import { CREATE_PASSENGERS } from '../../../queries'
import { SessionContext } from '../../../services/session'
import { Passenger, UploadPassengersProps } from '../../travel-page/types'
import { getUser } from '../../../services/auth';
import { User } from '../../../types/user.type'

const UploadPassengers: FC<UploadPassengersProps> = ({
  handleDrawerButton,
  refetch,
}) => {
  const { user } = useContext(SessionContext)
  const [stage, setStage] = useState(1)
  const [costCenterId, setcostCenterId] = useState('')
  const [passengers, setPassengers] = useState<Passenger[]>([])

  const [createPassengersMutation, { loading }] = useMutation(CREATE_PASSENGERS)

  const addPassengersToList = (passengers: Passenger[]) => {
    setPassengers(passengers)
    setStage(stage + 1)
  }

  const reset = () => {
    handleDrawerButton(0)
    setPassengers([])
    setStage(1)
  }


  const callCreatePassengerMutation = async (passengers: Passenger[]) => {
    const passengerstoSave = passengers
      .filter(passenger => passenger.saved)
      .map(p => {
        const costCenterIdMasivo = (p.costCenter)?._id
        if(user.role === 'SCC' || user.role === 'ADMIN_SCC'){
          p.costCenter = null
        }else{
          p.costCenter = costCenterIdMasivo
        }
        delete p.currentAccount
        const { company, saved, errorMessage, ...rest } = p
        return rest
      })

    try {
      await createPassengersMutation({
        variables: {
          input: passengerstoSave,
        },
      })
      message.success('Pasajeros agregados exitosamente.')
      setStage(stage + 1)
      setcostCenterId('')
      if (refetch) refetch()
    } catch (err) {
      message.error(err)
    }
  }

  const chargePassengers = async () => {

    if (passengers.some(passenger => !passenger.saved)){
      Modal.confirm({
        title: 'Hay registros con Error. ¿Deseas continuar?',
        content: 'Los registros con mensajes de error no serán guardados.',
        onOk: async () => {
          await callCreatePassengerMutation(passengers)
        },
        cancelText: 'Cancelar',
        okText: 'Continuar',
      })
  }else{
    await callCreatePassengerMutation(passengers)
  } 
  }

  const removePassenger = (passengertoRemove: Passenger) => {
    const list = passengers.filter(
      (passenger: Passenger) => passenger !== passengertoRemove,
    )
    setPassengers(list)
    if (list.length === 0) setStage(1)
  }

  return (
    <div style={{ width: '100%' }}>
      {/* {stage < 3 && user.role.includes('CCC') ? (
        <GroupSelect setSelectedCostCenter={setcostCenterId} />
      ) : null} */}
      {stage === 1 && (
        <>
          <UploadPassengersButton
            handleSelectedPassengers={addPassengersToList}
          />
          <DownloadTemplateButton />
        </>
      )}
      {stage === 2 && (
        <PassengersList
          passengers={passengers}
          removePassenger={removePassenger}
        />
      )}
      {stage === 3 && (
        <UploadSuccess
          passengersNumber={
            passengers.filter(passenger => passenger.saved).length
          }
        />
      )}
      <StageButtons
        loading={loading}
        stage={stage}
        saveAndAddPassengers={chargePassengers}
        onCancelClick={reset}
      />
    </div>
  )
}

export default UploadPassengers

import React, { FC, useContext, useEffect, useState } from 'react'
import {
  QuestionCircleOutlined,
  SearchOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  message,
  Row,
  Select,
  Tooltip,
} from 'antd'
import Checkbox from 'antd/lib/checkbox'

import LoadingSpinner from '../../../../../../components/LoadingSpinner'
import { checkAnonymousDrawer } from '../../../../../../helpers/my-travel-page'
import { fullRut } from '../../../../../../helpers/rutValidators'
import { passengerFullName } from '../../../../../../helpers/userNames'
import {
  MY_DETAIL_CURRENT_ACCOUNTS,
  NEW_PASSENGERS,
  PASSENGER_BY_COMPANY,
  UPDATE_TRAVEL_PASSENGERS,
} from '../../../../../../queries'
import { SessionContext } from '../../../../../../services/session'
import {
  CurrentAccount,
  GetCurrentAccountWithPassengers,
} from '../../../../../../types/currentAccount.type'
import {
  Passenger,
  PassengerGroupsFilters,
} from '../../../../../travel-page/types'
import { DetailGroupsProps } from '../../../../types'

import styles from './styles.module.css'

const { Panel } = Collapse
const { Option } = Select

// TODO: Refactor this component.

const DetailGroups: FC<DetailGroupsProps> = ({
  anonymousPassengers,
  bookingId,
  handleDrawerButton,
  handlePassengers,
  handleVisible,
  passengers,
}) => {
  const { user } = useContext(SessionContext)
  const [currentAccounts, setCurrentAccounts] = useState<CurrentAccount[]>([])
  const [limitSelected, setLimitSelected] = useState(false)
  const [selectedPassenger, setSelectedPassenger] = useState<Passenger[]>([])
  const [filters, setFilters] = useState<PassengerGroupsFilters>({})
  const [newPassengersVisible, setNewPassengersVisible] = useState(true)

  const [
    passengersByCompany,
    { data: sccData, loading: sccDataLoading },
  ] = useLazyQuery(PASSENGER_BY_COMPANY, {
    fetchPolicy: 'no-cache',
  })

  const [
    getNewPassengers,
    { data: newPassengersData, loading: newPassengersLoading },
  ] = useLazyQuery(NEW_PASSENGERS, {
    fetchPolicy: 'no-cache',
  })

  const [
    getCurrentAccountWithPassengers,
    { data, loading },
  ] = useLazyQuery<GetCurrentAccountWithPassengers>(
    MY_DETAIL_CURRENT_ACCOUNTS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => setCurrentAccounts(data?.myCurrentAccounts),
    },
  )

  const [
    updateMyTravelPassenger,
    { loading: updatePassengerLoading },
  ] = useMutation(UPDATE_TRAVEL_PASSENGERS)

  const handleClick = async () => {
    const {
      canCallQuery,
      newQuotas,
      passengersToSubmit,
      rule,
    } = checkAnonymousDrawer(passengers, selectedPassenger)

    if (rule)
      return message.info(
        'Alguna de sus selecciones ya se encuentran dentro del listado.',
      )
    if (canCallQuery.length > 0)
      return message.info(
        `Debe completar la selección de pasajeros anónimos, faltan ${canCallQuery.length}`,
      )
    if (canCallQuery.length < 1) {
      await updateMyTravelPassenger({
        variables: {
          input: {
            bookingId,
            passengers: passengersToSubmit,
          },
        },
      })
      handlePassengers(newQuotas)
      message.success('Pasajeros creados exitosamente.')
    }
    handleDrawerButton(0)
    handleVisible(false)
  }

  const selectPassenger = (checked: boolean, passenger: Passenger) => {
    checked
      ? setSelectedPassenger([passenger, ...selectedPassenger])
      : setSelectedPassenger(
          selectedPassenger.filter(({ _id }) => _id !== passenger._id),
        )
  }
  // TODO:  este codigo se repite en passenger groups
  const controlCheckbockLimit = (id: string) =>
    limitSelected ? !selectedPassenger.some(({ _id }) => _id === id) : false

  useEffect(() => {
    setLimitSelected(anonymousPassengers === selectedPassenger.length)
  }, [selectPassenger])

  useEffect(() => {
    getCurrentAccountWithPassengers()
    getNewPassengers()
    passengersByCompany()
  }, [getNewPassengers, passengersByCompany, getCurrentAccountWithPassengers])

  useEffect(() => {
    if (!filters?.rut && !filters?.fullName) {
      setNewPassengersVisible(true)
      getNewPassengers()
      getCurrentAccountWithPassengers()
      passengersByCompany()
    }
  }, [
    filters,
    getNewPassengers,
    passengersByCompany,
    getCurrentAccountWithPassengers,
  ])

  const handleSearch = () => {
    const variables = {
      filters,
    }
    getNewPassengers({ variables })
    passengersByCompany({ variables })
    getCurrentAccountWithPassengers({ variables })
    if (filters?.fullName || filters?.rut) setNewPassengersVisible(false)
  }

  const renderCurrentAccountSelect = () => {
    const onChange = (e: string) =>
      e && data
        ? setCurrentAccounts(
            data.myCurrentAccounts.filter(({ _id }) => _id === e),
          )
        : data && setCurrentAccounts(data.myCurrentAccounts)

    const mapOption = (_id: string, name: string) => (
      <Option key={_id} value={_id}>
        {name}
      </Option>
    )

    return user.role.includes('CCC') ? (
      <Select
        className={styles.cecoFilter}
        onChange={onChange}
        placeholder="Filtre centro de costo por cuenta corriente"
        optionFilterProp="children"
        showArrow={false}
        defaultValue={data?.myCurrentAccounts[0]._id}
        showSearch>
        {data?.myCurrentAccounts.map(({ _id, name }) => mapOption(_id, name))}
      </Select>
    ) : null
  }

  const renderSccPanel = (passengersByCompany: Passenger[]) => (
    <Collapse className={styles.drawerCollapse} key="by-company-collapse">
      <Panel header="Pasajeros" key="by-company">
        {passengersByCompany &&
          passengersByCompany.map(passenger => {
            const {
              _id: id,
              fatherLastName,
              firstName,
              motherLastName,
              rut,
              dv,
            } = passenger

            return (
              <Row className={styles.checkBoxRow} key={id}>
                <Col span={24}>
                  <Checkbox
                    disabled={
                      limitSelected
                        ? !selectedPassenger.some(({ _id }) => _id === id)
                        : false
                    }
                    onChange={e =>
                      selectPassenger(e.target.checked, passenger)
                    }>
                    {passengerFullName(
                      firstName,
                      fatherLastName,
                      motherLastName,
                    )}{' '}
                    <span className={styles.rutColor}>{fullRut(rut, dv)}</span>
                  </Checkbox>
                </Col>
              </Row>
            )
          })}
      </Panel>
    </Collapse>
  )

  const renderCostCenterPanel = (
    _id: string,
    name: string,
    passengers: Passenger[],
  ) =>
    passengers?.length ? (
      <Collapse className={styles.drawerCollapse} key={`collapse-${_id}`}>
        <Panel
          header={`${name} (${passengers?.length})`}
          key={_id}
          className={styles.paddingDiv}>
          {passengers.map(passenger => {
            const {
              _id: id,
              fatherLastName,
              firstName,
              motherLastName,
              rut,
              dv,
            } = passenger

            return (
              <Row className={styles.checkBoxRow} key={id}>
                <Col span={24}>
                  <Checkbox
                    checked={selectedPassenger.some(({ _id }) => _id === id)}
                    disabled={
                      limitSelected
                        ? !selectedPassenger.some(({ _id }) => _id === id)
                        : false
                    }
                    onChange={e =>
                      selectPassenger(e.target.checked, passenger)
                    }>
                    {passengerFullName(
                      firstName,
                      fatherLastName,
                      motherLastName,
                    )}{' '}
                    <span className={styles.rutColor}>{fullRut(rut, dv)}</span>
                  </Checkbox>
                </Col>
              </Row>
            )
          })}
        </Panel>
      </Collapse>
    ) : null

  const renderCccPanel = (currentAccounts: CurrentAccount[]) =>
    currentAccounts.map(({ costCenters }) =>
      costCenters.map(({ _id, name, passengers }) =>
        renderCostCenterPanel(_id, name, passengers),
      ),
    )

  const renderPassengerCollapse = () =>
    user.role.includes('SCC')
      ? renderSccPanel(sccData?.passengersByCompany)
      : renderCccPanel(currentAccounts)

  const renderSecondContainer = () => (
    <Col className={styles.secondaryContainer} span={24}>
      <Row align="middle" justify="space-between">
        <p className={styles.helpText}>
          ¿No encuentras un pasajero?. Agrégalo ahora
        </p>
        <Col xs={24} lg={11}>
          <Button
            block
            icon={<UserAddOutlined />}
            className={styles.multipleChargeButton}
            onClick={() => handleDrawerButton(1)}>
            Agregar pasajero
          </Button>
        </Col>
        <Col xs={0} lg={11}>
          <Button
            block
            icon={<UsergroupAddOutlined />}
            className={styles.multipleChargeButton}
            onClick={() => handleDrawerButton(2)}>
            Carga múltiple
          </Button>
        </Col>
      </Row>
    </Col>
  )

  const renderNewPassengersCollapse = (passengers: Passenger[]) => (
    <Collapse className={styles.drawerCollapse}>
      <Panel
        header={`Pasajeros recientes (${passengers?.length || 0})`}
        key="search panel">
        {passengers.map(passenger => {
          const {
            _id: id,
            fatherLastName,
            firstName,
            motherLastName,
            rut,
            dv,
          } = passenger

          return (
            <Row className={styles.checkBoxRow} key={id}>
              <Col span={24}>
                <Checkbox
                  checked={selectedPassenger.some(({ _id }) => _id === id)}
                  disabled={controlCheckbockLimit(id)}
                  onChange={e => selectPassenger(e.target.checked, passenger)}>
                  {passengerFullName(firstName, fatherLastName, motherLastName)}{' '}
                  <span className={styles.rutColor}>{fullRut(rut, dv)}</span>
                </Checkbox>
              </Col>
            </Row>
          )
        })}
      </Panel>
    </Collapse>
  )

  const renderPassengerSearch = () => (
    <Row justify="space-between" align="middle">
      <Col xs={12} sm={6}>
        Buscar pasajero{' '}
        <Tooltip title="Puedes buscar un pasajero por su nombre o RUT">
          <QuestionCircleOutlined className={styles.infoColorFont} />
        </Tooltip>
      </Col>

      <Col xs={12} sm={6}>
        <span className={styles.labelText}>Datos de pasajero</span>
      </Col>
      <Col xs={24} sm={12}>
        <Input
          className={styles.searchInput}
          placeholder="Buscar por rut"
          onChange={e => setFilters({ ...filters, rut: e.target.value })}
          value={filters.rut}
          addonAfter={<SearchOutlined onClick={handleSearch} />}
          onKeyUp={e => (e.key === 'Enter' ? handleSearch() : null)}
        />
        <Input
          className={styles.searchInput}
          placeholder="Buscar por nombre"
          onChange={e => setFilters({ ...filters, fullName: e.target.value })}
          value={filters.fullName}
          addonAfter={<SearchOutlined onClick={handleSearch} />}
          onKeyUp={e => (e.key === 'Enter' ? handleSearch() : null)}
        />
      </Col>
    </Row>
  )

  const renderInputsContainer = () => (
    <>
      {renderCurrentAccountSelect()}
      {renderPassengerSearch()}
    </>
  )

  const renderMainContainer = () => (
    <Col className={styles.mainContainer} span={24}>
      {renderInputsContainer()}
      {loading || sccDataLoading || newPassengersLoading ? (
        <LoadingSpinner size="50px" />
      ) : (
        <>
          {newPassengersVisible
            ? renderNewPassengersCollapse(
                newPassengersData?.getNewPassengers || [],
              )
            : null}
          {renderPassengerCollapse()}
        </>
      )}
    </Col>
  )

  const renderFooter = () => (
    <Col span={24}>
      <Row
        align="middle"
        className={styles.drawerButtonRow}
        justify="space-between">
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 11, order: 1 }}>
          <Button
            block
            className={styles.cancelButton}
            onClick={() => handleVisible(false)}>
            Cancelar
          </Button>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 11, order: 2 }}>
          <Button
            block
            className={
              selectedPassenger.length < 1
                ? styles.continueBlockedButton
                : styles.continueButton
            }
            loading={updatePassengerLoading}
            onClick={
              selectedPassenger.length < 1
                ? () =>
                    message.info(
                      'Para continuar debe haber seleccionado algún pasajero.',
                    )
                : () => handleClick()
            }>
            Agregar {selectedPassenger.length ?? ''} pasajeros
          </Button>
        </Col>
      </Row>
    </Col>
  )

  return (
    <>
      {renderMainContainer()}
      {renderSecondContainer()}
      <Divider />
      {renderFooter()}
    </>
  )
}

export default DetailGroups

import React, { useContext, useEffect, useState } from 'react'
import {
  AutoComplete,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Row,
  // Slider,
} from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment from 'moment'
import { isEmpty } from 'ramda'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { useAutocompleteAnt } from '../../../../helpers/autoCompleteList'
import { getDestinies, getOrigins } from '../../../../helpers/travel-page'
import { TravelPageContext } from '../../Provider'
import { QueryString, TravelFilterProps, TravelFilterType } from '../../types'

import styles from './styles.module.css'

const { Item } = Form

const TravelFilter: React.FC<TravelFilterProps> = () => {
  const today = moment(new Date(), 'DD-MM-YYYY')
  const {
    options,
    options: { origins, destinies, routes },
    hasReturn,
    search,
    setSearch,
    setStep,
    setOptions,
    setTransaction,
  } = useContext(TravelPageContext)

  const [initialValues, setinitialVlues] = useState({
    origin: search.origin,
    departureDate: moment(search.departureDate, 'DD-MM-YYYY'),
    destiny: search.destiny,
    returnDate: moment(search.returnDate, 'DD-MM-YYYY'),
    numberOfSeats: parseInt(search.numberOfSeats),
  })

  const {
    optionsList: filteredOriginList,
    filterAutocomplete: originFilter,
  } = useAutocompleteAnt(origins)

  const {
    updateDestinies,
    optionsList: filteredDestinyList,
    filterAutocomplete: DestinyFilter,
  } = useAutocompleteAnt(destinies)

  useEffect(() => {
    updateDestinies(destinies)
  }, [destinies])

  const onFinish = () => {
    if (
      isEmpty(initialValues.origin) ||
      isEmpty(initialValues.destiny) ||
      initialValues.numberOfSeats <= 0
    ) {
      message.warning({
        content:
          'Debes elegir un origen y un destino dependiendo de que tipo de viaje quieres. También asegurate que el número de pasajeros sea mayor a 0',
        style: { fontSize: 18, marginTop: 10 },
        duration: 7,
      })
      return
    }

    setStep(0)
    setTransaction({})
    setSearch({
      ...search,
      ...((initialValues as unknown) as QueryString),
      departureDate: initialValues.departureDate.format('DD-MM-YYYY'),
      returnDate: hasReturn
        ? initialValues.returnDate.format('DD-MM-YYYY')
        : null,
      departureCity:
        origins.find(el => el.name === initialValues.origin)?.id || '',
      destinationCity:
        destinies.find(el => el.name === initialValues.destiny)?.id || '',
    })
  }

  const onFinishFailed = (error: ValidateErrorEntity<TravelFilterType>) => {
    console.log(error)
  }

  const onResetButton = () => {
    setinitialVlues({
      origin: '',
      departureDate: moment(),
      destiny: '',
      returnDate: moment(),
      numberOfSeats: 1,
    })
  }

  return (
    <Row className={styles.containerPassageSearch}>
      <Col span={24}>
        <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row
            justify="space-between"
            className={`${styles.filterContainer} ${styles.paddingTop}`}>
            <Col className={styles.titleFont}>Filtrar</Col>
            <Col>
              <Button
                className={styles.resetFont}
                type="text"
                onClick={onResetButton}>
                Restaurar Filtros
              </Button>
            </Col>
          </Row>
          <Divider className={styles.divider} />
          <Row className={styles.filterContainer}>
            <Col span={24}>
              <p className={styles.labelFont}>
                {hasReturn
                  ? 'Origen y fecha de ida'
                  : 'Origen, fecha de ida y destino'}
              </p>
              <Item>
                <AutoComplete
                  className={styles.fullWidth}
                  value={initialValues.origin}
                  options={filteredOriginList}
                  onSearch={originFilter}
                  onChange={origin => {
                    setinitialVlues({ ...initialValues, origin })
                    setOptions({
                      ...options,
                      destinies: getDestinies(routes, origin),
                    })
                  }}></AutoComplete>
              </Item>
              <Item>
                <DatePicker
                  locale={locale}
                  value={initialValues.departureDate}
                  className={`${styles.fullWidth} ${styles.marginTop}`}
                  format="DD-MM-YYYY"
                  inputReadOnly
                  clearIcon={false}
                  disabledDate={current =>
                    current < moment.utc().startOf('day')
                  }
                  onChange={departureDate =>
                    setinitialVlues({
                      ...initialValues,
                      departureDate: moment(
                        moment(departureDate, 'DD-MM-YYYY'),
                      ),
                    })
                  }
                />
              </Item>
              {!hasReturn && (
                <Item>
                  <AutoComplete
                    className={styles.fullWidth}
                    value={initialValues.destiny}
                    options={filteredDestinyList}
                    onSearch={DestinyFilter}
                    onChange={destiny =>
                      setinitialVlues({ ...initialValues, destiny })
                    }></AutoComplete>
                </Item>
              )}
            </Col>
          </Row>
          <Divider className={styles.divider} />
          {hasReturn ? (
            <>
              <Row className={styles.filterContainer}>
                <Col span={24}>
                  <p className={styles.labelFont}>Destino y fecha de vuelta</p>
                  <Item>
                    <AutoComplete
                      className={styles.fullWidth}
                      value={initialValues.destiny}
                      options={filteredDestinyList}
                      onSearch={DestinyFilter}
                      onChange={destiny =>
                        setinitialVlues({ ...initialValues, destiny })
                      }></AutoComplete>
                  </Item>
                  <Item>
                    <DatePicker
                      className={`${styles.fullWidth} ${styles.marginTop}`}
                      value={initialValues.returnDate}
                      locale={locale}
                      format="DD-MM-YYYY"
                      inputReadOnly
                      clearIcon={false}
                      disabledDate={current =>
                        current < moment.utc().startOf('day') ||
                        moment
                          .utc(current)
                          .isBefore(initialValues.departureDate)
                      }
                      onChange={returnDate =>
                        setinitialVlues({
                          ...initialValues,
                          returnDate: moment(moment(returnDate, 'DD-MM-YYYY')),
                        })
                      }
                    />
                  </Item>
                </Col>
              </Row>
              <Divider className={styles.divider} />
            </>
          ) : null}
          <Row className={styles.filterContainer}>
            <Col span={24}>
              <p className={styles.labelFont}>Cantidad de pasajeros</p>
              <Item>
                <Input
                  className={styles.fullWidth}
                  type="number"
                  value={initialValues.numberOfSeats || 1}
                  onChange={quantity => {
                    setinitialVlues({
                      ...initialValues,
                      numberOfSeats: +quantity.target.value,
                    })
                  }}
                />
              </Item>
            </Col>
          </Row>
          <Divider className={styles.divider} />
          <Row className={styles.filterContainer}>
            <Col span={24} className={styles.buttonContainer}>
              <Button className={styles.button} htmlType="submit">
                Aplicar
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default TravelFilter

import React from 'react'
import { Col, Row } from 'antd'
import moment from 'moment'

import { getUser, tokenInfo } from '../../../services/auth'
import { Message } from '../../../types/HelpRequest.type'

import styles from './styles.module.css'

const ChatBubble: React.FC<{ chatMessage: Message }> = ({ chatMessage }) => {
  const { user: loggedUser } = tokenInfo(getUser() ?? '')

  const { user, message, createdAt } = chatMessage

  const isOwnMessage = user._id === loggedUser.sub
  return (
    <Row justify={isOwnMessage ? 'end' : 'start'}>
      <Col
        span={22}
        className={isOwnMessage ? styles.ownBubble : styles.bubble}>
        <Row justify={'space-between'}>
          <Col md={12} className={styles.userName}>
            {user.name}
          </Col>
          <Col md={12} className={styles.alignRight}>
            {moment(createdAt).locale('es').format('LLLL')}
          </Col>
        </Row>
        <Row>
          <Col md={24}>{message}</Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ChatBubble

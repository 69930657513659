import React, { FC, useContext } from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Col, Row, Timeline } from 'antd'

import { lowerCaseStringButFirstLetter } from '../../helpers/lowercaseStringButFirst'
import { TravelPageContext } from '../../screens/travel-page/Provider'
import { TravelStopDetailsProps } from '../types'

import SectionDisplay from './SectionDisplay'
import StartOrFinish from './StartOrFinish'

import styles from './styles.module.css'

const TravelStopsDetails: FC<TravelStopDetailsProps> = ({
  sections,
  departure_terminal_name,
  destination_terminal_name,
  arrives_at,
  departs_at,
  originCity,
  destinyCity,
}) => {
  const { hasReturn, step } = useContext(TravelPageContext)

  const genTravelType = () => {
    if (step === 0) return 'Ida'
    if (step === 1 && hasReturn) return 'Vuelta'
  }

  const genTypeArrow = () => {
    if (step === 0) return <ArrowRightOutlined className={styles.arrowColor} />
    if (step === 1 && hasReturn)
      return <ArrowLeftOutlined className={styles.arrowColor} />
  }

  const renderTravelType = () => (
    <div>
      <p className={styles.cardDarkFont}>
        {genTravelType()} {genTypeArrow()}
      </p>
    </div>
  )

  return (
    <Col>
      <Row justify="space-between" align="top" className={styles.rowSpacing}>
        <Col>{renderTravelType()}</Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle">
            <Col>
              <Timeline>
                <Timeline.Item key="start">
                  <StartOrFinish
                    date={departs_at}
                    city={originCity}
                    terminal={departure_terminal_name}
                  />
                </Timeline.Item>
                <SectionDisplay sections={sections} />
                <Timeline.Item key="end">
                  <StartOrFinish
                    date={arrives_at}
                    city={destinyCity}
                    terminal={destination_terminal_name}
                  />
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

export default TravelStopsDetails

import React, { FC, useContext, useLayoutEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Col } from 'antd'

import { DELETE_RESERVATION } from '../../queries'

import TravelFilter from './Common/filter'
import FilterDrawer from './Common/FilterDrawer'
import QuestionModal from './Common/modal'
import { renderStep } from './constant'
import { TravelPageContext } from './Provider'
import { Travel, TravelFilterType } from './types'

import styles from './styles.module.css'

const TravelPageContent: FC = () => {
  const {
    hasReturn,
    search,
    setStep,
    setTransaction,
    setVisible,
    passengersView,
    step,
    transaction,
  } = useContext(TravelPageContext)

  const [deleteReservation] = useMutation(DELETE_RESERVATION)
  const [width, setWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const onSelectGone = async (travel: Travel): Promise<void> => {
    const transactionTmp = { ...transaction }
    const { goneReservation, travelGone } = transactionTmp

    if (goneReservation && travelGone)
      await deleteReservation({
        variables: {
          input: {
            reservation: goneReservation,
            token: travelGone.integration_code,
          },
        },
      })

    transactionTmp.travelGone = travel
    setTransaction(transactionTmp)

    if (hasReturn) setStep(step + 1)
    else setVisible(true)
  }

  const onSelectReturn = async (travel: Travel): Promise<void> => {
    const transactionTmp = { ...transaction }
    const { returnReservation, travelReturn } = transactionTmp
    if (returnReservation && travelReturn)
      await deleteReservation({
        variables: {
          input: {
            reservation: returnReservation,
            token: travelReturn.integration_code,
          },
        },
      })
    transactionTmp.travelReturn = travel
    setTransaction(transactionTmp)
    setVisible(true)
  }

  const onApplyFilter = (filter: TravelFilterType) => {
    console.log('APLICAR FILTRO', filter)
  }

  const showFilter = step < (hasReturn ? 2 : 1)

  return (
    <>
      {!passengersView && showFilter ? (
        <Col xs={0} sm={0} md={0} xl={5}>
          <QuestionModal />
          <TravelFilter onApplyFilter={onApplyFilter} setVisible={null} />
        </Col>
      ) : null}

      <Col
        xs={24}
        sm={24}
        md={24}
        xl={passengersView || !showFilter ? 24 : 19}
        className={
          passengersView ? styles.background : styles.resultBackground
        }>
        {renderStep({
          onSelectGone,
          onSelectReturn,
          search,
          passengersView,
        })}
        {!passengersView && showFilter && width < 1200 ? (
          <FilterDrawer onApplyFilter={onApplyFilter} />
        ) : null}
      </Col>
    </>
  )
}
export default TravelPageContent

import React, { FC, useEffect, useState } from 'react'
import { Col } from 'antd'

import SEO from '../../components/seo'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../services/auth'

import MyTravelsContent from './myTravelsContent'
import MyTravelsHeader from './myTravelsHeader'
import MyTravelsLayout from './myTraveslLayout'

const MyTravels: FC = () => {
  const [activeDevolution, setActiveDevolution] = useState(false)

  const activateDevolution = (isActive: boolean) =>
    setActiveDevolution(isActive)

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')

    useEventSave('vista_mis_viajes', {
      email_usuarios: user.email,
    })
  }, [])

  return (
    <>
      <SEO title="Mis Viajes" />
      <MyTravelsLayout>
        <Col span={24}>
          <MyTravelsHeader setActiveDevolution={activateDevolution} />
          <MyTravelsContent
            activeDevolution={activeDevolution}
            setActiveDevolution={activateDevolution}
          />
        </Col>
      </MyTravelsLayout>
    </>
  )
}

export default MyTravels

import React, { FC } from 'react'
import { Col, Divider, Row } from 'antd'

import { numberWithSeparator } from '../../../../helpers/number.helper'
import { CurrentAccountResumeProps } from '../../../../types/page-types/pay-page.types'
import CostCenterSelect from '../CostCentersSelect'
import CurrentAccountSelect from '../CurrentAccountSelect'

import styles from './styles.module.css'

const CurrentAccountResume: FC<CurrentAccountResumeProps> = ({
  currentAccount,
  currentAccounts,
  handleCreditLine,
  status,
}) => (
  <Row align="middle" justify="center" className={styles.resumeBox}>
    <Col span={24}>
      <Row align="middle" justify="center" gutter={25}>
        <CurrentAccountSelect
          currentAccounts={currentAccounts}
          handleCreditLine={handleCreditLine}
        />
        <CostCenterSelect
          costCenters={currentAccount?.costCenters}
          handleCreditLine={handleCreditLine}
        />
      </Row>
    </Col>
    {currentAccount && (
      <Col className={styles.detailContainer} span={22}>
        <Row className={styles.detailFirstRow}>
          <div>
            <p>Detalles de linea de credito</p>
            {status ? (
              status === 'ACTIVE' ? (
                <p className={styles.active}>Vigente</p>
              ) : (
                <p className={styles.inactive}>Inactivo</p>
              )
            ) : null}
          </div>
          <p>{currentAccount?.name}</p>
        </Row>
        <Divider />
        <Row>
          <Col className={styles.balanceResume} span={11}>
            <p>Saldo </p>
            <p>${numberWithSeparator(currentAccount?.balance ?? 0)}</p>
          </Col>
          <Divider className={styles.verticalDivider} type="vertical" />
          <Col className={styles.balanceResume} span={11}>
            <p>Monto Credito</p>
            <p>${numberWithSeparator(currentAccount?.totalAmount ?? 0)}</p>
          </Col>
        </Row>
      </Col>
    )}
  </Row>
)

export default CurrentAccountResume

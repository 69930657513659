import React, { FC } from 'react'
import { Col, message, Row, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'

import UploadIcon from './uploadicon.inline.svg'
import { OnChangeInfo } from '../../../types/component-types/FileUploadForm.types'

import {UploadCeCosButtonProps } from './props'

import styles from './styles.module.css'

const UploadButton: FC<UploadCeCosButtonProps> = ({
    handleCeCos,
    userCompany
}) => {
  const allowedFiles = ['.xlsx']
  const documents: any[] = []
  const uploadProps = {
    name: 'file',
    accept: allowedFiles.join(','),
    multiple: false,
    action: `${process.env.GATSBY_API_UPLOAD_CECOS_TEMPLATE_URI}`,
    headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`,
        user: JSON.stringify(userCompany),
      },
      beforeUpload(file: UploadFile, fileList: UploadFile[]) {
        const fileIsValid = allowedFiles.includes(
          `.${file.name.split('.').pop() as string}`.toLocaleLowerCase(),
        )
  
        if (!fileIsValid) {
          message.error(
            'Este archivo no tiene ninguno de los formatos permitidos',
          )
          fileList.pop()
        }
    
        return fileIsValid
      },
    async onChange(info: OnChangeInfo) {
        const { file } = info
        if (file.status === 'done') {
          message.success(`${file.name} se cargó con éxito`)
          const { costCenters } = file.response
          handleCeCos(costCenters)
          documents.push({
            uid: file.uid,
            size: file.size,
          })
        } else if (file.status === 'error') {
          const errorMessage =
            file.response?.message ?? `${file.name} tuvo un problema al cargar`
          message.error(errorMessage)
        }
      },
  }


  return (
    <Row align="middle" justify="center" style={{ height: '65.5%' }}>
      <Col span={24}>
        <Row align="middle" justify="center">
          <Upload
            {...uploadProps}
            className={styles.uploadAlign}
            style={{ color: '#6477ED' }}>
            <UploadIcon
              className={styles.uploadIcon}
              style={{ color: '#6477ED' }}
            />
          </Upload>
        </Row>
        <Row align="middle" justify="center">
          <p className={styles.uploadDiscountsFont}>
          Presiona acá para cargar tus Centros de Costos
          </p>
        </Row>
        <Row align="middle" justify="center">
          <p className={styles.uploadDiscountsDescriptionFont}>
          Puedes subir un archivo .xlsx para agregar varios cecos a la
              vez.
          </p>
        </Row>
      </Col>
    </Row>
  )
}

export default UploadButton

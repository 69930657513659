import React, { FC, useContext } from 'react'
import { Button, Col, Divider, Form, Input, Row } from 'antd'

import PhonePrefix from '../../../components/phonePrefix'
import { getDisplayDate } from '../../../helpers/dateLongFormat'
import { MyUserPageContext } from '../Provider'

import styles from './styles.module.css'

const { Item } = Form

const InfoRow: FC = () => {
  const { selectedUser, setDeleteVisible } = useContext(MyUserPageContext)
  const [areaCode, phone] = selectedUser.phone.split(' ')
  const format = 'YYYY-MM-DD  '
  const response = 'DD [de] MMMM YYYY'

  return (
    <Row className={styles.userForm}>
      <Col
        xs={0}
        sm={18}
        className={styles.deleteCol}
        style={{ width: '100%' }}>
        <Row align="middle" justify="space-between">
          <Col>
            <p className={styles.company}>{selectedUser.company?.name}</p>
            <p className={styles.record}>
              Registrado:{' '}
              {getDisplayDate(
                selectedUser.createdAt.toString(),
                format,
                response,
              )}
            </p>
          </Col>
          <Col>
            <Button onClick={() => setDeleteVisible(true)}>Eliminar</Button>
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={18}>
        <p className={styles.initialsDrawerContainer}>
          {selectedUser.firstName[0]}
          {selectedUser.lastName[0]}
        </p>
      </Col>
      <Col xs={24} sm={18}>
        <p className={styles.labelText}>Nombre</p>
        <Item
          initialValue={`${selectedUser.firstName}`}
          className={styles.itemRow}
          name="firstName"
          rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
          <Input />
        </Item>
      </Col>
      <Col xs={24} sm={18}>
        <p className={styles.labelText}>Apellido</p>
        <Item
          initialValue={`${selectedUser.lastName}`}
          className={styles.itemRow}
          name="lastName"
          rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
          <Input />
        </Item>
      </Col>
      <Col xs={24} sm={18}>
        <p className={styles.labelText}>Rut</p>
        <Item
          className={styles.itemRow}
          initialValue={`${selectedUser.rut}-${selectedUser.dv}`}
          name="rut">
          <Input disabled />
        </Item>
      </Col>
      <Col xs={24} sm={18}>
        <p className={styles.labelText}>Teléfono</p>
        <Item
          className={styles.itemRow}
          initialValue={phone}
          name="phone"
          normalize={value => value.replace(/\D/, '')}
          rules={[
            { required: true, message: 'Este campo es obligatorio' },
            {
              max: 9,
              message: 'no se permiten mas de 9 caracteres',
            },
            {
              pattern: /^[0-9]*$/,
              message: 'debe ingresar solo números',
            },
          ]}>
          <Input
            addonBefore={<PhonePrefix areaCode={areaCode} />}
            className={styles.phoneInput}
          />
        </Item>
      </Col>
      <Col xs={24} sm={18}>
        <p className={styles.labelText}>Correo electrónico</p>
        <Item
          className={styles.itemRow}
          initialValue={selectedUser.email}
          name="email">
          <Input />
        </Item>
      </Col>
      <Divider style={{ margin: '1rem, 0' }} />
    </Row>
  )
}

export default InfoRow

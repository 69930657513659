import React, { FC } from 'react'
import { Button } from 'antd'

import { numberWithSeparator } from '../../../helpers/number.helper'

import { PropTypes } from './types'

import styles from './styles.module.css'

const Header: FC<PropTypes> = ({
  totalCompanyAmount,
  startPeriod,
  endPeriod,
  isHome,
}) => {
  const renderHeaderHome = () => {
    return (
      <div className={styles.homeHeader}>
        <div className={styles.homeAmountDetail}>
          <div className={styles.homeLabel}>Descuentos Monto acumulado:</div>
          <div className={styles.homeAmount}>
            ${numberWithSeparator(totalCompanyAmount || 0)}
          </div>
        </div>
      </div>
    )
  }

  const renderNormalHeader = () => {
    return (
      <div className={styles.normalHeader}>
        <div className={styles.amountDetail}>
          Monto acumulado:{' '}
          <div className={styles.amount}>
            ${numberWithSeparator(totalCompanyAmount || 0)}
          </div>
        </div>
        <div className={styles.period}>
          Desde {startPeriod?.format('LL')} hasta {endPeriod?.format('LL')}
        </div>
      </div>
    )
  }

  if (isHome)
    return <div className={styles.container}>{renderHeaderHome()}</div>
  return <div className={styles.container}>{renderNormalHeader()}</div>
}

export default Header

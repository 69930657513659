import React, { FC, useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Card, Col, Collapse, Divider, Modal, Row } from 'antd'

import DiscountBar from '../../../components/DiscountBar'
import MobileTravelInfoBox from '../../../components/MobileTravelInfoBox'
import TravelInfoBox from '../../../components/TravelInfoBox'
import { numberWithSeparator } from '../../../helpers/number.helper'
import {
  getTravelInfo,
  getTravelResume,
  PriceItem,
  TravelPriceInfo,
} from '../../../helpers/travel-page/dates.helper'
import { HAS_PERCENT_DISCOUNT } from '../../../queries'
import { TravelPageContext } from '../Provider'

import useIsMobile from './helpers/useIsMobile'
import BriefPicture from './brief.inline.svg'

import styles from './styles.module.css'

const { Panel } = Collapse

const AnonymousTraveler: FC = () => {
  const {
    hasReturn,
    transaction,
    quotas,
    search,
    percentDiscountValue,
    setPassengersView,
    setStep,
  } = useContext(TravelPageContext)
  const {
    departureTripDuration,
    formattedDepartureDate,
    formattedReturnDate,
    returnTripDuration,
  } = getTravelInfo(transaction)

  const isMobile = useIsMobile()
  const [resume, setResume] = useState<TravelPriceInfo>()
  const { loading: discountLoading, data: userInfo } =
    useQuery(HAS_PERCENT_DISCOUNT)
  const [totalDiscount, setTotalDiscount] = useState<number>(0)

  const [allPassengerAssigned, setAllPassengerAssigned] = useState(false)
  console.log(userInfo)

  useEffect(() => {
    const data = getTravelResume(
      transaction.goneSeats ?? [],
      transaction.returnSeats ?? [],
    )
    setResume(data)
    setTotalDiscount(
      Math.round(data.totalToPay * ((percentDiscountValue ?? 0) / 100)),
    )
  }, [])

  useEffect(() => {
    setAllPassengerAssigned(
      !quotas.some(
        quota => !quota.passenger || typeof quota.passenger === 'string',
      ),
    )
  }, [quotas])

  const payTravelOnClickHandler = () => {
    if (!allPassengerAssigned)
      Modal.confirm({
        title: '¿Continuar sin asignar pasajeros?',
        content:
          'Puedes completar la compra de tu viaje sin asignar pasajeros. Sin embargo, los boletos serán anónimos y los asientos se asignarán de manera aleatoria.',
        onCancel: () => setStep(hasReturn ? 3 : 2),
        cancelText: `Continuar ${!isMobile ? 'sin asignar' : ''}`,
        okText: 'Asignar pasajeros',
        bodyStyle: {
          padding: '0px !important',
        },
        style: {
          padding: '0px !important',
        },
        okButtonProps: {
          size: 'small',
          style: {
            padding: '0px !important',
            margin: '0px !important',
          },
        },
        cancelButtonProps: {
          size: 'small',
          style: {
            padding: '0px !important',
            margin: '0px !important',
          },
        },
        onOk: () => setPassengersView(true),
      })
    else setStep(hasReturn ? 3 : 2)
  }

  const renderPrices = (prices: PriceItem[], isReturn?: boolean) =>
    prices.map(({ quantity, priceFormatted }, index) => (
      <Row justify="space-between" key={index}>
        <Col className={styles.subTotalFont}>
          {quantity} x Boletos de {isReturn ? 'regreso' : 'ida'}
        </Col>
        <Col className={styles.amountFont}>${priceFormatted}</Col>
      </Row>
    ))

  const discount = (block?: boolean) => {
    if (!userInfo?.hasPercentDiscount) return <span />
    return (
      <div className={styles.discountContainer}>
        <DiscountBar />
      </div>
    )
  }

  const travelSummary = (block?: boolean) => (
    <Collapse
      bordered={false}
      defaultActiveKey={['2']}
      className={styles.cardContainer}>
      <Panel
        header="Resumen del viaje"
        key="2"
        style={{ borderBottom: 'none' }}>
        <Divider className={styles.panelDivider} />
        <div className={styles.titleCardContainer}>
          <p>Cantidad de pasajeros</p>
          <p>
            {search.numberOfSeats} pasajero
            {search.numberOfSeats === '1' ? '' : 's'}
          </p>
        </div>
        {renderPrices(resume?.gonePrices ?? [])}
        {renderPrices(resume?.returnPrices ?? [], true)}

        {percentDiscountValue && resume?.totalToPay ? (
          <Row justify="space-between">
            <Col className={styles.subTotalFont}>Descuento aplicado</Col>
            <Col className={styles.amountDiscountFont}>
              {percentDiscountValue}%
            </Col>
          </Row>
        ) : null}
        {percentDiscountValue && resume?.totalToPay ? (
          <Row justify="space-between">
            <Col className={styles.subTotalFont}>
              Descuento línea de crédito
            </Col>
            <Col className={styles.amountDiscountFont}>
              -$ {numberWithSeparator(totalDiscount)}
            </Col>
          </Row>
        ) : null}

        <Divider className={styles.divider} />
        <Row justify="space-between">
          <Col className={styles.subTotalFont}>Subtotal</Col>
          <Col className={styles.totalAmountFont}>
            $
            {totalDiscount && resume?.totalToPay
              ? numberWithSeparator(resume?.totalToPay - totalDiscount)
              : resume?.total}
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Button
            className={styles.paymentButton}
            onClick={payTravelOnClickHandler}
            block={block}
            size="large">
            Pagar viaje
          </Button>
        </Row>
      </Panel>
    </Collapse>
  )

  return (
    <Col className={styles.container}>
      <Row justify="center" gutter={8}>
        <Col xs={24} md={10} lg={6}>
          <Row>
            <Col span={24} className={styles.paddingTop1}>
              <Collapse bordered={false} className={styles.cardContainer}>
                <Panel
                  header="Detalles del viaje"
                  key="1"
                  style={{ borderBottom: 'none' }}>
                  <Divider className={styles.panelDivider} />
                  <Row>
                    <Col xs={0} md={24}>
                      <TravelInfoBox
                        departureTripDuration={departureTripDuration}
                        formattedDepartureDate={formattedDepartureDate}
                        transaction={transaction}
                        origin={search.origin}
                        destiny={search.destiny}
                        type="gone"
                        sections={transaction?.travelGone?.sections}
                      />
                    </Col>
                    <Col xs={24} md={0}>
                      <MobileTravelInfoBox
                        departureTripDuration={departureTripDuration}
                        transaction={transaction}
                        type="gone"
                      />
                    </Col>
                  </Row>

                  <Divider className={styles.divider} />

                  {hasReturn ? (
                    <Row>
                      <Col xs={0} md={24}>
                        <TravelInfoBox
                          formattedReturnDate={formattedReturnDate}
                          returnTripDuration={returnTripDuration}
                          transaction={transaction}
                          type="return"
                          origin={search.origin}
                          destiny={search.destiny}
                          sections={transaction?.travelReturn?.sections}
                        />
                      </Col>
                      <Col xs={24} sm={0}>
                        <MobileTravelInfoBox
                          returnTripDuration={returnTripDuration}
                          transaction={transaction}
                          type="return"
                        />
                      </Col>
                    </Row>
                  ) : null}
                </Panel>
              </Collapse>
            </Col>

            <Col span={24} className={styles.paddingTop1}>
              {discount()}
            </Col>

            <Col xs={0} md={24} className={styles.paddingTop1}>
              {travelSummary()}
            </Col>
          </Row>
        </Col>
        <Col xs={24} md={14} lg={8} className={styles.paddingTop1}>
          <Card className={styles.rightCardContainer}>
            <Row>
              <Col xs={0} md={24}>
                <BriefPicture />
              </Col>
            </Row>
            <p className={styles.titleRightContainer}>
              Asignación de pasajeros y asientos
            </p>
            <p>
              Asegúrate de que cada pasajero goce de una experiencia cómoda y
              personalizada
            </p>
            <Button
              className={styles.assigmentButton}
              onClick={() => setPassengersView(true)}>
              {allPassengerAssigned ? 'Modificar ' : 'Asignar'} Pasajeros
            </Button>
          </Card>
        </Col>
        <Col xs={24} md={0} className={styles.paddingTop1}>
          {travelSummary(true)}
        </Col>
      </Row>
    </Col>
  )
}

export default AnonymousTraveler

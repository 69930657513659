import React from 'react'
import { Col, Divider, List, Row, Typography } from 'antd'

import { typeSeat } from '../../travel-page/Common/travelDisplay/typeSeat'
import { FrequentSeatProps } from '../types/props'

import styles from './styles.module.css'

const { Text } = Typography

const FrequentSeat: React.FC<FrequentSeatProps> = ({
  frequentSeat,
  className,
}) => {
  const data: Record<string, string | number>[] = []

  for (const iterator of frequentSeat)
    if (
      data
        .map(el => String(el.type))
        .includes(String(typeSeat[iterator.type?.toLowerCase()]))
    ) {
      const index = data.findIndex(
        e => e.type === typeSeat[iterator.type?.toLowerCase()],
      )
      data[index] = {
        ...data[index],
        soldCounter: +data[index].soldCounter + iterator.soldCounter,
      }
    } else
      data.push({ ...iterator, type: typeSeat[iterator.type?.toLowerCase()] })

  return (
    <div className={className ? className : styles.containerList}>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, idx: number) => (
          <Row align="middle" justify="space-between">
            <Col span={14}>
              <Text className={styles.textIndex}>{`${idx + 1}`}</Text>
              <Text className={styles.textOpacity}>{`${item.type}`}</Text>
            </Col>
            <Col span={6}>
              <Text className={styles.textOpacity}>
                {`${item.soldCounter} vendidos`}
              </Text>
            </Col>
            <Divider className={styles.divider} />
          </Row>
        )}
      />
    </div>
  )
}

export default FrequentSeat

import React, { FC, useContext, useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Card, Col, Row } from 'antd'

import { numberWithSeparator } from '../../../../helpers/number.helper'
import { TravelPageContext } from '../../Provider'
import { PriceLegendProps } from '../../types'

import styles from './styles.module.css'

// TODO: refactor component conditionals.

const PriceLegend: FC<PriceLegendProps> = ({ isGone }) => {
  const { transaction } = useContext(TravelPageContext)

  const { gonePrices, returnPrices } = transaction

  const [min, setMin] = useState<string>('0')
  const [max, setMax] = useState<string>('0')

  useEffect(() => {
    setMin(
      isGone
        ? numberWithSeparator(Math.min(...(gonePrices ?? [0])))
        : numberWithSeparator(Math.min(...(returnPrices ?? [0]))),
    )
    setMax(
      isGone
        ? numberWithSeparator(Math.max(...(gonePrices ?? [0])))
        : numberWithSeparator(Math.max(...(returnPrices ?? [0]))),
    )
  }, [isGone, gonePrices, returnPrices])

  return (
    <Card className={styles.card}>
      <Row>
        <Col xs={0} md={0} lg={24}>
          <p className={styles.textHeader}>Tipos de asientos</p>
        </Col>
      </Row>

      {gonePrices === undefined && returnPrices === undefined ? (
        <LoadingOutlined spin />
      ) : min === max ? (
        <Row>
          <Col xs={12} md={12} lg={24}>
            <Row align="middle">
              <Col>
                <div className={styles.cheapSeat} />
              </Col>
              <Col offset={1}>
                <span className={styles.font}>${min}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Col>
            <Row align="middle">
              <Col>
                <div className={styles.cheapSeat} />
              </Col>
              <Col offset={1}>
                <span className={styles.font}>${min}</span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row align="middle">
              <Col>
                <div className={styles.expensiveSeat} />
              </Col>
              <Col>
                <p className={styles.font}>${max}</p>
              </Col>
            </Row>
          </Col>
        </>
      )}

      <Row>
        <Col xs={12} md={24}>
          <Row align="middle">
            <Col>
              <div className={styles.availableSeat} />
            </Col>
            <Col offset={1}>
              <span className={styles.font}>Seleccionado</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={24}>
          <Row align="middle">
            <Col>
              <div className={styles.notAvailableSeat} >
                <div></div>
              </div>
            </Col>
            <Col offset={1}>
              <span className={styles.font}>No Disponible</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}

export default PriceLegend

import React, { FC, useContext, useLayoutEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Col, Drawer, Form, message, Row } from 'antd'

import { isCCC } from '../../../helpers/auth.helper'
import { RolesEnum } from '../../../helpers/enums'
import { UPDATE_COMPANY_USER } from '../../../queries'
import { SessionContext } from '../../../services/session'
import { CostCenter } from '../../../types/costCenter.type'
import { CurrentAccount } from '../../../types/currentAccount.type'
import { User } from '../../my-travels/types'
import UserTypeRow from '../Content/UserTypeRow'
import { MyUserPageContext } from '../Provider'
import { UpdateUserDrawerProps, UpdateUserInput } from '../types'

import InfoRow from './InfoRow'
import SeatPolicy from './SeatPolicyForm'
import UsersCecoDisplay from './UsersCecoDisplay'

import styles from './styles.module.css'

const UpdateUserDrawer: FC<UpdateUserDrawerProps> = ({ refetch }) => {
  const { user } = useContext(SessionContext)
  const {
    accountSelect,
    costCenters,
    visible,
    selectedUser,
    setSelectedUser,
    setVisible,
    setSelectedCeco,
    setSelectedAccount,
  } = useContext(MyUserPageContext)
  const [form] = Form.useForm()

  const [width, setWidth] = useState<number>(0)
  const [seatOptions, setSeatOptions] = useState<string[]>(
    selectedUser.seatsAllowed,
  )

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const [updateCompanyUser, { loading }] = useMutation(UPDATE_COMPANY_USER)

  const getRadioValue = (value: 1 | 2) => {
    if (isCCC(user?.role as RolesEnum))
      return value === 1 ? RolesEnum.ADMIN_CCC : RolesEnum.CCC
    return value === 1 ? RolesEnum.ADMIN_SCC : RolesEnum.SCC
  }

  const updateUser = async (values: UpdateUserInput) => {
    const {
      areaCode,
      firstName,
      lastName,
      email,
      phone,
      role: roleOption,
    } = values

    const role =
      selectedUser.role === RolesEnum.MULTI_RUT_ADMIN ||
      selectedUser.role === RolesEnum.MULTI_RUT_BUYER
        ? selectedUser.role
        : getRadioValue(parseInt(roleOption) as 1 | 2)

    const isAdmin = [
      RolesEnum.ADMIN_CCC,
      RolesEnum.ADMIN_SCC,
      RolesEnum.MULTI_RUT_ADMIN,
    ].includes(role)

    const newCostCenters = isAdmin ? [] : costCenters?.map(({ _id }) => _id)

    const newAccs = isAdmin
      ? accountSelect.map(cc => cc._id)
      : costCenters.map(({ currentAccount }) => currentAccount._id)

    try {
      await updateCompanyUser({
        variables: {
          input: {
            _id: selectedUser._id,
            costCenters: newCostCenters,
            currentAccounts: newAccs,
            firstName,
            lastName,
            email: email.toLowerCase(),
            phone: `${areaCode} ${phone}`,
            status: selectedUser.status,
            role,
            seatsAllowed: seatOptions,
          },
        },
      })
      message.success('Se ha actualizado usuario exitosamente.')
      form.resetFields()
      setSelectedUser({} as User)
      setSelectedCeco({} as CostCenter)
      setSelectedAccount({} as CurrentAccount)
      await refetch()
      setVisible(false)
    } catch (err) {
      message.error(`Ha habido un error al actualizar usuario. ${err}`)
    }
  }

  const renderDeleteUserButton = () => {
    return (
      <div className={styles.deleteUser}>
        <Button>Eliminar usuario</Button>
      </div>
    )
  }

  return (
    <Drawer
      bodyStyle={{ padding: '1rem' }}
      footer={
        <Row align="middle" justify="end" gutter={15}>
          <Col xs={{ span: 24, order: 2 }} md={{ span: 10, order: 1 }}>
            <Button
              className={styles.cancelUpdateButton}
              onClick={() => {
                setSelectedUser({} as User)
                form.resetFields()
                setVisible(false)
              }}>
              Cancelar
            </Button>
          </Col>
          <Col xs={{ span: 24, order: 1 }} md={{ span: 10, order: 2 }}>
            <Button
              className={styles.saveUpdateButton}
              loading={loading}
              onClick={() =>
                form
                  .validateFields()
                  .then(updateUser)
                  .catch(err => {
                    message.info(
                      `Verifique que los campos fueron ingresados correctamente.`,
                    )
                    console.log(err)
                  })
              }>
              Guardar cambios
            </Button>
          </Col>
        </Row>
      }
      onClose={() => {
        setSelectedUser({} as User)
        form.resetFields()
        setVisible(false)
      }}
      title={'Perfil de usuario'}
      visible={visible}
      width={window?.innerWidth > 900 ? 700 : window?.innerWidth - 10}>
      <Form form={form}>
        <Col span={24}>
          <InfoRow />
          {selectedUser.role !== RolesEnum.MULTI_RUT_ADMIN &&
          selectedUser.role !== RolesEnum.MULTI_RUT_BUYER ? (
            <UserTypeRow userType={selectedUser.role} />
          ) : null}
          {selectedUser.role === RolesEnum.CCC ||
          selectedUser.role === RolesEnum.MULTI_RUT_BUYER ? (
            <>
              <UsersCecoDisplay />
              <SeatPolicy
                initialOption={selectedUser.seatsAllowed}
                onClick={data => setSeatOptions(data)}
              />
            </>
          ) : selectedUser.role === RolesEnum.SCC ? (
            <SeatPolicy
              initialOption={selectedUser.seatsAllowed}
              onClick={data => setSeatOptions(data)}
            />
          ) : null}
          {width < 768 && renderDeleteUserButton()}
        </Col>
      </Form>
    </Drawer>
  )
}

export default UpdateUserDrawer

import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { useEventSave } from '../../../../hooks/analytics.hook';
import { getUser, tokenInfo } from '../../../../services/auth';
import { TravelPageContext } from '../../Provider';
import { QueryString, Travel } from '../../types';
import Error from './cross.inline.svg';
import styles from './styles.module.css';
import {
  TRANSBANK_PAYMENT_ERROR,
  TRAVEL_BY_TRANSACTION,
} from '../../../../queries';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
const PaymentFinished: FC = () => {
  const { setTransaction, setSearch, setQuotas } = useContext(TravelPageContext);
  const { uuidTransbank } = useContext(TravelPageContext);
  
  console.log("uuid PaymentFinished", uuidTransbank);

  const [travelGone, setTravelGone] = useState<Travel>();
  const [travelReturn, setTravelReturn] = useState<Travel>();


  const [ErrorPayment, { loading: mutationLoading }] = useMutation(
    TRANSBANK_PAYMENT_ERROR,
  );

  const location = useLocation()

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '');
    useEventSave('pago_fallido', { user: user.email });
    setTransaction({});
    setSearch({} as QueryString);
    setQuotas([]);

    const failureURL = queryString.parse(location.href)


  if (uuidTransbank) {
      ErrorPayment({
        variables: {
          returnStr: failureURL,
          userEmail: user.email,
          uuid: uuidTransbank,
        },
      })
        .then(({ data }) => {
          const { transbankPaymentError } = data;
          // console.log("transbankPaymentError", transbankPaymentError);
          if (transbankPaymentError) {
            setTravelGone(transbankPaymentError.gone);
            setTravelReturn(transbankPaymentError.return);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [uuidTransbank]);

  return (
    <Row justify="center" align="middle" className={styles.background}>
      <Col
        xs={22}
        md={12}
        style={{
          textAlign: 'center',
        }}>
        <Error className={styles.errorIcon} />
        <p className={styles.titleFont}>Error en el Pago</p>
        <p className={styles.subText}>
          No se pudo concretar el pago por online
        </p>

        <Row justify="center">
          <Col xs={22} sm={12} md={10}>
            <Button
              block
              type="text"
              className={styles.returnButton}
              href="/app/inicio">
              Volver a inicio
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PaymentFinished;

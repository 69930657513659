import React, { FC, useState } from 'react'
import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Col, Row, Tooltip } from 'antd'
import { navigate } from 'gatsby'
import moment from 'moment'

import { OriginDestinationIcon } from '../../../../assets/originDestination'
import { formatDate, getDisplayDate } from '../../../../helpers/dateLongFormat'
import {
  fixTimeFormat,
  getMyTravelPrice,
} from '../../../../helpers/my-travel-page'
import { numberWithSeparator } from '../../../../helpers/number.helper'
import { ScheduledTripsProps, TravelSeat, TripResume } from '../../types'

import renderLogo from './Logo'

import styles from './styles.module.css'

const ScheduledTrips: FC<ScheduledTripsProps> = ({
  item,
  grayBackground,
  activeDevolution,
  toggleDevolutionList,
  tab,
  isTicketFilter,
  ticketNumber
}) => {
  const { gone, return: travelReturn } = item
  const format = 'YYYY-MM-DD'
  const response = 'YYYY-MM-DD'

  const getTotalPassengers = (
    goneSeats?: TravelSeat[],
    returnSeats?: TravelSeat[],
  ) => {
    const total = []
    if (goneSeats?.length)
      total.push(
        ...goneSeats
          ?.filter(seat => seat.passenger && typeof seat.passenger !== 'string')
          .map(seat => seat.passenger._id.toString()),
      )

    if (returnSeats?.length)
      total.push(
        ...returnSeats
          .filter(seat => seat.passenger && typeof seat.passenger !== 'string')
          .map(seat => seat.passenger._id.toString()),
      )

    return [...new Set(total)].length
  }


  const toggleClick = (item: TripResume) => {
    if (toggleDevolutionList) {
      const ids: (string | undefined)[] = []; 
      const itemsTransaction: string[] = []; 
  
      if (item.gone) {
        ids.push(item.gone._id);
        if (item.return && item.return.transaction === item.gone.transaction) {
          if (item.gone.transaction && !itemsTransaction.includes(item.gone.transaction)) {
            itemsTransaction.push(item.gone.transaction);
          }
        } else {
          itemsTransaction.push(item.gone.transaction || '');
        }
      }
  
      if (item.return) {
        ids.push(item.return._id);
        if (!item.gone || item.return.transaction !== item.gone.transaction) {
          if (item.return.transaction && !itemsTransaction.includes(item.return.transaction)) {
            itemsTransaction.push(item.return.transaction);
          }
        }
      }
  
      toggleDevolutionList(ids, itemsTransaction);
    }
  };
  

  const renderPassengerText = (
    goneSeats?: TravelSeat[],
    returnSeats?: TravelSeat[],
  ): string => {
    const totalPassengers = getTotalPassengers(goneSeats, returnSeats)

    const seats = goneSeats || returnSeats

    const totalSeats =
      (seats?.length ?? 0) > totalPassengers
        ? seats?.length ?? 0
        : totalPassengers

    return `${totalPassengers}/${totalSeats} pasajero${
      totalSeats > 1 ? 's' : ''
    }`
  }

  const handleDetail = () => {
    if ((window?.screen?.width ?? 1000) < 992 && !activeDevolution)
      navigate(`/app/mis-viajes/${gone?.bookingId || travelReturn?.bookingId}`)
  }

  return (
    <>
      <Row
        align="middle"
        className={`${styles.travelRow} ${
          grayBackground ? styles.grayBackground : null
        }`}
        onClick={handleDetail}
        style={{ whiteSpace: 'nowrap' }}
        >
        <Col xs={8} lg={3}>
          <Row align="middle">
            <Col>
              <OriginDestinationIcon className={styles.destinySvg} />
            </Col>
            <Col>
              <div>
                <div className={styles.routeFont}>
                  {gone?.departureCity || travelReturn?.departureCity}
                </div>
                <div className={styles.routeFont}>
                  {gone?.destinationCity || travelReturn?.destinationCity}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col className={styles.leftDivider} xs={0} lg={3}>
          <div className={styles.transactionLabel}>Núm Transacción</div>
          <div className={styles.transactionValue}>
            {gone?.transaction || travelReturn?.transaction}
          </div>
        </Col>

        <Col className={`${styles.leftDivider} ${styles.centeredCol}`} xs={0} lg={3}>
          <div className={styles.ticketLabel}>Núm Boleto(s)</div>
          
            <div className={styles.ticketValue}>
              {isTicketFilter ? (
                <>
                  {gone?.seats.map((seat) =>
                    seat.ticketId.toString().includes(ticketNumber ?? "") ? (
                      <div key={seat.ticketId}>I: {seat.ticketId}</div>
                    ) : null
                  )}
                  {travelReturn?.seats.map((seat) =>
                    seat.ticketId.toString().includes(ticketNumber ?? "") ? (
                      <div key={seat.ticketId}>V: {seat.ticketId}</div>
                    ) : null
                  )}
                </>
              ) : (
                <>
                  {/* Si el viaje tiene más boletos de ida y viaje que se desplieguen solo los primeros */}
                  {(gone?.seats?.length ?? 0) > 0 && (
                    <div key={gone?.seats[0].ticketId}>I: {gone?.seats[0].ticketId}</div>
                  )}
                  {(travelReturn?.seats?.length ?? 0) > 0 && (
                    <div key={travelReturn?.seats[0].ticketId}>V: {travelReturn?.seats[0].ticketId}</div>
                  )}
                </>
              )}

              <Button
                className={styles.travelDetailButton}
                onClick={() =>
                  navigate(
                    `/app/mis-viajes/${
                      gone?.bookingId || travelReturn?.bookingId
                    }`,
                    {
                      state: {
                        defaultActive: tab,
                      },
                    },
                  )
                }
              >
                Ver detalles
               </Button>
            </div>

        </Col>


        <Col className={styles.leftDivider} xs={0} lg={3}>
          <div className={styles.transactionLabel}>Fecha de compra</div>
          <div className={styles.transactionValue}>
            {moment(gone?.createdAt).format('DD/MM/YYYY')}
          </div>
        </Col>
        <Col
          xs={14}
          md={{ span: 12 }}
          lg={{ span: 5, offset: 0 }}
          className={`${styles.leftDivider} ${styles.right} ${styles.paddingRight1}`}>
          {gone && (
            <div className={styles.goneFont}>
               Ida:{' '}
              {getDisplayDate(gone?.departsAt, format, response)},{' '}
              {fixTimeFormat(gone?.departsTime)}
            </div>
          )}
          {travelReturn && (
            // <div className={styles.dateTravel}>
            <div className={styles.goneFont}>
              Vuelta:{' '}
              {getDisplayDate(travelReturn?.departsAt, format, response)},{' '}
              {fixTimeFormat(travelReturn?.departsTime)}
            </div>
          )}
        </Col>
        <Col xs={2} lg={0}>
          {activeDevolution ? (
            (item.gone?.canBeReturned ?? true) &&
            (item.return?.canBeReturned ?? true) ? (
              <Checkbox onClick={() => toggleClick(item)} />
            ) : (
              <Tooltip title="Ya existen pasajes devueltos para este viaje, si deseas devolver los pasajes restantes, hazlo a través de la devolución por boletos">
                <ExclamationCircleOutlined className={styles.infoColorFont} />
              </Tooltip>
            )
          ) : null}
        </Col>
        <Col
          className={`${styles.leftDivider} ${styles.rightDivider}`}
          xs={0}
          lg={2}>
          <div className={styles.operatorLabel}>Operador</div>
          <div className={styles.operatorValue}>
            {gone &&
            travelReturn &&
            gone?.operatorName !== travelReturn.operatorName ? (
              <div>
                {
                  renderLogo[
                    gone?.operatorName.toLowerCase() as
                      | 'turbus'
                      | 'condor bus'
                      | 'jac ltda.'
                      | 'buses bio-bio'
                  ]
                }
              <br></br>
                {
                  renderLogo[
                    travelReturn.operatorName.toLowerCase() as
                      | 'turbus'
                      | 'condor bus'
                      | 'jac ltda.'
                      | 'buses bio-bio'
                  ]
                }
              </div>
            ) : (
              <div>
                {renderLogo[
                  gone?.operatorName.toLowerCase() as
                    | 'turbus'
                    | 'condor bus'
                    | 'jac ltda.'
                    | 'buses bio-bio'
                ] ||
                  renderLogo[
                    travelReturn?.operatorName.toLowerCase() as
                      | 'turbus'
                      | 'condor bus'
                      | 'jac ltda.'
                      | 'buses bio-bio'
                  ]}
              </div>
            )}
          </div>
        </Col>
        <Col xs={24} lg={0} className={styles.topDivider} />
        <Col xs={24} lg={2}>
          <Row justify="space-between" align="middle">
            <Col
              xs={{ span: 8, order: 2 }}
              sm={{ span: 6 }}
              lg={{ span: 24, order: 1 }}>
              <div className={styles.passValue}>
                <TeamOutlined className={styles.teamStyle} />{' '}
                {renderPassengerText(gone?.seats, travelReturn?.seats)}
              </div>
            </Col>
            <Col
              xs={{ span: 8, order: 1, push: 2 }}
              sm={{ span: 8, order: 1, push: 1 }}
              md={{ span: 8, order: 1, push: 1 }}
              lg={{ span: 24, order: 2, push: 0 }}
              className={styles.amountFont}>
              $
              {numberWithSeparator(
                getMyTravelPrice(gone?.seats ?? []) +
                  getMyTravelPrice(travelReturn?.seats ?? []),
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          lg={0}
          className={`${styles.createdAtFont} ${styles.topDivider} ${styles.centered}`}>
          Fecha de venta:{' '}
          {formatDate(
            gone?.createdAt.toString() ?? travelReturn?.createdAt?.toString(),
          )}
        </Col>
        <Col xs={0} lg={1} className={`${styles.leftDivider} ${styles.centered}`} >
          {!activeDevolution ? (
            <Row>
              <Col>
                <Button
                  type="text"
                  href={`${process.env.GATSBY_API_TICKETS_URI}${
                    gone ? `${gone._id}/` : ''
                  }${travelReturn?._id ?? ''}`}
                  target="_blank"
                  className={styles.downloadButton}>
                  <DownloadOutlined className={styles.downloadIcon} />
                </Button>
              </Col>
            </Row>
          ) : (item.gone?.canBeReturned ?? true) &&
            (item.return?.canBeReturned ?? true) ? (
            <Checkbox
              className={styles.checkboxDevolution}
              onClick={() => toggleClick(item)}
            />
          ) : !item.gone && item.return?.canBeReturned ? (
            <Tooltip title="Los pasajes de ida no pueden devolverse. Para devolver pasajes de vuelta  hazlo a través de la devolución por boletos.">
              <ExclamationCircleOutlined className={styles.infoColorFont} />
            </Tooltip>
          ) : (
            <Tooltip title="Ya existen pasajes devueltos para este viaje, si deseas devolver los pasajes restantes, hazlo a través de la devolución por boletos">
              <ExclamationCircleOutlined className={styles.infoColorFont} />
            </Tooltip>
          )}
        </Col>
      </Row>
      <div className={styles.rowDivider} />
    </>
  )
}

export default ScheduledTrips

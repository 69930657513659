import { gql } from '@apollo/client'

export const UPDATE_INVOICE_DOCUMENTS = gql`
  mutation updateInvoiceDocuments($invoice: String!, $input: InvoiceDocumentsInput!) @api(name: "reports"){
    updateInvoiceDocuments(invoice: $invoice, input: $input){
      _id
      hes{
        number
        fileUrl
      }
      purchaseOrder {
        number
        fileUrl
      }
      gloss
    }
  }
`
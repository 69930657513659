import React, { FC } from 'react'
import { Col, Row, Timeline } from 'antd'

import {
  BiobioLogo,
  CondorLogo,
  JacLogo,
  TurbusLogo,
} from '../../assets/operators'
import { lowerCaseStringButFirstLetter } from '../../helpers/lowercaseStringButFirst'
import SectionDisplay from '../TravelStopsDetails/SectionDisplay'
import StartOrFinish from '../TravelStopsDetails/StartOrFinish'
import { TravelInfoBoxProps } from '../types'

import {
  biobioStyles,
  condorStyles,
  jacStyles,
  turbusStyles,
} from './logoStyles'

import styles from './styles.module.css'

const TravelInfoBox: FC<TravelInfoBoxProps> = ({
  formattedDepartureDate,
  formattedReturnDate,
  origin,
  destiny,
  transaction,
  type,
  sections,
}) => {
  const typeString = {
    gone: 'Ida',
    return: 'Vuelta',
  }

  const originCity = {
    gone: origin,
    return: destiny,
  }

  const destinyCity = {
    gone: destiny,
    return: origin,
  }

  const travel =
    type === 'gone' ? transaction?.travelGone : transaction?.travelReturn

  const renderLogo = (operatorName: string) => {
    if (operatorName === 'TURBUS') return <TurbusLogo style={turbusStyles} />

    if (operatorName === 'CONDOR BUS')
      return <img src={CondorLogo} alt="condorLogo" style={condorStyles} />

    if (operatorName === 'JAC LTDA.')
      return <img src={JacLogo} alt="jacLogo" style={jacStyles} />

    if (operatorName === 'BUSES BIO-BIO')
      return <img src={BiobioLogo} alt="biobioLogo" style={biobioStyles} />

    return null
  }

  return (
    <Col span={24}>
      <Row justify="space-between" align="top" className={styles.rowSpacing}>
        <Col>
          <p className={styles.cardDarkFont}>{typeString[type]}</p>
        </Col>
        <Col>
          <p className={styles.cardDarkFont}>{travel?.seat_class}</p>
          <Row justify="end" align="middle">
            <p className={styles.cardSmallLightFont}>
              {type === 'gone' ? formattedDepartureDate : formattedReturnDate}
            </p>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col style={{ marginBottom: 15 }}>
          {renderLogo(travel?.bus_operator_name ?? '')}
        </Col>
        <Col>
          <Row align="middle" style={{ minWidth: 300 }}>
            <Col>
              <Timeline>
                <Timeline.Item key="start">
                  <StartOrFinish
                    date={travel?.departs_at}
                    city={originCity[type]}
                    terminal={travel?.departure_terminal_name}
                  />
                </Timeline.Item>
                <SectionDisplay sections={sections} />
                <Timeline.Item key="end">
                  <StartOrFinish
                    date={travel?.arrives_at}
                    city={destinyCity[type]}
                    terminal={travel?.destination_terminal_name}
                  />
                </Timeline.Item>
              </Timeline>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}

export default TravelInfoBox

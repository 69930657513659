import React, { FC, useContext, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Col, Divider, Form, Input, message, Row, Select } from 'antd'
import { RuleObject, ValidateErrorEntity } from 'rc-field-form/lib/interface'

import IdentityPrefix from '../../../../components/IdentityPrefix'
import PhonePrefix from '../../../../components/phonePrefix'
import { evalPassportOrRutPassenger } from '../../../../helpers/my-passengers'
import { formatRut, validateRut } from '../../../../helpers/rutValidators'
import {
  COST_CENTERS_WITH_PASSENGERS,
  CREATE_SINGLE_PASSENGER,
  GET_ADDRESSES_QUERY,
  MY_COST_CENTERS,
} from '../../../../queries'
import { SessionContext } from '../../../../services/session'
import { CostCenter } from '../../../../types/costCenter.type'
import { PassengerFormProps, PassengerFormValues } from '../../types'

import styles from './styles.module.css'

const { Item } = Form
const { Option } = Select

// TODO: Handle complete name in separate inputs (first name and both last names).

const PassengerForm: FC<PassengerFormProps> = ({ handleDrawerButton }) => {
  const { user } = useContext(SessionContext)
  const [idRule, setIdRule] = useState(1)
  const [form] = Form.useForm()
  const { loading: addressLoading, data: addressData } =
    useQuery(GET_ADDRESSES_QUERY)
  const { loading: costCenterLoading, data: costCenterData } =
    useQuery(MY_COST_CENTERS)

  const [createPassenger, { loading }] = useMutation(CREATE_SINGLE_PASSENGER, {
    onCompleted: data => {
      if (data) {
        message.success('Pasajero creado exitosamente')
        form.resetFields()
        handleDrawerButton(0)
        // TODO: specify type on error in this message.
      } else message.error('Existe otro pasajero con el mismo rut.', 5)
    },
    refetchQueries: [{ query: COST_CENTERS_WITH_PASSENGERS }],
  })

  const onFinish = async (values: PassengerFormValues) => {
    const {
      firstName,
      fatherLastName,
      motherLastName,
      phone,
      areaCode,
      identityData,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      indentityPrefix,
      ...rest
    } = values

    await createPassenger({
      variables: {
        input: {
          ...rest,
          firstName,
          fatherLastName,
          motherLastName,
          phone: `${areaCode} ${phone}`,
          ...evalPassportOrRutPassenger(idRule, identityData),
        },
      },
    })
  }

  const onFinishFailed: (
    error: ValidateErrorEntity<PassengerFormValues>,
  ) => void = () => {
    message.error(
      'Asegúrese de haber completado todos los campos requeridos.',
      10,
    )
  }

  return (
    <Row>
      <Col className={styles.mainContainer} span={24}>
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical">
          <Item
            className={styles.inputRow}
            name="firstName"
            label="Nombre"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Nombre del pasajero" />
          </Item>
          <Item
            className={styles.inputRow}
            name="fatherLastName"
            label="Apellida paterno"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Apellido paterno del pasajero" />
          </Item>
          <Item
            className={styles.inputRow}
            name="motherLastName"
            label="Apellido materno"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Apellido materno del pasajero" />
          </Item>
          <Item
            className={styles.inputRow}
            label="Identificación"
            name="identityData"
            normalize={data => (idRule === 1 ? formatRut(data) : data)}
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              () =>
                idRule === 1
                  ? {
                      validator(rule, value) {
                        console.log('validando', idRule)
                        if (validateRut(value) || !value)
                          return Promise.resolve()

                        return Promise.reject('Documento inválido.')
                      },
                    }
                  : ({} as RuleObject),
            ]}>
            <Input
              addonBefore={<IdentityPrefix setIdRule={setIdRule} />}
              maxLength={12}
              placeholder={
                idRule === 1 ? 'Ejemplo: 76.325.945-k' : 'Ejemplo: 7537575'
              }
            />
          </Item>
          <Item
            className={styles.inputRow}
            name="phone"
            label="Teléfono"
            rules={[
              { required: true, message: '' },
              {
                max: 9,
                message: 'no se permiten mas de 9 caracteres',
              },
              {
                pattern: /^[0-9]*$/,
                message: 'debe ingresar solo números',
              },
            ]}>
            <Input
              name="areaCode"
              addonBefore={<PhonePrefix />}
              className={styles.inputPhone}
              placeholder="Ejemplo: 912345678"
            />
          </Item>
          <Item
            className={styles.inputRow}
            label="Correo electrónico"
            name="email"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Introduzca un correo válido',
              },
            ]}>
            <Input placeholder="Ejemplo: micorreo@correo.cl" />
          </Item>
          <Item
            className={styles.inputRow}
            label="Nacionalidad"
            name="nationality"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            {addressData && (
              <Select
                allowClear
                className={styles.selectInputRow}
                loading={addressLoading}
                placeholder="Seleccione País"
                optionFilterProp="children"
                showArrow={false}
                showSearch>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
                {addressData.getAddresses?.map(({ countryName }: any) => (
                  <Option key={countryName} value={countryName}>
                    {countryName}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
          <Item
            className={styles.inputRow}
            label="Cargo"
            name="position"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Input placeholder="Cargo del pasajero" />
          </Item>
          {user?.role === 'CCC' ||
          user?.role === 'ADMIN_CCC' ||
          user?.role === 'MULTI_RUT_ADMIN' ||
          user?.role === 'MULTI_RUT_BUYER'  ?  (
            <Item
              className={styles.inputRow}
              label="Centro de costo"
              name="costCenter"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
              ]}>
              {costCenterData && (
                <Select
                  className={styles.selectInputRow}
                  loading={costCenterLoading}
                  optionFilterProp="children"
                  placeholder="Centro de costo"
                  showSearch>
                  {costCenterData.myCostCenters.map(
                    ({ _id, name }: CostCenter) => (
                      <Option key={_id} value={_id}>
                        {name}
                      </Option>
                    ),
                  )}
                </Select>
              )}
            </Item>
          ) : null}

          <Divider />
          <Row
            align="middle"
            className={styles.drawerButtonRow}
            justify="center"
            gutter={25}>
            <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 1 }}>
              <Button
                block
                className={styles.cancelButton}
                onClick={() => handleDrawerButton(0)}>
                Cancelar
              </Button>
            </Col>
            <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 2 }}>
              <Button
                block
                className={styles.continueButton}
                loading={loading}
                htmlType="submit">
                Agregar pasajero
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  )
}

export default PassengerForm

import React, { useState } from 'react'
import { Button, Col, Row } from 'antd'

import { useScreenWidth } from '../../../hooks/screen.hook'
import { FrequentSectionProps } from '../types/props'

import FrequentSeat from './FrequentSeat'
import FrequentTravel from './FrequentTravel'

import styles from './styles.module.css'

const FrequentSection: React.FC<FrequentSectionProps> = ({
  frequentTravel,
  frequentSeat,
}) => {
  const [activeTravel, setActiveTravel] = useState<boolean>(true)
  const screenWidth = useScreenWidth()

  return (
    <Row className={styles.rowMargingX}>
      <Col className={styles.contentCard} md={24}>
        <div className={styles.containerContent}>
          <Row justify="space-around" align="middle">
            <Col xs={11}>
              <Button
                block
                className={`${styles.button} ${
                  activeTravel ? styles.activeButton : null
                }`}
                onClick={() => setActiveTravel(true)}>
                <span
                  className={screenWidth <= 320 ? styles.smallFont : undefined}>
                  Viajes Frecuentes
                </span>
              </Button>
            </Col>
            <Col xs={11}>
              <Button
                block
                className={`${styles.button} ${
                  activeTravel ? null : styles.activeButton
                }`}
                onClick={() => setActiveTravel(false)}>
                <span
                  className={screenWidth <= 320 ? styles.smallFont : undefined}>
                  Cabinas Frecuentes
                </span>
              </Button>
            </Col>
          </Row>
          {activeTravel ? (
            <FrequentTravel frequentTravel={frequentTravel} />
          ) : (
            <FrequentSeat frequentSeat={frequentSeat} />
          )}
        </div>
      </Col>
    </Row>
  )
}

export default FrequentSection

import React, { FC } from 'react'
import { Table, Timeline, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { BuyStatus } from '../../../helpers/enums'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { formatDateAndTime } from '../../../helpers/travel-page/compute.helper'
import { PaymentType } from '../../../types/enums'
import {
  GoneReturnTravel,
  Seat,
} from '../../../types/page-types/travel-details-page.types'
import { PurchasesTableProps } from '../types/props'

import styles from './styles.module.css'

const { Text } = Typography
const { Item } = Timeline

export const getMyTravelPrice = (data: Seat[], status: string): number =>
  data.reduce((acc, { price, buyStatus }) => {
    if (buyStatus === status) acc += price

    return acc
  }, 0)

const renderTravel = (record: GoneReturnTravel) => (
  <Timeline className={styles.timeLine}>
    <Item color="gray">
      <Text className={styles.text}>{record?.gone?.departureCity}</Text>
    </Item>
    <Item color="gray">
      <Text className={styles.text}>{record?.gone?.destinationCity}</Text>
    </Item>
  </Timeline>
)

const renderNumTransaction = (record: GoneReturnTravel) => (
  <div className={styles.containerText}>
    <Text className={styles.text}>Núm transacción</Text>
    <Text strong className={styles.boldText}>
      {record?.gone?.transaction}
    </Text>
  </div>
)

const renderDateTravel = (record: GoneReturnTravel) => (
  <div className={styles.containerText}>
    <Text className={styles.text}>
      {`ida: ${formatDateAndTime(
        record?.gone?.departsAt as Date,
        record?.gone?.departsTime as number,
      )}`}
    </Text>
    <Text className={styles.text}>
      {record?.return
        ? `vuelta: ${formatDateAndTime(
            record.return.departsAt,
            record.return.departsTime,
          )}`
        : ''}
    </Text>
  </div>
)

const renderCurrentAccountTravel = (record: GoneReturnTravel) => {
  if (record?.gone?.paymentType === PaymentType.CREDIT)
    return (
      <div className={styles.containerText}>
        <Text className={styles.text}>
          {`CC. ${record?.gone?.currentAccount?.sapNumber}`}
        </Text>
        <Text
          className={
            styles.boldText
          }>{`CeCo. ${record?.gone?.costCenter?.name}`}</Text>
      </div>
    )
  else
    return (
      <div className={styles.containerText}>
        <Text className={styles.text}>Transbank</Text>
      </div>
    )
}

const renderNumberPassengers = (
  record: GoneReturnTravel,
  showDevolutions: boolean,
) => {
  const goneSeats = record?.gone?.seats ?? []
  const validGone = goneSeats.filter(seat => seat.buyStatus === BuyStatus.VALID)
  const returnSeats = record?.return?.seats ?? []
  const validReturn = returnSeats.filter(
    seat => seat.buyStatus === BuyStatus.VALID,
  )
  const returned =
    (record?.gone?.seats.length ?? 0) -
    validGone.length +
    (record?.return?.seats.length ?? 0) -
    validReturn.length

  return (
    <div className={styles.containerText}>
      <Text className={styles.text}>{`${
        showDevolutions
          ? `${returned} devuelto(s) de ${
              goneSeats.length + returnSeats.length
            }`
          : `${validGone.length + validReturn.length} boleto(s)`
      }`}</Text>

      <Text strong className={styles.boldText}>
        {`$${numberWithSeparator(
          getMyTravelPrice(
            [...goneSeats, ...returnSeats],
            showDevolutions ? BuyStatus.CANCELED : BuyStatus.VALID,
          ),
        )}`}
      </Text>
    </div>
  )
}

const TravelTable: FC<PurchasesTableProps> = ({ travels, showDevolutions }) => {
  const columns: ColumnsType<GoneReturnTravel> = [
    {
      dataIndex: 'departsAt',
      key: 'departsAt',
      width: 50,
      render: (_, record: GoneReturnTravel) => renderTravel(record),
    },
    {
      dataIndex: 'transaction',
      key: 'transaction',
      width: 50,
      render: (_, record: GoneReturnTravel) => renderNumTransaction(record),
    },
    {
      dataIndex: 'departsAt',
      key: 'departsAt',
      width: 80,
      render: (_, record: GoneReturnTravel) => renderDateTravel(record),
    },
    {
      dataIndex: 'currentAccount',
      key: 'currentAccount',
      width: 50,
      render: (_, record: GoneReturnTravel) =>
        renderCurrentAccountTravel(record),
    },
    {
      dataIndex: 'seats',
      key: 'seats',
      width: 60,
      render: (_, record: GoneReturnTravel) =>
        renderNumberPassengers(record, showDevolutions),
    },
  ]
  return (
    <div className={styles.travelTable}>
      <Table
        rowKey={Math.random}
        columns={columns}
        dataSource={travels}
        pagination={{
          pageSize: 5,
          showSizeChanger: false,
          position: ['bottomCenter'],
        }}
        bordered={false}
        size={'small'}
        scroll={{ x: 700, y: 300 }}
        showHeader={false}
      />
    </div>
  )
}

export default TravelTable

import React, { FC } from 'react'

import { SeatDisplayProps } from '../../types'

import TwoWayTrip from './TwoWayTrip'

import styles from './styles.module.css'

const SeatsDisplay: FC<SeatDisplayProps> = ({ onChangeTravel }) => {
  return (
    <div className={styles.card}>
      <TwoWayTrip onChangeTravel={onChangeTravel} />
    </div>
  )
}

export default SeatsDisplay

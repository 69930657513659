import React, { FC, useContext, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Col, Divider, message, Row } from 'antd'
import { navigate } from 'gatsby'

import LoadingSpinner from '../../../components/LoadingSpinner'
import { RolesEnum } from '../../../helpers/enums'
import { numberWithSeparator } from '../../../helpers/number.helper'
import {
  calcPercentDiscount,
  createTravelInput,
} from '../../../helpers/travel-page'
import { getTravelResume } from '../../../helpers/travel-page/dates.helper'
import { useEventSave } from '../../../hooks/analytics.hook'
import {
  MY_DETAIL_CURRENT_ACCOUNTS,
  PAY_TRAVEL,
  PAY_TRAVEL_TRANSBANK,
} from '../../../queries'
import { getUser, tokenInfo } from '../../../services/auth'
import { SessionContext } from '../../../services/session'
import { CurrentAccount } from '../../../types/page-types/pay-page.types'
import { TravelPageContext } from '../Provider'

import CurrentAccountMobileSummary from './CurrentAccountMobileSummary'
import CurrentAccountResume from './CurrentAccountResume'
import OtherPaymentOptions from './OtherPaymentOptions'
import PayMethods from './PayMethods'
import TravelResume from './TravelResume'

import styles from './styles.module.css'

const PayPage: FC = () => {
  const { user, company } = useContext(SessionContext)
  const companyType = company.creditLine != null ? 'CCC' : 'SCC'
  const { search, transaction, quotas, percentDiscountValue} = useContext(
    TravelPageContext,
  )

  const { updateUuidTransbank } = useContext(TravelPageContext);
  const [selectedAccountId, setSelectedAccountId] = useState('')
  const [selectedCostCenterId, setSelectedCostCenterId] = useState('')
  const [selectedPayment, setSelectedPayment] = useState(
    user.role === RolesEnum.SCC || user.role === RolesEnum.ADMIN_SCC
      ? 'other'
      : 'creditLine',
  )

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_de_pago' as string

    useEventSave('vista_de_pago', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const [payTravelWithCredit, { loading: payWithCreditLoading }] = useMutation(
    PAY_TRAVEL,
  )
  const [
    payTravelTransbank,
    { loading: payTravelTranskankLoading },
  ] = useMutation(PAY_TRAVEL_TRANSBANK)

  const { data, loading } = useQuery(MY_DETAIL_CURRENT_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    onError: error =>
      message.error(
        `Ha ocurrido un error al obtener la información de tu cuenta, ${error}`,
      ),
    skip: user.role === RolesEnum.SCC || user.role === RolesEnum.ADMIN_SCC,
  })

  const handlePaymentChange = (value: string) => setSelectedPayment(value)

  const handleCreditLine = (value: string, type = 'account') => {
    if (type === 'account') setSelectedAccountId(value)
    if (type === 'costCenter') setSelectedCostCenterId(value)
  }

  const showStatus = (
    accounts?: CurrentAccount[],
    acc?: string,
    id?: string,
  ) => {
    if (accounts && acc && id) {
      const idx = accounts.findIndex(({ _id }) => acc === _id)
      return accounts[idx].costCenters.find(({ _id }) => id === _id)
    }
  }

  const findSelectedAccount = (accountId: string, accounts: CurrentAccount[]) =>
    accounts.find(({ _id }) => _id === accountId)

  const { totalToPay } = getTravelResume(
    transaction.goneSeats ?? [],
    transaction.returnSeats ?? [],
  )

  const getTotalToPay = () =>
    Math.round(
      percentDiscountValue
        ? totalToPay - calcPercentDiscount(totalToPay, percentDiscountValue)
        : totalToPay,
    )

  const getPaymentMutationOptions = (credit: boolean) => {
    const { travelReturn } = transaction
    return {
      variables: {
        input: {
          currentAccount: credit ? selectedAccountId : '',
          token: transaction.travelGone?.integration_code,
          totalToPay: getTotalToPay(),
          travelGone: createTravelInput(
            search,
            transaction,
            credit ? selectedAccountId : '',
            credit ? selectedCostCenterId : '',
            quotas,
            true,
            percentDiscountValue,
          ),
          travelReturn: travelReturn
            ? createTravelInput(
                search,
                transaction,
                credit ? selectedAccountId : '',
                credit ? selectedCostCenterId : '',
                quotas,
                false,
                percentDiscountValue,
              )
            : null,
        },
      },
    }
  }

  const payWithCreditClick = async () => {
    if (!selectedAccountId || !selectedCostCenterId)
      return message.info(
        'Para pagar debe seleccionar la cuenta corriente y centro de costo al que quiere cargar esta compra',
      )
    try {
      const res = await payTravelWithCredit(getPaymentMutationOptions(true))

      const { data } = res

      const { payTravel } = data
      
      navigate(`/app/viajes/pago-exitoso?id=${payTravel.gone.transaction}`)

    } catch (err) {
      message.error(err.message)
    }
  }


  const payWithIntegration = async () => {
    try {
      const { data } = await payTravelTransbank(getPaymentMutationOptions(false));

      console.log("data payWithIntegration", data)

      const uuidTransbankValue = data.payTravelTransbank;
      updateUuidTransbank(uuidTransbankValue)
      console.log("uuidTransbankValue", uuidTransbankValue)
      localStorage.setItem('uuidTransbank', uuidTransbankValue);

      const paymentUrl = `${process.env.GATSBY_PAYMENT_URL}/${data.payTravelTransbank}`;
      window.location.href = paymentUrl;

    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
  };
  
  return (
    <>
      {loading ? (
        <div style={{ height: '70vh' }}>
          <LoadingSpinner size="100px" />
        </div>
      ) : (
        <>
          <Row align="middle" justify="center">
            <Col xs={24} md={16} lg={11}>
              <TravelResume
                transaction={transaction}
                travelResume={search}
                withCollapse={true}
              />
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Col xs={24} md={16} lg={11}>
              <Row align="middle" justify="center" className={styles.payBox}>

              {companyType === 'CCC' ? (
                <Row>
                  <Col xs={22} className={styles.payMethod}>
                  <p>Método de pago</p>
                  <hr />
                  <PayMethods
                    handlePaymentChange={handlePaymentChange}
                    selectedPayment={selectedPayment}
                  />
                  </Col>

                  <Col xs={0} md={24} className={styles.paddingTop2}>
                    {selectedPayment === 'creditLine' && (
                      <CurrentAccountResume
                        currentAccount={findSelectedAccount(
                          selectedAccountId,
                          data?.myCurrentAccounts ?? [],
                        )}
                        currentAccounts={data?.myCurrentAccounts}
                        handleCreditLine={handleCreditLine}
                        status={
                          showStatus(
                            data?.myCurrentAccounts,
                            selectedAccountId,
                            selectedCostCenterId,
                          )?.status
                        }
                      />
                    )}
                    {selectedPayment === 'other' && <OtherPaymentOptions />}
                  </Col>

                  <Col xs={24} md={0} className={styles.paddingTop2}>
                    {selectedPayment === 'creditLine' && (
                      <CurrentAccountMobileSummary
                        currentAccount={findSelectedAccount(
                          selectedAccountId,
                          data?.myCurrentAccounts ?? [],
                        )}
                        currentAccounts={data?.myCurrentAccounts}
                        handleCreditLine={handleCreditLine}
                        status={
                          showStatus(
                            data?.myCurrentAccounts,
                            selectedAccountId,
                            selectedCostCenterId,
                          )?.status
                        }
                      />
                    )}
                    {selectedPayment === 'other' && <OtherPaymentOptions />}
                  </Col>

                  <Divider />
                    <Col className={styles.textContainer} span={24}>
                      <Row justify="center">
                        <Col xs={22}>
                          <Row justify="space-between" align="middle">
                            <Col>
                              <p className={styles.totalLabelFont}>Total:</p>
                            </Col>
                            <Col>
                              <p className={styles.totalValueFont}>
                                ${numberWithSeparator(getTotalToPay()) ?? '~'}
                              </p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                <Divider />

                </Row>
              ) :(
                  <Col xs={0} md={24} className={styles.paddingTop2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%' }}>
                    <p>Actualmente estamos presentando problemas con Transbank.</p>
                    <p>Le pedimos por favor contactar a su ejecutivo.</p>
                  </Col>
              )}

                {companyType === 'CCC' && (
                  <Col className={styles.payTravelButton} xs={22} md={8}>
                  <Button
                    block
                    onClick={
                      selectedPayment === 'creditLine'
                        ? payWithCreditClick
                        : selectedPayment === 'other'
                        ? payWithIntegration
                        : () =>
                            message.info('Debe seleccionar una opción de pago')
                    }
                    loading={payWithCreditLoading || payTravelTranskankLoading}>
                    Pagar viaje
                  </Button>
                </Col>
                )}

              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  )
}

export default PayPage

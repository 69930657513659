export enum PaymentType {
  CREDIT = 'CREDIT',
  PAYMENT_GATEWAY = 'PAYMENT_GATEWAY',
}
export enum TravelDateRange {
  PROGRAMMED = 'PROGRAMMED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}
export enum TicketStatus {
  'EMBARCADO' = 'EMBARCADO',
  'NO_EMBARCADO' = 'NO_EMBARCADO',
  'SIN_EMBARQUE' = 'SIN_EMBARQUE',
}

export enum frontTicketStatus {
  EMBARCADO = 'Abordó',
  NO_EMBARCADO = 'No abordó',
  SIN_EMBARQUE = 'Sin servicio',
}

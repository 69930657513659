
export const TICKETS_RETURNED_COLUMNS = [
  {
    title: 'Pasajero',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Asiento',
    dataIndex: 'seatNumber',
    key: 'seatNumber',
  },
  {
    title: 'Ticket',
    dataIndex: 'ticket',
    key: 'ticket',
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price'
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type'
  }
]
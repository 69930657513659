import React, { FC } from 'react'
import { Row } from 'antd'

import styles from './styles.module.css'

const MyPassengerFooter: FC = () => (
  <Row className={styles.passengersFooter}></Row>
)

export default MyPassengerFooter

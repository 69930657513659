import React, { useContext, useEffect } from 'react'
import { Breadcrumb, Col, Row } from 'antd'

import BackArrow from '../../../../components/BackArrow'
import { TravelPageContext } from '../../Provider'
import { SubHeaderProps } from '../../types'

import SeatSelectionHeader from './SeatSelectionHeader'

import styles from '../styles.module.css'

const { Item } = Breadcrumb

const SubHeader: React.FC<SubHeaderProps> = ({ onBack }) => {
  const { passengersView, hasReturn, step } = useContext(TravelPageContext)
  const statuses = ['Selecciona tus boletos de ida']

  hasReturn
    ? statuses.push(
        ...[
          'Selecciona tus boletos de vuelta',
          'Resumen y confirmación',
          'Pagar viaje',
        ],
      )
    : statuses.push(...['Resumen y confirmación', 'Pagar viaje'])

  useEffect(() => {
    const offset = document.getElementById(`step-item-${step}`)?.offsetLeft

    document.getElementById('stepsContainer')?.scrollTo({
      top: 0,
      left: offset,
      behavior: 'smooth',
    })
  }, [step])

  return passengersView ? (
    <SeatSelectionHeader />
  ) : (
    <Row justify="space-between" className={styles.content}>
      <Col span={24} className={styles.subheader}>
        <Row>
          <Col xs={2} sm={1}>
            <BackArrow clickAction={onBack} whiteColor={true} />
          </Col>
          <Col xs={22} sm={23} className={styles.scrollCol} id="stepsContainer">
            <Breadcrumb
              className={styles.breadCrum}
              separator={<span className={styles.opaqueBreadItem}>{'>'}</span>}>
              {statuses.map((status, index) => (
                <Item
                  key={index.toString()}
                  className={
                    index === step ? styles.breadItem : styles.opaqueBreadItem
                  }>
                  <span id={`step-item-${index}`}>{status}</span>
                </Item>
              ))}
            </Breadcrumb>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SubHeader

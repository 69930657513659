import React, { FC, useContext, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'

import OptionsHeader from '../../components/OptionsHeader'
import SEO from '../../components/seo'
import { useEventSave } from '../../hooks/analytics.hook'
import {
  DELETE_RESERVATION,
  GET_COMPANY_INFORMATION,
  HAS_PERCENT_DISCOUNT,
} from '../../queries'
import { getUser, tokenInfo } from '../../services/auth'
import { TravelPageContext } from '../travel-page/Provider'
import { Transaction } from '../travel-page/types'

import HomeFooter from './HomeFooter'
import NewTrip from './NewTrip'
import PersonalInformation from './PersonalInformation'

import styles from './styles.module.css'

const HomePage: FC = () => {
  const {
    setPassengersView,
    setHasReturn,
    setStep,
    setTransaction,
    transaction,
    setQuotas,
  } = useContext(TravelPageContext)

  const [deleteResevation] = useMutation(DELETE_RESERVATION)

  const [
    getCompanyInformation,
    {
      loading: companyInformationLoading,
      error: companyInformationError,
      data: companyInformationData,
    },
  ] = useLazyQuery(GET_COMPANY_INFORMATION, {
    fetchPolicy: 'no-cache',
  })

  const [getPercentDiscount, { data: userInfo }] = useLazyQuery(
    HAS_PERCENT_DISCOUNT,
  )

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'simplus_home' as string

    useEventSave('vista_simplus_home', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })

    const {
      goneReservation,
      returnReservation,
      travelGone,
      travelReturn,
    } = transaction

    if (goneReservation && travelGone?.integration_code)
      deleteResevation({
        variables: {
          input: {
            reservation: goneReservation,
            token: travelGone?.integration_code,
          },
        },
      }).catch(err => console.log(err))

    if (returnReservation && travelReturn?.integration_code)
      deleteResevation({
        variables: {
          input: {
            reservation: returnReservation,
            token: travelReturn.integration_code,
          },
        },
      }).catch(err => console.log(err))

    setPassengersView(false)
    setHasReturn(false)
    setStep(0)
    setQuotas([])
    setTransaction({} as Transaction)
  }, [])

  const evalBackGroundStrip = () => {
    if (window?.innerWidth <= 540) return 'short'
    return 'medium'
  }

  return (
    <div className={styles.content}>
      <SEO title="Home" />
      <OptionsHeader
        backgroundStrip={evalBackGroundStrip()}
        isHome
        getCompanyInformation={getCompanyInformation}
        getPercentDiscount={getPercentDiscount}
      />
      <PersonalInformation
        companyInformationError={companyInformationError}
        companyInformationLoading={companyInformationLoading}
        companyInformationData={companyInformationData}
        getCompanyInformation={getCompanyInformation}
        getPercentDiscountData={userInfo}
        getPercentDiscount={getPercentDiscount}
      />
      <NewTrip />
      <HomeFooter />
    </div>
  )
}

export default HomePage

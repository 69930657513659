import React, { FC } from 'react';
import { ToTopOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';

import styles from './styles.module.css';
import { getCurrentUser, getUser, isLoggedIn } from '../../services/auth';
import {User } from '../../types/user.type';

const DownloadTemplateButton: FC = () => {
  const currentUser = getCurrentUser();
  const userRole = currentUser?.role

  return (
    <Row align="middle" justify="center" style={{ height: '10%' }}>
      <Col span={24}>
        <Row align="middle" justify="center">
          <p className={styles.baseFont}>
            Descarga esta plantilla y llénala con la información de tus pasajeros.
          </p>
        </Row>
        <Row align="middle" justify="center">
          <Button
            icon={<ToTopOutlined />}
            href={`${process.env.GATSBY_API_DOWNlOAD_PASSENGERS_TEMPLATE_URI}?userRole=${userRole}`}
            className={styles.downloadButton}
          >
            Descargar Plantilla
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default DownloadTemplateButton;

import React, { FC } from 'react'
import { Drawer, Row } from 'antd'

import { DetailDrawerProps } from '../../screens/my-travels/types'

import styles from './styles.module.css'

const DetailDrawer: FC<DetailDrawerProps> = ({
  children,
  handleDrawerButton,
  handleVisible,
  step,
  visible,
}) => {
  return (
    <Drawer
      onClose={() => {
        handleVisible(false)
        handleDrawerButton(0)
      }}
      placement="right"
      title={
        (step === 0 && 'Agregar pasajeros registrados') ||
        (step === 1 && 'Agregar pasajero') ||
        (step === 2 && 'Carga de pasajeros')
      }
      visible={visible}
      width={window?.innerWidth > 900 ? 600 : window?.innerWidth - 10}>
      <Row className={styles.drawerMainRow} justify="center">
        {children}
      </Row>
    </Drawer>
  )
}

export default DetailDrawer

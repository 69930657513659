import React, { FC, useContext } from 'react'
import { Col, Row } from 'antd'

import { SuccessIcon } from '../../assets'
import { RolesEnum } from '../../helpers/enums'
import { SessionContext } from '../../services/session'

import { UploadSuccessProps } from './props'

import styles from './styles.module.css'

const UploadSuccess: FC<UploadSuccessProps> = ({ passengersNumber }) => {
  const { user } = useContext(SessionContext)
  return (
    <Row>
      <Col span={24} className={styles.passengersContainer}>
        <Row
          align="middle"
          justify="space-around"
          className={styles.successIcon}>
          <SuccessIcon />
        </Row>
        <Row className={styles.successFont} align="middle" justify="center">
          <p>Carga de pasajeros completada</p>
        </Row>
        <Row align="middle" justify="center">
          <p className={styles.successInformationFont}>
            {passengersNumber > 1
              ? `Se han cargado ${passengersNumber} nuevos pasajeros`
              : `Se ha cargado ${passengersNumber} nuevo pasajero`}
          </p>
        </Row>
        {user.role === RolesEnum.CCC ||
          user.role === RolesEnum.MULTI_RUT_BUYER ||
          user.role === RolesEnum.ADMIN_CCC ||
          (user.role === RolesEnum.MULTI_RUT_ADMIN && (
            <Row align="middle" justify="center">
              <p className={styles.successInformationFont}>
                ¿Deseas agregarlos a tu nuevo grupo?
              </p>
            </Row>
          ))}
      </Col>
    </Row>
  )
}

export default UploadSuccess

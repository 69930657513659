import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Col, Divider, List, Row, Typography } from 'antd'

import { FrequentTravelProps } from '../types/props'

import styles from './styles.module.css'

const { Text } = Typography

const FrequentTravel: React.FC<FrequentTravelProps> = ({
  frequentTravel,
  className,
}) => {
  return (
    <div className={className ? className : styles.containerList}>
      <List
        itemLayout="horizontal"
        dataSource={frequentTravel}
        renderItem={(item, idx: number) => (
          <Row align="middle" justify="space-between">
            <Col span={14}>
              <Text className={styles.text}>{`${idx + 1}- `}</Text>
              <Text className={styles.text}>{`${item.departureCity}`}</Text>
              <ArrowRightOutlined className={styles.iconRightArrow} />
              <Text className={styles.text}>{`${item.destinationCity}`}</Text>
            </Col>
            <Col span={6}>
              <Text className={styles.textOpacity}>
                {`${item.countTravels} viajes`}
              </Text>
            </Col>
            <Divider className={styles.divider} />
          </Row>
        )}
      />
    </div>
  )
}

export default FrequentTravel

import { Report } from './types/types'

export const initStateReport: Report = {
  usedTickets: 0,
  totalCost: 0,
  purchasedTickets: 0,
  averageRate: 0,
  totalSaving: 0,
  currentAccount: [],
  frequentTravel: [],
  frequentSeat: [],
}
import React, { FC } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Col, Divider, Input, Row, Select } from 'antd'

import { UsersFilterRowProps } from '../types'

import styles from './styles.module.css'

const { Option } = Select

const UsersFilterRow: FC<UsersFilterRowProps> = ({
  handleSearch,
  handleSort,
}) => {
  return (
    <>
      <Row
        align="middle"
        className={styles.usersFilterRow}
        gutter={10}
        justify="space-between">
        <Col xs={24} md={12}>
          <p>Buscar usuario</p>
          <Input
            addonAfter={<SearchOutlined />}
            className={styles.searchInput}
            onChange={e => handleSearch(e.target.value)}
            placeholder="¿A qué usuario buscas?"
          />
        </Col>
        <Col xs={24} md={12}>
          <p>Ordenar por</p>
          <Select
            className={styles.sortOrderSelect}
            defaultValue={0}
            onChange={handleSort}>
            <Option value={0}>Orden alfábetico (A-Z)</Option>
            <Option value={1}>Orden alfábetico (Z-A)</Option>
          </Select>
        </Col>
      </Row>
      <Divider className={styles.usersDivider} />
    </>
  )
}

export default UsersFilterRow

import React, { FC, useState } from 'react'
import { SyncOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Row } from 'antd'

import {
  PassengerSeatNumbers,
  PassengerSeatsProps,
} from '../../../../../types/page-types/travel-details-page.types'

import PassengerRow from './PassengerRow'

import styles from './styles.module.css'

const PassengerSeats: FC<PassengerSeatsProps> = ({
  handleVisible,
  anonymousPassengers,
  passengerList,
  activeDevolution,
  setActiveDevolution,
  devolutionLoading,
  makeTicketsDevolution,
  travelById,
}) => {
  const [ticketsForDevolution, setTicketsForDevolution] = useState<number[]>([])

  const cancelDevolution = () => {
    setActiveDevolution(false)
    setTicketsForDevolution([])
  }

  const handleCheckboxClick = (ticket: number) => {
    let tickets = [...ticketsForDevolution]

    if (tickets.includes(ticket)) tickets = tickets.filter(t => t !== ticket)
    else tickets.push(ticket)

    setTicketsForDevolution(tickets)
  }
  const genAssignedCounts = (passengerList?: PassengerSeatNumbers[]) =>
    passengerList?.filter(({ passenger }) => typeof passenger !== 'string')
      .length

  const renderAnonymousButton = () => {
    return anonymousPassengers ? (
      <Button className={styles.addButton} onClick={() => handleVisible(true)}>
        +
      </Button>
    ) : null
  }
  const assignedTotals = `${genAssignedCounts(passengerList)} / ${
    passengerList?.length
  }`
  const handleUpdateClick = () => travelById()

  return (
    <Row className={styles.passengerResume} justify="center">
      <Col span={24}>
        <Row justify="space-between" align="middle">
          <p className={styles.titleFont}>
            Pasajeros asignados ({assignedTotals}){renderAnonymousButton()}
          </p>
          <Button onClick={handleUpdateClick} className={styles.updateButton}>
            <SyncOutlined className={styles.whiteSync} />
          </Button>
        </Row>
        <Divider />
        <Row
          align="middle"
          className={styles.passengerListContainer}
          justify="center">
          <Col span={24} className={styles.passengerSeats}>
            {passengerList &&
              Object.values(passengerList)?.map((value, index) => (
                <PassengerRow
                  value={value}
                  key={index}
                  activeDevolution={activeDevolution}
                  handleClick={handleCheckboxClick}
                />
              ))}
          </Col>
          {activeDevolution ? (
            <Col span={24}>
              <Row justify="center">
                <Col span={24}>
                  <Divider />
                </Col>
                <Col>
                  <Button onClick={cancelDevolution} type="text">
                    Cancelar
                  </Button>
                </Col>
                <Col offset={1}>
                  <Button
                    disabled={ticketsForDevolution.length === 0}
                    loading={devolutionLoading}
                    onClick={() => makeTicketsDevolution(ticketsForDevolution)}
                    className={styles.devolutionConfirm}>
                    Devolver
                  </Button>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}
export default PassengerSeats

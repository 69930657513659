import { CurrentAccount } from '../../types/currentAccount.type'
import { cleanRut } from '../rutValidators'

export const parseRut = (inputRut: string) => {
  const splitRut = inputRut.split('-')
  const [digits, dv] = splitRut
  return {
    cleanRut: parseInt(cleanRut(digits)),
    dv,
  }
}

export const evalPassportOrRutPassenger = (
  identityId: number,
  identityData: string,
) => {
  if (identityId === 2) return { passport: identityData }
  const { cleanRut, dv } = parseRut(identityData)
  return {
    rut: cleanRut,
    dv,
  }
}
const reducer = (acc, account: CurrentAccount) => [...acc,...account.costCenters]

export const groupCostCenters = (currentAccounts: CurrentAccount[]) =>  currentAccounts.reduce(reducer,[])
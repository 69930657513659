import { RouteComponentProps } from '@reach/router'

import { DiscountTypeEnum } from '../../helpers/enums'
import { Passenger } from '../../screens/travel-page/types'

import { CostCenter, CurrentAccount } from './pay-page.types'

export type CleanTicketResume = {
  title: string
  price: number
  totalCount: number
}

export type TravelDetailsPageProps = RouteComponentProps & {
  bookingId?: string
}

export type UserBuyer = {
  _id: string
  email: string
}

export type Seat = {
  col: number
  row: number
  number: number
  type: string
  price: number
  originalPrice: number
  passenger: Passenger
  isGone?: boolean
  ticketId: number
  buyStatus?: string
  departure?: number
  departureTime?: number
  ticketStatus?: string
}

export type Travel = {
  _id: string
  arrivesAt: Date
  createdAt: Date
  arrivesTime: number
  bookingId: number
  departsAt: Date
  currentAccount?: CurrentAccount
  costCenter?: CostCenter
  departsTime: number
  departureTerminal: string
  departureCity: string
  destinationTerminal: string
  destinationCity: string
  isGone: boolean
  operatorName: string
  seats: Seat[]
  userBuyer: UserBuyer
  paymentType: string
  buyStatus?: string
  transaction?: string
}

export type DiscountDetail = {
  originalPrice: number
  finalPrice: number
  priceDifference: number
  discountType: DiscountTypeEnum
  currentAccount: string
}

export type GoneReturnTravel = {
  gone?: Travel
  return?: Travel
  discountDetails?: DiscountDetail
}

export type TravelBoxProps = {
  travel: Travel
  type: string
}

export type TravelTransactionResumeProps = {
  travel: GoneReturnTravel
}

export enum TravelType {
  GONE = 'GONE',
  RETURN = 'RETURN',
  BOTH = 'BOTH',
}

export type PassengerSeatsProps = {
  handleVisible: (value: boolean) => void
  anonymousPassengers?: number
  passengerList?: PassengerSeatNumbers[]
  setActiveDevolution(value: boolean): void
  activeDevolution: boolean
  devolutionLoading: boolean
  makeTicketsDevolution(tickets: number[]): void
  travelById(): void
}

export type PassengerSeatNumbers = {
  passenger: Passenger | string
  goneNumber?: number
  returnNumber?: number
  goneTicket?: number
  returnTicket?: number
  departure?: number
  departureTime?: number
  returnDeparture?: number
  returnDepartureTime?: number
  returnTicketStatus?: string
  goneTicketStatus?: string
}

export type ProccessSeat = {
  target: string
}

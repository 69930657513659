import React, { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { message, Modal } from 'antd'

import { DELETE_COMPANY_USER } from '../../../queries'
import { MyUserPageContext } from '../Provider'
import { DeleteUserModalProps } from '../types'

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({ refetch }) => {
  const {
    deleteVisible,
    setDeleteVisible,
    selectedUser,
    setVisible,
  } = useContext(MyUserPageContext)
  const [deleteCompanyUser, { loading }] = useMutation(DELETE_COMPANY_USER, {
    onCompleted: () => {
      message.success('Usuario eliminado exitosamente.')
    },
    onError: error =>
      message.error(`Ha ocurrido un error al eliminar usuario. ${error}`),
  })
  

  const handleDelete = async () => {
    try {
      await deleteCompanyUser({ variables: { userId: selectedUser._id } })
      await refetch()
      setDeleteVisible(false)
      setVisible(false)
    } catch (e) {
      message.error(e)
    }
  }

  return (
    <div>
      <Modal
        title="Eliminar usuario"
        visible={deleteVisible}
        onOk={handleDelete}
        okText="Eliminar"
        cancelText="Cancelar"
        confirmLoading={loading}
        onCancel={() => setDeleteVisible(false)}>
        <p> Esta seguro de eliminar este usuario?</p>
      </Modal>
    </div>
  )
}

export default DeleteUserModal

import React from 'react'
import { Col, Row } from 'antd'
import { navigate } from 'gatsby'

import BackArrow from '../../../components/BackArrow'

import styles from './styles.module.css'

const SubHeader: React.FC = () => {
  const handleBackArrow = () => {
    navigate(-1)
  }

  return (
    <Row className={styles.container} justify="space-between" align="middle">
      <Col>
        <BackArrow clickAction={handleBackArrow} whiteColor={true} />
      </Col>
      <Col className={styles.title}>Detalle de reclamo/sugerencia</Col>
      <Col />
    </Row>
  )
}

export default SubHeader

type searchType = {
  departureCity: string
  departureDate: string
  destinationCity: string
  destiny: string
  numberOfSeats: string
  origin: string
  returnDate?: string
}

export default (search: searchType): boolean => {
  const {
    departureCity,
    departureDate,
    destinationCity,
    destiny,
    numberOfSeats,
    origin,
  } = search

  if (
    !origin ||
    !destiny ||
    !destinationCity ||
    !departureCity ||
    !departureDate ||
    !numberOfSeats
  )
    return false

  return true
}

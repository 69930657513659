import React, { FC, useContext } from 'react'
import { Button, Col, Divider, Row } from 'antd'

import { SessionContext } from '../../services/session'

import { StageButtonProps } from './props'

import styles from './styles.module.css'

const StageButtons: FC<StageButtonProps> = ({
  loading,
  onCancelClick,
  stage,
  saveAndAddPassengers,
}) => {
  const { user } = useContext(SessionContext)
  const evalButtonText = (role: string) => {
    return role === 'CCC' || role === 'ADMIN_CCC' || role === 'MULTI_RUT_ADMIN'
      ? 'Asignar Grupo'
      : 'Asignar Pasajeros'
  }

  return (
    <>
      <Divider />

      <Col span={24}>
        <Row align="middle" className={styles.drawerButtonRow} justify="center">
          <Col span={12}>
            <Button
              block
              className={styles.cancelButton}
              onClick={onCancelClick}>
              Cancelar
            </Button>
          </Col>
          <Col span={12}>
            {stage < 3 ? (
              <Button
                block
                className={styles.continueButton}
                disabled={stage !== 2}
                loading={loading}
                onClick={saveAndAddPassengers}>
                {evalButtonText(user.role)}
              </Button>
            ) : (
              <Button
                block
                className={styles.continueButton}
                onClick={onCancelClick}>
                Continuar
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </>
  )
}

export default StageButtons

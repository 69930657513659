import React, { useEffect, useState } from 'react'
import { useSubscription } from '@apollo/client'
import { Col, Divider, Row, Tag } from 'antd'
import moment from 'moment'

import {
  displayHelpRequestStatus,
  HelpRequestStatusEnum,
  RolesEnum,
} from '../../../helpers/enums'
import { getUser, tokenInfo } from '../../../services/auth'
import { HelpRequest } from '../../../types/HelpRequest.type'
import AttachmentViewer from '../AttachmentViewer'
import CreditLineDocumentsViewer from '../CreditLineDocumentsViewer'
import { MESSAGE_ADDED } from '../graphql'

import { Props } from './types'

import styles from './styles.module.css'

const infoProp = (title: string, content: string) => {
  return (
    <Row>
      <Col>
        <div className={styles.titleText}>{title}</div>
        <div className={styles.requestText}>{content}</div>
      </Col>
    </Row>
  )
}

const HelpRequestInfo: React.FC<Props> = ({ helpRequest, loading }) => {
  const [status, setStatus] = useState('')
  const isSolved = () => helpRequest?.status === HelpRequestStatusEnum.FINISHED

  const { data } = useSubscription(MESSAGE_ADDED, {
    variables: {
      id: helpRequest._id,
    },
  })

  const { user } = tokenInfo(getUser() ?? '')

  const renderNotCreritLineInfo = (helpRequest: HelpRequest) => {
    return (
      <>
        {infoProp(
          'Mi requerimiento es:',
          helpRequest.requestType?.title ?? '---',
        )}
        {infoProp(
          'El motivo de mi requerimiento es:',
          helpRequest.requestReason?.title ?? '---',
        )}
        {infoProp(
          'La mejor opción que representa el motivo de mi requerimiento es:',
          helpRequest.requestReasonOption?.title ?? '--',
        )}
        {infoProp('Num. boleto del viaje', helpRequest.boletusNumber ?? '---')}
        {infoProp(
          'Núm. transacc. del viaje (opcional)',
          helpRequest.transactionNumber ?? '---',
        )}
        {infoProp('Detalles de la situación', helpRequest.body ?? '---')}
      </>
    )
  }

  const renderCreditLineInfo = (helpRequest: HelpRequest) => {
    return (
      <>
        {infoProp('Razón social:', helpRequest.company?.name ?? '---')}
        {infoProp(
          'Rut de la Empresa:',
          `${helpRequest.company?.rut}-${helpRequest.company.dv}`,
        )}
        <CreditLineDocumentsViewer company={helpRequest.company} />
      </>
    )
  }

  useEffect(() => {
    const messageUserId = data?.messageAdded?.user?._id
    if (messageUserId && messageUserId !== user.sub)
      if (status !== HelpRequestStatusEnum.IN_PROGRESS.toString())
        setStatus(HelpRequestStatusEnum.IN_PROGRESS)
  }, [data])

  useEffect(() => {
    setStatus(helpRequest?.status ?? '')
  }, [helpRequest])

  return helpRequest ? (
    <>
      <Row align="middle" justify="space-between">
        <Col md={6}>
          <div className={styles.companyText}>
            {helpRequest.user.company.name}
          </div>
          <div className={styles.titleText}>{helpRequest.ticketNumber}</div>
        </Col>
        <Col md={4}>
          {helpRequest.executive ? (
            <>
              <div className={styles.executiveText}>Ejecutivo asignado</div>
              <div
                className={
                  styles.titleText
                }>{`${helpRequest.executive.firstName} ${helpRequest.executive.lastName}`}</div>
            </>
          ) : (
            <div>'Ningún ejecutivo asignado'</div>
          )}
        </Col>
        <Col md={4}>
          {helpRequest.executive ? (
            <>
              <div className={styles.executiveText}>Solicitante</div>
              <div
                className={
                  styles.titleText
                }>{`${helpRequest.user.firstName} ${helpRequest.user.lastName}`}</div>
            </>
          ) : (
            <div>'Ningún ejecutivo asignado'</div>
          )}
        </Col>
        <Col md={3}>
          <Tag className={isSolved() ? styles.closedTag : styles.statusTag}>
            {displayHelpRequestStatus(status as HelpRequestStatusEnum)}
          </Tag>
        </Col>
        <Col
          md={5}
          className={styles.createdText}>
          Recibida: {moment(helpRequest.createdAt).locale('es').format('LLLL')}
        </Col>
      </Row>
      <Divider />
      {helpRequest.isCreditLine
        ? renderCreditLineInfo(helpRequest)
        : renderNotCreritLineInfo(helpRequest)}
      <AttachmentViewer helpRequest={helpRequest} loading={loading} />
    </>
  ) : (
    <>no data</>
  )
}

export default HelpRequestInfo

import React, { FC, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Form, Input, Row, Select } from 'antd'

import {
  estimatedAmountSelectContent,
  estimatedPassengerSelectContent,
} from '../../../helpers/companyRegisterSelectContent'
import { formatRut } from '../../../helpers/rutValidators'
import { GET_ADDRESSES_QUERY } from '../../../queries'
import { Address } from '../../../types/address.type'
import { CreditLineRequestFormProps } from '../../../types/component-types/CreditLineRequestDrawer'
import UploadForm from '../UploadForm'

import styles from './style.module.css'

const { Item } = Form
const { Option } = Select

// TODO: Refactor this form.

const CreditLineRequestForm: FC<CreditLineRequestFormProps> = ({
  company,
  companyUpdateForm,
  uploadForm,
  stage,
  setDocuments,
}) => {
  const {
    name,
    rut,
    dv,
    estimatedAmount,
    estimatedPassengers,
    commercialActivity,
    country,
    city,
    commune,
    street,
    streetNumber,
  } = company
  const [selectedCity, setSelectedCity] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const { loading: getAddressesLoading, data: getAddressesData } = useQuery(
    GET_ADDRESSES_QUERY,
  )
  const [addressObject, setAddressObject] = useState([] as Address[])

  const addressObjectByCountry = addressObject.find(
    object => object.countryName === selectedCountry,
  )

  const addressObjectByCity = addressObjectByCountry?.regions?.find(
    object => object.name === selectedCity,
  )

  useEffect(() => {
    if (!getAddressesLoading && getAddressesData) {
      setAddressObject(getAddressesData.getAddresses)
      setSelectedCountry('Chile')
    }
  }, [getAddressesData, getAddressesLoading])

  return (
    <Row className={styles.drawerMainRow} justify="center">
      <Col className={styles.mainContainer} xs={24}>
        <p className={styles.formDescription}>
          Cuéntanos un poco respecto a tu empresa para poder brindarte una
          experiencia ideal.{' '}
        </p>
        {stage === 0 ? (
          <Form form={companyUpdateForm} layout="vertical">
            <Row>
              <Col xs={24}>
                <Item label="Razón social" name="name" initialValue={name}>
                  <Input
                    className={styles.updateCompanyInput}
                    placeholder="Ejemplo: mi razón social"
                  />
                </Item>
                <Item
                  name="commercialActivity"
                  label="Giro de la empresa (Opcional)"
                  initialValue={commercialActivity}>
                  <Input
                    className={styles.updateCompanyInput}
                    placeholder="Ejemplo: mi giro comercial"
                  />
                </Item>
                <Item
                  label="¿Cuántos pasajeros estimas transportar mensualmente?(opcional)"
                  name="estimatedPassengers"
                  initialValue={
                    estimatedPassengers
                      ? `${estimatedPassengers.from}-${
                          estimatedPassengers.to ?? 'x'
                        }`
                      : ''
                  }>
                  <Select
                    size="large"
                    className={styles.updateCompanySelect}
                    placeholder="Seleccione una opción"
                    allowClear>
                    {estimatedPassengerSelectContent.map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.content}
                      </Option>
                    ))}
                  </Select>
                </Item>
                <Item
                  name="estimatedAmount"
                  label="Estimación de compra mensual en viajes (opcional)"
                  initialValue={
                    estimatedAmount?.from
                      ? `$${estimatedAmount.from}-$${estimatedAmount.to ?? 'x'}`
                      : ''
                  }>
                  <Select
                    className={styles.updateCompanySelect}
                    size="large"
                    placeholder="Seleccione una opción"
                    allowClear>
                    {estimatedAmountSelectContent.map(option => (
                      <Option key={option.value} value={option.value}>
                        {option.content}
                      </Option>
                    ))}
                  </Select>
                </Item>
                <p className={styles.companyLocationTitle}>
                  Ubicación de la empresa
                </p>
                {country ? (
                  <Item
                    name="country"
                    label="País"
                    normalize={countryName => {
                      setSelectedCountry(countryName)
                      return countryName
                    }}
                    initialValue={country}>
                    <Select
                      className={styles.updateCompanySelect}
                      size="large"
                      placeholder="Seleccione País"
                      disabled>
                      {addressObject?.map(country => (
                        <Option
                          key={country.countryName}
                          value={country.countryName}>
                          {country.countryName}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                ) : (
                  <Item
                    name="country"
                    label="País"
                    normalize={countryName => {
                      setSelectedCountry(countryName)
                      return countryName
                    }}
                    rules={[
                      { required: true, message: 'Este campo es obligatorio' },
                    ]}>
                    <Select
                      className={styles.updateCompanySelect}
                      size="large"
                      placeholder="Seleccione País">
                      {addressObject
                        ?.filter(country => country.countryName === 'Chile')
                        .map(country => (
                          <Option
                            key={country.countryName}
                            value={country.countryName}>
                            {country.countryName}
                          </Option>
                        ))}
                    </Select>
                  </Item>
                )}
                <Item
                  name="rut"
                  label="RUT de la empresa"
                  normalize={rut => {
                    return formatRut(rut)
                  }}
                  initialValue={`${rut}-${dv}`}>
                  <Input
                    className={styles.updateCompanyInput}
                    placeholder="Ejemplo: 76.325.945-k"
                    disabled
                  />
                </Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} lg={11}>
                <Item
                  name="city"
                  label="Región"
                  normalize={city => {
                    setSelectedCity(city)
                    return city
                  }}
                  rules={[
                    { required: true, message: 'Este campo es obligatorio' },
                  ]}
                  initialValue={city}>
                  <Select
                    className={styles.updateCompanySelect}
                    size="large"
                    placeholder="Seleccione Región">
                    {addressObjectByCountry?.regions?.map(city => (
                      <Option key={city.name} value={city.name}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col xs={24} lg={11}>
                <Item
                  name="commune"
                  initialValue={commune}
                  label="Comuna"
                  rules={[
                    { required: true, message: 'Este campo es obligatorio' },
                  ]}>
                  <Select
                    className={styles.updateCompanySelect}
                    size="large"
                    placeholder="Seleccione Comuna">
                    {addressObjectByCity?.communes?.map(commune => (
                      <Option key={commune} value={commune}>
                        {commune}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} lg={11}>
                <Item
                  name="street"
                  label="Calle"
                  initialValue={street}
                  rules={[
                    { required: true, message: 'Este campo es obligatorio' },
                  ]}>
                  <Input
                    className={styles.updateCompanyInput}
                    placeholder="Ejemplo: Calle x"
                  />
                </Item>
              </Col>
              <Col xs={24} lg={11}>
                <Item
                  name="streetNumber"
                  label="Número"
                  rules={[
                    { required: true, message: 'Este campo es obligatorio' },
                  ]}
                  initialValue={streetNumber}>
                  <Input
                    className={styles.updateCompanyInput}
                    placeholder="Ejemplo: 123"
                  />
                </Item>
              </Col>
            </Row>
          </Form>
        ) : (
          <UploadForm form={uploadForm} setDocuments={setDocuments} />
        )}
      </Col>
    </Row>
  )
}

export default CreditLineRequestForm

import React, { FC, useState } from 'react'
import { Drawer } from 'antd'

import { TravelFilterType } from '../../types'
import Filter from '../filter'

import styles from './styles.module.css'

type PropTypes = {
  onApplyFilter(filter: TravelFilterType): void
}

const FilterDrawer: FC<PropTypes> = ({ onApplyFilter }) => {
  const [visible, setVisible] = useState<boolean>(false)

  const url = window?.location?.href?.includes('/app/viajes/buscar')

  const renderOnPress = () => {
    if (!url) return <span />
    return (
      <div className={styles.filterPress} onClick={() => setVisible(true)}>
        Filtrar
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderOnPress()}
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        title="Filtro de viaje"
        placement="right"
        width={window?.innerWidth > 900 ? 400 : window?.innerWidth - 10}>
        <Filter onApplyFilter={onApplyFilter} setVisible={setVisible} />
      </Drawer>
    </div>
  )
}

export default FilterDrawer
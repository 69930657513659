import { gql } from '@apollo/client'

export const MY_COST_CENTERS = gql`
  query @api(name: "users") {
    myCostCenters {
      _id
      name
      users {
        _id
        email
      }
    }
  }
`

import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, Col, DatePicker, InputNumber, Row, Select } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment, { Moment } from 'moment'
import { isNil } from 'ramda'

import LoadingSpinner from '../../../../components/LoadingSpinner'
import { normalizeText } from '../../../../helpers/lowercaseStringButFirst'
import { getCityName } from '../../../../helpers/travel-page'
import { useEventSave } from '../../../../hooks/analytics.hook'
import { AutoCompleteOption } from '../../../../types/page-types/home-page.types'
import { TravelPageContext } from '../../../travel-page/Provider'
import { NewTripTabProps } from '../../types'

import styles from './styles.module.css'

const { Option } = Select

const OneWay: FC<NewTripTabProps> = ({
  handleClick,
  loading,
  loginLoading,
}) => {
  const {
    options: { destinies, origins, routes },
    search,
    setHasReturn,
    setSearch,
  } = useContext(TravelPageContext)
  const {
    origin,
    destiny,
    departureCity,
    departureDate,
    destinationCity,
    numberOfSeats,
  } = search

  const [departureMoment, setDepartureMoment] = useState<Moment | null>(null)

  const onTravelSearch = () => {
    useEventSave('busqueda_de_viajes', {
      rutas_consultadas: `${origin}-${destiny}`,
    })
    handleClick()
  }

  useEffect(() => {
    const today = moment(new Date(), 'DD-MM-YYYY')
    search.departureDate &&
      setDepartureMoment(
        moment(search.departureDate, 'DD-MM-YYYY') >= today
          ? moment(search.departureDate, 'DD-MM-YYYY')
          : null,
      )
  }, [])

  const isButtonDisabled =
    isNil(departureDate) || isNil(destinationCity) || isNil(departureCity)

  return loading ? (
    <LoadingSpinner size="50px" />
  ) : (
    <Row className={styles.row} gutter={16}>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 1 }}
        sm={8}
        md={8}
        lg={6}>
        <p className={styles.labelFont}>Origen</p>
        <Select
          allowClear
          className={styles.input}
          placeholder={'¿Desde dónde viajas?'}
          onChange={value =>
            setSearch({
              ...search,
              departureCity: (value as string) ?? '',
              origin:
                getCityName(routes, value as string)?.originCityName ?? '',
            })
          }
          filterOption={(text, option) =>
            normalizeText(option?.children).includes(normalizeText(text))
          }
          defaultValue={search.origin === '' ? undefined : search.origin}
          optionFilterProp="children"
          showArrow={false}
          showSearch
          size="large">
          {origins.map(({ id, name }: AutoCompleteOption) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 3 }}
        sm={8}
        md={8}
        lg={6}>
        <p className={styles.labelFont}>Fecha de ida</p>
        <DatePicker
          allowClear={false}
          className={styles.datePicker}
          disabledDate={current => current < moment.utc().startOf('day')}
          inputReadOnly
          locale={locale}
          onChange={(date: Moment | null, dateString: string) => {
            setSearch({ ...search, departureDate: dateString })
            setDepartureMoment(date)
          }}
          placeholder="Fecha de ida"
          size="large"
          format="DD-MM-YYYY"
          value={departureMoment}
        />
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 2 }}
        sm={8}
        md={8}
        lg={6}>
        <p className={styles.labelFont}>Destino</p>
        <Select
          allowClear
          notFoundContent={<p>Selecciona un viaje de ida primero..</p>}
          className={styles.input}
          placeholder={'¿A dónde vas?'}
          onChange={value =>
            setSearch({
              ...search,
              destinationCity: (value as string) ?? '',
              destiny:
                getCityName(routes, value as string, 'destiny')
                  ?.destinationCityName ?? '',
            })
          }
          filterOption={(text, option) =>
            normalizeText(option?.children).includes(normalizeText(text))
          }
          defaultValue={search.destiny === '' ? undefined : search.destiny}
          optionFilterProp="children"
          showArrow={false}
          showSearch
          size="large">
          {destinies.map(({ id, name }: AutoCompleteOption) => (
            <Option key={id} value={id}>
              {name}
            </Option>
          ))}
        </Select>
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 4 }}
        sm={8}
        md={8}
        lg={2}>
        <p className={styles.labelFont}>Pasajeros</p>
        <InputNumber
          className={styles.inputNumber}
          onChange={value =>
            setSearch({
              ...search,
              numberOfSeats: (value as string) ?? '',
            })
          }
          max={45}
          min={1}
          size={'large'}
          value={!isNaN(parseInt(numberOfSeats)) ? parseInt(numberOfSeats) : 1}
        />
      </Col>
      <Col
        className={styles.colItem}
        xs={{ span: 24, order: 5 }}
        sm={8}
        md={8}
        lg={4}>
        <Button
          className={isButtonDisabled ? styles.disabledButton : styles.button}
          disabled={isButtonDisabled}
          loading={loginLoading}
          onClick={() => {
            setSearch({ ...search, returnDate: '' })
            setHasReturn(false)
            onTravelSearch()
          }}>
          Buscar viajes
        </Button>
      </Col>
    </Row>
  )
}

export default OneWay

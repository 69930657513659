import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import { Badge, Button, Card, Col, Divider, message, Modal, Row } from 'antd'
import moment from 'moment'

import {
  BiobioLogo,
  CondorLogo,
  JacLogo,
  TurbusLogo,
} from '../../../../assets/operators'
import {
  getTimeDiff,
  getTravelInfo,
} from '../../../../helpers/travel-page/dates.helper'

import styles from './styles.module.css'

const dateFormat = 'DD/MM/YYYY HH:mm'
const dateFormatDay = 'DD/MM/YYYY'

import { useQuery } from '@apollo/client'

import { Sanidad, Usb } from '../../../../assets'
import TravelStopsDetails from '../../../../components/TravelStopsDetails'
import { lowerCaseStringButFirstLetter as toLowerCase } from '../../../../helpers/lowercaseStringButFirst'
import { numberWithSeparator } from '../../../../helpers/number.helper'
import { GET_PROFILE_INFO } from '../../../../queries/profile'
import { User } from '../../../../types/user.type'
import { TravelPageContext } from '../../Provider'
import { TravelDisplayProps } from '../../types'

import { biobioStyles, condorStyles, jacStyles } from './logoStyles'
import { SeatingGroup } from './SeatingGroup'
import { initTravelOptions, typeSeat } from './typeSeat'

const terminal = (at: string, name: string) => (
  <div>
    <div className={`${styles.timeFont} ${styles.centered}`}>
      {moment(at, dateFormat).format('HH:mm')}
    </div>
    <div className={`${styles.cityFont} ${styles.centered}`}>
      {toLowerCase(name)}
    </div>
  </div>
)

const TravelDisplay: React.FC<TravelDisplayProps> = ({
  travelGroup,
  onSelectButton,
}) => {
  const [user, setUser] = useState<User>()

  useQuery(GET_PROFILE_INFO, {
    onCompleted: data => {
      setUser(data.myProfile)
    },
  })
  // useEffect(() => {   setSelectedTravel(travelGroup[0]); }, [travelGroup]);
  const [selectedTravel, setSelectedTravel] = useState(travelGroup[0])
  const [width, setWidth] = useState<number>(0)
  const [stopsModal, setStopsModal] = useState(false)
  const { search } = useContext(TravelPageContext)
  const { origin, destiny } = search
  const firstTravel = travelGroup[0]
 
  const fd_at = moment(firstTravel.departs_at, dateFormatDay).format('DD/MM/YYYY')
  

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

    const renderTravelDate =()=> ( 
      <Col xs={1} sm={2}> 
          {
          <Row justify="space-between" align={width < 100 ? 'top' : 'middle'}>
            <div className={styles.centered}  >
             <span className={styles.stopsFont}>
               Fecha Salida 
            </span>
            <Divider style={{ margin: '5px 0' }} />
            <span className={styles.cityFont}>             
              { 
              fd_at              
              }                
            </span>
            </div>
          </Row>
          }
      </Col>);

  const renderTravelInfo = () => (
    <Col xs={24} md={8}>
      <Row justify="space-between" align={width < 600 ? 'top' : 'middle'}>
     
        <Col xs={8} sm={8}>
          {terminal(
            firstTravel.departs_at,
            firstTravel.departure_terminal_name,
          )}
        </Col>
        <Col xs={6} sm={6}>
          <div className={styles.centered} onClick={() => setStopsModal(true)}>
            <span className={styles.stopsFont}>
              {firstTravel.number_of_intermediate_sections} Parada(s)
            </span>
            <Divider style={{ margin: '4px 0' }} />
            <span className={styles.cityFont}>
              {getTimeDiff(
                moment(firstTravel.departs_at, dateFormat),
                moment(firstTravel.arrives_at, dateFormat),
              )}
            </span>
          </div>
        </Col>
        <Col xs={8} sm={8}>
          {terminal(
            firstTravel.arrives_at,
            firstTravel.destination_terminal_name,
          )}
        </Col>
      </Row>
    </Col>
  )

  const renderOperator = () => (
    <Col xs={8} sm={2}>
      {firstTravel.bus_operator_name === 'TURBUS' ? <TurbusLogo /> : null}
      {firstTravel.bus_operator_name === 'CONDOR BUS' ? (
        <img src={CondorLogo} alt="condorLogo" style={condorStyles} />
      ) : null}
      {firstTravel.bus_operator_name === 'JAC LTDA.' ? (
        <img src={JacLogo} alt="jacLogo" style={jacStyles} />
      ) : null}
      {firstTravel.bus_operator_name === 'BUSES BIO-BIO' ? (
        <img src={BiobioLogo} alt="biobioLogo" style={biobioStyles} />
      ) : null}
      <Divider type="vertical" className={styles.divider} />
    </Col>
  )

  const onSelectButtonHandler = () => {
    if (
      user?.role.includes('ADMIN') ||
      (!user?.role.includes('ADMIN') &&
        user?.seatsAllowed?.includes(selectedTravel.seat_class))
    ){
      onSelectButton(selectedTravel)  
    }      
    else
      message.warning(
        `No tienes permiso para comprar el tipo ${selectedTravel.seat_class}`,
      )
  }

  const handleModalCancel = () => setStopsModal(false)

  const renderModal = () => (
    <Modal
      title="Detalle paradas de viaje"
      onCancel={handleModalCancel}
      visible={stopsModal}
      width={400}
      footer={null}>
      <TravelStopsDetails
        sections={selectedTravel.sections}
        departure_terminal_name={selectedTravel.departure_terminal_name}
        destination_terminal_name={selectedTravel.destination_terminal_name}
        arrives_at={selectedTravel.arrives_at}
        departs_at={selectedTravel.departs_at}
        bus_operator_name={selectedTravel.bus_operator_name}
        originCity={origin}
        destinyCity={destiny}
      />
      <Divider />
      <Row align="middle" justify="space-around">
        <Col span={12}>
          <Row align="middle" justify="center">
            <Col>
              <Usb className={styles.footerIconsPadding} />
            </Col>
            <Col>
              <div className={styles.iconsFont}>USB</div>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row align="middle" justify="center">
            <Col>
              <Sanidad className={styles.footerIconsPadding} />
            </Col>
            <Col>
              <div className={styles.iconsFont}>
                Sanitación <br />
                COVID-20
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
  return (
    <>
      <Row justify="space-between" align="middle">
        <Col span={24} className={styles.container}>
          <Card className={styles.card}>
            <Row align="middle">
              {renderOperator()}
              {renderTravelDate()}
              {renderTravelInfo()}
              <Col xs={24} md={7} className={styles.centered}>
                <Row align="middle">
                  {initTravelOptions.map((travel, index) => (
                    <Col
                      xs={8}
                      sm={8}
                      key={index}
                      className={`${
                        !index && width < 768 ? '' : styles.divitionContainer
                      } ${
                        travel.seat_class !==
                        typeSeat[selectedTravel.seat_class?.toLowerCase()]
                          ? styles.disabled
                          : styles.travelHover
                      }`}>
                      {
                        <SeatingGroup
                          travel={travel}
                          travelGroup={travelGroup}
                          setSelectedTravel={setSelectedTravel}
                          active={
                            travel.seat_class ===
                            typeSeat[selectedTravel.seat_class?.toLowerCase()]
                          }
                          user={user as User}
                        />
                      }
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col
                xs={24}
                md={4}
                className={
                  width < 768
                    ? styles.divitionContainerTwo
                    : styles.divitionContainer
                }>
                {selectedTravel.discount_price ? (
                  <Badge
                    count="Tarifa plana"
                    style={{ backgroundColor: '#52c41a' }}
                  />
                ) : null}
                <div className={styles.priceContent}>
                  <span className={styles.priceLabel}>Precio desde:</span>
                  <span className={styles.priceValue}>
                    $
                    {numberWithSeparator(
                      selectedTravel.discount_price ?? selectedTravel.price,
                    )}{' '}
                  </span>
                </div>
                <div>
                  <Button
                    className={styles.selectButton}
                    onClick={onSelectButtonHandler}>
                    Seleccionar
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {renderModal()}
    </>
  )
}

export default TravelDisplay

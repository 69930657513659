import React, { FC } from 'react'
import { Badge, Checkbox, Col, Row } from 'antd'
import moment from 'moment'

import {
  dateToNumber,
  timeToNumber,
} from '../../../../../helpers/dateLongFormat'
import { frontTicketStatus, TicketStatus } from '../../../../../types/enums'
import { PassengerRowProps } from '../../../types'

import styles from './styles.module.css'

const PassengerRow: FC<PassengerRowProps> = ({
  value,
  activeDevolution,
  handleClick,
}) => {
  const canBeReturned = (date?: number, time?: number): boolean => {
    if (!date || !time) return false

    const now = dateToNumber(new Date())
    const timeNow = timeToNumber(new Date())
    const diff = now - date

    return diff < 0 || (diff === 0 && time > timeNow)
  }
  const genOnBoardStatus = (status: string) => {
    switch (status) {
      case TicketStatus.SIN_EMBARQUE:
        return (
          <span className={styles.notOnBoardText}>
            <Badge status="default" />
            {frontTicketStatus.SIN_EMBARQUE}
          </span>
        )
      case TicketStatus.EMBARCADO:
        return (
          <span className={styles.onBoardText}>
            <Badge status="success" />
            {frontTicketStatus.EMBARCADO}
          </span>
        )
      case TicketStatus.NO_EMBARCADO:
        return (
          <span className={styles.notOnBoardText}>
            <Badge status="default" />
            {frontTicketStatus.NO_EMBARCADO}
          </span>
        )
      default:
        return ''
    }
  }

  const isAfter = (date: number, time: number) => {
    const currentDate = parseInt(moment.utc().format('YYYYMMDD'))
    const currentTime = parseInt(moment.utc().format('HHmm'))

    if (date > currentDate) return true
    if (date === currentDate && time > currentTime) return true

    return false
  }

  return (
    <>
      {/* Row for "Gone" information */}
      <Row className={styles.namesList} justify="space-between" align="middle">
        <Col span={8}>
          <div>
            {`${
              typeof value.passenger === 'string'
                ? value.passenger
                : `${value.passenger?.firstName} ${value.passenger?.fatherLastName}`
            }`}
          </div>
        </Col>
        <Col span={8}>
          <Row justify="space-between" align="middle">
            <Col span={24} className={styles.right}>
              {value?.goneNumber && value.goneNumber > 0
                ? `Ida: #${value.goneNumber}`
                : null}
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={24} className={styles.right}>
              {/* Display the goneTicket value */}
              {value?.goneTicket && (
                <div>
                  <span> <strong>{value.goneTicket}</strong></span>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          {/* Checkbox for "Gone" */}
          <Row justify="center" align="middle">
            <Col span={12} className={styles.centered}>
              {activeDevolution ? (
                value?.goneTicket ? (
                  <div>
                    {canBeReturned(value.departure, value.departureTime) && (
                      <Checkbox
                        onClick={() => handleClick(value.goneTicket as number)}
                      />
                    )}
                  </div>
                ) : null
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
  
      {/* Row for "Return" information */}
      <Row className={styles.namesList} justify="space-between" align="middle">
        <Col span={8}></Col>
        <Col span={8}>
          <Row justify="space-between" align="middle">
            <Col span={24} className={styles.right}>
              {value?.returnNumber && value.returnNumber > 0 ? (
                <span>Vuelta: #{value.returnNumber} </span>
              ) : null}
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col span={24} className={styles.right}>
              {/* Display the returnTicket value */}
              {value?.returnTicket && (
                <div>
                  <span> <strong>{value.returnTicket}</strong> </span>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          {/* Checkbox for "Return" */}
          <Row justify="center" align="middle">
            <Col span={12} className={styles.centered}>
              {activeDevolution ? (
                value?.returnTicket ? (
                  <div>
                    {canBeReturned(
                      value.returnDeparture,
                      value.returnDepartureTime,
                    ) && (
                      <Checkbox
                        onClick={() => handleClick(value.returnTicket as number)}
                      />
                    )}
                  </div>
                ) : null
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
  
  
    
  
}


export default PassengerRow

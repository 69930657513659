import React from 'react'
import { Row } from 'antd'
import { isEmpty } from 'ramda'

import { FloorProps, Seat } from '../../../types'

import SingleSeat from './SingleSeat'

import styles from './styles.module.css'

const Floor: React.FC<FloorProps> = ({ seats, isGone }) => {
  const hasSeats = seats?.length > 0
  const cols = hasSeats ? new Set(seats.map(({ column }) => column)) : []
  const totalCols = hasSeats ? Math.max(...cols) : 0
  const rows = hasSeats ? new Set(seats.map(({ row }) => row)) : []
  const totalRows = hasSeats ? Math.max(...rows) : 0
  const initialSeatMap = hasSeats
    ? [...Array(totalRows).keys()].map(() => {
        return [...Array(totalCols).keys()].map(() => {
          return {}
        })
      })
    : []

  seats?.forEach(seat => {
    initialSeatMap[seat.row - 1][seat.column - 1] = seat
  })

  const prices = seats?.map(({ price }) => price)
  const maxPrice = Math.max(...prices)
  const minPrice = Math.min(...prices)
  // console.log(initialSeatMap)
  const createId = (i: string): string => {
    return `${new Date().getTime()}${i}`
  }
  return (
    <>
      <div style={{ marginTop: '7%' }} />
      {initialSeatMap.map((row, i) => (
        <Row
          justify="space-around"
          align="middle"
          className={styles.seatRow}
          key={createId(`${i}`)}>
          {row.map((seat, j) =>
            isEmpty(seat) ? (
              j === 2 ? (
                <div
                  className={styles.positionFont}
                  key={createId(`${i}${j}`)}
                />
              ) : (
                <div className={styles.empty} key={createId(`${i}${j}`)} />
              )
            ) : (
              <SingleSeat
                key={createId(`${j}`)}
                minPrice={minPrice}
                maxPrice={maxPrice}
                seat={seat as Seat}
                isGone={isGone}
              />
            ),
          )}
        </Row>
      ))}
    </>
  )
}

export default Floor

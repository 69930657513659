import React, { FC } from 'react'
import { PaperClipOutlined } from '@ant-design/icons'
import { Button } from 'antd'

import styles from './styles.module.css'

type Props = {
  onChange(files: FileList | null): void
  inputId: string
}

const FileInput: FC<Props> = ({ onChange, inputId, children }) => {
  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        id={inputId}
        onChange={e => onChange(e.target.files)}
      />
      <Button
        className={styles.attachButton}
        onClick={() => document.getElementById(inputId)?.click()}>
        <PaperClipOutlined />
        {children}
      </Button>
    </>
  )
}

export default FileInput

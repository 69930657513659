import React, { FC, useEffect, useState } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Input,
  Row,
  Select,
  Tooltip,
} from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment, { Moment } from 'moment'
import { isEmpty, uniq } from 'ramda'

import Loading from '../../../../components/LoadingSpinner'
import { formatRut } from '../../../../helpers/rutValidators'
import { getDestinies, getOriginsFilter } from '../../../../helpers/travel-page'
import { ALL_ROUTES } from '../../../../queries/home-page/queries'
import { FilterTypes } from '../types'

import { MY_COST_CENTERS } from './queries'

const { Option } = Select

import { CostCenter } from '../../../../types/costCenter.type'
import { AutoCompleteOptionFilter } from '../../../../types/page-types/home-page.types'

import styles from './styles.module.css'

type PropTypes = {
  onClose: () => void
  visible: boolean
  setFilterItems: (values: FilterTypes) => void
}

const DrawerFilter: FC<PropTypes> = ({ onClose, visible, setFilterItems }) => {
  const [allRoutes, setAllRoutes] = useState([])
  const [originRoutes, setOriginRoutes] = useState<AutoCompleteOptionFilter[]>(
    [],
  )
  const [destinyRoutes, setDestinyRoutes] = useState<any>([])
  const [allCostCenter, setAllCostCenter] = useState([])

  const [purchaseDate, setPurchaseDate] = useState<Moment | null>(null)
  const [departure, setDeparture] = useState<string | null>(null)
  const [departureDate, setDepartureDate] = useState<Moment | null>(null)
  const [destiny, setDestiny] = useState<string | null>(null)
  const [arrivalDate, setArrivalDate] = useState<Moment | null>(null)
  const [passengerName, setPassengerName] = useState<string | null>(null)
  const [passengerRut, setPassengerRut] = useState<string | null>(null)
  const [ticketId, setTicketId] = useState<string | null>(null)
  const [selectedCostCenter, setSelectedCostCenter] = useState<string | null>(
    null,
  )

  const [transaction, setTransactionValue] = useState<string | null>(null)

  const handleOrigin = (value: string) => setDeparture(value)
  const handleDestiny = (value: string) => setDestiny(value)
  const handleCostCenter = (value: string) => setSelectedCostCenter(value)

  const { loading } = useQuery(ALL_ROUTES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ allRoutes }) => {
      setAllRoutes(allRoutes)
      const options = getOriginsFilter(allRoutes)

      const filterOptions = uniq(options)
      setOriginRoutes(filterOptions)
    },
  })

  useQuery(MY_COST_CENTERS, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ myCostCenters }) => {
      const options = myCostCenters.map((costCenter: CostCenter) => {
        return {
          label: costCenter.name,
          value: costCenter._id,
        }
      })
      setAllCostCenter(options)
    },
  })

  const onFilter = () => {
    setFilterItems({
      purchaseDate: purchaseDate?.toDate() || null,
      userName: passengerName,
      costCenter: selectedCostCenter,
      departureCity: departure,
      destinationCity: destiny,
      departsAt: departureDate?.toDate() || null,
      arrivesAt: arrivalDate?.toDate() || null,
      passengerRut,
      ticketId,
      travelDateRange: null,
      transaction
    })
    onClose()
  }


  const restoreFilters = () => {
    setPurchaseDate(null)
    setDeparture(null)
    setDepartureDate(null)
    setDestiny(null)
    setArrivalDate(null)
    setPassengerName(null)
    setPassengerRut(null)
    setSelectedCostCenter(null)
    setTicketId(null)
    setTransactionValue(null)
    setFilterItems({} as FilterTypes)
    onClose()
  }

  useEffect(() => {
    if (isEmpty(allRoutes) || !departure) {
      setDestinyRoutes([])
      return
    }

    const destinies = getDestinies(allRoutes, departure)

    const options = destinies.map(route => {
      return {
        destinyCityId: route.id,
        label: route.name,
        value: route.name,
      }
    })

    setDestinyRoutes(options)
  }, [departure])

  const renderHeader = () => {
    return (
      <Row align="middle" justify="space-between">
        <Col>Filtrar</Col>
        <Col>
          <button className={styles.cleanButton} onClick={restoreFilters}>
            Restaurar filtros
          </button>
        </Col>
      </Row>
    )
  }

  return (
    <Drawer
      title={renderHeader()}
      placement="right"
      closable={false}
      onClose={onClose}
      visible={visible}
      width={window?.innerWidth > 600 ? 400 : window?.innerWidth - 10}>
      {loading ? (
        <div>
          {' '}
          Cargando información <Loading />
        </div>
      ) : (
        <div className={styles.filterDrawer}>
          <Row align="middle" justify="space-between">


            <Col className={styles.cols} xs={24}>
              Pasajeros{' '}
              <Tooltip title="Aqui puedes buscar a cualquier pasajero que tengas registrado">
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
            <Col className={styles.cols} xs={24} md={7}>
              Nombre
            </Col>
            <Col className={styles.cols} xs={24} md={16}>
              <Input
                placeholder="Nombre del pasajero"
                value={passengerName || ''}
                onChange={text => setPassengerName(text.target.value)}
              />
            </Col>
            <Col className={styles.cols} xs={24} md={7}>
              Rut
            </Col>
            <Col className={styles.cols} xs={24} md={16}>
              <Input
                placeholder="Rut del pasajero"
                value={passengerRut || ''}
                onChange={text => setPassengerRut(formatRut(text.target.value))}
              />
            </Col>
            <Col className={styles.cols} xs={24} md={7}>
              Número de boleto
            </Col>
            <Col className={styles.cols} xs={24} md={16}>
              <Input
                placeholder="Boleto"
                value={ticketId || ''}
                onChange={text => setTicketId(text.target.value)}
              />
            </Col>

            <Col className={styles.cols} xs={24} md={7}>
              Número de Transacción
            </Col>
            <Col className={styles.cols} xs={24} md={16}>
              <Input
                placeholder="Transaccion"
                value={transaction || ''}
                onChange={text => setTransactionValue(text.target.value)}
              />
            </Col>

          </Row>
          <div className={styles.buttonContainer}>
            <Button
              className={styles.submitButton}
              type="primary"
              onClick={onFilter}>
              Aplicar
            </Button>
            <Button
              className={styles.exitButton}
              type="primary"
              onClick={onClose}
              danger>
              Salir
            </Button>
          </div>
        </div>
      )}
    </Drawer>
  )
}

export default DrawerFilter

import { Passenger } from '../../screens/travel-page/types'
import {
  GoneReturnTravel,
  PassengerSeatNumbers,
} from '../../types/page-types/travel-details-page.types'

import {
  filterPassengerSeats,
  formatSeats,
  getPassengerSeatNumber,
} from './travel-detail'

type UpdatePassengerObj = {
  id: string
  seatGone?: number
  seatReturn?: number
}

export const constructPassengerSeats = (
  travel?: GoneReturnTravel,
): PassengerSeatNumbers[] => {
  if (travel) {
    const seats = formatSeats(travel)
    const passengerSeats = filterPassengerSeats(seats)
    const passengersSeatNumbers = getPassengerSeatNumber(passengerSeats)

    const goneSeatsWithoutPassenger = seats.filter(
      seat => !seat.passenger && seat.isGone,
    )

    const returnSeatsWithoutPassenger = seats.filter(
      seat => !seat.passenger && !seat.isGone,
    )

    const goneLength = goneSeatsWithoutPassenger.length
    const returnLength = returnSeatsWithoutPassenger.length

    const withoutPassengerSeatNumbers: PassengerSeatNumbers[] = []

    for (let i = 0; i < Math.max(goneLength, returnLength); i++) {
      const goneData =
        goneSeatsWithoutPassenger.length > i
          ? goneSeatsWithoutPassenger[i]
          : undefined
      const returnData =
        returnSeatsWithoutPassenger.length > i
          ? returnSeatsWithoutPassenger[i]
          : undefined

      withoutPassengerSeatNumbers.push({
        passenger: 'Sin asignar',
        goneNumber: goneData?.number,
        departure: goneData?.departure,
        departureTime: goneData?.departureTime,
        goneTicket: goneData?.ticketId,
        returnNumber: returnData?.number,
        returnDeparture: returnData?.departure,
        returnDepartureTime: returnData?.departureTime,
        returnTicket: returnData?.ticketId,
        returnTicketStatus: returnData?.ticketStatus,
        goneTicketStatus: goneData?.ticketStatus,
      })
    }

    passengersSeatNumbers.push(...withoutPassengerSeatNumbers)

    return passengersSeatNumbers
  } else return [] as PassengerSeatNumbers[]
}

export const checkAnonymousDrawer = (
  passengers: PassengerSeatNumbers[],
  selectedPassenger: Passenger[],
): {
  canCallQuery: UpdatePassengerObj[]
  newQuotas: PassengerSeatNumbers[]
  passengersToSubmit: UpdatePassengerObj[]
  rule: boolean
} => {
  let rule = false
  const newQuotas: PassengerSeatNumbers[] = JSON.parse(
    JSON.stringify(passengers),
  )

  const byStringPassenger = (passenger: PassengerSeatNumbers) =>
  passenger.passenger === 'Sin asignar'


  const anonymous = [...newQuotas].filter(byStringPassenger)

  const evalQuotaRule = (passenger: Passenger) =>
    newQuotas.some(
      ({ passenger: pasajero }) =>
        passenger._id === (pasajero as Passenger)._id,
    )

  const evalQuotaAssign = (passenger: Passenger, idx: number) => {
    if (anonymous.length > 0 && idx < anonymous.length) {
      const index = newQuotas.findIndex(byStringPassenger)
      newQuotas[index].passenger = passenger
    } else {
      newQuotas[idx].passenger = passenger
    }
  }

  selectedPassenger.forEach((passenger: Passenger, idx: number) => {
    rule = evalQuotaRule(passenger)
    evalQuotaAssign(passenger, idx)
  })

  const passengersToSubmit = [...JSON.parse(JSON.stringify(newQuotas))].map(
    ({ goneNumber, passenger, returnNumber }) => ({
      id: (passenger as Passenger)._id,
      seatGone: goneNumber,
      seatReturn: returnNumber,
    }),
  )

  const canCallQuery = [...passengersToSubmit].filter(
    ({ id }) => id === undefined,
  )

  return { canCallQuery, newQuotas, passengersToSubmit, rule }
}

import React, { FC, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useLocation } from '@reach/router'
import { Col, Collapse, Divider, message, Row } from 'antd'
import { navigate } from 'gatsby'

import { TICKETS_DEVOLUTION } from '../../.././queries'
import BackArrow from '../../../components/BackArrow'
import DetailDrawer from '../../../components/DetailDrawer'
import DetailForm from '../../../components/DetailDrawer/DetailForm'
import LoadingSpinner from '../../../components/LoadingSpinner'
import MobileTravelInfoBox from '../../../components/MobileTravelInfoBox'
import {
  constructPassengerSeats,
  formattedTravel,
} from '../../../helpers/my-travel-page'
import { getTravelInfo } from '../../../helpers/travel-page/dates.helper'
import { TRAVEL_BY_ID } from '../../../queries'
import {
  GoneReturnTravel,
  PassengerSeatNumbers,
  TravelDetailsPageProps,
  TravelType,
} from '../../../types/page-types/travel-details-page.types'
import { Transaction } from '../../travel-page/types'
import MyTravelsLayout from '../myTraveslLayout'

import AlertMessage from './Components/AlertMessage'
import DetailGroups from './Components/DetailDrawer/DetailGroups'
import UploadToDetail from './Components/DetailDrawer/UploadToDetail'
import DetailSubHeader from './Components/DetailSubHeader'
import PassengerSeats from './Components/PassengerSeats'
import TravelByIdDisplay from './Components/TravelByIdDisplay'
import TravelTransactionResume from './Components/TravelTransactionResume'

import styles from './styles.module.css'
const { Panel } = Collapse

const TravelDetailsPage: FC<TravelDetailsPageProps> = ({ bookingId }) => {
  const location: Record<string, any> = useLocation()

  const [anonymousPassengers, setAnonymousPassengers] = useState(0)
  const [activeDevolution, setActiveDevolution] = useState(false)
  const [passengerList, setPassengerList] = useState<PassengerSeatNumbers[]>([])
  const [step, setStep] = useState(0)
  const [visible, setVisible] = useState(false)
  const [ticketsDevolution, { loading: devolutionLoading }] = useMutation(
    TICKETS_DEVOLUTION,
  )

  const [transactionNumber, setTransactionNumber] = useState<string>('')


  const genPassengerSeats = (travel: GoneReturnTravel) => {
    const passengerSeatNumber = constructPassengerSeats(travel)
    setAnonymousPassengers(
      passengerSeatNumber.reduce(
        (acc, { passenger }) =>
          typeof passenger === 'string' ? (acc += 1) : acc,
        0,
      ),
    )
    setPassengerList(passengerSeatNumber)
  }

  
  const [travelById, { data, error, loading }] = useLazyQuery(TRAVEL_BY_ID, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ travelById }) => {
      if (travelById) genPassengerSeats(data.travelById)
      const transactionNumber = travelById.gone?.transaction || travelById.return?.transaction;
      setTransactionNumber(transactionNumber);
    },
    variables: {
      bookingId: parseInt(bookingId ?? ''),
    },
  })


  const defineTravelType = (travel: GoneReturnTravel) => {
    if (travel?.gone && !travel?.return) return TravelType.GONE
    if (travel?.return && !travel?.gone) return TravelType.RETURN
    return TravelType.BOTH
  }

  const handleDrawerButton = (value: number) => setStep(value)

  const handlePassengers = (value: PassengerSeatNumbers[]) => {
    setAnonymousPassengers(
      [...value.keys()].filter(idx => typeof value[idx].passenger === 'string')
        .length,
    )
    setPassengerList(value)
    travelById()
  }

  const handleVisible = (value: boolean) => setVisible(value)

  const travelType = defineTravelType(data?.travelById)
  const transaction = formattedTravel(data?.travelById)
  const {
    departureTripDuration,
    formattedDepartureDate,
    formattedReturnDate,
    returnTripDuration,
  } = getTravelInfo(transaction ? (transaction as Transaction) : undefined)


  const makeTicketsDevolution = async (tickets: number[]) => {
    try {
      const { data: devolutionData } = await ticketsDevolution({
        variables: {
          ticketsIds: tickets,
          bookingId: parseInt(bookingId ?? ''),
        },
      })

      const { errors, ticketsReturned } = devolutionData.ticketsDevolution

      if (errors?.length > 0) message.error(errors.join('\n'))

      if (ticketsReturned?.length > 0)
        navigate('/app/mis-viajes/devolucion', {
          state: {
            travelDevolutions: [
              data?.travelById?.gone,
              data?.travelById?.return,
            ],
            ticketsReturned,
          },
        })
    } catch (err) {
      message.error(err.message)
    }
  }

  useEffect(() => {
    travelById()
  }, [])

  return (
    <MyTravelsLayout>
      {loading ? (
        <LoadingSpinner size="50px" />
      ) : error ? (
        <AlertMessage />
      ) : (
        <Col className={styles.detailMainContainer} span={24}>
          <DetailDrawer
            handleVisible={handleVisible}
            handleDrawerButton={handleDrawerButton}
            step={step}
            visible={visible}>
            {step === 0 && (
              <DetailGroups
                anonymousPassengers={anonymousPassengers}
                bookingId={parseInt(bookingId ?? '0')}
                handleDrawerButton={handleDrawerButton}
                handleVisible={handleVisible}
                handlePassengers={handlePassengers}
                passengers={passengerList}
              />
            )}
            {step === 1 && (
              <DetailForm handleDrawerButton={handleDrawerButton} />
            )}
            {step === 2 && (
              <UploadToDetail
                anonymousPassengers={anonymousPassengers}
                bookingId={parseInt(bookingId ?? '0')}
                handleDrawerButton={handleDrawerButton}
                handleVisible={handleVisible}
                handlePassengers={handlePassengers}
                passengers={passengerList}
              />
            )}
          </DetailDrawer>
          <DetailSubHeader
            icon={
              <BackArrow
                clickAction={() =>
                  navigate('/app/mis-viajes', {
                    state: {
                      defaultActive: location?.state?.defaultActive ?? 1,
                    },
                  })
                }
                whiteColor
              />
            }
            travelType={travelType}
            travel={data?.travelById}
            setActiveDevolution={setActiveDevolution}
          />
          <Row className={styles.resumeContainer} justify="center">
            <Col xs={24} lg={9} xl={7} className={styles.paddingX_5}>
              <Collapse bordered={false} className={styles.cardContainer}>
                <Panel
                  header={`Detalles del viaje: # ${transactionNumber}`}
                  key="1"
                  style={{ borderBottom: 'none' }}>
                  <Divider style={{ marginTop: '1%' }} />
                  {data?.travelById?.gone && (
                    <Row>
                      <Col xs={24} lg={0}>
                        <MobileTravelInfoBox
                          departureTripDuration={departureTripDuration}
                          transaction={transaction as Transaction}
                          type="gone"
                        />
                      </Col>
                      <Col xs={0} lg={24}>
                        <TravelByIdDisplay
                          departureTripDuration={departureTripDuration}
                          formattedDepartureDate={formattedDepartureDate}
                          transaction={transaction as Transaction}
                          type="gone"
                          origin={data.travelById.gone.departureCity}
                          destiny={data.travelById.gone.destinationCity}
                          goneSections={data.travelById.gone.sections}
                        />
                      </Col>
                    </Row>
                  )}
                  <Divider />
                  {data?.travelById?.return && (
                    <Row>
                      <Col xs={24} lg={0}>
                        <MobileTravelInfoBox
                          returnTripDuration={returnTripDuration}
                          transaction={transaction as Transaction}
                          type="return"
                        />
                      </Col>
                      <Col xs={0} lg={24}>
                        <TravelByIdDisplay
                          formattedReturnDate={formattedReturnDate}
                          returnTripDuration={returnTripDuration}
                          transaction={transaction as Transaction}
                          type="return"
                          origin={data.travelById.return.departureCity}
                          destiny={data.travelById.return.destinationCity}
                          returnSections={data.travelById.return.sections}
                        />
                      </Col>
                    </Row>
                  )}
                </Panel>
              </Collapse>
              {data?.travelById && (
                <PassengerSeats
                  handleVisible={handleVisible}
                  anonymousPassengers={anonymousPassengers}
                  passengerList={passengerList}
                  activeDevolution={activeDevolution}
                  setActiveDevolution={setActiveDevolution}
                  devolutionLoading={devolutionLoading}
                  makeTicketsDevolution={makeTicketsDevolution}
                  travelById={travelById}
                />
              )}
            </Col>
            <Col xs={24} lg={9} xl={7} className={styles.paddingX_5}>
              <TravelTransactionResume travel={data?.travelById} />
            </Col>
          </Row>
        </Col>
      )}
    </MyTravelsLayout>
  )
}

export default TravelDetailsPage
import React from 'react'
import { Col, Row, Typography } from 'antd'

import { FrequentSeatSccProps } from '../types/props'

import FrequentSeat from './FrequentSeat'

import styles from './styles.module.css'

const { Text } = Typography

const FrequentSeatScc: React.FC<FrequentSeatSccProps> = ({ frequentSeat }) => {
  return (
    <Row className={styles.rowMaringLeft}>
      <Col className={styles.contentCard} md={24}>
        <div className={styles.containerContent}>
          <Text className={styles.text}>Cabinas frecuentes</Text>
          <div className={styles.containerFrequentInfo}>
            <FrequentSeat
              frequentSeat={frequentSeat}
              className={styles.containerListSCC}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FrequentSeatScc

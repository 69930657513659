const turbusStyles = {
  margin: 0,
  width: '100%',
}

const condorStyles = {
  margin: 0,
  width: 65,
}

const jacStyles = {
  margin: 0,
  width: 60,
}

const biobioStyles = {
  margin: 0,
  width: 60,
}

export { biobioStyles, condorStyles, jacStyles, turbusStyles }

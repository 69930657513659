import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Tooltip } from 'antd'

import {
  getTravelResume,
  TravelPriceInfo,
} from '../../../../helpers/travel-page/dates.helper'
import MainDrawer from '../../Common/MainDrawer'
import { TravelPageContext } from '../../Provider'
import { Quota, Seat } from '../../types'

import styles from './styles.module.css'

const AssignPassengers: FC = () => {
  const {
    hasReturn,
    drawerRender,
    search: { numberOfSeats },
    transaction,
    quotas,
    currentQuotaSelected,
    setCurrentQuotaSelected,
    setDrawerRender,
    setQuotas,
  } = useContext(TravelPageContext)
  const [drawerStep, setDrawerStep] = useState(0)
  const [resume, setResume] = useState<TravelPriceInfo>({} as TravelPriceInfo)

  const handleVisible = (value: boolean) => setDrawerRender(value)

  const handleDrawerButton = (value: number) => setDrawerStep(value)

  const setAssignSeat = (quota: Quota) => setCurrentQuotaSelected(quota)

  const clearSeats = (quotaForClear: Quota) => {
    setQuotas(
      quotas.map(quota => {
        if (quota === quotaForClear) {
          quota.goneSeat = undefined
          quota.returnSeat = undefined
        }
        return quota
      }),
    )
  }

  useEffect(() => {
    if (transaction.goneReservation)
      setResume(
        getTravelResume(
          transaction.goneSeats ?? [],
          transaction.returnSeats ?? [],
        ),
      )
    else
      setResume(
        getTravelResume(
          quotas.filter(q => !!q.goneSeat).map(q => q.goneSeat) as Seat[],
          quotas.filter(q => !!q.returnSeat).map(q => q.returnSeat) as Seat[],
        ),
      )
  }, [transaction, quotas])

  useEffect(() => {
    const selected = quotas.find(quota => !quota.goneSeat)
    if (selected) setAssignSeat(selected)
    else setCurrentQuotaSelected(null)
  }, [])

  return (
    <Card className={styles.card}>
      <Col>
        <MainDrawer
          handleVisible={handleVisible}
          handleDrawerButton={handleDrawerButton}
          step={drawerStep}
          visible={drawerRender}
        />
        <Row justify="space-between" align="middle" className={styles.border}>
          <Col span={12} className={`${styles.padding1} ${styles.borderRight}`}>
            <p className={styles.titleFont}>
              Agregar pasajeros (
              {
                quotas.filter(quota => typeof quota.passenger !== 'string')
                  .length
              }
              /{parseInt(numberOfSeats)})
            </p>
          </Col>
          <Col span={12} className={`${styles.padding1} ${styles.right}`}>
            <Button
              className={styles.addButton}
              onClick={() => setDrawerRender(true)}>
              +
            </Button>
          </Col>
        </Row>
        {quotas?.map((quota, index) => (
          <Row
            key={index}
            justify="space-between"
            align="middle"
            className={styles.passengerContainer}
            onClick={() => setAssignSeat(quota)}>
            <Col
              span={12}
              className={`${styles.padding1} ${styles.borderRight} ${
                currentQuotaSelected === quota ? styles.quotaSelected1 : ''
              }`}>
              <p className={styles.passengerFont}>
                {typeof quota.passenger === 'string'
                  ? `${quota.passenger} #${index + 1}`
                  : `${quota.passenger?.firstName} ${
                      quota.passenger?.fatherLastName ?? ''
                    }`}
              </p>
            </Col>
            <Col
              span={12}
              className={`${styles.padding1} ${
                currentQuotaSelected === quota ? styles.quotaSelected2 : ''
              }`}>
              <div className={styles.seatFontContainer}>
                {quota.goneSeat || quota.returnSeat ? (
                  <>
                    {`Asiento: ida: `}
                    {quota.goneSeat ? (
                      <span
                        className={`${styles.numberFont} ${
                          currentQuotaSelected === quota ? styles.whiteFont : ''
                        }`}>
                        {quota.goneSeat.number}
                      </span>
                    ) : (
                      '--'
                    )}
                    {hasReturn ? ' | vuelta: ' : ''}
                    {quota.returnSeat ? (
                      <span
                        className={`${styles.numberFont} ${
                          currentQuotaSelected === quota ? styles.whiteFont : ''
                        }`}>
                        {quota.returnSeat.number}
                      </span>
                    ) : null}
                    {!(
                      transaction?.goneSeats &&
                      transaction?.goneSeats?.length > 0
                    ) ? (
                      <Tooltip title="eliminar selección de asientos">
                        <span
                          className={styles.clearButton}
                          onClick={() => clearSeats(quota)}>
                          X
                        </span>
                      </Tooltip>
                    ) : null}
                  </>
                ) : currentQuotaSelected === quota ? (
                  `Asiento: ida -- ${hasReturn ? '| vuelta: --' : ''}`
                ) : (
                  <span className={styles.seatFont}>
                    {`Asiento: ida -- ${hasReturn ? '| vuelta: --' : ''}`}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        ))}
        <Row justify="space-between" align="middle" className={styles.border}>
          <Col
            span={12}
            className={`${styles.passengerFont} ${styles.padding1} ${styles.borderRight}`}>
            Subtotal
          </Col>
          <Col className={`${styles.totalAmountFont} ${styles.padding1}`}>
            ${resume.total}
          </Col>
        </Row>
      </Col>
    </Card>
  )
}

export default AssignPassengers

import moment from 'moment'

import {
  Passenger,
  Travel as TransactionType,
} from '../../screens/travel-page/types/index'
import {
  GoneReturnTravel,
  PassengerSeatNumbers,
  Seat,
  Travel,
} from '../../types/page-types/travel-details-page.types'

import { fixTimeFormat } from '.'

type ShorterTransaction = {
  travelGone?: ShorterTravelType
  travelReturn?: ShorterTravelType
}

type ShorterTravelType = Pick<
  TransactionType,
  | 'arrives_at'
  | 'bus_operator_name'
  | 'buss_class'
  | 'departs_at'
  | 'departure_terminal_name'
  | 'destination_terminal_name'
> | null

const formattedTime = (date: Date, time: number) =>
  `${moment.utc(date).format('DD/MM/YYYY')} ${fixTimeFormat(time)}`

const formattedObj = (obj: Travel): ShorterTravelType => ({
  arrives_at: formattedTime(obj.arrivesAt, obj.arrivesTime),
  bus_operator_name: obj.operatorName,
  buss_class: obj.seats.length > 0 ? obj.seats[0].type : '',
  departs_at: formattedTime(obj.departsAt, obj.departsTime),
  departure_terminal_name: obj.departureTerminal,
  destination_terminal_name: obj.destinationTerminal,
})

export const formattedTravel = (
  item?: GoneReturnTravel,
): ShorterTransaction | undefined => {
  if (item) {
    const { gone, return: tripReturn } = item

    return {
      travelGone: gone ? formattedObj(gone) : null,
      travelReturn: tripReturn ? formattedObj(tripReturn) : null,
    }
  }
}

// TODO: Check on return type of this helper function.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const formatSeats = (travel: GoneReturnTravel) => {
  const goneSeats =
    travel?.gone?.seats.map(seat => ({
      ticketStatus: '',
      ...seat,
      isGone: true,
      departure: parseInt(
        travel.gone?.departsAt.toString().substr(0, 10).split('-').join('') ??
        '0',
      ),
      departureTime: travel.gone?.departsTime,
    })) || []
  const returnSeats =
    travel?.return?.seats.map(seat => ({
      ticketStatus: '',
      ...seat,
      isGone: false,
      departure: parseInt(
        travel.return?.departsAt.toString().substr(0, 10).split('-').join('') ??
        '0',
      ),
      departureTime: travel.return?.departsTime,
    })) || []

  return [...goneSeats, ...returnSeats]
}
export const filterPassengerSeats = (seats: Seat[]): Seat[] =>
  seats.filter(({ passenger }) => passenger)

const getSeatNumbers = ({ isGone, number, ticketId }: Seat) => ({
  goneNumber: isGone ? number : 0,
  returnNumber: isGone ? undefined : number,
  goneTicket: isGone ? ticketId : undefined,
  returnTicket: isGone ? undefined : ticketId,
})

const reducer = (acc: PassengerSeatNumbers[], seat: Seat) => {
  let tmp = acc.find(
    item => (item.passenger as Passenger)?._id === seat.passenger._id,
  )

  if (tmp)
    tmp = {
      passenger: tmp.passenger,
      returnTicketStatus: seat.isGone ? undefined : seat.ticketStatus,
      goneTicketStatus: seat.isGone ? seat.ticketStatus : tmp.goneTicketStatus,
      goneNumber: seat.isGone ? seat.number : tmp.goneNumber,
      returnNumber: seat.isGone ? tmp.returnNumber : seat.number,
      goneTicket: seat.isGone ? seat.ticketId : tmp.goneTicket,
      returnTicket: seat.isGone ? tmp.returnTicket : seat.ticketId,
      departure: seat.isGone ? seat.departure : tmp.departure,
      departureTime: seat.isGone ? seat.departureTime : tmp.departureTime,
      returnDeparture: seat.isGone ? tmp.returnDeparture : seat.departure,
      returnDepartureTime: seat.isGone
        ? tmp.returnDepartureTime
        : seat.departureTime,
    }
  else
    tmp = {
      departure: seat.isGone ? seat.departure : undefined,
      departureTime: seat.isGone ? seat.departureTime : undefined,
      returnDeparture: seat.isGone ? undefined : seat.departure,
      returnDepartureTime: seat.isGone ? undefined : seat.departureTime,
      passenger: seat.passenger,
      returnTicketStatus: seat.isGone ? undefined : seat.ticketStatus,
      goneTicketStatus: seat.isGone ? seat.ticketStatus : 'sin ticket',
      ...getSeatNumbers(seat),
    }

  acc = acc.filter(
    item => (item.passenger as Passenger)?._id !== seat.passenger._id,
  )
  acc.push(tmp)
  return acc
}

export const getPassengerSeatNumber = (data: Seat[]): PassengerSeatNumbers[] =>
  data.reduce(reducer, [])

import React, { FC } from 'react'
import { Col, Collapse, Divider, Row } from 'antd'

import { getDisplayDate } from '../../../../helpers/dateLongFormat'
import { lowerCaseStringButFirstLetter } from '../../../../helpers/lowercaseStringButFirst'
import { TravelResumeProps } from '../../../../types/page-types/pay-page.types'

import IconTravel from './IconTravel.inline.svg'

import styles from './styles.module.css'

const { Panel } = Collapse

const TravelResume: FC<TravelResumeProps> = ({
  travelResume: { origin, destiny, numberOfSeats },
  transaction,
  withCollapse,
}) => {
  const { travelGone, travelReturn } = transaction

  const renderTravelInfo = (
    label: string,
    departs_at?: string,
    numberOfSeats?: string,
    operator?: string,
  ) => (
    <div className={styles.yMargin2}>
      <Row className={styles.departureDates}>
        <Col xs={7} sm={6} className={styles.departureDatesFont}>
          {label}:
        </Col>
        <Col push={1}>
          {getDisplayDate(departs_at ?? new Date().toISOString())}
        </Col>
      </Row>
      {numberOfSeats && operator && (
        <Row className={styles.departureDates}>
          <Col xs={7} sm={6} className={styles.departureDatesFont}>
            Operación:
          </Col>
          <Col push={1}>
            {numberOfSeats} pasajero(s) -{' '}
            {lowerCaseStringButFirstLetter(operator)}
          </Col>
        </Row>
      )}
    </div>
  )

  const renderContent = () => (
    <>
      <Row className={styles.tripInfo} align="middle">
        <Col className={`${styles.left} ${styles.routeFont}`}>
          <p>{origin}</p>
        </Col>
        <Col xs={3} className={`${styles.centered} ${styles.routeFont}`}>
          <IconTravel />
        </Col>
        <Col className={`${styles.left} ${styles.routeFont}`}>
          <p>{destiny}</p>
        </Col>
      </Row>
      {renderTravelInfo(
        'Ida',
        travelGone?.departs_at,
        numberOfSeats,
        travelGone?.bus_operator_name,
      )}
      {travelReturn &&
        renderTravelInfo(
          'Vuelta',
          travelReturn?.departs_at,
          numberOfSeats,
          travelReturn.bus_operator_name,
        )}
    </>
  )

  return (
    <Row className={styles.travelResumeBox}>
      <Col span={24}>
        {withCollapse ? (
          <Collapse ghost>
            <Panel header="Detalles del viaje" key={1}>
              <Divider className={`${styles.noPadding} ${styles.noMargin}`} />
              {renderContent()}
            </Panel>
          </Collapse>
        ) : (
          renderContent()
        )}
      </Col>
    </Row>
  )
}

export default TravelResume

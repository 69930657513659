import React, { FC, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Col, message, Row, Select } from 'antd'

import { MY_CURRENT_ACCOUNTS } from '../../../../queries'
import { MyUserPageContext } from '../../Provider'

import CecoRow from './CecoRow'

import styles from './styles.module.css'

const { Option } = Select

const UsersCecoDisplay: FC = () => {
  const {
    accountSelect,
    cecoSelect,
    costCenters,
    selectedAccount,
    selectedCeco,
    setAccountSelect,
    setCecoSelect,
    setCostCenters,
    setSelectedAccount,
    setSelectedCeco,
  } = useContext(MyUserPageContext)

  useQuery(MY_CURRENT_ACCOUNTS, {
    onCompleted: ({ myCurrentAccounts }) => {
      if (myCurrentAccounts) setAccountSelect(myCurrentAccounts)
    },
  })

  const handleSelect = (id: string) => {
    const idx = accountSelect.findIndex(({ _id }) => _id === id)
    setCecoSelect(accountSelect[idx].costCenters)
    setSelectedAccount(accountSelect[idx])
  }

  const handleCecoSelect = (id: string) => {
    const idx = cecoSelect.findIndex(({ _id }) => _id === id)
    setSelectedCeco(cecoSelect[idx])
  }

  const addCeco = () => {
    const oldList = [...costCenters]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newCeco: any = { ...selectedCeco }
    newCeco.currentAccount = selectedAccount
    const alreadyIn = oldList.find(
      ({ _id, currentAccount }) =>
        _id === newCeco._id &&
        currentAccount._id === newCeco.currentAccount._id,
    )
    if (alreadyIn)
      return message.error(
        'La cuenta corriente con el centro de costo seleccionado ya se encuentran en la lista.',
      )
    oldList.push(newCeco)
    setCostCenters(oldList)
  }

  return (
    <>
      <Row
        align="middle"
        className={styles.selectRow}
        justify="start"
        gutter={10}>
        <Col xs={24} md={8}>
          <p>Cuenta corriente</p>
          <Select
            className={styles.selectStyles}
            onChange={value => handleSelect(value as string)}
            optionFilterProp="children"
            placeholder="Seleccione cuenta corriente"
            showSearch>
            {accountSelect?.map(({ _id, name }) => (
              <Option key={_id} value={_id}>
                {name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} md={8}>
          <p>Centro de costo</p>
          <Select
            className={styles.selectStyles}
            onChange={value => handleCecoSelect(value as string)}
            optionFilterProp="children"
            placeholder="Seleccione centro de costo"
            showSearch>
            {cecoSelect?.map(({ _id, name }) => (
              <Option key={_id} value={_id}>
                {name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col xs={24} md={8}>
          <Button
            className={
              Object.keys(selectedCeco).length > 0
                ? styles.addCeco
                : styles.disabledAddCeco
            }
            onClick={
              Object.keys(selectedCeco).length > 0
                ? addCeco
                : () => message.info('Debe seleccionar un centro de costo')
            }>
            Asignar CECO
          </Button>
        </Col>
      </Row>
      {costCenters?.length > 0 && (
        <Row align="middle" justify="center">
          <Col className={styles.listContainer} span={24}>
            {costCenters.map(costCenter => (
              <CecoRow key={costCenter._id} costCenter={costCenter} />
            ))}
          </Col>
        </Row>
      )}
    </>
  )
}

export default UsersCecoDisplay

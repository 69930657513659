import { navigate } from 'gatsby';
import React, { createContext, FC, useState } from 'react'

import { CostCenter } from '../../../types/costCenter.type'
import { CurrentAccount } from '../../../types/currentAccount.type'
import { CostCenterWithAccount, User } from '../../my-travels/types'

type MyUserPageContextType = {
  accountSelect: CurrentAccount[];
  allUsers: User[];
  cecoSelect: CostCenter[];
  costCenters: CostCenterWithAccount[];
  createDrawer: boolean;
  deleteVisible: boolean;
  filteredUsers: User[];
  selectedAccount: CurrentAccount;
  selectedCeco: CostCenter;
  selectedUser: User;
  visible: boolean;
  setAccountSelect: (value: CurrentAccount[]) => void;
  setAllUsers: (value: User[]) => void;
  setCecoSelect: (value: CostCenter[]) => void;
  setCostCenters: (value: CostCenterWithAccount[]) => void;
  setCreateDrawer: (value: boolean) => void;
  setDeleteVisible: (value: boolean) => void;
  setFilteredUsers: (value: User[]) => void;
  setSelectedAccount: (value: CurrentAccount) => void;
  setSelectedCeco: (value: CostCenter) => void;
  setSelectedUser: (value: User) => void;
  setVisible: (value: boolean) => void;
  /*nuevo*/
  changePage: (page: number) => void;
  totalUsers: number;
  usersByPage: number;
  selectedPage: number;
  setSelectedPage: (value: number) => void;
  setTotalUsers: (value: number) => void;
  setUsersByPage: (value: number) => void;
  /*prueba */
  isSort: number;
  setSort: (value: number) => void;
  sortedUsers: User[];
  setsortedUsers: (value: User[]) => void;

};


export const MyUserPageContext = createContext<MyUserPageContextType>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!,
)

export type UserSearchFilters = {
  fullName?: string
  rut?: string
  email?: string
}

export const MyUserPageProvider: FC = ({ children }) => {
  const [accountSelect, setAccountSelect] = useState<CurrentAccount[]>([])
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [cecoSelect, setCecoSelect] = useState<CostCenter[]>([])
  const [costCenters, setCostCenters] = useState<CostCenterWithAccount[]>([])
  const [createDrawer, setCreateDrawer] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])
  const [selectedAccount, setSelectedAccount] = useState({} as CurrentAccount)
  const [selectedCeco, setSelectedCeco] = useState({} as CostCenter)
  const [selectedUser, setSelectedUser] = useState({} as User)
  const [visible, setVisible] = useState(false)
  /*nuevo*/
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const [totalUsers, setTotalUsers] = useState<number>(0)
  const [usersByPage, setUsersByPage] = useState<number>(10)

  const changePage = (page: number) => {
    setSelectedPage(page);
    navigate(`/app/mis-usuarios?page=${page}#pagination`);
  };

  const [isSort, setSort] = useState<number>(0)
  const [sortedUsers, setsortedUsers] = useState<User[]>([])


  return (
    <MyUserPageContext.Provider
      value={{
        accountSelect,
        allUsers,
        cecoSelect,
        costCenters,
        createDrawer,
        deleteVisible,
        filteredUsers,
        selectedAccount,
        selectedCeco,
        selectedUser,
        visible,
        setAccountSelect,
        setAllUsers,
        setCecoSelect,
        setCostCenters,
        setCreateDrawer,
        setDeleteVisible,
        setFilteredUsers,
        setSelectedAccount,
        setSelectedCeco,
        setSelectedUser,
        setVisible,
        /*nuevo*/
        changePage,
        totalUsers,
        usersByPage,
        selectedPage: selectedPage,
        setSelectedPage,
        setTotalUsers,
        setUsersByPage,
        /*prueba*/
        isSort,
        setSort,
        sortedUsers,
        setsortedUsers
      }}>
      {children}
    </MyUserPageContext.Provider>
  )


}

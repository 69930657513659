import { gql } from '@apollo/client'

export const MY_CURRENT_ACCOUNTS = gql`
  query myCurrentAccount @api(name: "users"){
    myCurrentAccounts{
      _id
      name
      balance
      sapNumber
      totalAmount
    }
  }
`

export const COMPANY_DETAIL = gql`
  query companyDetail($companyId: String!) @api(name: "users"){
    companyDetail(companyId: $companyId) {
      _id
      creditLine {
        _id
        totalAmount
        balance
      }
      hasERP
    }
  }
`

export const GET_DEBT_SUMMARY = gql`
  query getDebtSummary($currentAccount: String, $from: DateTime, $to: DateTime) @api(name: "reports"){
    getDebtSummary(currentAccount: $currentAccount, from: $from, to: $to){
      totalDebt
      invoicesToPay
      expiredDays
      expiredFrom
    }
  }
`
export const GET_INVOICES = gql`
  query getInvoices($input: InvoicesFilterInput!) @api(name: "reports"){
    getInvoices(input: $input){
      total
      currentPage
      invoicesByPage
      data{
        _id
        invoiceNumber
        gloss
        status
        emissionDate
        dueDate
        invoicePath
        totalAmount
        hes{
          number
          fileUrl
        }
        purchaseOrder {
          number
          fileUrl
        }
        sent
        currentAccount {
          _id
          sapNumber        
        }
      }      
    }
  }
`
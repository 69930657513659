import React, { FC } from 'react'
import { Row } from 'antd'

import TravelInfoBox from '../../../../../components/TravelInfoBox'
import PlaceholderIcon from '../../../../travel-page/SeatSelection/TripInformation/placeholdericon.inline.svg'
import { TravelByIdProps } from '../../../types'

import styles from './styles.module.css'

const TravelByIdDisplay: FC<TravelByIdProps> = ({
  departureTripDuration,
  formattedDepartureDate,
  formattedReturnDate,
  returnTripDuration,
  transaction,
  origin,
  destiny,
  type,
  goneSections,
  returnSections,
}) =>
  type === 'gone' ? (
    <Row align="middle" className={styles.travelInfoBox} justify="center">
      <TravelInfoBox
        departureTripDuration={departureTripDuration}
        formattedDepartureDate={formattedDepartureDate}
        icon={<PlaceholderIcon />}
        transaction={transaction}
        type="gone"
        origin={origin}
        destiny={destiny}
        sections={goneSections}
      />
    </Row>
  ) : (
    <Row align="middle" className={styles.travelInfoBox} justify="center">
      <TravelInfoBox
        formattedReturnDate={formattedReturnDate}
        returnTripDuration={returnTripDuration}
        icon={<PlaceholderIcon />}
        transaction={transaction}
        type="return"
        origin={origin}
        destiny={destiny}
        sections={returnSections}
      />
    </Row>
  )

export default TravelByIdDisplay

import React, { FC, useState } from 'react'
import { QuestionCircleFilled } from '@ant-design/icons'
import { gql, useLazyQuery } from '@apollo/client'
import { Button, Col, Form, Input, Radio, Row, Tooltip } from 'antd'
import { navigate } from 'gatsby'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import PhonePrefix from '../../../components/phonePrefix'
import { formatRut } from '../../../helpers/rutValidators'
import {
  PersonalRegistrationFormProps,
  PersonalRegistrationFormValues,
} from '../../../types/component-types/PersonalRegistrationForm.types'

import styles from './styles.module.css'

const CHECK_EMAIL_AVALAIBILITY = gql`
  query checkEmailAvalaibility($email: String!) @api(name: "users") {
    checkEmailAvalaibility(email: $email)
  }
`
const { Item } = Form

const PersonalRegistrationForm: FC<PersonalRegistrationFormProps> = ({
  stage,
  stageHandler,
  informationHandler,
  accountTypeHandler,
}) => {
  const [emailIsAvailable, setEmailIsAvailable] = useState(true)
  const [form] = Form.useForm()

  const [checkEmailAvailabilityQuery, { loading }] = useLazyQuery(
    CHECK_EMAIL_AVALAIBILITY,
    {
      onCompleted: res => {
        const { checkEmailAvalaibility } = res
        setEmailIsAvailable(checkEmailAvalaibility)
      },
    },
  )

  let timer: NodeJS.Timeout = setTimeout(() => {
    console.log('')
  }, 100)

  const onFinish: (values: PersonalRegistrationFormValues) => void = values => {
    if (emailIsAvailable) {
      informationHandler({
        ...values,
        phone: `${values.areaCode} ${values.shortPhone}`,
      })
      stageHandler(2)
    } else form.scrollToField('shortPhone')
  }

  const checkEmailAvailability = (email: string) => {
    clearTimeout(timer)
    timer = setTimeout(async () => {
      await checkEmailAvailabilityQuery({
        variables: {
          email,
        },
      })
    }, 500)
  }

  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<PersonalRegistrationFormValues>,
  ) => void = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const hidden = stage !== 1

  return (
    <Row>
      <Col>
        <Form
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          {hidden ? null : <p className={styles.labelFont}>Nombre(s)</p>}
          <Item
            className={styles.inputContainer}
            name="firstName"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Input
              className={styles.input}
              placeholder="Ejemplo: Juan Ignacio"
            />
          </Item>

          {hidden ? null : <p className={styles.labelFont}>Apellido(s)</p>}
          <Item
            className={styles.inputContainer}
            name="lastName"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Input
              className={styles.input}
              placeholder="Ejemplo: Pérez González"
            />
          </Item>
          
          {hidden ? null : (
            <p className={styles.labelFont}>Rut (Con guión sin puntos)</p>
          )}
          <Item
            className={styles.itemRow}
            name="rut"
            hidden={hidden}
            normalize={rut => {
              return formatRut(rut)
            }}
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                pattern: /^[0-9]+[-|‐]{1}[0-9kK]{1}$/,
                message: 'Rut inválido',
              },
            ]}>
            <Input />
          </Item>
          {hidden ? null : (
            <p className={styles.labelFont}>
              Teléfono (incluir número de área)
            </p>
          )}
          <Row>
            <Col xs={24}>
              <Item
                className={styles.inputContainer}
                name="shortPhone"
                rules={[
                  { required: true, message: 'Este campo es obligatorio'  },
                  {
                    pattern: /^[0-9]*$/,
                    message: 'debe ingresar solo números',
                  },
                  {
                    max: 9,
                    message: 'no se permiten mas de 9 caracteres',
                  },
                ]}

                hidden={hidden}>
                <Input
                  addonBefore={<PhonePrefix />}
                  className={styles.inputPhone}
                  placeholder="Ejemplo: 912345678"
                />
              </Item>
            </Col>
          </Row>
          {hidden ? null : (
            <p className={styles.labelFont}>Correo electrónico corporativo</p>
          )}
          {loading ? (
            <div className={styles.successText}>Comprobando correo ...</div>
          ) : !emailIsAvailable ? (
            <div className="ant-form-item-explain ant-form-item-explain-error">
              Este correo ya existe en el sistema
            </div>
          ) : null}

          <Item
            className={styles.inputContainer}
            name="email"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: 'Introduzca un correo válido',
              },
            ]}
            hidden={hidden}>
            <Input
              className={styles.input}
              onChange={({ target }) => checkEmailAvailability(target.value)}
              placeholder="Ejemplo: micorreo@correo.cl"
            />
          </Item>

          {hidden ? null : (
            <p className={styles.labelFont}>Crea una contraseña</p>
          )}
          <Item
            className={styles.passwordInputContainer}
            name="password"
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              {
                pattern:
                  /(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*.?&#])[A-Za-z\d@$!%*.?&#]{8,}/,
                message: 'Introduzca una contraseña válida',
              },
            ]}
            hidden={hidden}>
            <Input.Password
              className={styles.input}
              placeholder="Ejemplo: auyTKs259$."
            />
          </Item>

          {hidden ? null : (
            <p className={styles.labelFont}>Repite la contraseña</p>
          )}
          <Item
            className={styles.passwordInputContainer}
            name="repeatPassword"
            hidden={hidden}
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value)
                    return Promise.resolve()

                  return Promise.reject('Las contraseñas no coinciden')
                },
              }),
            ]}>
            <Input.Password
              className={styles.input}
              placeholder="Ejemplo: auyTKs259$."
            />
          </Item>

          {hidden ? null : (
            <p className={styles.passwordDescriptionFont}>
              Tu contraseña debe contener al menos 8 caracteres, incluyendo
              mayúsculas, minúsculas y un caracter especial. (Ej. @$!%*?&#.)
            </p>
          )}

          {hidden ? null : <div className={styles.lineSeparator} />}

          {hidden ? null : (
            <p className={styles.accountTypeFont}>
              ¡Facilita tu experiencia! ¿Deseas abrir una línea de crédito?
              <Tooltip
                placement="topLeft"
                title="Obtén una línea de crédito dentro de Simplus y paga tus viajes con crédito. Al final del mes se facturarán los viajes comprados">
                <QuestionCircleFilled className={styles.icon} />
              </Tooltip>
            </p>
          )}
          <Item
            className={styles.radioInputContainer}
            name="role"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Radio.Group
              onChange={event => accountTypeHandler(event.target.value)}>
              <Row>
                <Col>
                  <Radio value="ADMIN_CCC" className={styles.accountTypeOption}>
                    Sí, solicitar línea de crédito
                  </Radio>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Radio value="ADMIN_SCC" className={styles.accountTypeOption}>
                    En otro momento
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Item>
          <div className={styles.lineSeparator} />
          <Item hidden={hidden}>
            <Row justify="space-between" className={styles.buttonContainer}>
              <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 1 }}>
                <Button
                  className={styles.backButton}
                  onClick={() => {
                    navigate('/inicio-sesion/')
                  }}
                  block>
                  Volver
                </Button>
              </Col>
              <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 2 }}>
                <Button
                  className={styles.continueButton}
                  htmlType="submit"
                  block>
                  Continuar
                </Button>
              </Col>
            </Row>
          </Item>
        </Form>
      </Col>
    </Row>
  )
}

export default PersonalRegistrationForm

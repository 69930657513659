import React, { FC } from 'react'
import { Col, Row } from 'antd'

import OptionsHeader from '../../../components/OptionsHeader'

import styles from '../styles.module.css'

const MyTravelsLayout: FC = ({ children }) => (
  <Row className={styles.screenContainer}>
    <Col span={24}>
      <OptionsHeader />
      <Row className={styles.mainRowContainer}>{children}</Row>
    </Col>
  </Row>
)

export default MyTravelsLayout

import React, { FC } from 'react'
import { Col, Row } from 'antd'

import OptionsHeader from '../OptionsHeader'

import AdminLayoutFooter from './AdminLayoutFooter'
import AdminLayoutHeader from './AdminLayoutHeader'
import { AdminLayoutProps } from './types'

import styles from './styles.module.css'

const AdminLayout: FC<AdminLayoutProps> = ({
  addOn,
  children,
  quantity,
  subTitle,
  title,
}) => (
  <Row className={styles.layoutRow}>
    <Col span={24}>
      <OptionsHeader />
      <AdminLayoutHeader
        addOn={addOn}
        quantity={quantity}
        subTitle={subTitle}
        title={title}
      />
      <Row align="top" className={styles.mainAdminRow} justify="center">
        <Col xs={24} sm={24} md={16} lg={12}>
          {children}
        </Col>
      </Row>
      <AdminLayoutFooter />
    </Col>
  </Row>
)

export default AdminLayout

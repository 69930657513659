import React, { FC, useState } from 'react'
import { Checkbox, Col, message, Row } from 'antd'

import Premium from './svg/Premium.inline.svg'
import SalonCama from './svg/SalonCama.inline.svg'
import Semicama from './svg/Semicama.inline.svg'
import { props } from './type'

import styles from './styles.module.css'

const SeatPolicy: FC<props> = ({ onClick, initialOption }) => {
  const [options, setOptions] = useState<string[]>(initialOption ?? [])

  const toggleOption = (option: string): void => {
    let newOptions = [...options]
    if (newOptions.includes(option))
      if (newOptions.length > 1)
        newOptions = newOptions.filter(opt => opt !== option)
      else message.warning('Debe existir al menos un tipo de asiento permitido')
    else newOptions.push(option)

    onClick && onClick(newOptions)
    setOptions(newOptions)
  }

  return (
    <Row justify="space-between" align="middle" className={styles.margingTop1}>
      <Col xs={24} md={6} className={styles.label}>
        Permiso de compra de asientos
      </Col>
      <Col xs={24} md={18}>
        <Row justify="space-between" align="middle" gutter={5}>
          <Col xs={24} md={8} onClick={() => toggleOption('Semi Cama')}>
            <div className={styles.seatTypeContent}>
              <div className={styles.typeFont}>
                <Semicama /> Semi Cama
              </div>
              <span>
                {options.includes('Semi Cama') ? 'Habilitado' : 'Deshabilitado'}{' '}
              </span>{' '}
              <Checkbox checked={options.includes('Semi Cama')} />
            </div>
          </Col>
          <Col xs={24} md={8} onClick={() => toggleOption('Salón Cama')}>
            <div className={styles.seatTypeContent}>
              <div className={styles.typeFont}>
                <SalonCama /> Salón Cama
              </div>
              <span>
                {options.includes('Salón Cama')
                  ? 'Habilitado'
                  : 'Deshabilitado'}{' '}
              </span>{' '}
              <Checkbox checked={options.includes('Salón Cama')} />
            </div>
          </Col>
          <Col xs={24} md={8} onClick={() => toggleOption('Premium')}>
            <div className={styles.seatTypeContent}>
              <div className={styles.typeFont}>
                <Premium /> Premium
              </div>
              <span>
                {options.includes('Premium') ? 'Habilitado' : 'Deshabilitado'}{' '}
              </span>{' '}
              <Checkbox checked={options.includes('Premium')} />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default SeatPolicy

import { useCallback, useState } from 'react'

import { OptionsType } from '../screens/travel-page/types'
import { AutoCompleteOption } from '../types/page-types/home-page.types'

type response = {
  optionsList: OptionsType[]
  filterAutocomplete: (value: string) => void
  updateDestinies: (value: Array<AutoCompleteOption>) => void
}
// Array<AutoCompleteOption> = []
export const useAutocompleteAnt = (
  list: Array<AutoCompleteOption> = [],
): response => {
  const initList = list.reduce(
    (acc: OptionsType[], el) => [...acc, { value: el.name }],
    [],
  )

  const [optionsList, setOptionsList] = useState({
    list: initList,
    filteredList: initList,
  })

  const updateDestinies = useCallback(
    list => {
      const newOptions = list?.map((city: { id: string; name: string }) => {
        return {
          value: city.name,
        }
      })

      setOptionsList({
        list: newOptions,
        filteredList: newOptions,
      })
    },
    [list],
  )

  const filterAutocomplete = (searchText: string) => {
    if (searchText === '')
      setOptionsList({
        ...optionsList,
        filteredList: optionsList.list,
      })
    else {
      const regExSearchText = new RegExp(searchText, 'i')
      setOptionsList({
        ...optionsList,
        filteredList: optionsList.list.filter((r: OptionsType) =>
          regExSearchText.test(r.value),
        ),
      })
    }
  }

  return {
    optionsList: optionsList.filteredList,
    filterAutocomplete,
    updateDestinies,
  }
}

import React, { useEffect, useState } from 'react'
import ScrollToBottom from 'react-scroll-to-bottom'
import { SendOutlined } from '@ant-design/icons'
import { useMutation, useSubscription } from '@apollo/client'
import { Button, Col, Divider, Form, Input, Row } from 'antd'
import axios from 'axios'

import FileInput from '../../../components/FileInput'
import { HelpRequestStatusEnum } from '../../../helpers/enums'
import { useNotification } from '../../../hooks/notifications.hook'
import { useScreenFocus } from '../../../hooks/screen.hook'
import { getUser, tokenInfo } from '../../../services/auth'
import { Message } from '../../../types/HelpRequest.type'
import Bubble from '../Bubble'
import {
  ADD_FILE_TO_HELPREQUEST,
  ADD_MESSAGE,
  MESSAGE_ADDED,
  SET_MESSAGES_TO_SEEN,
} from '../graphql'

import { Props } from './types'

import styles from './styles.module.css'

const Chat: React.FC<Props> = ({ helpRequest, setLoading }) => {
  const isSolved = () => helpRequest?.status === HelpRequestStatusEnum.FINISHED

  const [newMessages, setNewMessages] = useState<number>(0)

  const isTabFocused = useScreenFocus()
  const notify = useNotification()
  const { user } = tokenInfo(getUser() ?? '')

  const [addMessage, { loading: addMessageLoading }] = useMutation(ADD_MESSAGE)

  const [addFile, { loading }] = useMutation(ADD_FILE_TO_HELPREQUEST)
  const [setMessagesToSeen] = useMutation(SET_MESSAGES_TO_SEEN)

  const [messages, setMessages] = useState(helpRequest.messages as Message[])

  const [form] = Form.useForm()

  const { data } = useSubscription(MESSAGE_ADDED, {
    variables: {
      id: helpRequest._id,
    },
  })

  const sendMessage = async (message: string) => {
    try {
      await addMessage({
        variables: {
          helpRequest: helpRequest._id,
          message,
        },
      })
      form.resetFields()
      document.getElementById('chatInput')?.focus()
    } catch (err) {
      console.log(err)
    }
  }

  const handleFilesUpload = (files: FileList | null) => {
    if (files) {
      if (setLoading) setLoading(true)
      const form = new FormData()
      form.append('file', files[0])
      axios
        .post(`${process.env.GATSBY_API_UPLOAD_URI}/HELP`, form, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(({ data }) => {
          addFile({
            variables: {
              id: helpRequest._id,
              path: data.data.fileUrl,
            },
          })
        })
        .catch(err => console.error(err))
    }
  }


  useEffect(() => {
    if (data?.messageAdded) {
      if (data?.messageAdded.user._id !== user.sub)
        if (isTabFocused)
          setMessagesToSeen({
            variables: {
              messageIds: [data?.messageAdded._id],
            },
          })
        else {
          setNewMessages(newMessages + 1)
          notify({
            title: 'Simplus | Nuevo mensaje',
            body: data?.messageAdded.message,
          })
        }

      const currentMessages = [...messages]
      currentMessages.push(data.messageAdded)
      setMessages(currentMessages)
    }
  }, [data])

  useEffect(() => {
    if (setLoading) setLoading(loading)
  }, [loading])

  useEffect(() => {
    if (isTabFocused)
      setMessagesToSeen({
        variables: {
          messageIds: messages
            .filter(message => message.user._id !== user.sub)
            .map(message => message._id),
        },
      }).then(() => {
        setNewMessages(0)
      })
  }, [isTabFocused])

  const playPop = () => {
    const audio = document.getElementById('pop')
    audio?.play()
  }

  useEffect(() => {
    document.title = `${
      newMessages > 0 ? `(${newMessages}) mensajes nuevos` : ''
    } Simplus | Detalle de solicitud`
    if (newMessages > 0) playPop()
  }, [newMessages])

  return (
    <>
      <Row
        className={
          isSolved() ? styles.bubbleContainerFull : styles.bubbleContainer
        }
        align="stretch">
        <Col span="24">
          <ScrollToBottom
            followButtonClassName={styles.followButton}
            className={
              isSolved() ? styles.bubbleContainerFull : styles.bubbleContainer
            }>
            {messages.map(message => (
              <Bubble key={message._id} chatMessage={message} />
            ))}
          </ScrollToBottom>
        </Col>
      </Row>
      {isSolved() ? null : (
        <div className={styles.chatFormContent}>
          <Divider />
          <Row>
            <Col span="24">
              <Form
                form={form}
                onFinish={({ message }) => {
                  sendMessage(message)
                }}>
                <div className={styles.chatForm}>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: 'debes agregar un comentario',
                      },
                    ]}>
                    <Input
                      size="large"
                      placeholder="Escribe un comentario..."
                      id="chatInput"
                      bordered={false}
                      className={styles.chatInput}
                    />
                  </Form.Item>
                  <Divider />
                  <FileInput
                    onChange={files => handleFilesUpload(files)}
                    inputId="documentsInput"
                  />
                  <Button
                    htmlType="submit"
                    className={styles.sendButton}
                    disabled={addMessageLoading}>
                    <SendOutlined />
                  </Button>
                </div>
              </Form>
              <audio
                id="pop"
                src="https://assets.mixkit.co/sfx/preview/mixkit-long-pop-2358.mp3"
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  )
}

export default Chat

import React, { FC } from 'react'
import { Empty } from 'antd'

import LoadingSpinner from '../../../../components/LoadingSpinner'
import ScheduledTrips from '../ScheduledTrips'

import { props } from './types'

const TravelsTab: FC<props> = ({
  activeDevolution,
  toggleDevolutionList,
  loading,
  travels,
  isTicketFilter,
  ticketNumber,
  tabKey
}) => {

  const filteredTravels = travels.filter((item) => {
    const goneSeats = item.gone?.seats || [];
    const returnSeats = item.return?.seats || [];

    const hasCanceledTicket = goneSeats.some(seat => seat.ticketId?.toString() === ticketNumber) ||
      returnSeats.some(seat => seat.ticketId?.toString() === ticketNumber);

    return isTicketFilter ? hasCanceledTicket : true;
  });

  return (
    <>
      {loading ? (
        <LoadingSpinner size="50px" />
      ) : filteredTravels.length > 0 ? (
        filteredTravels.map((item, index) => (
          <ScheduledTrips
            key={item.gone?.bookingId || item.return?.bookingId}
            item={item}
            grayBackground={index % 2 === 1}
            activeDevolution={activeDevolution}
            toggleDevolutionList={toggleDevolutionList}
            tab={tabKey}
            isTicketFilter={isTicketFilter}
            ticketNumber={ticketNumber}
          />
        ))
      ) : (
        <Empty description="No hay viajes." />
      )}
    </>
  )
}

export default TravelsTab

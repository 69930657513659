import React, { FC, useContext, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import LoadingSpinner from '../../components/LoadingSpinner'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../services/auth'

import { TravelPageContext } from './Provider'
import TravelPageLayout from './travelLayout'
import TravelPageContent from './TravelPageContent'
import validateContextItems from './travelValidate'

const TravelPage: FC<RouteComponentProps> = () => {
  const { anonymous, search, setHasReturn, setStep, step } = useContext(
    TravelPageContext,
  )

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_viajes' as string

    useEventSave('vista_viajes', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const onBack = async (): Promise<void> =>
    step > 0 ? setStep(step - 1) : navigate(-1)

  useEffect(() => {
    if (!validateContextItems(search)) navigate('/app/inicio')
    if (Object.keys(search).length > 0 && search.returnDate)
      setHasReturn(!!search.returnDate)
  }, [search])

  return (
    <TravelPageLayout anonymous={anonymous} onBack={onBack}>
      {Object.keys(search).length < 1 ? (
        <LoadingSpinner size="50px" />
      ) : (
        <TravelPageContent />
      )}
    </TravelPageLayout>
  )
}

export default TravelPage

import React, { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Col, Row } from 'antd'
import { navigate } from 'gatsby'

import withLocation from '../../components/WithLocation'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../services/auth'

import SuccessIcon from './success.inline.svg'

import styles from './styles.module.css'

const CONFIRM_ACCOUNT = gql`
  mutation confirmAccount($emailToken: String!) @api(name: "users") {
    confirmAccount(emailToken: $emailToken)
  }
`

interface Props extends RouteComponentProps {
  search: {
    emailToken: string
  }
}

const AccountConfirmationPage: React.FC<Props> = ({ search }) => {
  const { emailToken } = search
  const [
    accountConfirmation,
    { data: accountConfirmationResult, loading: accountConfirmationLoading },
  ] = useMutation(CONFIRM_ACCOUNT)

  useEffect(() => {
    accountConfirmation({
      variables: { emailToken },
    })

    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_confirmacion_de_cuenta' as string

    useEventSave('vista_confirmacion_de_cuenta', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  if (accountConfirmationLoading)
    return (
      <Row justify="center" align="middle" className={styles.outerContainer}>
        <Col>
          <SuccessIcon />
          <p className={styles.title}>Cargando</p>
        </Col>
      </Row>
    )

  if (accountConfirmationResult)
    return (
      <Row justify="center" align="middle" className={styles.outerContainer}>
        <Col>
          <SuccessIcon />
          <p className={styles.title}>Correo verificado con éxito</p>
          <p className={styles.body}>
            ¡Tu registro ha concluido! Ya puedes acceder a Simplus y empezar a
            viajar hoy.
          </p>
          <Button
            className={styles.continueButton}
            onClick={() => {
              navigate('/inicio-sesion/')
            }}>
            Acceder
          </Button>
        </Col>
      </Row>
    )
  else
    return (
      <Row justify="center" align="middle" className={styles.outerContainer}>
        <Col>
          <p className={styles.title}>Error</p>
          <p className={styles.body}>
            Hubo un error, por favor inténtalo más tarde.
          </p>
          <Button
            className={styles.continueButton}
            onClick={() => {
              navigate('/inicio-sesion/')
            }}>
            Volver
          </Button>
        </Col>
      </Row>
    )
}

export default withLocation(AccountConfirmationPage)

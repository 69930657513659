import React, { FC } from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'

import { formatRut } from '../../helpers/rutValidators'
import { PassengerListProps } from '../../screens/travel-page/types'

import styles from './styles.module.css'
import { CostCenter } from '../../types/page-types/pay-page.types';
import { getCurrentUser } from '../../services/auth'

const PassengersList: FC<PassengerListProps> = ({
  passengers,
  removePassenger,
}) => {

  const currentUser = getCurrentUser();
  const userRole = currentUser?.role

  const renderCostCenterInfo = (costCenterData:any) => {

    if(costCenterData){
      return costCenterData.name
    }else{
      return 'No se pudo cargar información de Centro de Costos';
    }

  };

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24} className={styles.passengersContainer}>
      <p className={styles.uploadedPassengersFont}>
        {passengers.length ?? 0} pasajeros cargados
      </p>
        {passengers?.length > 0 ? (
          passengers.map((passenger, idx) => {
            const {
              dv,
              saved,
              email,
              fatherLastName,
              firstName,
              rut,
              errorMessage,
              passport,
              currentAccount,
              costCenter,
            } = passenger;

            let costCenterInfo: any;

            if(userRole!=='SCC') {
              costCenterInfo = renderCostCenterInfo(costCenter);
            }else{
              costCenterInfo = null
            }

            return (
              <div key={idx}>
                <Row
                  align="middle"
                  justify="space-between"
                  className={`${styles.passengerRow} ${
                    !saved ? styles.errorRow : null
                  }`}>
                  <Col
                    span={1}
                    className={`${styles.errorColor} ${styles.centered}`}>
                    {!saved ? <CloseCircleOutlined /> : null}
                  </Col>
                  <Col span={6}>
                    <p
                      className={`${styles.passengerNameFont} ${styles.alignLeft}`}>
                      {firstName?.toLocaleLowerCase().trim()}{' '}
                      {fatherLastName?.toLocaleLowerCase().trim()}
                    </p>
                    <p
                      className={`${styles.userEmailFont} ${styles.alignLeft}`}>
                      {email}
                    </p>
                    {saved ? (
                      <p className={`${styles.rutFont} ${styles.alignLeft}`}>
                        {rut && dv
                          ? `RUT ${formatRut(`${rut}${dv}`)}`
                          : `PASAPORTE ${passport}`}
                      </p>
                    ) : (
                      errorMessage
                    )}
                  </Col>

                  {userRole !== 'SCC' && userRole !== 'ADMIN_SCC' && (
                    <Col span={6}>
                      <Col>
                      <p
                        className={`${styles.passengerNameFont} ${styles.alignLeft}`}>
                        Cuenta Corriente:
                      </p>
                      <p
                        className={`${styles.userEmailFont} ${styles.alignLeft}`}>
                        {currentAccount ? currentAccount: null}
                        
                      </p>
                    </Col>
                    <Col>
                      <p
                        className={`${styles.passengerNameFont} ${styles.alignLeft}`}>
                        Centro de Costos:
                      </p>
                      <p
                        className={`${styles.userEmailFont} ${styles.alignLeft}`}>
                        {costCenterInfo ? costCenterInfo: null}
                        
                      </p>
                    </Col>                                                            
                  </Col>
                  )}
                  
                  <Col span={4}>
                    <Row align="middle" justify="center">
                      <div
                        className={styles.removePassenger}
                        key={rut}
                        onClick={() => removePassenger(passenger)}>
                        <CloseCircleOutlined />
                      </div>
                    </Row>
                  </Col>

                </Row>
                <div className={styles.passengerHorizontalLine} />
              </div>
            );
          })
        ) : (
          <p>Sin pasajeros</p>
        )}
      </Col>
    </Row>
  );
};

export default PassengersList;

import React, { FC } from 'react'
import { Col, Row, Tooltip } from 'antd'

import DiscountBar from '../../../../components/DiscountBar'

import { PropTypes } from './types'

import styles from './styles.module.css'

const HeaderWithDiscount: FC<PropTypes> = ({
  userName,
  executiveName,
  totalAmount,
  balance,
  executiveEmail,
  executivePhone,
}) => {
  const renderLeftPanel = () => (
    <div className={styles.leftPanel}>
      <div className={styles.welcomeMessageFont}>
        ¡Bienvenido de nuevo, {userName}!
      </div>
      <div className={styles.welcomeMessageDescriptionFont}>
        Con Simplus, puedes comprar y administrar todos los viajes de trabajo de
        manera rápida y sencilla.
      </div>
    </div>
  )

  const renderExecutive = () => (
    <Row className={styles.executive} justify="space-around" align="middle">
      <Col>
        <div className={styles.label}>Ejecutivo asignado</div>
      </Col>
      <Col>
        <div className={styles.executiveName}>
          {executiveName
            ? executiveName
            : 'Aún no tienes un ejecutivo asignado...'}
        </div>
      </Col>
      <Col>
        <Tooltip
          placement="rightTop"
          title={`Correo: ${executiveEmail} Teléfono: ${executivePhone}`}>
          <div className={styles.contactFont}>Contacto</div>
        </Tooltip>
      </Col>
    </Row>
  )

  const renderRightPanel = () => {
    return (
      <div className={styles.rightPanel}>
        {renderExecutive()}
        <div className={styles.creditLine}>
          <div className={styles.discountCard}>
            <DiscountBar isHome />
          </div>
          <div className={styles.balance}>
            <div className={styles.labelTwo}>Cupo disponible</div>
            <div className={styles.amount}>${totalAmount}</div>
            <div className={styles.divider} />
            <div className={styles.labelTwo}>Saldo disponible</div>
            <div className={styles.amount}>${balance}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderLeftPanel()}
      {renderRightPanel()}
    </div>
  )
}

export default HeaderWithDiscount

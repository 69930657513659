import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'antd'

import { BusBackPicture, BusFrontPicture } from '../../../assets/bus'
import { useEventSave } from '../../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../../services/auth'

import AssignPassengers from './AssignPassengers'
import MobileAssignPassengers from './MobileAssignPassengers'
import PriceLegend from './PriceLegend'
import SeatsDisplay from './SeatsDisplay'
import TripInformation from './TripInformation'

import styles from './styles.module.css'

const SeatSelection: FC = () => {
  const [isGone, setIsGone] = useState<boolean>(true)

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'seleccion_de_asientos' as string

    useEventSave('vista_seleccion_de_asientos', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const onChangeTravel = (isGone: boolean): void => {
    setIsGone(isGone)
  }

  const conditionalHeight = () => {
    return window.screen.height < 900
      ? {
          height: '100%',
        }
      : {}
  }

  return (
    <Col className={styles.container}>
      <Row justify="center">
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 12, order: 1, pull: 12 }}
          lg={{ span: 8, order: 1, pull: 0 }}
          className={styles.leftCol}>
          {window.screen.height < 900 ? null : <TripInformation />}

          <Row style={conditionalHeight()}>
            <Col xs={0} lg={24}>
              <AssignPassengers />
            </Col>
            <Col xs={24} lg={0} style={conditionalHeight()}>
              {window.screen.height < 1024 ? (
                <div className={styles.top15}>
                  <MobileAssignPassengers />
                </div>
              ) : (
                <AssignPassengers />
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 20, order: 3 }}
          sm={{ span: 12, order: 3 }}
          lg={{ span: 6, order: 2 }}>
          <Row>
            <Col xs={0} md={0} lg={24}>
              <BusFrontPicture className={styles.busPicture} />
            </Col>
          </Row>
          <Row>
            <Col xs={24} md={24} lg={0} className={styles.marginTop2} />
            <Col span={24} style={{ zIndex: 5 }} className={styles.mobileBus}>
              <SeatsDisplay onChangeTravel={onChangeTravel} />
            </Col>
            <Col xs={24} md={24} lg={0} className={styles.marginBottom2} />
          </Row>
          <Row>
            <Col xs={0} md={0} lg={24} style={{ zIndex: 4 }}>
              <BusBackPicture className={styles.busPictureBack} />
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 20, order: 2 }}
          sm={{ span: 12, order: 2, push: 12 }}
          lg={{ span: 4, order: 3, push: 0 }}
          className={styles.mobileMargin}>
          <PriceLegend isGone={isGone} />
        </Col>
      </Row>
    </Col>
  )
}

export default SeatSelection

import React, { FC } from 'react'
import { Col, Collapse, Divider, Row } from 'antd'
import moment from 'moment'

import { DiscountTypeEnum } from '../../../../../helpers/enums'
import { numberWithSeparator } from '../../../../../helpers/number.helper'
import { translatePaymentType } from '../../../../../helpers/travel-page/compute.helper'
import {
  CleanTicketResume,
  GoneReturnTravel,
  Seat,
  TravelTransactionResumeProps,
} from '../../../../../types/page-types/travel-details-page.types'

import { discountType } from './vars'

import styles from './styles.module.css'

const { Panel } = Collapse

const getTravelsSeat = (travel: GoneReturnTravel) => ({
  goneSeats: travel?.gone?.seats,
  returnSeats: travel?.return?.seats,
})

const genTicketName = (title: string, amount: number) => {
  if (amount === 1) return `Boleto de ${title}`
  else return `Boletos de ${title}`
}

// additonal properties like more ticket info can be added here
const genTicketInfo = (
  acc: CleanTicketResume[],
  title: string,
  price: number,
) => ({
  ...acc,
  [price]: {
    ...(acc[price] || {}),
    title: genTicketName(
      title,
      acc[price]?.totalCount ? acc[price]?.totalCount + 1 : 1,
    ),
    price: acc[price]?.price ? acc[price]?.price + price : price,
    totalCount: acc[price]?.totalCount ? acc[price]?.totalCount + 1 : 1,
  },
})

const genSeatsTransactionResume = (title: string, seats: Seat[]) => {
  const reducer = (acc: CleanTicketResume[], seat: Seat) =>
    genTicketInfo(acc, title, seat.originalPrice || seat.price)
  return seats.reduce(reducer, [])
}

const TravelTransactionResume: FC<TravelTransactionResumeProps> = ({
  travel,
}) => {
    const { goneSeats, returnSeats } = getTravelsSeat(travel)
  const discountDetails = travel?.discountDetails
  const goneAccount = {
    costCenter: travel?.gone?.costCenter,
    currentAccount: travel?.gone?.currentAccount,
  }

  const returnAccount = {
    costCenter: travel?.return?.costCenter,
    currentAccount: travel?.return?.currentAccount,
  }

  const fechaActual = moment(travel?.gone?.createdAt);

  const dif_hour = process.env.GATSBY_DIF_HOUR;
  const difHourAsNumber= parseFloat("0") ;
  var fechaSumada ;
  if (typeof dif_hour === 'string') {
    // Ahora sabemos que dif_hour es una cadena válida
    const difHourAsNumber = parseFloat(dif_hour);
    if (!isNaN(difHourAsNumber)) {
      // dif_hour se ha convertido con éxito a un número        
      // Suma Diferencia horaria
      fechaSumada = fechaActual.add(difHourAsNumber, 'hours');
    } else {
      // dif_hour no se pudo convertir a un número válido
      console.error('dif_hour no es un número válido.');
    }
  } else {
    // dif_hour es undefined o no es una cadena
    console.error('dif_hour no está definido o no es una cadena.');
  }
    
  

 

  const goneResume = goneSeats
    ? genSeatsTransactionResume('ida', goneSeats)
    : {}
  const returnResume = returnSeats
    ? genSeatsTransactionResume('vuelta', returnSeats)
    : {}

  const cleanResume = [
    ...Object.values(goneResume),
    ...Object.values(returnResume),
  ] as CleanTicketResume[]

  const cleanResumeTotal = cleanResume.reduce(
    (acc, resume) => acc + resume.price,
    0,
  )

  const getDiscountPercentage = (
    originalPrice: number,
    priceDifference: number,
  ) => {
    if (!priceDifference) return 0
    return ((100 / (originalPrice || 1)) * (priceDifference || 0)).toFixed(0)
  }

  const renderDiscount = () => {
    if (!discountDetails?.priceDifference) return <span />
    return (
      <Row
        align="middle"
        className={styles.discountRow}
        justify="space-between"
        gutter={[0, 10]}>
        <Col span={12}>
          <div className={styles.label}>Descuento línea de crédito</div>
        </Col>
        <Col span={12}>
          <div className={styles.discountText}>
            - $ {numberWithSeparator(discountDetails?.priceDifference || 0)} CLP
          </div>
        </Col>
        {discountDetails?.discountType ===
          DiscountTypeEnum.PERCENT_DISCOUNT && (
          <>
            <Col span={12}>
              <div className={styles.label}>Porcentaje descuento aplicado</div>
            </Col>
            <Col span={12}>
              <div className={styles.discountText}>
                {getDiscountPercentage(
                  discountDetails?.originalPrice,
                  discountDetails?.priceDifference,
                )}{' '}
                %
              </div>
            </Col>
          </>
        )}

        <Col span={12}>
          <div className={styles.label}>Tipo de descuento </div>
        </Col>
        <Col span={12}>
          <div className={styles.discountType}>
            {discountType[
              discountDetails?.discountType as
                | 'PERCENT_DISCOUNT'
                | 'FLAT_RATE_DISCOUNT'
                | 'none'
            ] || 'Sin definir'}
          </div>
        </Col>
      </Row>
    )
  }

  return (
    <Row className={styles.buyResume}>
      <Col span={24}>
        <Collapse ghost defaultActiveKey={1}>
          <Panel header="Resumen de compra" key={1}>
            <Divider style={{ margin: 0 }} />
            <Row
              align="middle"
              className={styles.buyResumeRow}
              justify="space-between">
              <Col span={12}>
                <p>Método pago</p>
              </Col>
              <Col span={12}>
                <p>
                  {translatePaymentType(travel?.gone?.paymentType) ||
                    translatePaymentType(travel?.return?.paymentType)}
                </p>
              </Col>
            </Row>
            {travel?.gone?.paymentType === 'CREDIT' && (
              <Row
                align="middle"
                className={styles.buyResumeRow}
                justify="space-between">
                <Col span={12}>
                  <p>Centro de costo</p>
                </Col>
                <Col span={12}>
                  <p>
                    {goneAccount?.costCenter?.name ??
                      returnAccount?.costCenter?.name}
                  </p>
                </Col>
              </Row>
            )}
                        <Row
              align="middle"
              className={styles.buyResumeRow}
              justify="space-between">
              <Col span={12}>
                <p>Fecha de compra</p>
              </Col>
              <Col span={12}>
                <p className={styles.dateDisplay}>
                  {moment(fechaSumada).format(
                    'ddd, MMMM DD YYYY, HH:mm',
                  )}
                </p>
              </Col>
            </Row>
            <Divider />
            <Row
              align="middle"
              className={styles.buyResumeRow}
              justify="space-between">
              <Col span={12}>
                <p>Cantidad de pasajeros</p>
              </Col>
              <Col span={12}>
                <p className={styles.dateDisplay}>
                  {goneSeats?.length} Pasajero(s)
                </p>
              </Col>
            </Row>
            {cleanResume.map(({ price, title, totalCount }, index) => (
              <Row
                align="middle"
                className={styles.buyResumeRow}
                justify="space-between"
                key={index}>
                <Col span={12}>
                  <p>{`${totalCount} x ${title}`}</p>
                </Col>
                <Col span={12}>
                  <p className={styles.pricesText}>
                    $ {numberWithSeparator(price)} CLP
                  </p>
                </Col>
              </Row>
            ))}
            {renderDiscount()}
            <Divider />
            <Row
              align="middle"
              className={styles.totalRow}
              justify="space-between">
              <Col span={12}>
                <p>Total pagado</p>
              </Col>
              <Col span={12}>
                <p>
                  ${' '}
                  {numberWithSeparator(
                    cleanResumeTotal - (discountDetails?.priceDifference || 0),
                  )}
                </p>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

export default TravelTransactionResume

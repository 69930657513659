import { message } from 'antd'
import { isEmpty } from 'ramda'

type cecoType = {
  currentAccountId: string
  name: string
  description: string
}

export default (ceco: cecoType) => {
  if (!ceco) {
    message.warning('Debes ingresar la información requerida')
    return false
  }

  const { name, currentAccountId, description } = ceco

  if (!currentAccountId || isEmpty(currentAccountId)) {
    message.warning('Debes seleccionar la cuenta corriente')
    return false
  }

  if (!name || isEmpty(name)) {
    message.warning('Debes ingresar el Nombre requerido')
    return false
  }

  if (!description || isEmpty(description)) {
    message.warning('Debes ingresar la descripción requerida')
    return false
  }

  return true
}

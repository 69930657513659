import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Button, Col, Form, Input, message, Row, Select } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import {
  businessAreaSelectContent,
  estimatedAmountSelectContent,
  estimatedPassengerSelectContent,
} from '../../../helpers/companyRegisterSelectContent'
import { formatRut, validateRut } from '../../../helpers/rutValidators'
import { CHECK_COMPANY_AVALAIBILITY } from '../../../queries/register-page'
import { Address } from '../../../types/address.type'
import {
  CompanyRegistrationFormProps,
  CompanyRegistrationFormValues,
} from '../../../types/component-types/CompanyRegistrationForm.types'

import styles from './styles.module.css'

const GET_ADDRESSES_QUERY = gql`
  query getAddresses @api(name: "users") {
    getAddresses {
      _id
      countryName
      regions {
        name
        communes
      }
    }
  }
`
const { Option } = Select

const CompanyRegistrationForm: React.FC<CompanyRegistrationFormProps> = ({
  stage,
  accountType,
  stageHandler,
  informationHandler,
}) => {
  const { loading, data } = useQuery(GET_ADDRESSES_QUERY)
  const [addressObject, setAddressObject] = useState([] as Address[])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [isRutAvailable, setIsRutAvailable] = useState(true)
  const [formValues, setFormValues] = useState(
    {} as CompanyRegistrationFormValues,
  )
  const [checkCompanyAvalaibility, { loading: checkRutLoading }] = useLazyQuery(
    CHECK_COMPANY_AVALAIBILITY,
    {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: data => {
        if (data) {
          const { checkCompanyAvalaibility } = data

          setIsRutAvailable(checkCompanyAvalaibility)
        }
      },
    },
  )

  let timer: NodeJS.Timeout = setTimeout(() => {
    console.log('')
  }, 100)

  const requestCompanyAvailability = (rut: string) => {
    const cleanRut = formatRut(rut)
    clearTimeout(timer)
    if (validateRut(cleanRut))
      timer = setTimeout(async () => {
        await checkCompanyAvalaibility({
          variables: {
            rut: cleanRut,
          },
        })
      }, 500)
  }

  const onFinish = async (values: CompanyRegistrationFormValues) => {
    setFormValues(values)
    if (isRutAvailable) {
      informationHandler(values)
      stageHandler(3)
    } else message.error('Revisar RUT empresa')
  }

  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<CompanyRegistrationFormValues>,
  ) => void = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const hidden = stage !== 2 || accountType !== 'ADMIN_CCC'

  const renderCompanyRutCheckStatus = () => {
    return checkRutLoading ? (
      <div className={styles.successText}>Comprobando RUT ...</div>
    ) : !isRutAvailable ? (
      <div className="ant-form-item-explain ant-form-item-explain-error">
        Esta empresa ya existe en el sistema
      </div>
    ) : null
  }

  const renderCompanyRutInput = () => {
    return (
      <Form.Item
        className={styles.inputContainer}
        name="companyRut"
        normalize={companyRut => {
          return formatRut(companyRut)
        }}
        rules={[
          { required: true, message: 'Este campo es obligatorio' },
          ({}) => ({
            validator(rule, value) {
              if (validateRut(value) || !value) return Promise.resolve()

              return Promise.reject('RUT inválido')
            },
          }),
        ]}
        hidden={hidden}>
        <Input
          className={styles.input}
          placeholder="Ejemplo: 76.325.945-k"
          onChange={({ target }) => requestCompanyAvailability(target.value)}
        />
      </Form.Item>
    )
  }

  const addressObjectByCountry = addressObject.find(
    object => object.countryName === selectedCountry,
  )

  const addressObjectByCity = addressObjectByCountry?.regions?.find(
    object => object.name === selectedCity,
  )

  useEffect(() => {
    if (!loading && data) {
      setAddressObject(data.getAddresses)
      setSelectedCountry('Chile')
    }
  }, [data, loading])

  return (
    <Row>
      <Col xs={24}>
        <Form
          initialValues={{ remember: true, country: 'Chile' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          {hidden ? null : <p className={styles.labelFont}>Razón Social</p>}
          <Form.Item
            className={styles.inputContainer}
            name="name"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Input
              className={styles.input}
              placeholder="Ejemplo: mi razón social"
            />
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>RUT de la empresa</p>
          )}
          {renderCompanyRutCheckStatus()}
          {renderCompanyRutInput()}

          {hidden ? null : (
            <p className={styles.labelFont}>Giro de la empresa</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="commercialActivity"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Input
              className={styles.input}
              
              placeholder="Ejemplo: mi giro comercial"
            />
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>
              ¿Cuántos pasajeros estimas transportar mensualmente?
            </p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="estimatedPassengers"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              placeholder="Seleccione una opción"
              allowClear>
              {estimatedPassengerSelectContent.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.content}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>
              Estimación de compra mensual en viajes
            </p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="estimatedAmount"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              placeholder="Seleccione una opción"
              allowClear>
              {estimatedAmountSelectContent.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.content}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>Rubro de la empresa</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="businessArea"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              placeholder="Seleccione una opción">
              {businessAreaSelectContent.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.content}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {hidden ? null : (
            <p className={styles.addressLabelFont}>Ubicación de tu empresa</p>
          )}

          {hidden ? null : <p className={styles.labelFont}>País</p>}
          <Form.Item
            className={styles.inputContainer}
            name="country"
            normalize={countryName => {
              setSelectedCountry(countryName)
              return countryName
            }}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              disabled={true}
              placeholder="Seleccione País">
              {addressObject?.map(country => (
                <Option key={country.countryName} value={country.countryName}>
                  {country.countryName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Row justify="space-between">
            <Col xs={24} sm={11}>
              {hidden ? null : <p className={styles.labelFont}>Región</p>}
              <Form.Item
                className={styles.leftSelectFormItem}
                name="city"
                normalize={city => {
                  setSelectedCity(city)
                  return city
                }}
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                ]}
                hidden={hidden}>
                <Select
                  className={styles.selectAddress}
                  size="large"
                  placeholder="Seleccione Región">
                  {addressObjectByCountry?.regions?.map(city => (
                    <Option key={city.name} value={city.name}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={11}>
              {hidden ? null : <p className={styles.labelFont}>Comuna</p>}
              <Form.Item
                className={styles.rightSelectFormItem}
                name="commune"
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                ]}
                hidden={hidden}>
                <Select
                  className={styles.selectAddress}
                  size="large"
                  placeholder="Seleccione Comuna">
                  {addressObjectByCity?.communes?.map(commune => (
                    <Option key={commune} value={commune}>
                      {commune}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col xs={24} sm={11}>
              {hidden ? null : <p className={styles.labelFont}>Calle</p>}
              <Form.Item
                name="street"
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                  {
                    pattern: /^[a-zA-Z0-9\-\s]+$/,
                    message: 'Introduzca una calle válida',
                  },
                ]}
                hidden={hidden}>
                <Input
                  className={styles.input}
                  placeholder="Ejemplo: Calle x"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={11}>
              {hidden ? null : (
                <p className={styles.labelFont}>Número de Calle</p>
              )}
              <Form.Item
                name="streetNumber"
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                  {
                    pattern: /^[a-zA-Z0-9\-\s]+$/,
                    message: 'Introduzca un número',
                  },
                ]}
                hidden={hidden}>
                <Input className={styles.input} placeholder="Ejemplo: 123" />
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.lineSeparator} />
          <Form.Item hidden={hidden}>
            <Row justify="space-between" className={styles.buttonContainer}>
              <Col xs={{ span: 24, order: 2 }} sm={{ span: 12, order: 1 }}>
                <Button
                  className={styles.backButton}
                  onClick={() => stageHandler(1)}>
                  Volver
                </Button>
              </Col>
              <Col xs={{ span: 24, order: 1 }} sm={{ span: 12, order: 2 }}>
                <Button
                  className={styles.continueButton}
                  htmlType="submit"
                  block>
                  Continuar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default CompanyRegistrationForm

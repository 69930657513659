import moment from 'moment'

import { Travel as IntegrationTravel } from '../../screens/travel-page/types'
import { Travel } from '../../types/page-types/travel-details-page.types'
import { fixTimeFormat } from '../my-travel-page'

export const formatDateAndTime = (date: Date, time: number): string =>
  `${moment.utc(date).format('DD/MM/YYYY')}, ${fixTimeFormat(time)}`

export const travelToIntegrationTravel = (travel: Travel): IntegrationTravel => {
  return {
    departs_at: formatDateAndTime(travel.departsAt, travel.departsTime),
    bus_operator_name: travel.operatorName,
    travelId: travel._id,
    departure_terminal_name: travel.departureTerminal,
    arrives_at: formatDateAndTime(travel.arrivesAt, travel.arrivesTime),
    destination_terminal_name: travel.destinationTerminal,
  } as unknown as IntegrationTravel
}

export const translatePaymentType = (paymentType: string | undefined): string => {

  if (!paymentType) return ''

  const options: Record<string, string> = {
    CREDIT: 'Crédito',
    PAYMENT_GATEWAY: 'WebPay'
  }

  return options[paymentType]
}


import React, { FC } from 'react'
import { Button, Checkbox, Col, Divider, Row, Select } from 'antd'

import { CreditLineFilterProps } from '../types'

import styles from './styles.module.css'

const { Option } = Select

const CreditLineFilter: FC<CreditLineFilterProps> = ({
  applyFilter,
  creditLineOptions,
  costCenterList,
  handleSelectChange,
  selectedCostCenters,
}) => {
  const click = (id: string): void => {
    document.getElementById(id)?.click()
  }
  return (
    <Row align="top" className={styles.creditLineFilter} justify="space-around">
      <Col span={24}>
        <Row className={styles.creditLineRow}>
          <p>Cuentas Corrientes</p>
          <Select
            className={styles.costCenterSelect}
            onChange={value => handleSelectChange(value as string)}
            optionFilterProp="children"
            placeholder="Seleccione línea de crédito."
            showSearch
            defaultActiveFirstOption>
            <Option key={0} value={'todos'}>
              Todos
            </Option>
            {creditLineOptions.length > 0
              ? creditLineOptions.map(({ _id, name }) => (
                  <Option key={_id} value={_id}>
                    {name}
                  </Option>
                ))
              : null}
          </Select>
        </Row>
        <Divider className={styles.costCenterDivider} />
        <Row className={styles.costCenterRow}>
          {costCenterList && costCenterList.length > 0 && (
            <p>{costCenterList.length > 1 ? 'Centros' : 'Centro'} de Costo</p>
          )}
          {costCenterList && costCenterList.length > 0
            ? costCenterList.map(({ _id, name, passengers }) => (
                <Row
                  align="middle"
                  className={styles.costCenterRowContent}
                  key={_id}
                  justify="space-between"
                  onClick={() => click(`check-${_id}`)}>
                  <p className={styles.costCenterText}>
                    {name} ({passengers.length})
                  </p>
                  <Checkbox
                    id={`check-${_id}`}
                    onChange={e => selectedCostCenters(e.target.checked, _id)}
                    onClick={() => click(`check-${_id}`)}
                  />
                </Row>
              ))
            : null}
        </Row>
        <Row align="middle" justify="center">
          <Col span={24} className={styles.centered}>
            {window?.innerWidth < 768 ? <span /> : <Divider />}
            <Button
              className={styles.costCenterFilterButton}
              onClick={applyFilter}>
              Aplicar
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default CreditLineFilter

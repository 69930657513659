import React, { useEffect, useState } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { Button, Checkbox, Col, Form, Input, message, Row, Select } from 'antd'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import { formatRut, validateRut } from '../../../helpers/rutValidators'
import { CHECK_COMPANY_AVALAIBILITY } from '../../../queries/register-page'
import {
  AdditionInformationFormProps,
  AdditionInformationFormValues,
} from '../../../types/component-types/AdditionalInformationForm.types'

import styles from './styles.module.css'
import { estimatedAmountSelectContent } from '../../../helpers/companyRegisterSelectContent'
import { Address } from '../../../types/address.type'


const GET_ADDRESSES_QUERY = gql`
query getAddresses @api(name: "users") {
  getAddresses {
    _id
    countryName
    regions {
      name
      communes
    }
  }
}
`
const { Option } = Select

const AdditionalInformationForm: React.FC<AdditionInformationFormProps> = ({
  stage,
  accountType,
  stageHandler,
  informationHandler,
  registerUser,
  loading,
}) => {
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [isRutAvailable, setIsRutAvailable] = useState(true)

  const [checkCompanyAvalaibility] = useLazyQuery(CHECK_COMPANY_AVALAIBILITY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onCompleted: data => {
      if (data) {
        const { checkCompanyAvalaibility } = data
        setIsRutAvailable(checkCompanyAvalaibility)
        if (checkCompanyAvalaibility){
          registerUser()
        }else{
          message.error('Ya existe la empresa.')
        } 

      }
    },
  })

  const { data } = useQuery(GET_ADDRESSES_QUERY)
  const [addressObject, setAddressObject] = useState([] as Address[])
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [formValues, setFormValues] = useState(
    {} as AdditionInformationFormValues,
  )


  // const onFinish = async (values: AdditionInformationFormValues) => {
  //   const { companyRut } = values
  //   await checkCompanyAvalaibility({
  //     variables: {
  //       rut: companyRut,
  //     },
  //   })
  // }

  const onFinish = async (values: AdditionInformationFormValues) => {
    const { companyRut } = values
    await checkCompanyAvalaibility({
      variables: {
        rut: companyRut,
      },
    })

    if(isRutAvailable){
      setFormValues(values)
      informationHandler(values)
    }

  }

  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<AdditionInformationFormValues>,
  ) => void = errorInfo => {
    console.log(errorInfo)
  }

  const hidden = stage !== 2 || accountType !== 'ADMIN_SCC'

  const addressObjectByCountry = addressObject.find(
    object => object.countryName === selectedCountry,
  )

  const addressObjectByCity = addressObjectByCountry?.regions?.find(
    object => object.name === selectedCity,
  )

  useEffect(() => {
    if (!loading && data) {
      setAddressObject(data.getAddresses)
      setSelectedCountry('Chile')
    }
  }, [data, loading])

  return (
    <Row>
      <Col xs={24}>
        <Form
          initialValues={{ remember: true, country: 'Chile' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          {hidden ? null : (
            <p className={styles.labelFont}>Nombre de tu empresa</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="name"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Input
              className={styles.input}
              onChange={({ target }) =>
                informationHandler({ name: target.value })
              }
              placeholder="Ejemplo: mi empresa ldta"
            />
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>RUT de la empresa</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="companyRut"
            normalize={companyRut => {
              return formatRut(companyRut)
            }}
            rules={[
              { required: true, message: 'Este campo es obligatorio' },
              ({}) => ({
                validator(rule, value) {
                  if (validateRut(value) || !value) {
                    informationHandler({ companyRut: value })
                    return Promise.resolve()
                  }
                  return Promise.reject('RUT inválido')
                },
              }),
            ]}
            hidden={hidden}>
            <Input
              className={styles.input}
              placeholder="Ejemplo: 10.458.369-5"
            />
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>Giro de la empresa</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="commercialActivity"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Input
              className={styles.input}
              
              placeholder="Ejemplo: mi giro comercial"
            />
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>Cantidad aproximada de empleados</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="estimatedPassengers"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              onChange={value =>
                informationHandler({ estimatedPassengers: value.toString() })
              }
              placeholder="Seleccione una opción">
              <Option value="1-50">1 - 50</Option>
              <Option value="51-100">51 - 100</Option>
              <Option value="101-150">101 - 150</Option>
              <Option value="151-200">151 - 200</Option>
              <Option value="201-250">201 - 250</Option>
              <Option value="251-x">{'>'} 250</Option>
            </Select>
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>
              Estimación de compra mensual en viajes
            </p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="estimatedAmount"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              placeholder="Seleccione una opción"
              allowClear>
              {estimatedAmountSelectContent.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.content}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>Rubro de la empresa</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="businessArea"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              onChange={value =>
                informationHandler({ businessArea: value.toString() })
              }
              placeholder="Seleccione una opción">
              <Option value="Minería">Minería</Option>
              <Option value="Proveedores de Minería">
                Proveedores de Minería
              </Option>
              <Option value="Construcción">Construcción</Option>
              <Option value="Ingeniería">Ingeniería</Option>
              <Option value="Manufactureras y Distribuidores">
                Manufactureras y Distribuidores
              </Option>
              <Option value="Explotación Recursos Naturales (No minería)">
                Explotación Recursos Naturales (No minería)
              </Option>
              <Option value="Químicos y Plásticos">Químicos y Plásticos</Option>
              <Option value="Instituciones Públicas, Educación y Salud">
                Instituciones Públicas, Educación y Salud
              </Option>
              <Option value="Otros">Otros</Option>
            </Select>
          </Form.Item>

          {hidden ? null : (
            <p className={styles.labelFont}>Cargo del solicitante</p>
          )}
          <Form.Item
            className={styles.inputContainer}
            name="companyPosition"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Input
              className={styles.input}
              onChange={({ target }) =>
                informationHandler({ companyPosition: target.value })
              }
              placeholder="Ejemplo: Encargado de Compras"
            />
          </Form.Item>

          {hidden ? null : (
            <p className={styles.addressLabelFont}>Ubicación de tu empresa</p>
          )}

          {hidden ? null : <p className={styles.labelFont}>País</p>}
          <Form.Item
            className={styles.inputContainer}
            name="country"
            normalize={countryName => {
              setSelectedCountry(countryName)
              return countryName
            }}
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            hidden={hidden}>
            <Select
              className={styles.input}
              size="large"
              disabled={true}
              placeholder="Seleccione País">
              {addressObject?.map(country => (
                <Option key={country.countryName} value={country.countryName}>
                  {country.countryName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Row justify="space-between">
            <Col xs={24} sm={11}>
              {hidden ? null : <p className={styles.labelFont}>Región</p>}
              <Form.Item
                className={styles.leftSelectFormItem}
                name="city"
                normalize={city => {
                  setSelectedCity(city)
                  return city
                }}
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                ]}
                hidden={hidden}>
                <Select
                  className={styles.selectAddress}
                  size="large"
                  placeholder="Seleccione Región">
                  {addressObjectByCountry?.regions?.map(city => (
                    <Option key={city.name} value={city.name}>
                      {city.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={11}>
              {hidden ? null : <p className={styles.labelFont}>Comuna</p>}
              <Form.Item
                className={styles.rightSelectFormItem}
                name="commune"
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                ]}
                hidden={hidden}>
                <Select
                  className={styles.selectAddress}
                  size="large"
                  placeholder="Seleccione Comuna">
                  {addressObjectByCity?.communes?.map(commune => (
                    <Option key={commune} value={commune}>
                      {commune}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between">
            <Col xs={24} sm={11}>
              {hidden ? null : <p className={styles.labelFont}>Calle</p>}
              <Form.Item
                name="street"
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                  {
                    pattern: /^[a-zA-Z0-9\-\s]+$/,
                    message: 'Introduzca una calle válida',
                  },
                ]}
                hidden={hidden}>
                <Input
                  className={styles.input}
                  placeholder="Ejemplo: Calle x"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={11}>
              {hidden ? null : (
                <p className={styles.labelFont}>Número de Calle</p>
              )}
              <Form.Item
                name="streetNumber"
                rules={[
                  { required: true, message: 'Este campo es obligatorio' },
                  {
                    pattern: /^[a-zA-Z0-9\-\s]+$/,
                    message: 'Introduzca un número',
                  },
                ]}
                hidden={hidden}>
                <Input className={styles.input} placeholder="Ejemplo: 123" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item hidden={hidden} className={styles.termsAndConditions}>
            <Checkbox
              onChange={() => setTermsAndConditions(!termsAndConditions)}>
              Acepto{' '}
              <a href="/app/terminos-y-condiciones" target="_blank">
                Términos y Condiciones
              </a>
            </Checkbox>
          </Form.Item>

          <Form.Item className={styles.buttonContainer} hidden={hidden}>
            <Row justify="space-between">
              <Col xs={{ span: 24, order: 2 }} sm={{ span: 11, order: 1 }}>
                <Button
                  className={styles.backButton}
                  onClick={() => stageHandler(1)}>
                  Volver
                </Button>
              </Col>
              <Col xs={{ span: 24, order: 1 }} sm={{ span: 11, order: 2 }}>
                <Button
                  className={
                    termsAndConditions
                      ? styles.continueButton
                      : styles.continueButtonDisabled
                  }
                  loading={loading}
                  disabled={!termsAndConditions}
                  htmlType="submit"
                  block>
                  Registrar
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}

export default AdditionalInformationForm

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import { Location, RouteComponentProps } from '@reach/router'
import queryString from 'query-string'

// Any type used for convenience on this parameter function type.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withLocation = (ComponentToWrap: any) => (
  props: RouteComponentProps,
): JSX.Element => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
)

export default withLocation

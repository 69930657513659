import React from 'react'
import { Col, Divider, Row, Typography } from 'antd'

import { numberWithSeparator } from '../../../helpers/number.helper'
import { TotalCostProps } from '../types/props'

import styles from './styles.module.css'

const { Text } = Typography

const TotalCost: React.FC<TotalCostProps> = ({ report }) => {
  const { totalCost, purchasedTickets, usedTickets } = report

  const renderTotalCost = () => (
    <Text strong className={styles.text}>
      {`$${numberWithSeparator(totalCost)}`}
    </Text>
  )

  const renderPurchasedTickets = () => (
    <Text strong className={styles.text}>
      {`${numberWithSeparator(purchasedTickets)}`}
    </Text>
  )

  const renderUsedTickets = () => (
    <Text className={styles.textOpacity}>{`${usedTickets} utilizados`}</Text>
  )

  return (
    <Row className={styles.rowMargingX}>
      <Col className={styles.contentCard} span={24}>
        <Row justify="space-between" align="middle" className={styles.height90}>
          <Col xs={10} className={styles.colTotalCost}>
            <Row>
              <Col xs={24}>
                <Text className={styles.textOpacity}>Gasto total</Text>
              </Col>
              <Col xs={24}>{renderTotalCost()}</Col>
            </Row>
          </Col>
          <Col>
            <Divider type="vertical" plain className={styles.divider} />
          </Col>
          <Col xs={10} className={styles.colTotalCost}>
            <Row>
              <Col xs={24}>
                <Text className={styles.textOpacity}>Boletos comprados</Text>
              </Col>
              <Col xs={24}>{renderPurchasedTickets()}</Col>
              <Col xs={24}>{renderUsedTickets()}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default TotalCost

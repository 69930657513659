import React, { FC, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { message } from 'antd'

import CreditLineRequestDrawer from '../../components/CreditLineRequestDrawer'
import SEO from '../../components/seo'
import { HelpRequestStatusEnum } from '../../helpers/enums'
import { useEventSave } from '../../hooks/analytics.hook'
import { GET_ALL_HELP_REQUEST, GET_COMPANY_INFORMATION } from '../../queries'
import { getUser, tokenInfo } from '../../services/auth'

import DisplayColumn from './DisplayColumn'
import FilterColumn from './FilterColumn'
import MyRequestsLayout from './MyRequestsLayout'
import {
  GetCompanyDetailType,
  GetHelpRequestsType,
  HelpRequestPaginationType,
  HelpRequestsHandleFilters,
} from './types'

const MyRequestPage: FC = () => {
  const { user } = tokenInfo(getUser() ?? '')
  const [status, setStatus] = useState<HelpRequestStatusEnum[]>([])
  const [pagination, setPagination] = useState<HelpRequestPaginationType>({
    limit: 5,
    page: 1,
  })
  const [visible, setVisible] = useState(false)
  const [loadRequest, { data, loading }] = useLazyQuery<GetHelpRequestsType>(
    GET_ALL_HELP_REQUEST,
    {
      fetchPolicy: 'cache-and-network',
      onError: err => message.error(err),
    },
  )
  const [
    loadCompanyInfo,
    { data: companyInfo },
  ] = useLazyQuery<GetCompanyDetailType>(GET_COMPANY_INFORMATION, {
    fetchPolicy: 'no-cache',
    variables: { companyId: user?.company },
  })

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_solicitudes' as string

    useEventSave('vista_solicitudes', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const handleFilters: HelpRequestsHandleFilters = (
    value,
    type = 'page',
    key = 'page',
  ) => {
    if (value && type === 'page') {
      setPagination({ ...pagination, [key]: value })
      loadRequest({
        variables: { filter: {}, pagination: { ...pagination, page: value } },
      })
    }
    if (type === 'reset') {
      setStatus([])
      setPagination({ ...pagination, page: 1 })
      loadRequest({
        variables: { filter: {}, pagination: { ...pagination, page: 1 } },
      })
    }
    if (type === 'filter')
      loadRequest({
        variables: {
          filter: { status },
          pagination: { ...pagination, page: 1 },
        },
      })
  }

  const handleCheckbox = (checked: boolean, value: HelpRequestStatusEnum) =>
    checked
      ? setStatus([value, ...status])
      : setStatus(status.filter(item => item !== value))

  const handleVisible = (value: boolean) => {
    setVisible(value)
    if (!value) loadRequest({ variables: { filter: { status }, pagination } })
  }

  useEffect(() => {
    loadRequest({ variables: { filter: { status }, pagination } })
    if (user.role.includes('CCC')) loadCompanyInfo()
  }, [])

  return (
    <>
      <SEO title="Mis solicitudes" />
      <MyRequestsLayout>
        <FilterColumn
          creditLine={companyInfo?.companyDetail?.creditLine}
          dataDisplay={data?.getHelpRequests?.results.find(
            ({ isCreditLine }) => isCreditLine === true,
          )}
          handleCheckbox={handleCheckbox}
          handleFilters={handleFilters}
          handleVisible={handleVisible}
          role={user.role}
          status={status}
        />
        <DisplayColumn
          data={data?.getHelpRequests?.results ?? []}
          handleFilters={handleFilters}
          loading={loading}
          pagination={pagination}
          total={data?.getHelpRequests?.total}
        />
        <CreditLineRequestDrawer
          handleVisible={handleVisible}
          visible={visible}
        />
      </MyRequestsLayout>
    </>
  )
}

export default MyRequestPage

type Info = { [key: number]: { [key: string]: { [key: string]: string } } }

export const stageInformation: Info = {
  1: {
    ADMIN_CCC: {
      header: 'Tu información personal',
      pagination: '01 / 03',
      title: 'Cuéntanos de ti',
      description:
        'Por favor, proporciona los siguientes datos para comenzar con tu proceso de registro con nosotros.',
    },
    ADMIN_SCC: {
      header: 'Tu información personal',
      pagination: '01 / 03',
      title: 'Cuéntanos de ti',
      description:
        'Por favor, proporciona los siguientes datos para comenzar con tu proceso de registro con nosotros.',
    },
  },
  2: {
    ADMIN_CCC: {
      header: 'Información de empresa',
      pagination: '02 / 03',
      title: 'Acerca de tu empresa',
      description:
        'Cuéntanos un poco respecto a tu empresa para poder brindarte una experiencia ideal.',
    },
    ADMIN_SCC: {
      header: 'Información adicional',
      pagination: '02 / 02',
      title: 'Un paso más',
      description:
        'Finalmente, llena los siguientes campos para terminar con tu registro y ser parte de Turbus.',
    },
  },
  3: {
    ADMIN_CCC: {
      header: 'Adjuntar documentos',
      pagination: '03 / 03',
      title: 'Adjunta tu documentación',
      description:
        'Por favor, adjunta los siguientes documentos para completar tu solicitud de línea de crédito. Preferiblemente utiliza documentos en formato .pdf, .doc, .png o .jpeg con un peso máximo de 50 mb.',
    },
  },
}

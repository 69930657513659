import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useLocation } from '@reach/router'
import { Button, Col, Row } from 'antd'
import { navigate } from 'gatsby'
import queryString from 'query-string'

import DiscountBar from '../../../../components/DiscountBar'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { travelToIntegrationTravel } from '../../../../helpers/travel-page/compute.helper'
import { useEventSave } from '../../../../hooks/analytics.hook'
import {
  BOOKING_TRANSBANK_PAYED,
  HAS_PERCENT_DISCOUNT,
  TRANSBANK_PAYMENT_ERROR,
  TRAVEL_BY_TRANSACTION,
} from '../../../../queries'
import { getUser, tokenInfo } from '../../../../services/auth'
import { Travel } from '../../../../types/page-types/travel-details-page.types'
import { TravelPageContext } from '../../Provider'
import { QueryString, Seat, Travel as IntegrationTravel } from '../../types'
import TravelResume from '../TravelResume'

import Success from './success.inline.svg'

import styles from './styles.module.css'
import TransbankPaymentFinished from './TransbankPayment'
import CreditPaymentFinished from './CreditPayment'
import PaymentError from '../PaymentError'

// const PaymentFinished: FC = () => {
//   const { setTransaction, setSearch, setQuotas } = useContext(TravelPageContext)

//   console.log("llega al fin pago")

//   const [travelGone, settravelGone] = useState<Travel>()
//   const [travelReturn, setTravelReturn] = useState<Travel>()
//   const [text, setText] = useState<string>('')
//   const [showTickets, setShowTickets] = useState(false)

//   const { loading: discountLoading, data: userInfo } = useQuery(
//     HAS_PERCENT_DISCOUNT,
//   )

//   const [getTransaction, { loading }] = useLazyQuery(TRAVEL_BY_TRANSACTION, {
//     onCompleted: ({ travelByTransaction }) => {
//       if (travelByTransaction) {
//         settravelGone(travelByTransaction.gone)
//         setTravelReturn(travelByTransaction.return)
//       }
//     },
//     onError: err => {
//       console.log(err)
//     },
//   })

//   const [finishPayment, { loading: mutationLoading }] = useMutation(
//     BOOKING_TRANSBANK_PAYED,
//   )


//   const location = useLocation()
//   const successURL = queryString.parse(location.href)

//   const { id, idtrx } = queryString.parse(location.search)

//   console.log("location", location)

//   console.log("location query",queryString.parse(location.search) )
//   console.log("id", id)
//   console.log("idtrx", idtrx)

//   const subTexts = {
//     sended:
//       'Los boletos se han enviado a tus pasajeros vía correo electrónico.',
//     notSended:
//       'Antes de continuar, identifica a tus pasajeros para enviarles sus boletos vía correo electrónico.',
//     semiSended:
//       'Boletos enviados a los pasajeros identificados. Sin embargo, quedan pasajeros por identificar. Identifica tus pasajeros',
//   }

//   const handleClick = () => {
//     navigate(
//       `/app/mis-viajes/${travelGone?.bookingId || travelReturn?.bookingId}`,
//     )
//   }

//   useEffect(() => {
//     const { user } = tokenInfo(getUser() ?? '')
//     useEventSave('pago_exitoso', { user: user.email })
//     setTransaction({})
//     setSearch({} as QueryString)
//     setQuotas([])

//     console.log("user email", user.email)
//     console.log("id:", id, "idtrx:", idtrx);

//     if(id){
//       console.log("Es pago crédito")
//       getTransaction({
//         variables: {
//           transaction: id,
//         },
//       })
//     }else if(idtrx){
//       console.log("Es pago transbank")
//       finishPayment({
//         variables: {
//           uuid: idtrx,
//         },
//       })
//         .then(({ data }) => {
//           if (!data || !data.bookingTransbankPayed) {
//             throw new Error("Invalid response from finishPayment");
//           }
//           const { bookingTransbankPayed } = data;
//           settravelGone(bookingTransbankPayed.gone);
//           setTravelReturn(bookingTransbankPayed.return);
//         })
//         .catch(err => {
//           console.log("Error in finishPayment: " + err.message)
//           throw new Error("Error in finishPayment: " + err.message);
//         })
//     }else{
//       console.log("Neither id nor idtrx is defined for ", successURL)
//       throw new Error("Neither id nor idtrx is defined");
//     }

//   }, [])

//   useEffect(() => {
//     if (travelGone) {
//       const passengerAssigned = travelGone.seats.filter(seat => seat.passenger)
//       const showTickets = passengerAssigned.length === travelGone.seats.length
//       setShowTickets(showTickets)
//       setText(
//         showTickets
//           ? subTexts.sended
//           : passengerAssigned.length === 0
//           ? subTexts.notSended
//           : subTexts.semiSended,
//       )
//     }
//   }, [travelGone, travelReturn])

//   const toTravelResume = (travelGone: Travel): QueryString => {
//     return ({
//       origin: travelGone.departureCity,
//       destiny: travelGone.destinationCity,
//       numberOfSeats: travelGone.seats.length.toString(),
//     } as unknown) as QueryString
//   }

//   const renderDiscount = () => {
//     if (!userInfo?.hasPercentDiscount) return <span />
//     return (
//       <div className={styles.discountCard}>
//         <DiscountBar />
//       </div>
//     )
//   }

//   return (
//     <Row justify="center" align="stretch" className={styles.background}>
//       {mutationLoading || loading ? (
//         <Col>
//           <LoadingSpinner size="100px" />
//         </Col>
//       ) : (
//         <Col className={styles.content} xs={22} md={12}>
//           <Success />
//           <p className={styles.titleFont}>Pago completado, ¡buen viaje!</p>
//           <p className={styles.subText}>{text}</p>
//           {travelGone && (
//             <TravelResume
//               transaction={{
//                 travelGone: travelToIntegrationTravel(
//                   travelGone,
//                 ) as IntegrationTravel,
//                 goneSeats: (travelGone.seats as unknown) as Seat[],
//                 travelReturn: travelReturn
//                   ? (travelToIntegrationTravel(
//                       travelReturn,
//                     ) as IntegrationTravel)
//                   : undefined,
//                 returnSeats: travelReturn
//                   ? ((travelReturn.seats as unknown) as Seat[])
//                   : undefined,
//               }}
//               travelResume={toTravelResume(travelGone)}
//             />
//           )}
//           {renderDiscount()}
//           <Row justify="center">
//             <Col xs={24} md={10} lg={8}>
//               {!showTickets ? (
//                 <Button
//                   block
//                   className={`${styles.button} ${styles.blackButton}`}
//                   onClick={handleClick}>
//                   Identificar Pasajeros
//                 </Button>
//               ) : (
//                 <Button
//                   block
//                   className={`${styles.button} ${styles.blackButton}`}
//                   href={`${process.env.GATSBY_API_TICKETS_URI}${
//                     travelGone?._id
//                   }/${travelReturn?._id ?? ''}`}>
//                   descargar e-tickets
//                 </Button>
//               )}
//             </Col>
//             <Col xs={24} md={{ span: 10, push: 1 }} lg={{ span: 8, push: 1 }}>
//               <Button block className={styles.button} href="/app/inicio">
//                 Comprar otro viaje
//               </Button>
//             </Col>
//           </Row>
//           <Row justify="center">
//             <Col xs={24} md={10} lg={6}>
//               <Button
//                 block
//                 type="text"
//                 href="/app/inicio"
//                 className={styles.returnButton}>
//                 Volver a inicio
//               </Button>
//             </Col>
//           </Row>
//         </Col>
//       )}
//     </Row>
//   )
// }

const PaymentFinished: FC = () => {
  const location = useLocation();
  const { id, idtrx } = queryString.parse(location.search);

  if (idtrx) {
    return <TransbankPaymentFinished />;
  } else if (id) {
    return <CreditPaymentFinished />;
  } else {
    return <PaymentError />;
  }
  
}
export default PaymentFinished

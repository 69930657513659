import React from 'react'

import SEO from '../../components/seo'

const DisplayTicketsPage: React.FC = () => {
  return (
    <div>
      <SEO title="Solicitudes" />
      <h1>Solicitudes</h1>
    </div>
  )
}

export default DisplayTicketsPage
//

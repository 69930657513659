import { gql } from '@apollo/client'

export const CREATE_HELP = gql`
  mutation ContactExecutive($input: HelpRequestInput!) @api(name: "users") {
    createHelp(input: $input) {
      _id
      ticketNumber
    }
  }
`

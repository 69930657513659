import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Col, message, Row } from 'antd'
import moment from 'moment'
import { isNil } from 'ramda'

import OptionsHeader from '../../components/OptionsHeader'
import SEO from '../../components/seo'
import { RolesEnum } from '../../helpers/enums'
import { useEventSave } from '../../hooks/analytics.hook'
import {
  GET_PROFILE_INFO,
  GetProfileInfo,
  MyProfile,
} from '../../queries/profile'
import {
  GET_REPORT_BY_USER,
  MY_TRAVEL_REPORT,
} from '../../queries/reports-page'
import { getUser, tokenInfo } from '../../services/auth'
import { GoneReturnTravel } from '../../types/page-types/travel-details-page.types'

import FrequentSeatScc from './FrequentSection/FrequentSeatScc'
import FrequentTravelScc from './FrequentSection/FrequentTravelScc'
import { GetReportByUser, MyTravelReport, Report } from './types/types'
import CurrentAccount from './CurrentAccount'
import { initStateReport } from './data'
import FrequentSection from './FrequentSection'
import PeriodSection from './PeriodSection'
import ReportsFooter from './reportsFooter'
import SummarySection from './SummarySection'
import TablesSection from './TablesSection'

import styles from './styles.module.css'

const ReportPage: React.FC = () => {
  const [Report, setReport] = useState<Report>(initStateReport)
  const [canceledTravels, setCanceledTravels] = useState<GoneReturnTravel[]>([])
  const [purchasedTravels, setPurchasedTravels] = useState<GoneReturnTravel[]>(
    [],
  )
  const [startDateSelected, setStartDateSelected] = useState<Date>(
    moment().startOf('week').isoWeekday(1).toDate(),
  )
  const [endDateSelected, setEndDateSelected] = useState<Date>(
    moment().add(5, 'days').toDate(),
  )
  const [idCurrentAccount, setIdCurrentAccount] = useState<string | null>(null)
  const [idCostCenter, setIdCostCenter] = useState<string | null>(null)
  const [userProfile, setUserProfile] = useState<MyProfile | null>(null)

  useQuery<GetProfileInfo>(GET_PROFILE_INFO, {
    fetchPolicy: 'network-only',
    onCompleted: data => setUserProfile(data.myProfile),
    onError: () => message.error('Error en la búsqueda'),
  })

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_reportes' as string

    useEventSave('vista_reportes', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const { refetch: refetchReportByUser } = useQuery<GetReportByUser>(
    GET_REPORT_BY_USER,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          startDate: startDateSelected,
          endDate: endDateSelected,
          idCurrentAccount,
          idCostCenter,
        },
      },
      onCompleted: data => setReport(data.getReportByUser),
      onError: () => {},
    },
  )

  const { refetch: refetchTravelReport } = useQuery<MyTravelReport>(
    MY_TRAVEL_REPORT,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          startDate: startDateSelected,
          endDate: endDateSelected,
          idCurrentAccount,
          idCostCenter,
        },
      },
      onCompleted: data => {
        setPurchasedTravels(data.myTravelReport.travels)
        setCanceledTravels(data.myTravelReport.canceledTravels)
      },
      onError: () => message.error('Error en la búsqueda'),
    },
  )

  const refetchPeriodDisplayed = (startDate: Date, endDate: Date) => {
    const variables = {
      input: {
        startDate,
        endDate,
        idCurrentAccount,
        idCostCenter,
      },
    }
    refetchReportByUser({
      variables,
    })
    refetchTravelReport({
      variables,
    })
    setStartDateSelected(startDate)
    setEndDateSelected(endDate)
  }

  const refetchGetReportByUser = (
    idCurrentAccount?: string,
    idCostCenter?: string,
  ) => {
    if (!isNil(startDateSelected) && !isNil(endDateSelected))
      refetchReportByUser({
        variables: {
          input: {
            startDate: startDateSelected,
            endDate: endDateSelected,
            idCurrentAccount: idCurrentAccount ? idCurrentAccount : null,
            idCostCenter: idCostCenter ? idCostCenter : null,
          },
        },
      })
    setIdCurrentAccount(idCurrentAccount ? idCurrentAccount : null)
    setIdCostCenter(idCostCenter ? idCostCenter : null)
  }

  const renderSectionByRoleUser = (userProfile: MyProfile | null) => {
    if (
      userProfile?.role === RolesEnum.CCC ||
      userProfile?.role === RolesEnum.MULTI_RUT_BUYER ||
      userProfile?.role === RolesEnum.ADMIN_CCC ||
      userProfile?.role === RolesEnum.MULTI_RUT_ADMIN
    )
      return (
        <Row justify="space-between" align="middle">
          <Col xs={24} md={12}>
            <CurrentAccount
              currentAccount={Report.currentAccount}
              refetchGetReportByUser={refetchGetReportByUser}
            />
          </Col>
          <Col xs={24} md={12}>
            <FrequentSection
              frequentTravel={Report.frequentTravel}
              frequentSeat={Report.frequentSeat}
            />
          </Col>
        </Row>
      )
    else if (
      userProfile?.role === RolesEnum.SCC ||
      userProfile?.role === RolesEnum.ADMIN_SCC
    )
      return (
        <Row justify="space-between" align="middle">
          <Col span={12}>
            <FrequentTravelScc frequentTravel={Report.frequentTravel} />
          </Col>
          <Col span={12}>
            <FrequentSeatScc frequentSeat={Report.frequentSeat} />
          </Col>
        </Row>
      )
    else return null
  }

  return (
    <Row>
      <Col span={24} style={{ overflowX: 'hidden' }}>
        <SEO title="Reportes" />
        <OptionsHeader backgroundStrip="long" />
        <Row
          gutter={[8, 16]}
          justify="center"
          align="middle"
          className={styles.container}>
          <Col xs={24} sm={20} md={24} lg={20} xl={14}>
            <PeriodSection
              startDateSelected={startDateSelected}
              endDateSelected={endDateSelected}
              userProfile={userProfile}
              currentAccount={Report.currentAccount}
              refetchPeriodDisplayed={refetchPeriodDisplayed}
            />
            {renderSectionByRoleUser(userProfile)}
            <SummarySection report={Report} userRol={userProfile?.role} />
            <TablesSection
              purchasedTravels={purchasedTravels}
              canceledTravels={canceledTravels}
              excelFilters={{
                startDate: startDateSelected,
                endDate: endDateSelected,
                idCurrentAccount,
                idCostCenter,
              }}
            />
          </Col>
          <ReportsFooter />
        </Row>
      </Col>
    </Row>
  )
}

export default ReportPage

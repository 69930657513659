import React, { FC } from 'react'
import { Form, Select } from 'antd'

import { countriesExtensions } from '../helpers/countriesExtensions'

import styles from './DetailDrawer/styles.module.css'

const { Item } = Form
const { Option } = Select

interface PhonePrefixProps {
  areaCode?: string
}

const PhonePrefix: FC<PhonePrefixProps> = ({ areaCode }) => (
  <Item initialValue={areaCode ?? '56'} name="areaCode" noStyle>
    <Select
      className={styles.selectStyle}
      optionFilterProp="children"
      showArrow={false}
      showSearch>
      {countriesExtensions.map(({ callingCodes, flag }) => (
        <Option key={flag} value={callingCodes[0]}>
          <img src={flag} className={styles.flagStyle} />+{callingCodes[0]}
        </Option>
      ))}
    </Select>
  </Item>
)

export default PhonePrefix

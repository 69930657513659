import { gql } from '@apollo/client'

export const GET_REPORT_BY_USER = gql`
  query getReportByUser($input: GetReportInput!) @api(name: "reports") {
    getReportByUser(input: $input) {
      usedTickets
      totalCost
      totalOriginalCost
      totalDiscount
      purchasedTickets
      averageRate
      totalSaving
      currentAccount {
        _id
        balance
        name
        totalAmount
        costCenters {
          _id
          name
        }
      }
      frequentTravel {
        id
        countTravels
        departureCity
        destinationCity
      }
      frequentSeat {
        type
        soldCounter
      }
    }
  }
`

export const MY_TRAVEL_REPORT = gql`
  query myTravelReport($input: GetReportInput!) @api(name: "reports"){
    myTravelReport(input: $input) {
      travels {
        gone {
          _id
          arrivesAt
          arrivesTime
          buyStatus
          createdAt
          departsAt
          departsTime
          departureCity
          destinationCity
          transaction
          paymentType
          seats {
            number
            price
            ticketId
            buyStatus
          }
          currentAccount {
            sapNumber
          }
          costCenter {
            _id
            name
          }
        }
        return {
          _id
          arrivesAt
          arrivesTime
          buyStatus
          createdAt
          departsAt
          departsTime
          departureCity
          destinationCity
          transaction
          paymentType
          seats {
            buyStatus
            number
            price
            ticketId
          }
          costCenter {
            _id
            name
          }
          currentAccount {
            sapNumber
          }
        }
      }
      canceledTravels {
        gone {
          _id
          arrivesAt
          arrivesTime
          buyStatus
          createdAt
          departsAt
          departsTime
          departureCity
          destinationCity
          transaction
          paymentType
          seats {
            number
            price
            ticketId
            buyStatus
          }
          currentAccount {
            sapNumber
          }
          costCenter {
            _id
            name
          }
        }
        return {
          _id
          arrivesAt
          arrivesTime
          buyStatus
          createdAt
          departsAt
          departsTime
          departureCity
          destinationCity
          transaction
          paymentType
          seats {
            number
            price
            ticketId
            buyStatus
          }
          currentAccount {
            sapNumber
          }
          costCenter {
            _id
            name
          }
        }
      }
    }
  }
`

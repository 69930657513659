import React, { FC, useLayoutEffect, useState } from 'react'
import { Col, Row } from 'antd'

import OptionsHeader from '../../../components/OptionsHeader'

import styles from './styles.module.css'

const MyRequestsLayout: FC = ({ children }) => {
  const [width, setWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return (
    <Row>
      <Col span={24}>
        <OptionsHeader />
        <Row className={styles.requestsContainer}>
          <Col span={24} className={styles.content}>
            <Row className={styles.headerBackground} />
            <Row
              align="top"
              className={styles.requestsContent}
              justify="center"
              gutter={width < 600 ? 0 : 16}>
              {children}
            </Row>
            <Col
              className={styles.footerBackground}
              xs={0}
              sm={0}
              md={0}
              lg={24}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MyRequestsLayout
import React, {
  FC,
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { Button, Col, Divider, Pagination, Row } from 'antd'

import { User } from '../../my-travels/types'
import { MyUserPageContext } from '../Provider'

import styles from './styles.module.css'

// TODO: Add costCenter on row display.

const MyUsersListRow: FC = () => {
  const {
    filteredUsers,
    setCostCenters,
    setSelectedUser,
    setVisible,
    /* nuevo */
    changePage,
    totalUsers,
    usersByPage,
    selectedPage,
    setUsersByPage,
  } = useContext(MyUserPageContext)
  const [width, setWidth] = useState<number>(0)

  // console.log("filtered users in MyUsersListRow", filteredUsers)

  // console.log("selectedPage", selectedPage)

  const [numPages, setNumPages] = useState(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const handleSelect = (user: User) => {
    setCostCenters(user.costCenters)
    setSelectedUser(user)
    setVisible(true)
  }

  const totalUsersSize = 30
  const usersByPageSize = 10
  const getColumnSize = () => {
    if (numPages > 0 && numPages <= 5) return 24 / numPages
    else return 4
  }

  useEffect(() => {
    const calculatedNumPages = Math.ceil(totalUsersSize / usersByPageSize)
    setNumPages(calculatedNumPages)
  }, [])

  const renderUserMobile = () => {
    return filteredUsers.map((user, idx) => {
      const { email, firstName, lastName, role } = user
      return (
        <Fragment key={user._id}>
          <div
            key={idx.toString()}
            className={styles.userDataMobile}
            onClick={() => handleSelect(user)}>
            <div className={styles.initialsContainer}>
              <span>
                {firstName[0]}
                {lastName[0]}
              </span>
            </div>
            <div className={styles.userInfoMobile}>
              <div className={styles.userNameMobile}>
                {firstName} {lastName}
              </div>
              <div className={styles.userEmailMobile}>{email}</div>
            </div>
            <div className={styles.rolMobile}>
              Rol: {role.includes('ADMIN') ? 'Administrador' : 'Comprador'}
            </div>
          </div>
          <div className={styles.divider} />
        </Fragment>
      )
    })
  }

  return (
    <Row
      align="top"
      className={styles.costCenterDisplayContent}
      justify="center">
      <Col span={24}>
        {filteredUsers.length > 0 && (
          <Row>
            <p className={styles.usersCount}>
              Mostrando {filteredUsers.length}{' '}
              {filteredUsers.length !== 1 ? 'usuarios' : 'usuario'}
            </p>
            <Divider />
          </Row>
        )}
        <Row align="middle" className={styles.mainListRow} justify="center">
          <Col className={styles.mainListCol} span={24}>
            {width <= 1024
              ? renderUserMobile()
              : filteredUsers.map(item => {
                  const { _id, email, firstName, lastName, role } = item

                  return (
                    <Fragment key={_id}>
                      <Row align="middle" justify="center" gutter={15}>
                        <Col span={2}>
                          <div className={styles.initialsContainer}>
                            <span>
                              {firstName[0]}
                              {lastName[0]}
                            </span>
                          </div>
                        </Col>
                        <Col span={10}>
                          <p className={styles.userName}>
                            {firstName} {lastName}
                          </p>
                          <p className={styles.userEmail}>{email}</p>
                        </Col>
                        <Col className={styles.titleCol} span={8}>
                          <p className={styles.CCName}>
                            Rol:{' '}
                            {role.includes('ADMIN')
                              ? 'Administrador'
                              : 'Comprador'}
                          </p>
                        </Col>
                        <Col className={styles.titleCol} span={4}>
                          <Button
                            block
                            className={styles.editButton}
                            onClick={() => handleSelect(item)}>
                            Editar
                          </Button>
                        </Col>
                      </Row>
                      <Divider className={styles.listRowDivider} />
                    </Fragment>
                  )
                })}
            {/* paginacion */}
            <Row justify="center">
              <br></br>
              <Col span={getColumnSize()}>
                <div id="pagination">
                  {totalUsers > 0 && usersByPage > 0 ? (
                    <Pagination
                      defaultCurrent={selectedPage}
                      current={selectedPage}
                      total={totalUsers}
                      onChange={e => changePage(parseInt(e.toString()))}
                      defaultPageSize={usersByPage}
                      onShowSizeChange={(current, pageSize) =>
                        setUsersByPage(pageSize)
                      }
                      showSizeChanger={false}
                      pageSizeOptions={['5', '10', '20', '50']}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
            {/* fin paginacion */}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MyUsersListRow

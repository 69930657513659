import { gql } from '@apollo/client'

export const GET_LEVELS = gql`
  query @api(name: "users") {
    getLevels {
      _id
      title
      levelTwo {
        _id
        title
        operatorComplain
        options {
          code
          name
        }
        levelThree {
          _id
          title
          options {
            code
            name
          }
        }
      }
    }
  }
`

import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Button, Col, Drawer, Form, message, Row } from 'antd'

import { defaultFormErrorMessage } from '../../helpers/messages'
import { GET_COMPANY_INFORMATION } from '../../queries/my-requests-page/queries'
import { SessionContext } from '../../services/session'
import {
  CreditLineRequestDrawerProps,
  CreditLineRequestFormValues,
  CreditLineRequestSubmit,
} from '../../types/component-types/CreditLineRequestDrawer'
import LoadingSpinner from '../LoadingSpinner'

import CreditLineRequestForm from './CreditLineRequestForm'
import { CREATE_CREDITLINE_REQUEST } from './graphql'

import styles from './style.module.css'

const CreditLineRequestDrawer: FC<CreditLineRequestDrawerProps> = ({
  handleVisible,
  visible,
}) => {
  const { user } = useContext(SessionContext)
  const [stage, setStage] = useState(0)
  const [submitData, setSubmitData] = useState({} as CreditLineRequestSubmit)
  const [taxFolderUri, setTaxFolderUri] = useState('')
  const [DICOMUri, setDICOMUri] = useState('')
  const { company } = user
  const [companyUpdateForm] = Form.useForm()
  const [uploadForm] = Form.useForm()

  const [getCompanyInfo, { loading, data }] = useLazyQuery(
    GET_COMPANY_INFORMATION,
    {
      variables: {
        companyId: company,
      },
      fetchPolicy: 'no-cache',
    },
  )
  const [createCreditLineRequest, { loading: createHelpLoading }] = useMutation(
    CREATE_CREDITLINE_REQUEST,
  )

  const onCompanyUpdateFinish = (values: CreditLineRequestFormValues) => {
    const {
      rut,
      businessArea,
      estimatedAmount,
      estimatedPassengers,
      ...rest
    } = values
    const splitAmount = estimatedAmount.split('-')
    const splitPassengers = estimatedPassengers.split('-')

    const formattedAmounts = {
      from: splitAmount.length > 1 ? parseInt(splitAmount[0].slice(1)) || 0 : 0,
      to: splitAmount.length > 1 ? parseInt(splitAmount[1].slice(1)) : 0,
    }
    const formattedPassengers = {
      from: splitPassengers.length > 1 ? parseInt(splitPassengers[0]) : 0,
      to: splitPassengers.length > 1 ? parseInt(splitPassengers[1]) : 0,
    }
    setSubmitData({
      ...rest,
      estimatedAmount: formattedAmounts,
      estimatedPassengers: formattedPassengers,
    })
    setStage(1)
  }

  const validateCompanyUpdate = () => {
    companyUpdateForm
      .validateFields()
      .then(values =>
        onCompanyUpdateFinish(values as CreditLineRequestFormValues),
      )
      .catch(error => {
        console.log(error)
        message.error(defaultFormErrorMessage)
      })
  }

  const validateEnd = async () => {
    try {
      await uploadForm.validateFields()
      const documents = {
        taxFolder: taxFolderUri,
        DICOM: DICOMUri,
      }
      await createCreditLineRequest({
        variables: {
          input: { ...submitData, documents },
        },
      })
      handleVisible(false)
      message.success('Solicitud realizada con éxito')
    } catch (e) {
      console.log(e)
      message.error(defaultFormErrorMessage)
    }
  }
  useEffect(() => {
    getCompanyInfo()
  }, [getCompanyInfo])

  const handleReturnButton = () => {
    if (stage === 0) handleVisible(false)
    else setStage(stage - 1)
  }

  return (
    <Drawer
      visible={visible}
      onClose={() => handleVisible(false)}
      title="Solicitud de línea de crédito"
      placement="right"
      width={window?.innerWidth > 900 ? 400 : window?.innerWidth - 10}
      footer={
        <Row align="middle" justify="end">
          <Col xs={12} md={8}>
            <Button onClick={handleReturnButton}>Volver</Button>
          </Col>
          <Col xs={12} md={8}>
            <Button
              loading={createHelpLoading}
              className={styles.continueButton}
              onClick={
                stage === 0
                  ? () => validateCompanyUpdate()
                  : () => validateEnd()
              }>
              <p className={styles.continueButtonFont}>Continuar</p>
            </Button>
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <CreditLineRequestForm
          company={data?.companyDetail}
          handleVisible={handleVisible}
          companyUpdateForm={companyUpdateForm}
          uploadForm={uploadForm}
          stage={stage}
          setDocuments={{
            setDicom: setDICOMUri,
            setTaxFolder: setTaxFolderUri,
          }}
        />
      )}
    </Drawer>
  )
}

export default CreditLineRequestDrawer

import { gql } from '@apollo/client'

export const CREATE_CREDITLINE_REQUEST = gql`
    mutation createCreditLineRequest($input: UpdateCompanyInput!) @api(name: "users"){
        createCreditLineRequest(input: $input){
            body,
            status,
            isCreditLine,
            requestType{
                _id,
                title
            },
            requestReason{
                _id,
                title
            },
            requestReasonOption{
                _id,
                title
            },
            transactionNumber,
            boletusNumber,
            documents{
                path
                userId
            }
            user{
                email,
                firstName,
                lastName,
            }
        }
    }
`
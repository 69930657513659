import React, { FC, useContext } from 'react'
import { Button, Col, Row } from 'antd'
import { navigate } from 'gatsby'

import { TravelPageContext } from '../../Provider'

import styles from './styles.module.css'

const TravelFooter: FC = () => {
  const { step, setAnonymous, setStep } = useContext(TravelPageContext)

  return (
    <div className={styles.travelFooterContainer}>
      <div>
        <Row align="middle" justify="center">
          <Col
            className={styles.colFooter}
            xs={20}
            sm={{ span: 12, offset: 12 }}
            lg={{ span: 8, offset: 0 }}>
            <span>
              ¿Cambiaste de parecer? Configura un nuevo viaje con un sólo clic.
            </span>
          </Col>
          <Col
            xs={20}
            sm={{ span: 12, offset: 12 }}
            md={{ span: 8 }}
            lg={{ span: 4, push: 1, offset: 0 }}
            className={styles.colFooter}>
            <Button
              className={styles.resetButton}
              onClick={() => {
                setAnonymous(false)
                setStep(step - 2)
                navigate('/app/inicio')
              }}
              block>
              Reiniciar búsqueda
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default TravelFooter

import { gql } from '@apollo/client'

export const ADD_MESSAGE = gql`
  mutation addMessage($helpRequest:String!, $message: String!) @api(name: "users"){
    addMessage(helpRequest: $helpRequest, message: $message){
      _id
      message
      user{
        _id
        name
      }
      createdAt
      seen
    }
  }
`

export const ADD_FILE_TO_HELPREQUEST = gql`
  mutation addFileToHelpRequest($id: String!, $path: String!) @api(name: "users"){
    addFileToHelpRequest(id: $id, path: $path){
      userId
        path
      }    
  }
`

export const DELETE_FILE_FROM_HELPREQUEST = gql`
  mutation deleteFileFromHelpRequest($id: String!, $path: String!) @api(name: "users"){
    deleteFileFromHelpRequest(id: $id, path: $path){
      userId
      path
    }
  }
`

export const UPDATE_COMPANY_DOCUMENTS = gql`
  mutation updateCompanyDocuments($company: String!, $input: DocumentsInput!) @api(name: "users"){
    updateCompanyDocuments(company: $company, input: $input){
      _id
      documents{
        taxFolder
        DICOM
      }
    }
  }
`

export const SET_MESSAGES_TO_SEEN = gql`
  mutation setMessagesToSeen($messageIds: [String!]!) @api(name: "users"){
    setMessagesToSeen(messageIds: $messageIds)
  }
`
import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Card, message, Tabs } from 'antd'
import { navigate } from 'gatsby'

import { getDestinies, getOrigins } from '../../../helpers/travel-page'
import {
  ALL_ROUTES,
  GET_ACTIVE_DISCOUNT_PERCENT,
  INTEGRATION_LOGIN,
} from '../../../queries'
import { TravelPageContext } from '../../travel-page/Provider'

import OneWay from './OneWay'
import TwoWays from './TwoWays'

import styles from './styles.module.css'

const { TabPane } = Tabs

const NewTrip: FC = () => {
  const {
    options,
    search: { departureCity },
    setPercentDiscountValue,
    setIntegrationTokens,
    setOptions,
  } = useContext(TravelPageContext)

  const [width, setWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const { loading } = useQuery(ALL_ROUTES, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ allRoutes }) =>
      setOptions({
        ...options,
        origins: getOrigins(allRoutes),
        routes: allRoutes,
      }),
  })

  const [loginIntegration, { loading: loginLoading }] = useMutation(
    INTEGRATION_LOGIN,
    {
      onCompleted: ({ integrationLogin: { data } }) => {
        setIntegrationTokens(data)
        navigate(`/app/viajes/buscar`)
      },
    },
  )

  const [getActiveDiscountPercent] = useLazyQuery(GET_ACTIVE_DISCOUNT_PERCENT, {
    onCompleted: ({ getActiveDiscountPercent }) => {
      setPercentDiscountValue(getActiveDiscountPercent)
    },
  })

  const handleClick = async () => {
    try {
      await loginIntegration()
    } catch (err) {
      message.error(`Se produjo un error al conectar con viajes:\n${err}`)
    }
  }

  useEffect(() => {
    if (departureCity) {
      const { routes } = options
      setOptions({
        ...options,
        destinies: getDestinies(routes, departureCity),
      })
    } else setOptions({ ...options, destinies: [] })
  }, [departureCity])

  useEffect(() => {
    getActiveDiscountPercent()
  }, [])

  return (
    <Card className={styles.tripCard}>
      <Tabs defaultActiveKey="1" centered={width < 560}>
        <TabPane tab="Ida y vuelta" key="1">
          <TwoWays
            handleClick={handleClick}
            loading={loading}
            loginLoading={loginLoading}
          />
        </TabPane>
        <TabPane tab="Sólo ida" key="2">
          <OneWay
            handleClick={handleClick}
            loading={loading}
            loginLoading={loginLoading}
          />
        </TabPane>
      </Tabs>
    </Card>
  )
}

export default NewTrip

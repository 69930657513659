import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Col, message, Row } from 'antd'

import LoadingSpinner from '../../../../components/LoadingSpinner'
import { GET_SEAT_MAP } from '../../../../queries'
import { TravelPageContext } from '../../Provider'
import { OneWayTripProps, QueryString, Seat } from '../../types'

import Floor from './Floor'

import styles from './styles.module.css'

const OneWayTrip: FC<OneWayTripProps> = ({ service, isGone }) => {
  const { search, hasReturn, transaction, setTransaction } = useContext(
    TravelPageContext,
  )

  const [seats, setSeats] = useState<Seat[]>([])

  const [activeFloor, setActiveFloor] = useState<number>(1)

  const createRedisId = (search: QueryString, isGone: boolean) => {
    const { departureCity, destinationCity, departureDate, returnDate } = search
    return `${isGone ? departureCity : destinationCity};${
      isGone ? destinationCity : departureCity
    };${isGone ? departureDate : returnDate}`
  }

  const [floors, setFloors] = useState([[], []])
  const [getSeatMap, { data, loading }] = useLazyQuery(GET_SEAT_MAP, {
    variables: {
      input: {
        goneOrReturn: isGone ? (hasReturn ? 1 : 0) : 2,
        numberOfSeats: parseInt(search.numberOfSeats),
        redisId: createRedisId(search, isGone),
        referencePrice: service?.price,
        serviceId: service?.id.toString(),
        token: service?.integration_code,
      },
    },
    onError: error => {
      message.error(
        `Ha ocurrido un error al obtener los asientos de ida: ${error}`,
      )
    },
  })

  useEffect(() => {
    getSeatMap()
  }, [])

  useEffect(() => {
    if (data) {
      const { getSeatMap } = data

      const { seat_map } = getSeatMap
      if (seat_map?.length > 0) {
        const floors = seat_map.reduce(
          (accu: [Seat[], Seat[]], current: Seat) => {
            if (current.floor_number === 1) accu[0].push(current)
            if (current.floor_number === 2) accu[1].push(current)
            return accu
          },
          [[], []],
        )
        setFloors(floors)
      }
      if (isGone)
        setTransaction({
          ...transaction,
          gonePrices: (seat_map as Seat[]).map(
            seat => seat.discount_price ?? seat.price,
          ),
        })
      else
        setTransaction({
          ...transaction,
          returnPrices: (seat_map as Seat[]).map(
            seat => seat.discount_price ?? seat.price,
          ),
        })
    }
  }, [data])

  const onFloorChange = (floor: number, floorSeats: Seat[]) => {
    setSeats(floorSeats)
    setActiveFloor(floor)
  }

  useEffect(() => {
    const defaultSelectedFloor = floors.find(arr => arr.length > 0)

    onFloorChange(
      floors.indexOf(defaultSelectedFloor ?? []) + 1,
      defaultSelectedFloor ?? [],
    )
  }, [floors])

  return loading ? (
    <LoadingSpinner size="100px" />
  ) : (
    <>
      <Row justify="space-around" className={styles.firstRow}>
        {floors?.map((floorSeats, number) =>
          floorSeats.length > 0 ? (
            <Col span={10} key={`${number + 1}`}>
              <Button
                block
                onClick={() => onFloorChange(number + 1, floorSeats)}
                className={
                  activeFloor === number + 1
                    ? styles.travelButtonActive
                    : styles.travelButton
                }>
                Piso {number + 1}
              </Button>
            </Col>
          ) : null,
        )}
      </Row>
      <Row>
        <Col span={24}>
          <Floor seats={seats} isGone={isGone} />
        </Col>
      </Row>
    </>
  )
}

export default OneWayTrip

import moment from 'moment'
export * from './travel-detail'
export * from './travel-drawer'

import {
  TravelSeat,
} from '../../screens/my-travels/types'
import {
  GoneReturnTravel,
  TravelType,
} from '../../types/page-types/travel-details-page.types'

export const compareIsAfter = (time?: number, date?: string): boolean => {
  const today = moment()
  const hour = parseInt(today.format('Hmm'))

  if (moment.utc(date).isAfter(today, 'date')) return true
  if (moment.utc(date).isSame(today, 'date'))
    if (time && time > hour) return true
  return false
}

export const compareIsBefore = (time?: number, date?: string): boolean => {
  const today = moment().add(30, 'minutes')

  const hour = parseInt(today.format('Hmm'))

  if (moment.utc(date).isBefore(today, 'date')) return true
  if (moment.utc(date).isSame(today, 'date'))
    if (time && time < hour) return true
  return false
}

export const getMyTravelPrice = (data: TravelSeat[]): number =>
  data.reduce((acc, { price }) => acc + price, 0)

export const fixTimeFormat = (time: number): string => {
  const formatedTime = `000${time}`
  const fixedTime = formatedTime.substr(formatedTime.length - 4)
  return moment(fixedTime, 'Hmm').format('HH:mm')
}

const getDay = (travelDate: Date) => moment.utc(travelDate).format('MMM DD')

export const genTitle = (
  travelType: TravelType,
  travel?: GoneReturnTravel,
): string => {
  switch (travelType) {
    case TravelType.BOTH:
      return `Viaje ida y vuelta ${getDay(travel?.gone?.departsAt as Date)}-
            ${getDay(travel?.return?.arrivesAt as Date)}`
    case TravelType.GONE:
      return `Viaje ida ${getDay(travel?.gone?.departsAt as Date)}`
    default:
      return ''
  }
}

import React from 'react'
import { RightOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import moment from 'moment'

import {
  displayHelpRequestStatus,
  HelpRequestStatusEnum,
} from '../../../helpers/enums'
import { HelpRequest } from '../../../types/HelpRequest.type'

import styles from './styles.module.css'

export const columsMobile: ColumnsType<HelpRequest> = [
  {
    dataIndex: 'ticketNumber',
    key: 'ticketNumber',
    render: (value, record) => (
      <div>
        <div className={styles.ticketNumberMobile}>{record.ticketNumber}</div>
        <div className={styles.descriptionMobile}>
          <p className={styles.typeMobile}>
            {record?.requestReason?.title ?? 'Reclamo/Sugerencia'}
          </p>
          <p className={styles.operatorNameMobile}>
            {value?.title ?? 'Contacto'}
          </p>
          <p className={styles.descriptionMobile}>
            {record?.requestReasonOption?.title ?? ''}
          </p>
        </div>
      </div>
    ),
    width: '65%',
  },
  {
    dataIndex: 'status',
    key: 'status',
    render: (value, record) => (
      <div>
        <Tag
          className={
            record.status === HelpRequestStatusEnum.IN_PROGRESS
              ? styles.tagInProgress
              : record.status === HelpRequestStatusEnum.NOT_INITED
              ? styles.tagSent
              : record.status === HelpRequestStatusEnum.FINISHED
              ? styles.tagResolve
              : undefined
          }>
          <p className={styles.statusDisplay}>
            {displayHelpRequestStatus(value)}
          </p>
        </Tag>
        <div className={styles.daysSection}>
          <p>Hace {moment().diff(record.createdAt, 'days')} días</p>
        </div>
      </div>
    ),
    width: '30%',
  },
]

export const colums: ColumnsType<HelpRequest> = [
  {
    dataIndex: 'ticketNumber',
    key: 'ticketNumber',
    render: value => <p className={styles.requestIdText}>{value}</p>,
    width: '25%',
  },
  {
    dataIndex: 'requestType',
    key: 'requestType',
    render: (value, record) => (
      <div className={styles.description}>
        <p>{value?.title ?? 'Contacto'}</p>
        <p>{record?.requestReason?.title ?? 'Reclamo/Sugerencia'}</p>
        <p>{record?.requestReasonOption?.title ?? ''}</p>
      </div>
    ),
    width: '35%',
  },
  {
    dataIndex: 'status',
    key: 'status',
    render: (value: HelpRequestStatusEnum) => (
      <Tag
        className={
          value === HelpRequestStatusEnum.IN_PROGRESS
            ? styles.tagInProgress
            : value === HelpRequestStatusEnum.NOT_INITED
            ? styles.tagSent
            : value === HelpRequestStatusEnum.FINISHED
            ? styles.tagResolve
            : undefined
        }>
        <p className={styles.statusDisplay}>
          {displayHelpRequestStatus(value)}
        </p>
      </Tag>
    ),
    width: '15%',
  },
  {
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: value => (
      <div className={styles.daysSection}>
        <p>Hace {moment().diff(value, 'days')} días</p>
        <RightOutlined />
      </div>
    ),
    width: '20%',
  },
]

import React, { FC } from 'react'
import { Col, Row } from 'antd'

import OptionsHeader from '../../../components/OptionsHeader'
import { MyPassengerLayoutProps } from '../types'

import MyPassengerFooter from './MyPassengerFooter'
import MyPassengerHeader from './MyPassengerHeader'

import styles from './styles.module.css'

const MyPassengerLayout: FC<MyPassengerLayoutProps> = ({
  children,
  quantity,
}) => (
  <Row className={styles.layoutContainer}>
    <Col span={24} className={styles.layoutRow}>
      <OptionsHeader />
      <MyPassengerHeader quantity={quantity} />
      {children}
      <MyPassengerFooter />
    </Col>
  </Row>
)

export default MyPassengerLayout
import React, {
  FC,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import {
  SearchOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons'
import { Button, Col, Divider, Empty, Input, Pagination, Row, Select, Table } from 'antd'

import LoadingSpinner from '../../../components/LoadingSpinner'
import { isCCC } from '../../../helpers/auth.helper'
import { RolesEnum } from '../../../helpers/enums'
import { SessionContext } from '../../../services/session'
import { CostCenter } from '../../../types/page-types/pay-page.types'
import { Passenger } from '../../travel-page/types'
import { PassengerDisplayProps } from '../types'

import EditPassenger from './EditPassenger'
import PassengerList from './PassengerListPhone'

import styles from './styles.module.css'

/*pagination import*/
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { navigate } from 'gatsby'
import { PASSENGER_BY_COMPANY } from '../../../queries'
import { useQuery } from '@apollo/client'
import moment from 'moment'

const { Option } = Select

const PassengerDisplay: FC<PassengerDisplayProps> = ({
  costCenters,
  handleDrawerStep,
  handleVisible,
  handleSearch,
  handleSortOrder,
  refetch,
  loading,
  passengerList,
  changePage,
  totalPassengers,
  passengersByPage,
  selectedPage,
  setPassengersByPage,
}) => {
  const { user } = useContext(SessionContext)
  const [passenger, setPassenger] = useState({} as Partial<Passenger>)
  const [visible, setVisible] = useState(false)
  const [width, setWidth] = useState<number>(0)

  /*Pagination*/

  const location = useLocation()

  const { page } = queryString.parse(location.search)
  const [numPages, setNumPages] = useState(0);

  /*fin paginacion*/

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  const handleDrawer = (value: boolean) => {
    if (!value) setPassenger({} as Partial<Passenger>)
  }

  const onPassengerClick = (passenger: Partial<Passenger>) => {
    setPassenger(passenger)
  }

  useEffect(() => {
    if (passenger?._id) setVisible(true)
    else setVisible(false)
  }, [passenger])

  const totalPassengersSize = 50;
  const passengeresByPageSize = 10;

  useEffect(() => {
    const calculatedNumPages = Math.ceil(totalPassengersSize / passengeresByPageSize);
    setNumPages(calculatedNumPages);
  }, []);

  const getColumnSize = () => {
    if (numPages > 0 && numPages <= 5) {
      return 24 / numPages;
    } else {
      return 4;
    }
  };

  return (
    <Row className={styles.passengerDisplay} justify="space-around">
      <Col span={24}>
        <EditPassenger
          handleDrawer={handleDrawer}
          passenger={passenger}
          refetch={refetch}
          visible={visible}
        />
        <Row
          align="middle"
          className={styles.passengerFiltersRow}
          gutter={[15, 10]}
          justify="center"
        >
          <Col xs={24} sm={24} md={12}>
            <p>Buscar pasajero</p>
            <Input
              addonAfter={<SearchOutlined />}
              className={styles.searchInput}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="¿A qué pasajero buscas?"
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <p>Ordenar por</p>
            <Select
              className={styles.sortTypeSelect}
              defaultValue={0}
              onChange={handleSortOrder}
            >
              <Option value={0}>Ordenar alfabético (A-Z)</Option>
              <Option value={1}>Ordenar inverso (Z-A)</Option>
            </Select>
          </Col>
        </Row>
        <Divider className={styles.passengerDivider} />
        <Row
          align="top"
          className={styles.passengerDisplayContent}
          justify="center"
        >
          <Col span={24}>
            {passengerList && passengerList.length > 0 && (
              <Row>
                <p className={styles.subTextPassengerCount}>
                  Mostrando {passengerList.length} pasajeros
                </p>
                <Divider className={styles.passengerListDivider} />
              </Row>
            )}

            {loading ? (
              <LoadingSpinner size="50px" />
            ) : passengerList && totalPassengers > 0 ? (
              <Row
                align="middle"
                className={styles.mainPassengerListRow}
                justify="center"
              >
                <Col className={styles.mainPassengerListCol} span={24}>
                  
                  {width < 768 ? (
                    <PassengerList
                      passengerList={passengerList}
                      onPassengerClick={onPassengerClick}
                    />
                  ) : (
                    passengerList.map((passenger) => {
                      const {
                        _id,
                        costCenter,
                        fatherLastName,
                        firstName,
                        email,
                      } = passenger
                      return (
                        <Row
                          align="middle"
                          key={_id}
                          justify="center"
                        >
                          <Col span={8}>
                            <p className={styles.fontName}>
                              {firstName} {fatherLastName}
                            </p>
                            <p className={styles.fontEmail}>{email}</p>
                          </Col>
                          <Col offset={1} span={7}>
                            {costCenter && (
                              <p className={styles.fontCostCenter}>
                                Centro de Costo:{' '}
                                {(costCenter as CostCenter).name ?? ''}
                              </p>
                            )}
                          </Col>
                          <Col offset={1} span={7}>
                            <Button
                              block
                              className={styles.showDetails}
                              onClick={() => onPassengerClick(passenger)}
                            >
                              Ver detalles
                            </Button>
                          </Col>
                          <Divider className={styles.passengerListRowDivider} />
                        </Row>
                      )
                    })
                  )}
                </Col>
              </Row>
            ) : (
              <Empty
                description={
                  costCenters
                    ? 'No se encontraron pasajeros en este centro de costo.'
                    : isCCC(user?.role as RolesEnum)
                    ? 'Por favor seleccione un centro de costo.'
                    : 'No se encontraron pasajeros asociados.'
                }
              />
            )}
          </Col>
        </Row>
        {/* paginacion */}
        <Row justify="center">
          <Col span={getColumnSize()}>
            <br></br>
            <div id="pagination">
              {totalPassengers > 0 && passengersByPage > 0 ? (
                <Pagination
                  defaultCurrent={selectedPage}
                  current={selectedPage}
                  total={totalPassengers}
                  onChange={(e) => changePage(parseInt(e.toString()))}
                  defaultPageSize={passengersByPage}
                  onShowSizeChange={(current, pageSize) =>
                    setPassengersByPage(pageSize)
                  }
                  showSizeChanger={false}
                  pageSizeOptions={['5', '10', '20', '50']}
                />
              ) : null}
            </div>
          </Col>
        </Row>
        {/* fin paginacion */}
        <Row
          align="middle"
          className={styles.passengerUploadRowButton}
          justify="center"
        >
          <Divider />
          <Col xs={24} lg={12} className={styles.padding0_2}>
            <Button
              className={styles.button}
              icon={<UserAddOutlined />}
              onClick={() => {
                handleDrawerStep(1)
                handleVisible(true)
              }}
            >
              Agregar pasajero
            </Button>
          </Col>
          <Col xs={0} lg={12} className={styles.padding0_2}>
            <Button
              className={styles.button}
              icon={<UsergroupAddOutlined />}
              onClick={() => {
                handleDrawerStep(2)
                handleVisible(true)
              }}
            >
              Carga múltiple
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default PassengerDisplay

import React from 'react'

import {
  BiobioLogo,
  CondorLogo,
  JacLogo,
  TurbusLogo,
} from '../../../../assets/operators'

import { biobioStyles, condorStyles, jacStyles } from './logoStyles'

export default {
  'condor bus': <img src={CondorLogo} alt="condorLogo" style={condorStyles} />,
  turbus: <TurbusLogo />,
  'jac ltda.': <img src={JacLogo} alt="jacLogo" style={jacStyles} />,
  'buses bio-bio': (
    <img src={BiobioLogo} alt="biobioLogo" style={biobioStyles} />
  ),
}

import React, { useState } from 'react'
import { Col, DatePicker, Divider, Row, Tooltip, Typography } from 'antd'
import locale from 'antd/es/date-picker/locale/es_ES'
import moment, { Moment } from 'moment'

import getDifferenceDays from '../../../helpers/getDifferenceDays'
import { PeriodDisplayedProps } from '../types/props'
import { CurrentAccount } from '../types/types'

import styles from './styles.module.css'

const { RangePicker } = DatePicker

type PeriodDisplayed = {
  startDate: Moment
  endDate: Moment
}

const { Title, Text } = Typography

const PeriodSection: React.FC<PeriodDisplayedProps> = ({
  startDateSelected,
  endDateSelected,
  userProfile,
  currentAccount,
  refetchPeriodDisplayed,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(
    moment().subtract(1, 'day'),
  )
  const [endDate, setEndDate] = useState<Moment | null>(moment().add(7, 'days'))
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false)

  const setDualDates = (dates: Moment[]) => {
    console.log({ dates })

    if (dates && dates[0] && dates[1]) {
      setStartDate(dates[0])
      setEndDate(dates[1])
      refetchPeriodDisplayed(
        moment(dates[0]).toDate(),
        moment(dates[1]).toDate(),
      )
    }
  }

  const renderCurrentAccountInfo = (currentAccount: CurrentAccount[]) => {
    const numberAccounts: number = currentAccount?.length || 0
    const numberCenters = currentAccount.reduce(
      (sum, val) => (sum += val.costCenters.length),
      0,
    )
    const textInfo = `${numberAccounts} ${
      numberAccounts > 1 ? 'cuentas corrientes' : 'cuenta corriente'
    } / ${numberCenters} ${numberCenters > 1 ? 'centros' : 'centro'} de costo`

    return <Text className={styles.textOpacity}>{textInfo}</Text>
  }

  return (
    <Row>
      <Col className={styles.contentCard} span={24}>
        <div className={styles.containerContent}>
          <Row align="top" justify="space-between">
            <Col xs={24} md={14}>
              <Title level={3} className={styles.title}>
                {userProfile?.company.name}
              </Title>
              {renderCurrentAccountInfo(currentAccount)}
            </Col>
            <Col xs={24} md={7}>
              <Text className={styles.text}>
                {`Registrado: ${moment(userProfile?.createdAt).format(
                  'DD/MM/YYYY',
                )}`}
              </Text>
            </Col>
          </Row>
          <Divider plain className={styles.divider} />
          <Row justify="center" align="middle">
            <Col xs={24} md={24}>
              <p className={styles.text}>
                {' '}
                Filtrar viajes por fecha de compra{' '}
              </p>
            </Col>
            <Col xs={24} md={8}>
              <div className={styles.datePickerPeriod}>
                <Tooltip
                  title={
                    !isDatePickerOpen && getDifferenceDays(startDate, endDate)
                  }>
                  <div className={styles.rangePicker}>
                    <RangePicker
                      className={styles.datePicker}
                      format="DD/MM/YYYY"
                      inputReadOnly={true}
                      defaultValue={[
                        moment(startDateSelected),
                        moment(endDateSelected),
                      ]}
                      value={[startDate, endDate]}
                      locale={locale}
                      onChange={(dates, stringDates) => setDualDates(dates)}
                      placeholder={['Fecha de ida', 'Fecha de vuelta']}
                      onOpenChange={value => setDatePickerOpen(value)}
                      renderExtraFooter={() => (
                        <div>{getDifferenceDays(startDate, endDate)}</div>
                      )}
                    />
                  </div>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  )
}

export default PeriodSection
import React, { FC, useEffect, useState } from 'react'
import {
  DeleteFilled,
  DownloadOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons'
import { useMutation, useSubscription } from '@apollo/client'
import { Button, Card, Col, message, Modal, Row, Tooltip } from 'antd'

import LoadingSpinner from '../../../components/LoadingSpinner'
import { getUser, tokenInfo } from '../../../services/auth'
import { FileDocument, HelpRequest } from '../../../types/HelpRequest.type'
import {
  DELETE_FILE_FROM_HELPREQUEST,
  DOCUMENT_ADDED,
  DOCUMENT_DELETED,
} from '../graphql'

import styles from './styles.module.css'

type Props = {
  helpRequest: HelpRequest
  loading: boolean
}

const docView = (
  doc: FileDocument,
  onDelete: () => void,
  deleteLoading: boolean,
) => {
  const fileName = doc.path.split('/').pop()
  const { user } = tokenInfo(getUser() ?? '')
  return deleteLoading ? (
    <Col>
      <Row justify="center" align="middle">
        <Col className={styles.padding2}>
          <LoadingSpinner size="50px" />
        </Col>
      </Row>
    </Col>
  ) : (
    <Tooltip placement="top" title={fileName}>
      <Card>
        <Row>
          <Col span={24} className={styles.centered}>
            <img src={doc.path} alt="" />
            {fileName}
          </Col>
          <Col span={24} className={styles.centered}>
            <Button
              className={styles.downloadButton}
              href={`${process.env.GATSBY_FILES_URI}${doc.path}`}
              target="blank"
              icon={<DownloadOutlined />}
            />
            {user.sub === doc.userId ? (
              <Button
                icon={<DeleteFilled />}
                onClick={() =>
                  Modal.confirm({
                    cancelText: 'No',
                    content: '',
                    icon: <ExclamationCircleTwoTone />,
                    okText: 'Si, eliminar',
                    onOk: onDelete,
                    title: <h4>¿Eliminar Documento?</h4>,
                  })
                }
              />
            ) : null}
          </Col>
          <Col span={24} className={styles.centered}>
            subido por:
            <p>{doc.userFullName}</p>
          </Col>
        </Row>
      </Card>
    </Tooltip>
  )
}

const AttachmentViewer: FC<Props> = ({ helpRequest, loading }) => {
  const [documents, setDocuments] = useState<FileDocument[]>([])
  const [deletingPath, setDeletingPath] = useState<string | null>(null)

  const { data } = useSubscription(DOCUMENT_ADDED, {
    variables: {
      id: helpRequest._id,
    },
  })

  const { data: dataDocumentDeleted } = useSubscription(DOCUMENT_DELETED, {
    variables: {
      id: helpRequest._id,
    },
  })

  const [deleteFile] = useMutation(DELETE_FILE_FROM_HELPREQUEST, {
    onCompleted: () => {
      message.success('Documento eliminado exitosamente')
      setDeletingPath(null)
    },
    onError: err => {
      message.error(err.message)
      setDeletingPath(null)
    },
  })

  useEffect(() => {
    if (data?.documentAdded) setDocuments([...documents, data.documentAdded])
  }, [data])

  useEffect(() => {
    if (dataDocumentDeleted) {
      const { documentDeleted } = dataDocumentDeleted
      setDocuments(documents.filter(doc => doc.path !== documentDeleted.path))
    }
  }, [dataDocumentDeleted])

  useEffect(() => {
    setDocuments(helpRequest.documents)
  }, [helpRequest])

  const handleDelete = (id: string, fileDocument: FileDocument): void => {
    setDeletingPath(fileDocument.path)
    deleteFile({
      variables: {
        id,
        path: fileDocument.path,
      },
    })
  }

  return (
    <Row wrap>
      <Col>
        <div className={styles.titleText}>
          {documents.length} Archivos adjuntos
        </div>
        <Row align="middle">
          {documents.map(doc => (
            <Col key={doc.path}>
              {docView(
                doc,
                () => handleDelete(helpRequest._id, doc),
                deletingPath === doc.path,
              )}
            </Col>
          ))}
          {loading ? (
            <Col>
              <Row justify="center" align="middle">
                <Col className={styles.padding2}>
                  <LoadingSpinner size="50px" />
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Row>
  )
}

export default AttachmentViewer

import React, { FC, useEffect, useState } from 'react'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { BackTop, Button, Col, Divider, Row } from 'antd'

import { formatDate } from '../../../helpers/dateLongFormat'
import { renderStatus } from '../../../helpers/invoices'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { useScreenWidth } from '../../../hooks/screen.hook'
import { Invoice } from '../../../types/invoice.type'
import { InvoiceListMobileViewProps } from '../types'

import styles from './styles.module.css'

const InvoiceListMobileView: FC<InvoiceListMobileViewProps> = ({
  showHESManager,
  invoices,
  openDocumentsDrawer,
  getExcel,
  changePage,
  selectedPage,
  excelLoading,
  totalInvoices,
  pageBySize,
  loading,
}) => {
  const [mergedInvoices, setMergedInvoices] = useState<Invoice[]>([])
  const [maxPage, setMaxPage] = useState<number>(1)
  const screenWidth = useScreenWidth()

  useEffect(() => {
    if (
      invoices &&
      invoices?.length > 0 &&
      mergedInvoices.length < totalInvoices
    )
      setMergedInvoices([...mergedInvoices, ...invoices])
  }, [invoices])

  useEffect(() => {
    setMaxPage(Math.ceil(totalInvoices / pageBySize))
  }, [pageBySize, totalInvoices])

  const reset = () => {
    if (selectedPage > 1) setMergedInvoices([])
    changePage(1)
  }

  return (
    <Row>
      <Col
        span={24}
        style={{ height: '600px', overflowY: 'auto' }}
        id="invoicesMobileContainer">
        <Divider className={styles.noMargin} />
        <BackTop />
        {mergedInvoices?.map((invoice, index) => (
          <div
            className={index % 2 === 1 ? styles.grayBackground : ''}
            key={invoice._id}>
            {showHESManager ? (
              <Row
                justify="space-around"
                align="middle"
                className={styles.rowPadding}>
                <Col span={12} className={styles.tableRowLabel}>
                  OC/HES
                </Col>
                <Col span={12}>
                  <Button
                    block
                    className={
                      !invoice.sent ? styles.uploadButton : styles.detailButton
                    }
                    onClick={() => openDocumentsDrawer(invoice)}>
                    {invoice.sent ? 'Detalles' : <UploadOutlined />}
                  </Button>
                </Col>
                <Col span={24}>
                  <Divider
                    className={`${styles.noMargin} ${styles.marginTop1}`}
                  />
                </Col>
              </Row>
            ) : null}
            <Row
              justify="space-around"
              align="middle"
              className={styles.rowPadding}>
              <Col span={12}>
                <div className={styles.tableRowLabel}>N° de factura</div>
                <div className={styles.tableRowData}>
                  {invoice.invoiceNumber ?? '---'}
                </div>
              </Col>
              <Col span={12}>
                <div className={styles.tableRowLabel}>Estado</div>
                <div
                  className={`${styles.tableRowData} ${
                    styles[renderStatus(invoice.status)?.class ?? '']
                  }`}>
                  {renderStatus(invoice.status)?.text}
                </div>
              </Col>
            </Row>
            <Row
              justify="space-around"
              align="middle"
              className={styles.rowPadding}>
              <Col span={10} className={styles.rightDivider}>
                <div className={styles.tableRowLabel}>Cuenta corriente</div>
                <div className={styles.tableRowData}>
                  {invoice.currentAccount?.sapNumber ?? '---'}
                </div>
              </Col>
              <Col span={12} offset={2}>
                <div>
                  <span className={styles.tableRowLabel}> Emisión: </span>
                  <span className={styles.tableRowData}>
                    {formatDate(invoice.emissionDate) ?? '---'}
                  </span>
                </div>
                <div className={styles.dateData}>
                  <span className={styles.tableRowLabel}>Vence: </span>
                  <span className={styles.tableRowData}>
                    {formatDate(invoice.dueDate) ?? '---'}
                  </span>
                </div>
              </Col>
            </Row>
            <Row
              justify="space-around"
              align="middle"
              className={styles.rowPadding}>
              <Col span={24}>
                <Divider
                  className={`${styles.noMargin} ${styles.marginTop1}`}
                />
              </Col>
              <Col span={12} className={styles.amountLabelFont}>
                Monto
              </Col>
              <Col span={12} className={styles.amountDataFont}>
                ${numberWithSeparator(invoice?.totalAmount ?? 0)}
              </Col>
            </Row>
            <Row
              justify="space-around"
              align="middle"
              gutter={6}
              className={`${styles.rowPadding} ${styles.buttonsRowDivider}`}>
              <Col span={13}>
                <Button
                  block
                  loading={excelLoading === invoice._id}
                  icon={<DownloadOutlined />}
                  className={styles.documentButton}
                  onClick={() =>
                    getExcel(invoice._id, invoice.invoiceNumber ?? '')
                  }>
                  <span
                    className={
                      screenWidth <= 320 ? styles.smallFont : undefined
                    }>
                    Excel movimientos
                  </span>
                </Button>
              </Col>
              <Col span={11}>
              <a href={invoice.invoicePath}
              target="blank">
                <Button
                  block
                  className={styles.mobileDocumentButton}
                  icon={<DownloadOutlined />}
                  disabled={!invoice.invoicePath}>
                  <span
                    className={
                      screenWidth <= 320 ? styles.smallFont : undefined
                    }>
                    .PDF facturas
                  </span>
                </Button>
              </a>
              </Col>
            </Row>
          </div>
        ))}

        <Row justify="center">
          <Col className={styles.marginBottom2}>
            {maxPage > selectedPage && mergedInvoices.length < totalInvoices ? (
              <Button
                loading={loading}
                onClick={() => changePage(selectedPage + 1)}>
                Cargar más
              </Button>
            ) : null}
            {maxPage === selectedPage && selectedPage > 1 ? (
              <Button loading={loading} onClick={reset}>
                Volver a las primeras facturas
              </Button>
            ) : null}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default InvoiceListMobileView

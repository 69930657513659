import React, { FC, useContext, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Col, Form, Input, Select } from 'antd'

import PhonePrefix from '../../../components/phonePrefix'
import { formatRut, validateRut } from '../../../helpers/rutValidators'
import { GET_ADDRESSES_QUERY, MY_COST_CENTERS } from '../../../queries'
import { SessionContext } from '../../../services/session'
import { CostCenter } from '../../../types/page-types/pay-page.types'
import { InnerDrawerFormProps } from '../types'

import styles from './styles.module.css'

const { Item } = Form
const { Option } = Select

const InnerDrawerForm: FC<InnerDrawerFormProps> = ({ form, passenger }) => {
  const {
    costCenter,
    dv,
    email,
    firstName,
    fatherLastName,
    motherLastName,
    nationality,
    rut,
    passport,
    position,
  } = passenger
  const [areaCode, phone] = passenger?.phone?.split(' ') ?? ['56', undefined]
  const { user } = useContext(SessionContext)

  const { loading: addressLoading, data: addressData } =
    useQuery(GET_ADDRESSES_QUERY)

  const [
    loadCostCenters,
    { loading: costCenterLoading, data: costCenterData },
  ] = useLazyQuery(MY_COST_CENTERS)

  useEffect(() => {
    if (user.role.includes('CCC')) loadCostCenters()
  }, [])

  return (
    <Col span={20}>
      <Form
        form={form}
        layout="vertical"
        preserve={false}
        initialValues={{
          costCenter: (costCenter as CostCenter)?._id,
          firstName,
        }}>
        <Item
          className={styles.inputRow}
          name="firstName"
          label="Nombre"
          rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
          <Input placeholder="Nombre del pasajero" />
        </Item>
        <Item
          className={styles.inputRow}
          name="fatherLastName"
          label="Apellido paterno"
          initialValue={fatherLastName}
          rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
          <Input placeholder="Apellido paterno del pasajero" />
        </Item>
        <Item
          className={styles.inputRow}
          name="motherLastName"
          label="Apellido materno"
          initialValue={motherLastName}
          rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
          <Input placeholder="Apellido materno del pasajero" />
        </Item>
        {rut ? (
          <Item
            className={styles.inputRow}
            initialValue={rut ? `${rut}-${dv}` : null}
            label="Identificación"
            name="rut"
            normalize={rut => formatRut(rut)}
            rules={[
              { required: false, message: 'Este campo es obligatorio' },
              () => ({
                validator(rule, value) {
                  if (validateRut(value) || !value) return Promise.resolve()

                  return Promise.reject('Documento inválido.')
                },
              }),
            ]}>
            <Input
              disabled
              maxLength={12}
              placeholder="Ejemplo: 76.325.945-k"
            />
          </Item>
        ) : null}
        {passport ? (
          <Item
            initialValue={passport}
            className={styles.inputRow}
            label="Pasaporte"
            name="passport">
            <Input disabled maxLength={12} placeholder="Ejemplo: 76325945" />
          </Item>
        ) : null}

        <Item
          className={styles.inputRow}
          initialValue={phone}
          label="Teléfono"
          name="phone"
          rules={[
            { required: true, message: '' },
            {
              max: 9,
              message: 'no se permiten mas de 9 caracteres',
            },
            {
              pattern: /^[0-9]*$/,
              message: 'debe ingresar solo números',
            },
          ]}>
          <Input
            addonBefore={<PhonePrefix areaCode={areaCode} />}
            placeholder="Ejemplo: 912345678"
          />
        </Item>
        <Item
          className={styles.inputRow}
          initialValue={email}
          label="Correo electrónico"
          name="email"
          rules={[
            { required: true, message: 'Este campo es obligatorio' },
            {
              pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: 'Introduzca un correo válido',
            },
          ]}>
          <Input placeholder="Ejemplo: micorreo@correo.cl" />
        </Item>
        <Item
          className={styles.inputRow}
          initialValue={nationality}
          label="Nacionalidad"
          name="nationality"
          rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
          {addressData && (
            <Select
              allowClear
              className={styles.selectInputRow}
              loading={addressLoading}
              placeholder="Seleccione País"
              optionFilterProp="children"
              showArrow={false}
              showSearch>
              {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
              {addressData.getAddresses?.map(({ countryName }: any) => (
                <Option key={countryName} value={countryName}>
                  {countryName}
                </Option>
              ))}
            </Select>
          )}
        </Item>
        <Item
          className={styles.inputRow}
          initialValue={position}
          label="Cargo"
          name="position"
          rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
          <Input placeholder="Cargo del pasajero" />
        </Item>
        {user?.role === 'CCC' ||
          user?.role === 'ADMIN_CCC' ||
          user?.role === 'MULTI_RUT_ADMIN' ||
          user?.role === 'MULTI_RUT_BUYER'  &&  (
          <Item
            className={styles.inputRow}
            label="Centro de costo"
            name="costCenter"
            rules={[{ required: true, message: 'Este campo es obligatorio' }]}>
            <Select
              className={styles.selectInputRow}
              loading={costCenterLoading}
              optionFilterProp="children"
              placeholder="Centro de costo"
              showSearch>
              {costCenterData &&
                costCenterData.myCostCenters.map(
                  ({ _id, name }: CostCenter) => (
                    <Option key={_id} value={_id}>
                      {name}
                    </Option>
                  ),
                )}
            </Select>
          </Item>
        )}
      </Form>
    </Col>
  )
}

export default InnerDrawerForm

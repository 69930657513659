import React, { FC, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Col, Empty, message, Row } from 'antd'

import AdminLayout from '../../components/AdminLayout'
import LoadingSpinner from '../../components/LoadingSpinner'
import SEO from '../../components/seo'
import { useEventSave } from '../../hooks/analytics.hook'
import { DELETE_COST_CENTER, MY_CURRENT_ACCOUNTS } from '../../queries'
import { getUser, tokenInfo } from '../../services/auth'
import { CostCenter } from '../../types/costCenter.type'
import { CurrentAccount } from '../../types/currentAccount.type'

import CECODrawer from './CECODrawer'
import CostCenterFilterRow from './CostCenterFilterRow'
import CostCenterListRow from './CostCenterListRow'

import styles from './styles.module.css'

const CostCentersPage: FC = () => {
  const [currentAccounts, setCurrentAccounts] = useState<CurrentAccount[]>([])
  const [costCenters, setCostCenters] = useState<CostCenter[]>([])
  const [selectedAccount, setSelectedAccount] = useState({} as CurrentAccount)
  const [selectedCC, setSelectedCC] = useState({} as CostCenter)
  const [visible, setVisible] = useState(false)

  const thereIsCostCenter =
    Object.keys(selectedCC).length > 0 ? selectedCC : undefined

  const onChangeCurrentAccount = (
    currentId?: string,
    accounts = currentAccounts,
  ) => {
    if (currentId) {
      const selected = accounts.find(({ _id }) => _id === currentId)
      setCostCenters(selected?.costCenters ?? [])
      setSelectedAccount(selected ?? ({} as CurrentAccount))
    }
  }

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_centro_de_costos' as string

    useEventSave('vista_centro_de_costos', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const { loading, refetch } = useQuery(MY_CURRENT_ACCOUNTS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ myCurrentAccounts }) => {
      if (myCurrentAccounts) {
        if (selectedAccount._id)
          onChangeCurrentAccount(selectedAccount._id, myCurrentAccounts)
        setCurrentAccounts(myCurrentAccounts ?? [])
      }
    },
  })

  const [deleteCostCenter] = useMutation(DELETE_COST_CENTER)

  const handleDelete = async (costCenterId: string) => {
    try {
      await deleteCostCenter({
        variables: { input: costCenterId },
      })
      await refetch()
      message.success(`Centro de costo eliminado de exitosamente`)
    } catch (err) {
      message.error(`Ha ocurrido un error al eliminar centro de costo. ${err}`)
    }
  }

  const handleVisible = (value: boolean, selected?: CostCenter) => {
    setSelectedCC(selected ?? ({} as CostCenter))
    setVisible(value)
  }

  return (
    <>
      <SEO title="Mis centros de costo" />
      <AdminLayout title="Centros de costo">
        <Row className={styles.costCentersDisplay} justify="space-around">
          <CECODrawer
            costCenter={thereIsCostCenter}
            creditLines={currentAccounts}
            currentCreditLine={selectedAccount}
            handleVisible={handleVisible}
            refetch={refetch}
            visible={visible}
          />
          <Col span={24}>
            <CostCenterFilterRow
              currentAccounts={currentAccounts}
              handleVisible={handleVisible}
              onChangeCurrentAccount={onChangeCurrentAccount}
              refetch={refetch}
            />
            <Row
              align="top"
              className={styles.costCenterDisplayContent}
              justify="center">
              <Col span={24}>
                {loading ? (
                  <LoadingSpinner size="50px" />
                ) : costCenters && costCenters.length > 0 ? (
                  <CostCenterListRow
                    costCenters={costCenters}
                    handleDelete={handleDelete}
                    handleVisible={handleVisible}
                  />
                ) : (
                  <Empty description="No se encontraron cuentas asociadas." />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </AdminLayout>
    </>
  )
}

export default CostCentersPage

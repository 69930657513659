import React, { useEffect, useLayoutEffect, useState } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import {
  Button,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Skeleton,
} from 'antd'
import { navigate } from 'gatsby'

import SEO from '../../components/seo'
import { defaultFormErrorMessage } from '../../helpers/messages'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../services/auth'
import {
  LevelOneType,
  LevelThreeType,
  LevelTwoType,
  Option,
  UploadDocument,
} from '../../types/page-types/create-ticket-page.types'
import HelpCreatedPage from '../help-created-page'

import Cross from './cross.inline.svg'
import { CREATE_HELP, GET_LEVELS } from './graphql'
import LevelThreeInputs from './LevelThreeInputs'
import StageSelects from './StageSelects'

import styles from './styles.module.css'

const { Item } = Form

const CreateTicketPage: React.FC<RouteComponentProps> = () => {
  const [createHelp, { loading }] = useMutation(CREATE_HELP)
  const [width, setWidth] = useState<number>(0)

  useLayoutEffect(() => {
    const updateSize = () => setWidth(window?.innerWidth)
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_creacion_solicitudes' as string

    useEventSave('vista_creacion_solicitudes', {
      email_usuarios: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const {
    loading: levelsLoading,
    error: levelsError,
    data: levelsData,
  } = useQuery(GET_LEVELS)

  const [selectedLevelOne, setSelectedLevelOne] = useState<string | undefined>(
    undefined,
  )
  const [selectedLevelTwo, setSelectedLevelTwo] = useState<string | undefined>(
    undefined,
  )
  const [selectedLevelThree, setSelectedLevelThree] = useState<
    string | undefined
  >(undefined)

  const [boletusNumber, setBoletusNumber] = useState('')

  const [transactionNumber, setTransactionNumber] = useState('')

  const [comment, setComment] = useState('')

  const [isOperatorComplain, setIsOperatorComplain] = useState(false)

  const [isSuccess, setIsSuccess] = useState(false)
  const [newTicketNumber, setNewTicketNumber] = useState('')
  const documents: UploadDocument[] = []

  const { Option } = Select

  const getDocumentUrls = () => {
    return documents.map(doc => doc.url)
  }

  const formLoader = () => {
    const numberOfLoaders = [...Array(4).keys()]
    return (
      <>
        {numberOfLoaders.map(number => {
          return (
            <Skeleton.Input
              className={styles.inputSkeleton}
              active
              size="default"
              key={number}
            />
          )
        })}
      </>
    )
  }

  if (levelsError) return <p>error</p>
  // level managment
  const newLevelOneMatrix = levelsData?.getLevels.find(
    (object: LevelOneType) => object._id === selectedLevelOne,
  )

  const newLevelTwoMatrix = newLevelOneMatrix?.levelTwo.find(
    (object: LevelTwoType) => object._id === selectedLevelTwo,
  )

  const newLevelThreeMatrix = newLevelTwoMatrix?.levelThree.find(
    (object: LevelThreeType) => object._id === selectedLevelThree,
  )
  const getLevelTwoOptionCode =
    newLevelTwoMatrix?.options?.map((option: Option) => {
      return option.code
    }) || []

  const getLevelThreeOptionCode = newLevelThreeMatrix?.options.map(
    (option: Option) => {
      return option.code
    },
  )

  const options =
    (newLevelTwoMatrix?.options?.length > 0 && getLevelTwoOptionCode) ||
    (newLevelThreeMatrix?.options?.length > 0 && getLevelThreeOptionCode)
  // end of levels managment
  const isSendValid =
    newLevelTwoMatrix?.options?.length > 0 ||
    newLevelThreeMatrix?.options?.length > 0

  const isLevelTwoNeeded =
    newLevelTwoMatrix?.options?.length > 0 || !selectedLevelTwo
  const isLevelThreeNeeded =
    newLevelTwoMatrix?.options?.length > 0 ||
    newLevelThreeMatrix?.options?.length > 0

  const evalIfOperatorComplain = (levelId: string) => {
    const foundLevel = newLevelOneMatrix?.levelTwo.find(
      (object: LevelTwoType) => object._id === levelId,
    )
    foundLevel && foundLevel.operatorComplain
      ? setIsOperatorComplain(true)
      : setIsOperatorComplain(false)
  }

  const controlFormData = (value: string, stage: number) => {
    switch (stage) {
      case 1:
        setSelectedLevelOne(value as string)
        setSelectedLevelTwo(undefined)
        setSelectedLevelThree(undefined)
        setBoletusNumber('')
        setTransactionNumber('')
        setComment('')
        break
      case 2:
        setSelectedLevelTwo(value)
        evalIfOperatorComplain(value)
        setSelectedLevelThree(undefined)
        setBoletusNumber('')
        setTransactionNumber('')
        setComment('')
        break
      case 3:
        setSelectedLevelThree(value)
        setBoletusNumber('')
        setTransactionNumber('')
        setComment('')
        break
      case 4:
        break
      default:
        break
    }
  }
  const [form] = Form.useForm()

  const onFormFinish = async () => {
    try {
      await form.validateFields()
      const helpData = await createHelp({
        variables: {
          input: {
            requestType: selectedLevelOne,
            requestReason: selectedLevelTwo,
            requestReasonOption: selectedLevelThree,
            boletusNumber,
            transactionNumber,
            body: comment,
            documents: getDocumentUrls(),
          },
        },
      })
      if (helpData) {
        setNewTicketNumber(helpData?.data?.createHelp?.ticketNumber)
        setIsSuccess(true)
      }
    } catch (error) {
      message.error(defaultFormErrorMessage, 5)
    }
  }

  const renderHeaderMobile = () => {
    return (
      <div className={styles.headerMobile}>
        <div className={styles.iconMobile}>
          <ArrowLeftOutlined
            style={{ color: '#fff', fontSize: '18px' }}
            onClick={() => {
              navigate(-1)
            }}
          />
        </div>
        <div className={styles.titleMobile}>Nueva solicitud</div>
      </div>
    )
  }

  return isSuccess ? (
    <HelpCreatedPage ticketNumber={newTicketNumber} />
  ) : (
    <>
      <SEO title="Creación de Ticket" />
      <Row justify="end" className={styles.container}>
        <Col xs={24} md={12} className={styles.formContainer}>
          {width < 768 ? (
            renderHeaderMobile()
          ) : (
            <>
              <Row align="middle" className={styles.rowBack}>
                <Col md={20}>
                  <p className={styles.pageTitleFont}>
                    Solicitud de requerimiento
                  </p>
                </Col>
                <Col md={4}>
                  <div
                    className={styles.crossIcon}
                    onClick={() => {
                      navigate(-1)
                    }}>
                    <Cross />
                  </div>
                </Col>
              </Row>
              <Divider />
            </>
          )}
          <Row className={styles.content}>
            <Col xs={24}>
              {levelsLoading ? (
                formLoader()
              ) : (
                <Form form={form} layout="vertical">
                  <StageSelects
                    stage={0}
                    controlFormData={controlFormData}
                    selectedLevelOne={selectedLevelOne}
                    selectedLevelTwo={selectedLevelTwo}
                    selectedLevelThree={selectedLevelThree}
                    levelsData={levelsData?.getLevels}
                    newLevelOneMatrix={newLevelOneMatrix}
                    newLevelTwoMatrix={newLevelTwoMatrix}
                  />
                  {selectedLevelOne ? (
                    <StageSelects
                      stage={1}
                      controlFormData={controlFormData}
                      selectedLevelOne={selectedLevelOne}
                      selectedLevelTwo={selectedLevelTwo}
                      selectedLevelThree={selectedLevelThree}
                      levelsData={levelsData?.getLevels}
                      newLevelOneMatrix={newLevelOneMatrix}
                      newLevelTwoMatrix={newLevelTwoMatrix}
                    />
                  ) : null}
                  {isLevelTwoNeeded ? null : (
                    <StageSelects
                      stage={2}
                      controlFormData={controlFormData}
                      selectedLevelOne={selectedLevelOne}
                      selectedLevelTwo={selectedLevelTwo}
                      selectedLevelThree={selectedLevelThree}
                      levelsData={levelsData?.getLevels}
                      newLevelOneMatrix={newLevelOneMatrix}
                      newLevelTwoMatrix={newLevelTwoMatrix}
                    />
                  )}
                  {isLevelThreeNeeded ? (
                    <LevelThreeInputs
                      options={options}
                      setBoletusNumber={setBoletusNumber}
                      setTransactionNumber={setTransactionNumber}
                      setComment={setComment}
                      isOperatorComplain={isOperatorComplain}
                      documents={documents}
                    />
                  ) : null}
                </Form>
              )}
            </Col>
          </Row>
          <div className={styles.buttons}>
            <div className={styles.button}>
              <Button
                className={styles.backButton}
                onClick={() => {
                  navigate('/app/ayuda')
                }}>
                Cancelar
              </Button>
            </div>
            <div className={styles.button}>
              <Button
                className={
                  !isSendValid
                    ? styles.continueDisabledButton
                    : styles.continueButton
                }
                disabled={!isSendValid}
                loading={loading}
                onClick={async () => await onFormFinish()}>
                Enviar
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default CreateTicketPage

import React, { FC, useState } from 'react'
import { Button, Col, Form, message, Row, Tooltip, Upload } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import axios from 'axios'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import {
  UploadFormProps,
  UploadFormSubmit,
} from '../../../types/component-types/CreditLineRequestDrawer'
import { OnChangeInfo } from '../../../types/component-types/FileUploadForm.types'

import styles from './style.module.css'

const UploadForm: FC<UploadFormProps> = ({ form, setDocuments }) => {
  const [taxFolderUri, setTaxFolderUri] = useState('')
  const [DICOMUri, setDICOMUri] = useState('')

  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<UploadFormSubmit>,
  ) => void = errorInfo => {
    console.log('Failed:', errorInfo)
  }

  const onChange = (
    info: OnChangeInfo,
    setUri: (uri: string) => void,
    setLocal: (uri: string) => void,
  ) => {
    const { file } = info
    const { status } = file

    if (status === 'done') {
      message.success(`${info.file.name} se cargó con éxito`)
      console.log(info.file.response.data)
      const serverResponse = info.file.response
      setUri(serverResponse.data.fileUrl)
      setLocal(serverResponse.data.fileUrl)
    } else if (status === 'error')
      message.error(`${info.file.name} tuvo un problema al cargar`)
  }

  const onChangeDICOM = (info: OnChangeInfo) =>
    onChange(info, setDocuments.setDicom, setDICOMUri)

  const onChangeTaxFolder = (info: OnChangeInfo) =>
    onChange(info, setDocuments.setTaxFolder, setTaxFolderUri)

  const onRemove = (path: string, setFileUri: (uri: string) => void) => {
    axios
      .delete(process.env.GATSBY_API_UPLOAD_URI as string, {
        data: {
          path,
        },
      })
      .then(() => setFileUri(''))
      .catch(err => console.error(err))
  }

  const beforeUpload = (
    file: UploadFile,
    fileList: UploadFile[],
    fileUri: string,
  ) => {
    const isLt5M = file.size / 1024 / 1024 < 5

    if (!isLt5M) {
      message.error('¡Tamaño del documento es mayor al límite de 5MB!')
      fileList.length = 0
    }

    const notValid = !!fileUri

    if (notValid) {
      message.error('Primero elimine el documento seleccionado')
      fileList.pop()
    }

    return isLt5M && !notValid
  }

  const beforeUploadTaxFolder = (file: UploadFile, fileList: UploadFile[]) =>
    beforeUpload(file, fileList, taxFolderUri)

  const beforeuploadDICOM = (file: UploadFile, fileList: UploadFile[]) =>
    beforeUpload(file, fileList, DICOMUri)

  const uploadProps = {
    name: 'file',
    multiple: false,
    headers: {
      authorization: 'authorization-text',
    },
  }

  return (
    <Row justify="center">
      <Col xs={24}>
        <Form form={form} onFinishFailed={onFinishFailed}>
          <Form.Item name="taxFolder">
            <Row justify="space-around" align="bottom">
              <Col xs={24} sm={10}>
                <p>Carpeta tributaria</p>
              </Col>
              <Col xs={24} sm={10} push={3}>
                <Upload
                  {...uploadProps}
                  action={`${process.env.GATSBY_API_UPLOAD_URI}/taxFolder`}
                  onChange={onChangeTaxFolder}
                  onRemove={() => onRemove(taxFolderUri, setTaxFolderUri)}
                  beforeUpload={beforeUploadTaxFolder}>
                  <Button>Subir archivo</Button>
                </Upload>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item name="DICOM">
            <Row justify="space-around" align="bottom">
              <Col xs={24} sm={10}>
                <p className={styles.dicomFont}>DICOM full</p>
              </Col>
              <Col xs={24} sm={10} push={3}>
                <Upload
                  {...uploadProps}
                  action={`${process.env.GATSBY_API_UPLOAD_URI}/DICOM`}
                  onChange={onChangeDICOM}
                  onRemove={() => onRemove(DICOMUri, setDICOMUri)}
                  beforeUpload={beforeuploadDICOM}>
                  <Button>Subir archivo</Button>
                </Upload>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <p className={styles.continueDescription}>
          ¿Te falta un documento? Aún así puedes continuar tu solicitud. Uno de
          nuestros ejecutivos se contará a la brevedad contigo. Contáctanos a
          contacto.simplus@simplus.cl
        </p>
      </Col>
    </Row>
  )
}

export default UploadForm

import React, { FC, useContext } from 'react'
import { Collapse, Divider } from 'antd'

import MobileTravelInfoBox from '../../../../components/MobileTravelInfoBox'
import { getTravelInfo } from '../../../../helpers/travel-page/dates.helper'
import { TravelPageContext } from '../../Provider'

import styles from './styles.module.css'
const { Panel } = Collapse
const TripInformation: FC = () => {
  const { hasReturn, transaction } = useContext(TravelPageContext)
  const {
    departureTripDuration,
    returnTripDuration,
  } = getTravelInfo(transaction)

  return (
    <>
      <Collapse bordered={false} className={styles.card}>
        <Panel
          header="Detalles del viaje"
          key="1"
          style={{ borderBottom: 'none' }}>
          <Divider className={styles.panelDivider} />
          <MobileTravelInfoBox
            departureTripDuration={departureTripDuration}
            transaction={transaction}
            type="gone"
          />
          <Divider />
          {hasReturn ? (
            <MobileTravelInfoBox
              returnTripDuration={returnTripDuration}
              transaction={transaction}
              type="return"
            />
          ) : null}
        </Panel>
      </Collapse>
    </>
  )
}

export default TripInformation

import React, { FC } from 'react'
import { Form, Select } from 'antd'

import {
  LevelOneType,
  LevelThreeType,
  LevelTwoType,
  StageSelectsProps,
} from '../../../types/page-types/create-ticket-page.types'

import styles from './styles.module.css'

const { Item } = Form
const { Option } = Select

const StageSelects: FC<StageSelectsProps> = ({
  stage,
  controlFormData,
  selectedLevelOne,
  selectedLevelTwo,
  selectedLevelThree,
  levelsData,
  newLevelOneMatrix,
  newLevelTwoMatrix,
}) => {
  switch (stage) {
    case 0:
      return (
        <Item label="Mi requerimiento es:">
          <Select
            className={styles.input}
            size="large"
            placeholder="Seleccione una opción"
            onChange={value => controlFormData(value, 1)}
            value={selectedLevelOne}>
            {levelsData?.map((levelOne: LevelOneType) => {
              return (
                <Option value={levelOne._id} key={levelOne._id}>
                  {levelOne.title}
                </Option>
              )
            })}
          </Select>
        </Item>
      )
    case 1:
      return (
        <Item label="El motivo de mi requerimiento es:">
          <Select
            className={styles.input}
            size="large"
            placeholder="Seleccione una opción"
            onChange={value => controlFormData(value, 2)}
            value={selectedLevelTwo}>
            {newLevelOneMatrix?.levelTwo?.map((levelTwo: LevelTwoType) => {
              return (
                <Option value={levelTwo._id} key={levelTwo._id}>
                  {levelTwo.title}
                </Option>
              )
            })}
          </Select>
        </Item>
      )
    case 2:
      return (
        <Item label="La mejor opción que representa el motivo de mi requerimiento es:">
          <Select
            className={styles.input}
            size="large"
            placeholder="Seleccione una opción"
            onChange={value => controlFormData(value, 3)}
            value={selectedLevelThree}>
            {newLevelTwoMatrix?.options &&
            newLevelTwoMatrix?.options?.length > 0
              ? null
              : newLevelTwoMatrix?.levelThree?.map(
                  (levelThree: LevelThreeType) => {
                    return (
                      <Option value={levelThree._id} key={levelThree._id}>
                        {levelThree.title}
                      </Option>
                    )
                  },
                )}
          </Select>
        </Item>
      )
    default:
      return null
  }
}

export default StageSelects

import React, { FC } from 'react'
import { Router } from '@reach/router'

import PrivateRoute from '../components/privateRoute'
import { RolesEnum } from '../helpers/enums'
import ConfirmAccount from '../screens/account-confirm'
import ConfirmRegistrationPage from '../screens/confirm-register'
import ContactExecutivePage from '../screens/contact-executive-page'
import CostCentersPage from '../screens/cost-centers-page'
import CreateTicketPage from '../screens/create-ticket-page'
import FAQPage from '../screens/FAQ-page'
import FinishDevolution from '../screens/finish-devolution-page'
import HelpRequestDetailPage from '../screens/help-request-detail-page'
import Home from '../screens/home-page'
import InvoicesPage from '../screens/invoices'
import DemoPage from '../screens/landing-page/Demo'
import MyPassengers from '../screens/my-passengers'
import MyRequestPage from '../screens/my-requests-page'
import MyTravels from '../screens/my-travels'
import TravelDetailsPage from '../screens/my-travels/travelDetail'
import MyUsersPage from '../screens/my-users-page'
import RegisterPage from '../screens/register'
import ReportPage from '../screens/reports-page'
import TermsAndConditionsPage from '../screens/terms-and-conditions-page'
import TicketsPage from '../screens/tickets-page'
import TravelPage from '../screens/travel-page'
import PaymentError from '../screens/travel-page/PayPage/PaymentError'
import PaymentFinished from '../screens/travel-page/PayPage/PaymentFinished'

import NotFoundPage from './404'

import './calendarStyles.css'
import HelpCreatedPage from '../screens/help-created-page'
import HelpPage from '../screens/help-page'
import TransbankPaymentFinished from '../screens/travel-page/PayPage/PaymentFinished/TransbankPayment'

const App: FC = () => {
  return (
    <Router basepath="/app">
      <NotFoundPage path="*" />
      <RegisterPage path="/registro" />
      <DemoPage path="/solicitud-demo" />
      <TermsAndConditionsPage path="/terminos-y-condiciones" />
      <ConfirmRegistrationPage path="/confirmar-registro" />
      <ConfirmAccount path="/confirmar-cuenta" />
      <PrivateRoute path="/inicio" component={Home} />
      <PrivateRoute path="/mis-pasajeros" component={MyPassengers} />
      <PrivateRoute path="/mis-solicitudes/" component={MyRequestPage} />
      <PrivateRoute path="/mis-viajes" component={MyTravels} />
      <PrivateRoute
        path="/mis-viajes/:bookingId"
        component={TravelDetailsPage}
      />
      <PrivateRoute
        path="/mis-viajes/devolucion"
        component={FinishDevolution}
      />
      <PrivateRoute
        path="/centros-de-costo/"
        roles={[RolesEnum.ADMIN_CCC, RolesEnum.MULTI_RUT_ADMIN]}
        component={CostCentersPage}
      />
      <PrivateRoute
        path="/mis-usuarios/"
        roles={[
          RolesEnum.ADMIN_CCC,
          RolesEnum.ADMIN_SCC,
          RolesEnum.MULTI_RUT_ADMIN,
        ]}
        component={MyUsersPage}
      />
    
      <PrivateRoute path="/ayuda" component={HelpPage} />
      <PrivateRoute path="/ayuda/faq" component={FAQPage} />
      <PrivateRoute path="/ayuda/tickets" component={TicketsPage} />
      <PrivateRoute path="/ayuda/contacto" component={ContactExecutivePage} />
      <PrivateRoute path="/ayuda/crear-ticket" component={CreateTicketPage} />
      <PrivateRoute path="/ayuda/contacto/exito" component={HelpCreatedPage} />

      <PrivateRoute path="/viajes/buscar" component={TravelPage} />

      <PrivateRoute path="/viajes/pago-exitoso" component={PaymentFinished} />

      <PrivateRoute path="/viajes/pasarela/error" component={PaymentError} />

      <PrivateRoute path="/reportes" component={ReportPage} />

      <PrivateRoute path="/solicitudes/:id" component={HelpRequestDetailPage} />

      <PrivateRoute
        roles={[RolesEnum.ADMIN_CCC, RolesEnum.MULTI_RUT_ADMIN]}
        path="/facturas"
        component={InvoicesPage}
      />
    </Router>
  )
}

export default App

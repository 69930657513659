import React from 'react'
import { Col, Row, Typography } from 'antd'

import { FrequentTravelSccProps } from '../types/props'

import FrequentTravel from './FrequentTravel'

import styles from './styles.module.css'

const { Text } = Typography

const FrequentTravelScc: React.FC<FrequentTravelSccProps> = ({
  frequentTravel,
}) => {
  return (
    <Row className={styles.rowMarginRight}>
      <Col className={styles.contentCard} md={24}>
        <div className={styles.containerContent}>
          <Text className={styles.text}>Viajes frecuentes</Text>
          <div className={styles.containerFrequentInfo}>
            <FrequentTravel
              frequentTravel={frequentTravel}
              className={styles.containerListSCC}
            />
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default FrequentTravelScc

import React, { FC } from 'react'
import { Breadcrumb, Col, Empty, Row } from 'antd'
import moment from 'moment'

import { TravelInfoProps } from '../../types'

import styles from './styles.module.css'

const { Item } = Breadcrumb

const TravelInfo: FC<TravelInfoProps> = ({
  date,
  destiny,
  origin,
  amountOfTrips,
}) => (
  <>
    <Row>
      <Col xs={15} span={24}>
        {date && destiny && origin ? (
          <Row align="middle">
            <Col>
              <Breadcrumb separator=">" className={styles.font}>
                <Item className={styles.font}>{origin}</Item>
                <Item className={styles.font}>{destiny}</Item>
              </Breadcrumb>
            </Col>
            <Col className={styles.dateFont}>
              {moment(date, 'DD-MM-YYYY').format('LL')}
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
    {amountOfTrips > 0 ? (
      <p className={styles.amountOfTrips}>{amountOfTrips} resultados </p>
    ) : (
      <div className={styles.emptyTravels}>
        <Empty description="No se encontraron viajes." />
      </div>
    )}
  </>
)

export default TravelInfo

import React, { FC } from 'react'
import { Col, Row, Timeline } from 'antd'
import { dropLast } from 'ramda'

import { Section } from '../../../screens/travel-page/types'
import { SectionProps } from '../../types'

import styles from './styles.module.css'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const SectionDisplay: FC<SectionProps> = ({
  sections,
}): JSX.Element | JSX.Element[] => {
  const genArrivalTime = (integrationTime: string) => {
    const [date, time] = integrationTime.split(' ')
    return time
  }
  const renderDirect = () => (
    <Timeline.Item key="direct">
      <Row align="middle">
        <Col>
          <p>Directo</p>
        </Col>
      </Row>
    </Timeline.Item>
  )
  const renderRow = (section: Section, index: number) => {
    const { date_time_city_from_section, agency_name_from_section } = section
    return (
      <Timeline.Item key={`${index}${date_time_city_from_section}`}>
        <Row align="middle" key={`${index}${agency_name_from_section}`}>
          <Col style={{ marginRight: 20 }}>
            <span>{index + 1} Parada</span>
          </Col>
          <Col>
            <div className={styles.cardLightFont}>
              Llegada: {genArrivalTime(date_time_city_from_section)}
            </div>
            <span className={styles.cardLightFont}>
              {agency_name_from_section}
            </span>
          </Col>
        </Row>
      </Timeline.Item>
    )
  }

  const renderSectionsZone = () => {
    return sections?.length === 1
      ? renderDirect()
      : sections
      ? sections?.map((section, index) => renderRow(section, index))
      : []
  }

  return renderSectionsZone()
}

export default SectionDisplay

import React, { FC } from 'react'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Form, Input, message } from 'antd'
import { navigate } from 'gatsby'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import WhiteLogo from '../../../components/OptionsHeader/white-logo.inline.svg'
import PhonePrefix from '../../../components/phonePrefix'
import Footer from '../new-landing/Footer'

import { CREATE_DEMO_REQUEST } from './mutations.graphql'

import styles from './styles.module.css'

const { Item } = Form

const { TextArea } = Input

type FormTypes = {
  name: string
  email: string
  areaCode: string
  shortPhone: string
  company: string
  comment: string
}

const Demo: FC<RouteComponentProps> = () => {
  const [requestDemo, { loading }] = useMutation(CREATE_DEMO_REQUEST, {
    onCompleted: () => {
      message.success(
        'Tu solicitud fue enviada a nuestros ejecutivos. Te contactaremos a la brevedad!',
      )
      navigate('/inicio')
    },
    onError: err => {
      message.error(err.message)
      message.error('Ocurrió un error al enviar la solicitud')
    },
  })

  const onFinishFailed: (errorInfo: ValidateErrorEntity) => void =
    errorInfo => {
      console.log('Failed:', errorInfo)
    }

  const onFinish = (data: FormTypes) => {
      const { areaCode, shortPhone,name, email,company,comment } = data
      const formattedData = {
          name,
          email,
          company,
          comment,
        phone: `${areaCode} ${shortPhone}`
      }
    requestDemo({ variables: {
        data: formattedData
        } })
  }

  const renderCenter = () => {
    return (
      <div className={styles.center}>
        <div className={styles.title}>
          Simplus, tu solución en transporte empresarial
        </div>
        <div className={styles.form}>
          <Form
            initialValues={{ remember: true, country: 'Chile' }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            <div className={styles.label}>Nombre apellido</div>
            <Item
              className={styles.inputContainer}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Este campo es obligatorio',
                },
                {
                  pattern: new RegExp(
                    /[A-zÀ-ÿ]/
                  ),
                  message: 'Solo se permiten letras'
                }
              ]}>
              <Input className={styles.input} />
            </Item>

            <div className={styles.label}>Teléfono (incluir número de área)</div>
              <Item
                  className={styles.inputContainer}
                  name="shortPhone"
                  rules={[
                    { required: true, message: '' },
                    {
                      pattern: /^[0-9]*$/,
                      message: 'debe ingresar solo números',
                    },
                    {
                      max: 9,
                      message: 'no se permiten mas de 9 caracteres',
                    },
                  ]}
                  >
                <Input
                    addonBefore={<PhonePrefix />}
                    className={styles.input}
                    placeholder="Ejemplo: 912345678"
                />
              </Item>

            <div className={styles.label}>Email</div>
            <Item
              className={styles.inputContainer}
              name="email"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
                {
                  pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: 'Introduzca un correo válido',
                },
              ]}>
              <Input className={styles.input} />
            </Item>

            <div className={styles.label}>Empresa</div>
            <Item
              className={styles.inputContainer}
              name="company"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
              ]}>
              <Input className={styles.input} />
            </Item>

            <div className={styles.label}>Tu mensaje</div>
            <Item
              className={styles.inputContainer}
              name="comment"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
              ]}>
              <TextArea className={styles.input} />
            </Item>
            <Button
              className={styles.sendButton}
              htmlType="submit"
              block
              loading={loading}>
              Enviar
            </Button>
          </Form>
        </div>
      </div>
    )
  }

  const renderBack = () => {
    return (
      <button className={styles.back} onClick={() => navigate('/inicio')}>
        <ArrowLeftOutlined /> Volver
      </button>
    )
  }

  const renderHeader = () => {
    return (
      <div className={styles.header}>
        {window?.innerWidth > 500 ? <WhiteLogo /> : <span />}
        <div className={styles.buttons}>
          <Button
            className={styles.signIn}
            onClick={() => navigate('/inicio-sesion/')}>
            Iniciar sesión
          </Button>{' '}
          <Button
            className={styles.signUp}
            onClick={() => navigate('/app/registro')}>
            Regístrate
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderHeader()}
      {renderBack()}
      {renderCenter()}
      <Footer />
    </div>
  )
}

export default Demo

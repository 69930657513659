import React, { useLayoutEffect, useState } from 'react'
import { Button } from 'antd'
import { navigate } from 'gatsby'

import { WhiteLogo } from '../../../assets/logo'

import styles from './styles.module.css'

const HomeFooter = (): React.ReactElement => {
  const renderLogo = () => {
    return (
      <div className={styles.logo}>
        <WhiteLogo className={styles.logoFooter} />
      </div>
    )
  }

  const renderText = () => {
    return (
      <div className={styles.request}>
        <div className={styles.text}>
          ¡Obtén la mejor experiencia! Solicita aumento de cupo.
        </div>
        <div className={styles.button}>
          <Button
            className={styles.requestButton}
            onClick={() => {
              navigate('/app/ayuda/crear-ticket')
            }}>
            Solicitar
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {renderText()}
        {renderLogo()}
      </div>
    </div>
  )
}

export default HomeFooter

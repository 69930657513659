import React, { FC } from 'react'
import { Col, Row } from 'antd'

import styles from './styles.module.css'

// TODO: Add onClick functionality depending on option selected.

const OtherPaymentOptions: FC = () => (
  <Row align="middle" justify="center">
    <Col className={styles.centered} span={16}>
      <b style={{ fontSize: 20 }}>¡Paga tu viaje con débito o crédito! </b>
      <br />
      Presiona el siguiente botón y serás direccionado al sistema de pagos web
      con Transbank.
    </Col>
  </Row>
)

export default OtherPaymentOptions

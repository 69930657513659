import React, { FC } from 'react'
import { Col, Row, Timeline, Typography } from 'antd'

import { BuyStatus } from '../../../helpers/enums'
import { numberWithSeparator } from '../../../helpers/number.helper'
import { formatDateAndTime } from '../../../helpers/travel-page/compute.helper'
import { PaymentType } from '../../../types/enums'
import {
  GoneReturnTravel,
  Seat,
} from '../../../types/page-types/travel-details-page.types'
import { PurchasesTableProps } from '../types/props'

import styles from './styles.module.css'

const { Text } = Typography
const { Item } = Timeline

export const getMyTravelPrice = (data: Seat[], status: string): number =>
  data.reduce((acc, { price, buyStatus }) => {
    if (buyStatus === status) acc += price

    return acc
  }, 0)

const renderTravel = (record: GoneReturnTravel) => (
  <Timeline className={styles.timeLine}>
    <Item color="gray">
      <Text className={styles.text}>{record?.gone?.departureCity}</Text>
    </Item>
    <Item color="gray">
      <Text className={styles.text}>{record?.gone?.destinationCity}</Text>
    </Item>
  </Timeline>
)

const renderNumTransaction = (record: GoneReturnTravel) => (
  <div>
    <div className={styles.text}>Núm transacción</div>
    <div className={styles.boldText}>{record?.gone?.transaction}</div>
  </div>
)

const renderDateTravel = (record: GoneReturnTravel) => (
  <div>
    <div className={styles.text}>
      {`ida: ${formatDateAndTime(
        record?.gone?.departsAt as Date,
        record?.gone?.departsTime as number,
      )}`}
    </div>
    <div className={styles.text}>
      {record?.return
        ? `vuelta: ${formatDateAndTime(
            record.return.departsAt,
            record.return.departsTime,
          )}`
        : ''}
    </div>
  </div>
)

const renderCurrentAccountTravel = (record: GoneReturnTravel) => {
  if (record?.gone?.paymentType === PaymentType.CREDIT)
    return (
      <>
        <div className={styles.text}>
          {`CC. ${record?.gone?.currentAccount?.sapNumber}`}
        </div>
        <div
          className={
            styles.boldText
          }>{`CeCo. ${record?.gone?.costCenter?.name}`}</div>
      </>
    )
  else
    return (
      <div className={styles.containerText}>
        <Text className={styles.text}>Transbank</Text>
      </div>
    )
}

const renderNumberPassengers = (
  record: GoneReturnTravel,
  showDevolutions: boolean,
) => {
  const goneSeats = record?.gone?.seats ?? []
  const validGone = goneSeats.filter(seat => seat.buyStatus === BuyStatus.VALID)
  const returnSeats = record?.return?.seats ?? []
  const validReturn = returnSeats.filter(
    seat => seat.buyStatus === BuyStatus.VALID,
  )
  const returned =
    (record?.gone?.seats.length ?? 0) -
    validGone.length +
    (record?.return?.seats.length ?? 0) -
    validReturn.length

  return {
    tickets: (
      <Text className={styles.text}>{`${
        showDevolutions
          ? `${returned} devuelto(s) de ${
              goneSeats.length + returnSeats.length
            }`
          : `${validGone.length + validReturn.length} boleto(s)`
      }`}</Text>
    ),
    amount: (
      <Text strong className={styles.boldText}>
        {`$${numberWithSeparator(
          getMyTravelPrice(
            [...goneSeats, ...returnSeats],
            showDevolutions ? BuyStatus.CANCELED : BuyStatus.VALID,
          ),
        )}`}
      </Text>
    ),
  }
}

const MobileTravelTable: FC<PurchasesTableProps> = ({
  travels,
  showDevolutions,
}) => {
  return (
    <Row>
      <Col span={24} className={styles.mobileContainer}>
        {travels.map((reg, index) => (
          <Row
            key={index}
            className={index % 2 === 0 ? '' : styles.backgroundGray}>
            <Col span={24} style={{ padding: '0 10px' }}>
              <Row
                justify="space-between"
                align="middle"
                className={styles.paddingY1}>
                <Col span={12}>{renderNumTransaction(reg)}</Col>
                <Col span={12}>{renderCurrentAccountTravel(reg)}</Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                className={styles.paddingY1}>
                <Col span={11} className={styles.rightDivider}>
                  {renderTravel(reg)}
                </Col>
                <Col span={12}>{renderDateTravel(reg)}</Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                className={`${styles.topDivider} ${styles.paddingY1}`}>
                <Col>{renderNumberPassengers(reg, showDevolutions).amount}</Col>
                <Col>
                  {renderNumberPassengers(reg, showDevolutions).tickets}
                </Col>
              </Row>
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  )
}

export default MobileTravelTable

import React, { FC, useContext, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Tooltip } from 'antd'

import {
  getTravelResume,
  TravelPriceInfo,
} from '../../../../helpers/travel-page/dates.helper'
import MainDrawer from '../../Common/MainDrawer'
import { TravelPageContext } from '../../Provider'
import { Quota, Seat } from '../../types'

import styles from './styles.module.css'

const MobileAssignPassengers: FC = () => {
  const {
    hasReturn,
    drawerRender,
    search: { numberOfSeats },
    transaction,
    quotas,
    currentQuotaSelected,
    setCurrentQuotaSelected,
    setDrawerRender,
    setQuotas,
  } = useContext(TravelPageContext)
  const [drawerStep, setDrawerStep] = useState(0)
  const [resume, setResume] = useState<TravelPriceInfo>({} as TravelPriceInfo)

  const handleVisible = (value: boolean) => setDrawerRender(value)

  const handleDrawerButton = (value: number) => setDrawerStep(value)

  const setAssignSeat = (quota: Quota) => setCurrentQuotaSelected(quota)

  const clearSeats = (quotaForClear: Quota) => {
    setQuotas(
      quotas.map(quota => {
        if (quota === quotaForClear) {
          quota.goneSeat = undefined
          quota.returnSeat = undefined
        }
        return quota
      }),
    )
  }

  useEffect(() => {
    if (transaction.goneReservation)
      setResume(
        getTravelResume(
          transaction.goneSeats ?? [],
          transaction.returnSeats ?? [],
        ),
      )
    else
      setResume(
        getTravelResume(
          quotas.filter(q => !!q.goneSeat).map(q => q.goneSeat) as Seat[],
          quotas.filter(q => !!q.returnSeat).map(q => q.returnSeat) as Seat[],
        ),
      )
  }, [transaction, quotas])

  useEffect(() => {
    const selected = quotas.find(quota => !quota.goneSeat)
    if (selected) setAssignSeat(selected)
    else setCurrentQuotaSelected(null)
  }, [])

  useEffect(() => {
    if (currentQuotaSelected) {
      const offset = document.getElementById(
        `quota-${quotas.indexOf(currentQuotaSelected)}`,
      )?.offsetLeft

      document.getElementById('passengersContainer')?.scrollTo({
        top: 0,
        left: offset,
        behavior: 'smooth',
      })
    }
  }, [currentQuotaSelected])

  return (
    <Row justify="center">
      <Col>
        <MainDrawer
          handleVisible={handleVisible}
          handleDrawerButton={handleDrawerButton}
          step={drawerStep}
          visible={drawerRender}
        />
        <div className={styles.passengersContainer} id="passengersContainer">
          {quotas?.map((quota, index) => (
            <div
              key={index}
              id={`quota-${index}`}
              className={`${styles.passengerContainer} ${
                currentQuotaSelected === quota ? styles.quotaSelected : ''
              }`}
              onClick={() => setAssignSeat(quota)}>
              <Col span={24} className={styles.centered}>
                <span>
                  {typeof quota.passenger === 'string'
                    ? `${quota.passenger} #${index + 1}`
                    : `${quota.passenger?.firstName} ${
                        quota.passenger?.fatherLastName ?? ''
                      }`}
                </span>
              </Col>
              <Col span={24} className={styles.centered}>
                <div>
                  {quota.goneSeat || quota.returnSeat ? (
                    <>
                      {`ida: `}
                      {quota.goneSeat ? (
                        <span
                          className={`${styles.numberFont} ${
                            currentQuotaSelected === quota
                              ? styles.whiteFont
                              : ''
                          }`}>
                          {quota.goneSeat.number}
                        </span>
                      ) : (
                        '--'
                      )}
                      {hasReturn ? ' | vuelta: ' : ''}
                      {quota.returnSeat ? (
                        <span
                          className={`${styles.numberFont} ${
                            currentQuotaSelected === quota
                              ? styles.whiteFont
                              : ''
                          }`}>
                          {quota.returnSeat.number}
                        </span>
                      ) : null}
                      {!(
                        transaction?.goneSeats &&
                        transaction?.goneSeats?.length > 0
                      ) ? (
                        <Tooltip title="eliminar selección de asientos">
                          <span
                            className={styles.clearButton}
                            onClick={() => clearSeats(quota)}>
                            X
                          </span>
                        </Tooltip>
                      ) : null}
                    </>
                  ) : currentQuotaSelected === quota ? (
                    `ida -- ${hasReturn ? '| vuelta: --' : ''}`
                  ) : (
                    <span className={styles.seatFont}>
                      {`ida -- ${hasReturn ? '| vuelta: --' : ''}`}
                    </span>
                  )}
                </div>
              </Col>
            </div>
          ))}
        </div>

        <Row justify="center" align="middle">
          <Col>
            <Button
              className={styles.addButton}
              onClick={() => setDrawerRender(true)}>
              Agregar pasajero +
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default MobileAssignPassengers

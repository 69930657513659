import React, { FC } from 'react'
import { Col, Row } from 'antd'

import { CecoModalContentProps } from '../types'

import styles from './styles.module.css'

const CecoModalContent: FC<CecoModalContentProps> = ({ costCenter }) => (
  <Row className={styles.modalContent} justify="center">
    <Col span={24}>
      <Row align="top" justify="center">
        <p>Confirma que deseas eliminar este centro de costo:</p>
      </Row>
    </Col>
    <Col className={styles.summaryBox} span={24}>
      <Row align="top" justify="space-between">
        <p>Centro de costo</p>
        <p className={styles.status}>
          {costCenter.status === 'ACTIVE' && 'Vigente'}
        </p>
      </Row>
      <Row>
        <Col span={24}>
          <p className={styles.cecoName}>{costCenter.name}</p>
          <p className={styles.description}>{costCenter.description}</p>
        </Col>
      </Row>
    </Col>
  </Row>
)

export default CecoModalContent

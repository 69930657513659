import { gql } from '@apollo/client'

export const GET_HELP_REQUEST = gql`
  query getHelpRequestById($id: ID!) @api(name: "users") {
    getHelpRequestById(_id: $id) {
      _id
      body
      isCreditLine
      boletusNumber
      documents {
        path
        userId
        userFullName
      }
      executive {
        _id
        firstName
        lastName
      }
      requestReason {
        _id
        title
      }
      requestReasonOption {
        _id
        title
      }
      requestType {
        _id
        title
      }
      status
      ticketNumber
      transactionNumber
      user {
        _id
        email
        firstName
        lastName
        company {
          name
        }
      }
      messages {
        _id
        message
        user {
          _id
          name
        }
        seen
        createdAt
      }
      company {
        _id
        name
        rut
        dv
        documents {
          taxFolder
          DICOM
        }
      }
      createdAt
    }
  }
`

export const GET_EXECUTIVES = gql`
  query getExecutives @api(name: "users"){
    getExecutives {
      _id
      firstName
      lastName
    }
  }
`

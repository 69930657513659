export const estimatedPassengerSelectContent = [
  {
    value: '1-50',
    content: '1 - 50',
  },
  {
    value: '51-100',
    content: '51 - 100',
  },
  {
    value: '101-150',
    content: '101 - 150',
  },
  {
    value: '151-200',
    content: '151 - 200',
  },
  {
    value: '201-250',
    content: '201 - 250',
  },
  {
    value: '251-x',
    content: '> 250',
  },
]

export const estimatedAmountSelectContent = [
  {
    value: '0-499999',
    content: '$0 - $499.999',
  },
  {
    value: '500000-999999',
    content: '$500.000 - $999.999',
  },
  {
    value: '1000000-1999999',
    content: '$1.000.000 - $1.999.999',
  },
  {
    value: '2000000-4999999',
    content: '$2.000.000 - $4.999.999',
  },
  {
    value: '5000000-9999999',
    content: '$5.000.000 - $9.999.999',
  },
  {
    value: '10000000-x',
    content: '> $10.000.000',
  },
]

export const businessAreaSelectContent = [
  {
    value: 'Minería',
    content: 'Minería',
  },
  {
    value: 'Proveedores de Minería',
    content: 'Proveedores de Minería',
  },
  {
    value: 'Construcción',
    content: 'Construcción',
  },
  {
    value: 'Ingeniería',
    content: 'Ingeniería',
  },
  {
    value: 'Manufactureras y Distribuidores',
    content: 'Manufactureras y Distribuidores',
  },
  {
    value: 'Explotación Recursos Naturales (No minería)',
    content: 'Explotación Recursos Naturales (No minería)',
  },
  {
    value: 'Químicos y Plásticos',
    content: 'Químicos y Plásticos',
  },
  {
    value: 'Instituciones Públicas, Educación y Salud',
    content: 'Instituciones Públicas, Educación y Salud',
  },
  {
    value: 'Otros',
    content: 'Otros',
  },
]

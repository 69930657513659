import React, { useEffect, useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { Button, Col, Divider, Form, Input, message, Row } from 'antd'
import { navigate } from 'gatsby'
import { ValidateErrorEntity } from 'rc-field-form/lib/interface'

import SEO from '../../components/seo'
import { useEventSave } from '../../hooks/analytics.hook'
import { getUser, tokenInfo } from '../../services/auth'
import { ContactExecutivePageFormValues } from '../../types/page-types/contact-executive-page.types'
import HelpCreatedPage from '../help-created-page'

import Cross from './cross.inline.svg'

import styles from './styles.module.css'

const CONTACT_EXECUTIVE = gql`
  mutation ContactExecutive($input: HelpRequestInput!) @api(name: "users") {
    createHelp(input: $input) {
      _id
      ticketNumber
    }
  }
`

const ContactExecutivePage: React.FC<RouteComponentProps> = () => {
  const [createHelp, { error, loading }] = useMutation(CONTACT_EXECUTIVE)
  const [isSuccess, setIsSuccess] = useState(false)
  const [newTicketNumber, setNewTicketNumber] = useState('')
  const [commentIsEmpty, setCommentIsEmpty] = useState(true)

  useEffect(() => {
    const { user } = tokenInfo(getUser() ?? '')
    const params: { [key: string]: string } = {}
    params[user.email as string] = 'vista_contacto_ejecutivo' as string

    useEventSave('vista_contacto_ejecutivo', {
      email_usuario: user.email,
    })

    useEventSave('paginas_por_usuario', params as { [key: string]: string })
  }, [])

  const onFinish: (
    values: ContactExecutivePageFormValues,
  ) => void = async values => {
    console.log('Success:', values)
    try {
      const helpData = await createHelp({
        variables: {
          input: { body: values.body },
        },
      })
      if (helpData) {
        setNewTicketNumber(helpData?.data?.createHelp?.ticketNumber)
        setIsSuccess(true)
      }
    } catch (error) {
      message.error('Hubo un error, por favor intenténtalo más tarde', 5)
    }
  }

  const onFinishFailed: (
    errorInfo: ValidateErrorEntity<ContactExecutivePageFormValues>,
  ) => void = errorInfo => {
    console.log(errorInfo)
  }

  if (error) return <p>error</p>
  return isSuccess ? (
    <HelpCreatedPage ticketNumber={newTicketNumber} />
  ) : (
    <>
      <SEO title="Contacto" />

      <Row justify="end" className={styles.outerContainer}>
        <Col md={12} className={styles.innerRightContainer}>
          <div className={styles.pageTitleContainer}>
            <p className={styles.pageTitleFont}>Contactar a un ejecutivo</p>
          </div>
          <div
            className={styles.crossIcon}
            onClick={() => {
              navigate('/app/ayuda')
            }}>
            <Cross />
          </div>
          <Divider />

          <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <p className={styles.labelFont}>Comentarios</p>
            <Form.Item
              name="body"
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
              ]}>
              <Input.TextArea
                showCount
                maxLength={1000}
                rows={18}
                className={styles.textArea}
                placeholder="Por favor explica brevemente tu situación."
                onChange={({ target }) => setCommentIsEmpty(!target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Divider />
              <Row justify="space-around">
                <Col>
                  <Button
                    className={styles.backButton}
                    onClick={() => {
                      navigate('/app/ayuda')
                    }}>
                    Cancelar
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={commentIsEmpty}
                    className={
                      commentIsEmpty
                        ? styles.continueDisabledButton
                        : styles.continueButton
                    }
                    loading={loading}
                    htmlType="submit">
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default ContactExecutivePage

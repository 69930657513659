import React, { FC } from 'react'
import { Col, Row } from 'antd'

import { MyPassengerHeaderProps } from '../types'

import styles from './styles.module.css'

const MyPassengerHeader: FC<MyPassengerHeaderProps> = ({ quantity }) => (
  <Row align="top" className={styles.passengersHeader} justify="space-between">
    <Col xs={12}>
      <div className={styles.title}>Mis Pasajeros</div>
    </Col>
    <Col xs={12}>
      <div className={styles.passengers}>
        {quantity ? `${quantity} pasajeros registrados` : ''}
      </div>
    </Col>
  </Row>
)

export default MyPassengerHeader

import React, { FC, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Button, Col, Drawer, Form, message, Row } from 'antd'

import { RolesEnum } from '../../../helpers/enums'
import { CREATE_USER_BY_COMPANY } from '../../../queries'
import { SessionContext } from '../../../services/session'
import UserTypeRow from '../Content/UserTypeRow'
import { MyUserPageContext } from '../Provider'
import { CreateUserDrawerProps, CreateUserInput } from '../types'

import BasicInfoRow from './BasicInfoRow'
import PasswordRow from './PasswordRow'

import styles from './styles.module.css'

const CreateUserDrawer: FC<CreateUserDrawerProps> = ({ refetch }) => {
  const { createDrawer, setCreateDrawer } = useContext(MyUserPageContext)
  const { user } = useContext(SessionContext)
  const [form] = Form.useForm()

  const [createCompanyUser, { loading }] = useMutation(CREATE_USER_BY_COMPANY)

  const getRadioValue = (value: 1 | 2) => {
    if (
      user.role === RolesEnum.ADMIN_CCC ||
      user.role === RolesEnum.MULTI_RUT_ADMIN
    )
      return value === 1 ? RolesEnum.ADMIN_CCC : RolesEnum.CCC
    return value === 1 ? RolesEnum.ADMIN_SCC : RolesEnum.SCC
  }

  const createUser = async (value: CreateUserInput) => {
    const {
      areaCode,
      firstName,
      lastName,
      phone,
      rut,
      password,
      repeatedPassword,
      email,
      role: roleOption,
      ...rest
    } = value
    const role = getRadioValue(parseInt(roleOption) as 1 | 2)

    if (password !== repeatedPassword)
      return message.info(
        'Las contraseñas deben coincidir, por favor, verifique.',
      )
    try {
      await createCompanyUser({
        variables: {
          input: {
            ...rest,
            email: email.toLowerCase(),
            rut,
            firstName,
            lastName,
            password,
            phone: `${areaCode} ${phone}`,
            role,
          },
        },
      })
      message.success('Se ha creado usuario exitosamente.')
      form.resetFields()
      await refetch()
      setCreateDrawer(false)
    } catch (err) {
      message.error(`Ha habido un error al crear usuario. ${err.message}`)
    }
  }

  return (
    <Drawer
      bodyStyle={{ padding: '1rem' }}
      footer={
        <Row align="middle" justify="end" gutter={15}>
          <Col xs={{ span: 24, order: 2 }} sm={{ span: 10, order: 2 }}>
            <Button
              className={styles.cancelUpdateButton}
              onClick={() => {
                form.resetFields()
                setCreateDrawer(false)
              }}>
              Cancelar
            </Button>
          </Col>
          <Col xs={{ span: 24, order: 1 }} sm={{ span: 10, order: 2 }}>
            <Button
              className={styles.saveUpdateButton}
              onClick={() =>
                form
                  .validateFields()
                  .then(createUser)
                  .catch(() =>
                    message.info(
                      `Verifique que los campos fueron ingresados correctamente.`,
                    ),
                  )
              }
              loading={loading}>
              Guardar cambios
            </Button>
          </Col>
        </Row>
      }
      onClose={() => {
        form.resetFields()
        setCreateDrawer(false)
      }}
      title={'Agregar usuario'}
      visible={createDrawer}
      width={window?.innerWidth > 900 ? 400 : window?.innerWidth - 10}>
      <Form form={form}>
        <BasicInfoRow />
        <UserTypeRow />
        <PasswordRow />
      </Form>
    </Drawer>
  )
}

export default CreateUserDrawer

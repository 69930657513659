import React from 'react'
import { Form, Select } from 'antd'

import { IdentityPrefixProps } from '../../types/component-types/IdentityPrefix'
const { Item } = Form
const { Option } = Select

const IdentityPrefix: React.FC<IdentityPrefixProps> = ({ setIdRule }) => (
    <Item initialValue={1} name="indentityPrefix" noStyle>
        <Select style={{ width: 75 }} onChange={val => setIdRule(+val)}>
            <Option value={1}>RUT</Option>
            <Option value={2}>PAS</Option>
        </Select>
    </Item>
)

export default IdentityPrefix